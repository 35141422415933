import { camelize } from 'humps'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Box, Button, PopIn, TinyText } from '~design-system'
import { useDegrees } from '~hooks'

import { ConfirmationPopin } from '../components'
import { DegreeForm } from '../forms'
import { FormContainer } from '../styles'

interface Props {
  data: EditInfos
  onClose: () => void
}

const DegreesPopIn = ({ data, onClose }: Props) => {
  const { useAddDegree, useDeleteDegree, useUpdateDegree } = useDegrees()
  const { mutate: updateDegree, isLoading: isLoadingUpdate } = useUpdateDegree()
  const { mutate: deleteDegree, isLoading: isLoadingDelete } = useDeleteDegree()
  const { mutate: addDegree, isLoading: isLoadingAdd } = useAddDegree()
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const degreeData = data?.data as Degree

  // Define Form Methods and DefaultValues
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...degreeData,
      nom_diplome: degreeData?.name,
      nom_etablissement: degreeData?.etablishmentName,
      annee_obtention: degreeData?.yearOfGraduation,
      degree: degreeData?.url,
    },
  })

  // Define Form
  const {
    handleSubmit,
    getValues,
    setError,
    formState: { isDirty, isValid, dirtyFields },
  } = methods

  // Delete Degree
  const onDeleteDegree = () => {
    deleteDegree(degreeData?.id, {
      onSuccess() {
        onClose()
      },
    })
  }

  // Update/Create Experience
  const handleRequest = () => {
    const actions = {
      onSuccess() {
        onClose()
      },
      onError(err: any) {
        const fieldName = camelize(
          Object.keys(err.response.data.data)[0]
        ) as keyof Degree & UploadDocumentProps
        setError(
          fieldName,
          {
            type: 'custom',
            message:
              err.response.data.message ||
              'Oups, une erreur est survenue, veuillez réessayer plus tard...',
          },
          { shouldFocus: true }
        )
      },
    }

    // Only Update DirtyFields
    const updatedData: Partial<UploadDocumentProps> = { id: degreeData?.id }
    dirtyFields &&
      Object.keys(dirtyFields).map(
        (key: any) =>
          (updatedData[key as keyof Partial<UploadDocumentProps>] =
            getValues(key))
      )

    // Update OR Add Degree
    degreeData
      ? updateDegree(updatedData, actions)
      : addDegree(getValues(), actions)
  }

  if (!showConfirmation) {
    return (
      <PopIn open closable onClose={onClose}>
        <FormProvider {...methods}>
          <FormContainer onSubmit={handleSubmit(handleRequest)}>
            <Box di="flex" ai="center" jc="space-between" mb={12}>
              <PopIn.Title textAlign="left">{data.title}</PopIn.Title>
              {data?.data && (
                <Button
                  textColor="danger"
                  option="unfilled"
                  pr={1}
                  pl={1}
                  pt={1}
                  pb={1}
                  size="small"
                  isLoading={isLoadingDelete}
                  disabled={isLoadingAdd || isLoadingUpdate}
                  onClick={() => setShowConfirmation(true)}
                >
                  <TinyText textColor="danger">Supprimer</TinyText>
                </Button>
              )}
            </Box>
            <PopIn.Content>
              <Box w="100%">
                <DegreeForm />
              </Box>
            </PopIn.Content>
            <PopIn.Actions
              actions={[
                <Button
                  key="cancel"
                  variant="secondary"
                  onClick={onClose}
                  disabled={isLoadingAdd || isLoadingDelete || isLoadingUpdate}
                >
                  Annuler
                </Button>,
                <Button
                  key="save"
                  disabled={!isDirty || !isValid || isLoadingDelete}
                  type="submit"
                  isLoading={isLoadingAdd || isLoadingUpdate}
                >
                  Enregistrer
                </Button>,
              ]}
            />
          </FormContainer>
        </FormProvider>
      </PopIn>
    )
  } else {
    return (
      <ConfirmationPopin
        title="Supprimer le diplôme"
        content="Cette action est définitive, tous les éléments associés à ce service seront supprimés..."
        isLoading={isLoadingDelete}
        onCancel={() => setShowConfirmation(false)}
        onValidate={onDeleteDegree}
      />
    )
  }
}

export default DegreesPopIn
