import styled, { css } from 'styled-components'

const KeyNumber = styled('p')`
  ${({ theme }) => css`
    font-family: ${theme.fonts.primary};
    font-size: 3rem;
    font-weight: 700;
    color: ${theme.colors.primary};
  `}
`

export default KeyNumber
