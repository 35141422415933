import { useSearchParams } from 'react-router-dom'

import Pagination from '~components/Pagination'
import { Box, Text } from '~design-system'

import Question from '../Question'
import { Container } from './styles'

type Props = {
  results?: ApiPaginatedResponse<QuestionTeaser[]>
}

const QuestionsResults = ({ results }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const resultsResume = 'résultat$ trouvé$'.replaceAll(
    '$',
    (results?.meta.total || 0) > 1 ? 's' : ''
  )

  const clickHandler = (key: string, value: string) => {
    searchParams.set(key, value)
    setSearchParams(searchParams)
  }

  if (results?.data.length === 0) {
    return (
      <Container>
        <img src="/images/notfound.svg" alt="" />
        <Text weight="medium">Aucun résultat trouvé</Text>
        <Text>
          Veuillez vérifier l’orthographe ou essayez d’utiliser d’autres mots
          clés.
        </Text>
      </Container>
    )
  }

  return (
    <div>
      <Box mb={4} mt={8}>
        <Text textColor="greyDarker">
          {results?.meta.total} {resultsResume}
        </Text>
      </Box>

      {results?.data.map((question) => (
        <Question question={question} />
      ))}

      {!!results?.meta.lastPage && (
        <Pagination
          mt={8}
          currentPage={results?.meta.currentPage}
          totalPage={results?.meta.lastPage}
          onChange={(page: number) => clickHandler('page', page.toString())}
        />
      )}
    </div>
  )
}

export default QuestionsResults
