import { generatePath } from 'react-router'

import { env, routes } from '~config'
import { isYoung } from '~helpers'

export const getNavMenu = ({
  matchings,
  user,
}: {
  matchings?: Matchings[]
  user?: User
}) => {
  if (!user) return

  const baseItems = [
    {
      link: routes.authenticated.home,
      icon: 'house',
      label: 'Accueil',
    },
    {
      link: routes.authenticated.events,
      icon: 'calendar',
      label: 'Évènements',
    },
    {
      link: routes.authenticated.jobs,
      icon: 'briefcase',
      label: `Offres d'emploi`,
    },
    {
      link: routes.authenticated.services,
      icon: 'book',
      label: `Services partenaires`,
    },
    {
      link: routes.authenticated.faq,
      icon: 'question',
      label: `FAQ`,
    },
  ]

  const nqtClubItem = {
    link: env.LEVELUP_URL,
    icon: 'news',
    label: 'Club NQT',
  }

  const itemsWithNQTClub = [
    ...baseItems.slice(0, 3),
    nqtClubItem,
    ...baseItems.slice(3, baseItems.length),
  ]

  const getMenuItems = () => {
    if (!isYoung(user)) return itemsWithNQTClub
    else if (user.isAlumni) return itemsWithNQTClub
    return baseItems
  }

  const menuItems = getMenuItems()

  if (isYoung(user)) {
    const matching = matchings?.find((match) => !match.endedSponsorship)
    if (matching && matching.sponsor) {
      menuItems.push({
        link: generatePath(routes.authenticated.sponsor, {
          id: matching.sponsor.id.toString(),
        }),
        icon: 'friendship',
        label: 'Fiche parrain',
      })
    }
  }
  return menuItems
}
