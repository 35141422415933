import styled, { css } from 'styled-components'

import { spacing } from '~design-system/helpers'

interface Props {
  withBackground: boolean
}

const EditIconContainer = styled('button')<Props>`
  ${({ theme, withBackground }) => css`
    cursor: pointer;
    ${spacing};
    border-radius: 100%;
    background-color: ${withBackground
      ? theme.colors.blueLight
      : 'transparent'};
    padding: ${withBackground ? theme.spaces[4] : 0};
    width: ${withBackground ? '40px' : 'initial'};
    height: ${withBackground ? '40px' : 'initial'};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

export default EditIconContainer
