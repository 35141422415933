import { camelize } from 'humps'
import { useFormContext } from 'react-hook-form'

import { InputDate } from '~components/Chat/components'
import { getParseBodyParams, getType } from '~components/Chat/helpers'
import { getDateFormattedForAPI } from '~helpers'

type Props = {
  dialog: Stepper
  dialogType: ApiGetStepResponse['data'][0]['type']
  index: number
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const Date = ({
  dialog,
  dialogType,
  index,
  setAnswers,
  handleAnswer,
}: Props) => {
  const { getValues } = useFormContext()

  const answer = getType(dialog, 'date')[0]
  const parsedBodyParam = getParseBodyParams(answer)
  const id = camelize(Object.keys(parsedBodyParam)[0])

  const handleTextInputValue = (answer: Answer, id: string) => {
    setAnswers(getValues(id))
    handleAnswer(answer.action, {
      [`${id}`]: getDateFormattedForAPI(getValues(id), 'dd/MM/yyyy'),
    })
  }

  return (
    <InputDate
      key={dialogType + index}
      id={id}
      placeholder={answer.label}
      onSubmit={() => handleTextInputValue(answer, id)}
    />
  )
}

export default Date
