import styled, { css } from 'styled-components'

type Props = {
  isAnswer: boolean
}

const MessageText = styled('p')<Props>`
  ${({ theme, isAnswer }) => css`
    color: ${isAnswer ? 'white' : 'black'};
    white-space: ${isAnswer ? 'normal' : 'pre-line'};
    word-break: normal;
    align-items: center;
    display: flex;
    justify-content: space-between;
  `}
`

export default MessageText
