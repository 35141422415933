import { ButtonHTMLAttributes, ComponentProps, ReactElement } from 'react'
import styled, { Theme, css } from 'styled-components'

import Loader from '../Loader'
import {
  DisplayProps,
  LayoutProps,
  SpacingProps,
  display,
  layout,
  spacing,
} from '../helpers'

type Props = SpacingProps & ComponentProps<typeof Container>

type ContentProps = {
  isLoading?: boolean
  rightComponent?: ReactElement
  forceOneLine?: boolean
}

type ButtonProps = {
  fullWidth?: boolean
  isLoading?: boolean
  option?: 'dashed' | 'filled' | 'outlined' | 'unfilled'
  size?: keyof Theme['components']['button']['sizes']
  square?: boolean
  forceOneLine?: boolean
  variant?: 'primary' | 'secondary'
} & ButtonHTMLAttributes<HTMLButtonElement> &
  DisplayProps &
  LayoutProps

function getBackgroundColor(
  variant: 'primary' | 'secondary',
  option: 'dashed' | 'filled' | 'outlined' | 'unfilled',
  theme: Theme
) {
  if (!(option === 'outlined') && !(option === 'dashed')) {
    return theme.colors[variant]
  }
  if (variant === 'primary') {
    return 'white'
  }

  return theme.colors[variant]
}

function getBorder(
  option: 'dashed' | 'filled' | 'outlined' | 'unfilled',
  theme: Theme
) {
  if (option === 'dashed') return `1px dashed ${theme.colors.primary}`
  if (option === 'outlined') return `1px solid ${theme.colors.primary}`
  return 'none'
}

function getColor(
  variant: 'primary' | 'secondary',
  option: 'dashed' | 'filled' | 'outlined' | 'unfilled',
  theme: Theme
) {
  if (variant === 'primary') {
    if (option === 'dashed' || option === 'outlined')
      return theme.colors[variant]
    return theme.colors.white
  }

  return theme.colors.primary
}

const Container = styled('button').attrs((props: ButtonProps) => ({
  isLoading: props.isLoading,
  size: props.size || 'big',
  type: props.type || 'button',
  variant: props.variant || 'primary',
  option: props.option || 'filled',
}))<ButtonProps>`
  ${({
    disabled,
    fullWidth,
    isLoading,
    option,
    size,
    square,
    theme,
    variant,
  }) => css`
    align-items: center;
    background-color: ${option === 'unfilled'
      ? 'transparent'
      : getBackgroundColor(variant, option, theme)};
    border-radius: ${theme.radii.lg};
    border: ${option === 'unfilled' ? 'none' : getBorder(option, theme)};
    color: ${option === 'unfilled'
      ? theme.colors.primary
      : getColor(variant, option, theme)};
    cursor: pointer;
    display: inline-flex;
    font-family: ${theme.fonts.primary};
    font-size: ${theme.components.button.fontSize};
    font-weight: ${theme.components.button.weight};
    justify-content: center;
    height: ${theme.components.button.sizes[size].height};
    line-height: 1;
    opacity: ${(disabled || isLoading) && 0.5};
    padding: ${!square && theme.components.button.padding};
    position: relative;
    text-decoration: none;
    width: ${fullWidth && '100%'};
    width: ${square && theme.components.button.sizes[size].height};
    ${spacing}

    &:hover {
      cursor: ${disabled || isLoading ? 'not-allowed' : 'pointer'};
    }

    a {
      color: inherit;
    }
  `}
  ${display}
  ${layout}
`
function getSpacing(rightComponent: ReactElement | undefined) {
  if (rightComponent) {
    return 'space-between'
  }
  return 'center'
}

const Content = styled('div')<ContentProps>`
  ${({ isLoading, rightComponent, forceOneLine }) => css`
    display: inline-flex;
    flex-grow: 1;
    justify-content: ${getSpacing(rightComponent)};
    opacity: ${isLoading && 0};
    overflow: hidden;
    white-space: ${forceOneLine && 'nowrap'};
  `}
`

const LoaderContainer = styled('div')`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  transition: opacity 0.3s;
  width: 100%;
`

const Button = ({ children, forceOneLine = false, ...rest }: Props) => {
  const { color, outlined, isLoading, rightComponent } = rest
  return (
    <Container {...rest}>
      <Content
        isLoading={isLoading}
        rightComponent={rightComponent}
        forceOneLine={forceOneLine}
      >
        {children}
        {rightComponent && rightComponent}
      </Content>
      {isLoading && (
        <LoaderContainer>
          <Loader color={outlined ? color : 'white'} size="small" />
        </LoaderContainer>
      )}
    </Container>
  )
}

export default Button
