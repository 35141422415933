import { FormProvider, useForm } from 'react-hook-form'

import { useAuth } from '~context/AuthContext'
import { Box, Panel, SmallText, Switch, Text } from '~design-system'
import { useUser } from '~hooks'

interface Props {
  isEditable?: boolean
  label: string
  name: 'odd_jobs' | 'volonteering' | 'hasDrivingLicenceB'
  value: boolean
  smallLabel?: boolean
}

const BooleanCard = ({
  isEditable = false,
  label,
  name,
  smallLabel = false,
  value,
}: Props) => {
  const { useUpdateUser } = useUser()
  const { mutate: updateUser } = useUpdateUser()
  const {
    authState: { user },
  } = useAuth()

  // Define Form
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      odd_jobs: user?.isDoingOddJobs,
      volonteering: user?.isVolonteering,
      hasDrivingLicenceB: user?.hasDrivingLicenceB,
    },
  })
  const { register } = methods

  // Update User
  const handleRequest = (value: boolean) => {
    updateUser(
      { [name]: value },
      {
        onError(error: any) {
          console.error(error)
        },
      }
    )
  }

  return (
    <Panel pb={3} pt={3}>
      <Box di="f" jc="sb" ai="ce">
        {smallLabel ? (
          <SmallText>{label}</SmallText>
        ) : (
          <Text weight="medium">{label}</Text>
        )}

        {isEditable ? (
          <FormProvider {...methods}>
            <Switch
              id={name}
              label={label}
              {...register(name, {
                onChange: (e) => handleRequest(e?.target.checked),
              })}
            />
          </FormProvider>
        ) : (
          <Text textColor="greyDarkest" weight="regular">
            {value ? 'Oui' : 'Non'}
          </Text>
        )}
      </Box>
    </Panel>
  )
}

export default BooleanCard
