import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';



import CardInfo from '~components/CardInformation';
import { routes } from '~config';
import { Icon, Text } from '~design-system'
import { canAccessPrematching } from '~helpers'
import { ProgressBarTest } from '~pages/Authenticated/Home/components/ProgressBar';
import { ArrowOrCheckContainer, Card, GreenIconsContainer, IconAndTextContainer, IconsContainer, LeftCardContainer, RightCardContainer, TodoBeforeContent, TodoBeforeHeader, TodoBeforeItems } from '~pages/Authenticated/Home/styles/WelcomeCard';


type Props = {
  user: User
}

type LogoItemsProps = {
  iconName: string
}

type ArrowOrCheckIconProps = {
  dataToCheck: UserDocumentContent[] | Degree[] | undefined | string | null
  todoName: string
}

type TodoItemsProps = {
  dataToCheck: UserDocumentContent[] | Degree[] | undefined | string | null
  todoName: string
  todoText: string
  iconName: string
  onClick: () => void
}

const LogoItems = ({ iconName }: LogoItemsProps) => (
  <IconsContainer>
    <Icon size="small" name={iconName} color="blueDark" />
  </IconsContainer>
)

const ArrowOrCheckIcon = ({ dataToCheck, todoName }: ArrowOrCheckIconProps) => {
  if (todoName === 'degrees' || todoName === 'cv') {
    return (
      <ArrowOrCheckContainer>
        {!isEmpty(dataToCheck) ? (
          <GreenIconsContainer>
            <Icon size="small" name="check" color="appleGreen" />
          </GreenIconsContainer>
        ) : (
          <Icon name="arrow-right" color="blueDark" />
        )}
      </ArrowOrCheckContainer>
    )
  }
  return (
    <ArrowOrCheckContainer>
      {dataToCheck ? (
        <GreenIconsContainer>
          <Icon size="small" name="check" color="appleGreen" />
        </GreenIconsContainer>
      ) : (
        <Icon size="medium" name="arrow-right" color="blueDark" />
      )}
    </ArrowOrCheckContainer>
  )
}

const TodoItems = ({
  todoText,
  dataToCheck,
  todoName,
  iconName,
  onClick,
}: TodoItemsProps) => (
  <TodoBeforeItems onClick={onClick}>
    <IconAndTextContainer>
      <LogoItems iconName={iconName} />
      {todoText}
    </IconAndTextContainer>
    <ArrowOrCheckIcon dataToCheck={dataToCheck} todoName={todoName} />
  </TodoBeforeItems>
)

const WelcomeCard = ({ user }: Props) => {
  const navigate = useNavigate()
  const [realisedTasks, setRealisedTasks] = useState(0)
  const hasSeenWelcomeTour = localStorage.getItem('hasSeenWelcomeTour')
  const linkedinUrl = user.linkedinUrl
  const degrees = user.degrees
  const cv = user.resumes

  const informationsToCheck = [degrees, linkedinUrl, hasSeenWelcomeTour, cv]

  useEffect(() => {
    informationsToCheck.forEach((information) => {
      if (!isEmpty(information)) {
        handleRealisedTasks()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRealisedTasks = () => setRealisedTasks((value) => value + 1)

  const resetWelcomeTour = () => {
    localStorage.removeItem('hasSeenWelcomeTour')
    return navigate({
      pathname: routes.authenticated.home,
    })
  }

  return (
    <Card>
      <LeftCardContainer>
        <img
          style={{ height: 80, width: 80 }}
          alt="picto météo"
          src="/images/weather.svg"
        />
        <h1>Bienvenue {user.firstname} ! </h1>
        {canAccessPrematching(user) && (
          <CardInfo
            key="find-sponsor"
            variant="blue"
            route={routes.authenticated.preMatching}
            content="Je souhaite trouver mon parrain idéal"
            actionMessage="Choisir mon parrain"
            illustration={
              <img alt="Trouver son parrain" src="/images/complete.svg" />
            }
          />
        )}
      </LeftCardContainer>
      <RightCardContainer>
        <TodoBeforeHeader>
          <Text textColor="yellow" textTransform="uppercase" weight={'medium'}>
            À faire avant de rencontrer ton mentor
          </Text>
          <Text textAlign={'right'} textColor={'greyDarkest'}>
            {realisedTasks}/4
          </Text>
        </TodoBeforeHeader>
        <ProgressBarTest progress={realisedTasks} max={4} />
        <TodoBeforeContent>
          <TodoItems
            todoText="Réaliser le Welcome Tour"
            dataToCheck={hasSeenWelcomeTour}
            todoName="welcomeTour"
            iconName="video"
            onClick={() => resetWelcomeTour()}
          />
          <TodoItems
            todoText="Compléter mon curriculum vitae"
            dataToCheck={user.resumes}
            todoName="cv"
            iconName="faq"
            onClick={() =>
              navigate({
                pathname: routes.authenticated.documents,
              })
            }
          />
          <TodoItems
            todoText="Renseigner mon profil"
            dataToCheck={user.linkedinUrl}
            todoName="linkedin"
            iconName="linkedin-square"
            onClick={() =>
              navigate({
                pathname: routes.authenticated.profile,
              })
            }
          />
          <TodoItems
            todoText="Ajouter mes diplômes"
            dataToCheck={user.degrees}
            todoName="degrees"
            iconName="degree"
            onClick={() =>
              navigate({
                pathname: routes.authenticated.documents,
              })
            }
          />
        </TodoBeforeContent>
      </RightCardContainer>
    </Card>
  )
}

export default WelcomeCard