import { isNumber } from 'lodash'
import styled, { Theme, css } from 'styled-components'

import { DisplayProps, SpacingProps, display, spacing } from '../../helpers'

export type GridProps = {
  ai?: DisplayProps['ai']
  columnGap?: keyof Theme['spaces']
  gridAutoRows?: string
  rowGap?: keyof Theme['spaces']
  templateColums?: string
  templateRows?: string
  columnBreakpoint?: number
} & SpacingProps &
  DisplayProps

const Grid = styled('div')<GridProps>`
  ${({
    ai,
    columnGap,
    gridAutoRows,
    rowGap,
    templateColums,
    templateRows,
    theme,
    columnBreakpoint = 500,
  }) => css`
    display: grid;
    grid-column-gap: ${isNumber(columnGap)
      ? theme.spaces[columnGap as keyof Theme['spaces']]
      : theme.components.grid.gap};
    grid-row-gap: ${isNumber(rowGap)
      ? theme.spaces[rowGap as keyof Theme['spaces']]
      : theme.components.grid.gap};
    grid-template-columns: ${templateColums ?? 'repeat(12, 1fr)'};
    ${templateRows
      ? css`
          grid-template-rows: ${templateRows};
        `
      : ``}
    ${gridAutoRows
      ? css`
          grid-auto-rows: ${gridAutoRows};
        `
      : ``}
    ${ai
      ? css`
          align-items: ${ai};
        `
      : ``}
    ${spacing};

    @media (max-width: ${columnBreakpoint}px) {
      grid-template-columns: none;
    }
  `}
  ${display};
`

export default Grid
