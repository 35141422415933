import styled, { css } from 'styled-components'

const Default = styled('main')`
  ${({ theme }) => css`
    background-color: ${theme.colors.greyLightest};
    display: flex;
    min-height: 100vh;
    justify-content: center;
    padding: ${theme.spaces[8]};
  `}
`

export default Default
