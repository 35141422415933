import styled from 'styled-components'

import { SpacingProps, spacing } from '~design-system/helpers'

const AvatarContainer = styled('div').attrs(
  (props: SpacingProps) => props
)<SpacingProps>`
  position: relative;
  ${spacing};
`

export default AvatarContainer
