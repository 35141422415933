import styled from 'styled-components'

const SwitchInput = styled('input')`
  -webkit-clip-path: inset(50%);
  border: 0;
  clip-path: inset(50%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  order: 1;
`

export default SwitchInput
