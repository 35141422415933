import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const LinkText = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.components.text.fontSize};
    font-weight: ${theme.components.text.weights.medium};
  `}
`
