import { Box, Button, Text } from '~design-system'

const ErrorBoundary = () => {
  return (
    <Box w="100vw" h="100vh" di="f" ai="center" jc="center">
      <Box di="f" fd="column" ai="center">
        <Text>Une erreur est survenue.</Text>
        <Box mt={4}>
          <Button onClick={() => window.location.reload()}>Réessayer</Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ErrorBoundary
