import styled, { css } from 'styled-components'

const DropzoneContainer = styled('div')`
  ${({ theme }) => css`
    align-items: center;
    background-color: #f7f8ff;
    border-radius: ${theme.radii['lg']};
    border: 1px dashed ${theme.colors.primary};
    display: flex;
    justify-content: center;
    margin-top: ${theme.spaces[2]};
    position: relative;
  `}
`

export default DropzoneContainer
