import { Box, Panel, Text } from '~design-system'
import { LayoutProps, SpacingProps } from '~design-system/helpers'

import { Circle, KeyNumber, Trophy } from './styles'

export type Props = {
  keyNumber: number
  title: string
} & LayoutProps &
  SpacingProps

const CardKeyNumber = ({ keyNumber, title, ...props }: Props) => (
  <Panel
    pb="0"
    pt="0"
    pr="0"
    pl="0"
    di="f"
    ai="ce"
    jc="sb"
    fd="r"
    pos="relative"
    overflow="hidden"
    {...props}
  >
    <Box di="f" ai="ce" fd="r" g="10px" pt={4} pb={4} pl={4} pr={4}>
      <KeyNumber>{keyNumber}</KeyNumber>
      <Text textColor="primary" weight="medium" lineHeight={1}>
        {title}
      </Text>
    </Box>
    <Trophy
      src="/images/trophy.svg"
      alt="image-trophée"
      width={100}
      height={100}
    />
    <Circle keyNumber={keyNumber} />
  </Panel>
)

export default CardKeyNumber
