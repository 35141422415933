import { PopIn } from '~design-system'

import {
  Diploma,
  ProfessionnalExperiences,
  ProofOfAddress,
  ProofOfIdentity,
} from './components'
import CV from './components/CV'

export type UploadPopinProps = {
  onClose: () => void
  option: {
    [key: string]: string | boolean
  }
  onSubmit: (answer: string[]) => void
}

const UploadPopin = (props: UploadPopinProps) => {
  const renderUpload = () => {
    switch (true) {
      case props.option.hasOwnProperty('proof_of_identity'):
        return <ProofOfIdentity {...props} />
      case props.option.hasOwnProperty('nom_diplome'):
        return <Diploma {...props} />
      case props.option.hasOwnProperty('proof_of_address'):
        return <ProofOfAddress {...props} />
      case props.option.hasOwnProperty('resume'):
        return <CV {...props} />
      case props.option.hasOwnProperty('experiences'):
        return <ProfessionnalExperiences {...props} />

      default:
        return null
    }
  }

  return (
    <PopIn backdropBgColor="white" open={true}>
      {renderUpload()}
    </PopIn>
  )
}

export default UploadPopin
