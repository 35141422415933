import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'

import env from '~config/env'

const api = axios.create({
  baseURL: env.API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
})

const redirectToLogin = () => {
  window.location.href = '/expired'
  localStorage.removeItem('token')
}

// Axios middleware to convert all api responses to camelCase
api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.config.url === '/api/v1/enums') return response
    if (
      response.data &&
      response.headers['content-type'] === 'application/json'
    ) {
      response.data = camelizeKeys(response.data)
    }

    return response
  },
  function (error: AxiosError) {
    const { response } = error
    const { status } = response || {}

    if (status === 401 && localStorage.getItem('token')) {
      redirectToLogin()
    } else {
      return Promise.reject(error)
    }
  }
)

// Axios middleware to convert all api requests to snake_case
api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const newConfig = { ...config }
    const token = localStorage.getItem('token')

    if (token) {
      newConfig.headers = {
        ...newConfig.headers,
        Authorization: `Bearer ${token}`,
      }
    }

    if (config.params) {
      newConfig.params = decamelizeKeys(config.params)
    }

    if (
      config.data &&
      config.headers &&
      config.headers['Content-Type'] !== 'multipart/form-data'
    ) {
      newConfig.data = decamelizeKeys(config.data)
    }

    return newConfig
  },
  (error) => Promise.reject(error)
)

export default api
