import styled from 'styled-components'

const WelcomeTourContainer = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: rgba(51, 102, 205, 0.66);
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  cursor: pointer;
`
const ClosingContainer = styled('div')`
  display: flex;
  flex-direction: row;
`

const ClosingText = styled('p')`
  color: white;
  font-weight: bold;
  margin-right: 10px;
`

export { WelcomeTourContainer, ClosingContainer, ClosingText }
