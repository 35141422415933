import styled from 'styled-components'

import { theme } from '~config'

const EventImage = styled('img')`
  border-radius: 8px;
  margin-bottom: ${theme.spaces[8]};
  width: 100%;
  object-fit: cover;
  height: 100%;
`

export default EventImage
