import { UserService } from '~api'
import { EventWidget, JobWidget } from '~components'
import { useToast } from '~context/ToastContext'
import { Box, Button } from '~design-system'

import CompanyWidget from './CompanyWidget'
import KeyNumbersWidget from './KeyNumbersWidget'
import ServicesWidget from './ServicesWidget'

type Props = {
  user: User
}

const DashboardReferent = ({ user }: Props) => {
  const { addToast } = useToast()

  const downloadRse = () => {
    UserService.apiReferentGetRseReport().then((res) => {
      addToast({
        title: res.message,
        type: res.status === 'success' ? 'success' : 'danger',
      })
    })
  }
  return (
    <>
      <Box jc="flex-end" di="f">
        <Button onClick={downloadRse}> Télécharger votre rapport RSE</Button>
      </Box>
      <KeyNumbersWidget />
      <CompanyWidget companyID={user.company?.id} />
      <EventWidget />
      <JobWidget />
      <ServicesWidget />
    </>
  )
}

export default DashboardReferent
