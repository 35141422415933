import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Label, Loader, Select, TinyText } from '~design-system'
import { useData } from '~hooks'

const DepartmentSelector = ({ withLabel = true }: { withLabel?: boolean }) => {
  const { control } = useFormContext()
  const { useGetDepartments } = useData()
  const { data, isLoading } = useGetDepartments()

  const list = useMemo(
    () => data?.map((dep) => ({ value: dep.code, label: dep.name })),
    [data]
  )

  return isLoading ? (
    <Loader />
  ) : (
    <Box w="100%">
      <Controller
        name="registrationDepartment"
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <Box mb={4}>
              {withLabel && (
                <TinyText
                  as={Label}
                  textColor="greyDarkest"
                  uppercase
                  mb={2}
                  ml={2}
                  htmlFor="dep-select"
                >
                  Choisissez un département
                </TinyText>
              )}
              <Select
                inputId="dep-select"
                placeholder="Sélectionner un département"
                isSearchable={false}
                maxMenuHeight={200}
                options={list}
                onChange={(e) => {
                  onChange(e?.value)
                }}
              />
            </Box>
          )
        }}
      />
    </Box>
  )
}

export default DepartmentSelector
