import styled, { css } from 'styled-components'

const CardsPager = styled('div')`
  ${({ theme }) => css`
    margin-bottom: ${theme.spaces[4]};
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      cursor: pointer;
    }

    & > p {
      color: ${theme.colors.primary};

      span {
        font-size: ${theme.pxToRem(12)};
      }
    }
  `}
`

export default CardsPager
