export const levelOptions = [
  { label: 'Bilingue', value: 'bilingual' },
  {
    label: 'Courant',
    value: 'fluent',
  },
  {
    label: 'Intermédiaire',
    value: 'intermediate',
  },
  {
    label: 'Débutant',
    value: 'beginner',
  },
]

export const getLanguageLevelLabel = (
  key: 'bilingual' | 'fluent' | 'intermediate' | 'beginner'
) => {
  const level = levelOptions.find(({ value }) => value === key) as {
    label: string
    value: string
  }
  return level.label
}
