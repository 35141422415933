import { camelize } from 'humps'
import { useFormContext } from 'react-hook-form'

import { Input } from '~components/Chat/components'
import { getParseBodyParams, getType } from '~components/Chat/helpers'
import { PASSWORD_REGEX, REQUIRED_VALIDATION } from '~config'

type Props = {
  dialog: Stepper
  dialogType: ApiGetStepResponse['data'][0]['type']
  index: number
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const Password = ({
  dialog,
  dialogType,
  index,
  setAnswers,
  handleAnswer,
}: Props) => {
  const { getValues } = useFormContext()

  const answer = getType(dialog, 'password')[0]
  const parsedBodyParam = getParseBodyParams(answer)
  const id = camelize(Object.keys(parsedBodyParam)[0])

  const handleUserPasswordAnswer = (answer: Answer) => {
    const value = getValues(camelize(id))
    setAnswers('Mot de passe transmis')
    handleAnswer(answer.action, { [`${id}`]: value })
  }

  return (
    <Input
      key={dialogType + index}
      id={id}
      placeholder={answer.label}
      type="password"
      onSubmit={() => handleUserPasswordAnswer(answer)}
      validator={
        id === 'passwordConfirmation'
          ? {
              ...REQUIRED_VALIDATION,
              validate: (value) =>
                value === getValues('password') ||
                'Le mot de passe et sa confirmation ne correspondent pas',
            }
          : {
              ...REQUIRED_VALIDATION,
              pattern: {
                value: PASSWORD_REGEX,
                message:
                  'Le mot de passe doit contenir plus de 12 caractères dont un chiffre, une lettre majuscule, une lettre minuscule et un caractère spécial',
              },
            }
      }
    />
  )
}

export default Password
