import { ComponentProps } from 'react'
import styled from 'styled-components'

const ButtonWrapper = styled('button')`
  background-color: #1b2e57;
  font-family: 'LatoBold', sans-serif !important;
  padding: 6px 10px 5px 13px;
  border-radius: 100px;
  border: 2px #1b2e57 solid;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
  &:focus {
    opacity: 0.7;
  }

  span {
    padding: 0 10px;
    color: #fff;
    font-size: 14px;
  }
`

const PeConnectButton = (props: ComponentProps<typeof ButtonWrapper>) => {
  return (
    <ButtonWrapper {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 46.91"
        width="34"
        height="31"
      >
        <path
          fill="#fff"
          d="M27.14,36.66c-2.7-2.78-4.06-6.54-4.07-11.38a19.07,19.07,0,0,1,1-6.3A13.76,13.76,0,0,1,27,14a12.64,12.64,0,0,1,4.72-3.15A17.11,17.11,0,0,1,38,9.78a16,16,0,0,1,5.88,1,23.45,23.45,0,1,0-.3,29.4,18.37,18.37,0,0,1-5,.64C33.61,40.83,29.84,39.43,27.14,36.66Z"
          transform="translate(-2 -1.85)"
        />
        <path
          fill="#fff"
          d="M31.12,23.26H44.4a.86.86,0,0,0,.89-.81,9.77,9.77,0,0,0-2.26-6,7,7,0,0,0-5.29-2.14,6.74,6.74,0,0,0-5.15,2.17,10.26,10.26,0,0,0-2.36,5.89C30.15,22.87,30.63,23.26,31.12,23.26Z"
          transform="translate(-2 -1.85)"
        />
        <path
          fill="#fff"
          d="M46,26.8H31.09a.84.84,0,0,0-.9.89,10.66,10.66,0,0,0,1.11,4.49,7.67,7.67,0,0,0,7,4.36A9.91,9.91,0,0,0,41,36.2a8.88,8.88,0,0,0,2.38-1.09,17.54,17.54,0,0,0,2.06-1.55c.62-.54,1.83-1.67,2.24-2.05a1.87,1.87,0,0,1,.43-.28,23.42,23.42,0,0,0,.72-4.69A14,14,0,0,1,46,26.8Z"
          transform="translate(-2 -1.85)"
        />
        <path
          fill="#fff"
          d="M44.16,40c3-1,6.27-4,6.83-6.75.37-1.81-.74-2.21-1.34-2.29l-.36,0a2.53,2.53,0,0,0-1.14.25,23.38,23.38,0,0,1-4.58,9Z"
          transform="translate(-2 -1.85)"
        />
        <path
          fill="#fff"
          d="M48.88,26.53A6.67,6.67,0,0,0,50.55,26c.92-.47,1.44-1.53,1.45-3.43a12.71,12.71,0,0,0-1.58-6.05,12.89,12.89,0,0,0-6.54-5.7,23.35,23.35,0,0,1,5,14.51Q48.91,25.92,48.88,26.53Z"
          transform="translate(-2 -1.85)"
        />
        <path
          fill="#1b2e57"
          d="M45.48,33.56a17.54,17.54,0,0,1-2.06,1.55A8.88,8.88,0,0,1,41,36.2a9.91,9.91,0,0,1-2.7.35,7.67,7.67,0,0,1-7-4.36,10.66,10.66,0,0,1-1.11-4.49.84.84,0,0,1,.9-.89H46a14,14,0,0,0,2.89-.27q0-.61,0-1.23a23.35,23.35,0,0,0-5-14.51,16,16,0,0,0-5.88-1,17.11,17.11,0,0,0-6.27,1.1A12.64,12.64,0,0,0,27,14,13.76,13.76,0,0,0,24.08,19a19.07,19.07,0,0,0-1,6.3c0,4.84,1.37,8.6,4.07,11.38s6.47,4.17,11.43,4.17a18.37,18.37,0,0,0,5-.64,23.38,23.38,0,0,0,4.58-9,1.87,1.87,0,0,0-.43.28C47.31,31.88,46.11,33,45.48,33.56ZM30.23,22.39a10.26,10.26,0,0,1,2.36-5.89,6.74,6.74,0,0,1,5.15-2.17A7,7,0,0,1,43,16.47a9.77,9.77,0,0,1,2.26,6,.86.86,0,0,1-.89.81H31.12C30.63,23.26,30.15,22.87,30.23,22.39Z"
          transform="translate(-2 -1.85)"
        />
      </svg>
      <span>Se connecter avec Pôle emploi</span>
    </ButtonWrapper>
  )
}

export default PeConnectButton
