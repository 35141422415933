import styled, { Theme, css } from 'styled-components'

import { DisplayProps, SpacingProps, display, spacing } from '../../helpers'

type Props = {
  textColor?: keyof Theme['colors']
  uppercase?: boolean
  textAlign?: keyof Theme['components']['text']['align']
  weight?: keyof Theme['components']['tinyText']['weights']
} & SpacingProps &
  DisplayProps

const TinyText = styled('p').attrs((props: Props) => ({
  textColor: props.textColor || 'black',
  uppercase: props.uppercase,
  textAlign: props.textAlign || 'left',
  weight: props.weight || 'medium',
}))<Props>`
  ${({ textColor, textAlign, theme, uppercase, weight }) => css`
    color: ${theme.colors[textColor]};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.components.tinyText.fontSize};
    font-weight: ${theme.components.tinyText.weights[weight]};
    line-height: 1.3;
    text-transform: ${uppercase && 'uppercase'};
    text-align: ${theme.components.text.align[textAlign]};
  `}
  ${spacing}
  ${display}
`

export default TinyText
