import styled, { css } from 'styled-components'

const PopInWrapper = styled('div')`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    min-height: 100vh;
    overflow: auto;
    z-index: ${theme.zIndex.popin.container};
  `}
`

export default PopInWrapper
