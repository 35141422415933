import { useMutation, useQuery } from 'react-query'

import { SurveyService, queryClient } from '~api'

function useSurvey() {
  const useGetMatchingsWithSurvey = () =>
    useQuery(['matchings-with-survey'], () =>
      SurveyService.apiGetMatchingsWithSurvey()
    )
  const useGetMatchings = () =>
    useQuery(['matchings'], () => SurveyService.apiGetMatchings())

  const useSendSurvey = () =>
    useMutation(SurveyService.apiSendSurvey, {
      onSuccess: () => {
        queryClient.invalidateQueries('matchings-with-survey')
      },
    })

  return {
    useGetMatchingsWithSurvey,
    useGetMatchings,
    useSendSurvey,
  }
}

export default useSurvey
