import styled, { css } from 'styled-components'

const IconButton = styled('button')`
  ${({ theme }) => css`
    position: absolute;
    right: ${theme.spaces[2]};
    top: ${theme.spaces[2]};
    width: ${theme.pxToRem(40)};
    height: ${theme.pxToRem(40)};
    border-radius: 50%;
    background: none;
    border: 0;
    margin-left: ${theme.spaces[4]};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover,
    &:focus {
      background: ${theme.colors.greyLighter};
    }
  `}
`

export default IconButton
