import { Outlet } from 'react-router-dom'

import Onboarding from './Onboarding'

const Authentication = () => (
  <Onboarding>
    <Outlet />
  </Onboarding>
)

export default Authentication
