import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { InputAddress } from '~components/Chat/components'
import { getParseBodyParams, getType } from '~components/Chat/helpers'
import { routes } from '~config'

type Props = {
  dialog: Stepper
  dialogType: ApiGetStepResponse['data'][0]['type']
  index: number
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const Address = ({
  dialog,
  dialogType,
  index,
  setAnswers,
  handleAnswer,
}: Props) => {
  const navigate = useNavigate()
  const { getValues } = useFormContext()

  const answer = getType(dialog, dialogType)[0]
  const parsedBodyParam = getParseBodyParams(answer)
  const id = Object.keys(parsedBodyParam)[0]
  const type = answer.type === 'address_perso' ? 'perso' : 'parents'

  const handleUserAddress = (answer: Answer) => (address: AddressDetails) => {
    setAnswers(address.properties.label)
    handleAnswer(answer.action, getValues(id))
  }

  const handleUserAddressYoungAnswer =
    (answer: Answer) =>
    (address: AddressDetails, response: PostAddressResponse) => {
      setAnswers(address.properties.label)
      const { status, data } = response
      if (status === 'OK' || status === 'KO') {
        navigate({
          pathname: routes.authenticated.eligibilityResult,
          search: `type=${status}&redirect=${data.redirectRegisterPathUrl}`,
        })
        return
      }
      answer.action = data.action
      handleAnswer(answer.action, getValues(id))
    }

  // The URL to post the final informations
  const goto = answer.action?.replace(':who', type).split('goto:')
  if (goto?.length === 0) {
    console.error(
      'attempt to split goto, unexpected split response (length != 1)'
    )
    return null
  }
  if (goto === undefined) {
    console.error('goto variable should not be undefined, should not happen')
    return null
  }

  if (answer.type === 'address_perso' || answer.type === 'address_parents') {
    const answer =
      getType(dialog, 'address_perso')[0] ||
      getType(dialog, 'address_parents')[0]

    return (
      <InputAddress
        key={dialogType + index}
        id={id}
        type={type}
        placeholder={answer.label}
        postUrl={goto[1]}
        onValidateYoung={handleUserAddressYoungAnswer(answer)}
      />
    )
  }

  return (
    <InputAddress
      key={dialogType + index}
      id={id}
      placeholder={answer.label}
      onValidate={handleUserAddress(answer)}
    />
  )
}

export default Address
