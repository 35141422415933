import { isEqual } from 'lodash'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { useAuth } from '~context/AuthContext'
import { Box, Button, Loader, Text } from '~design-system'
import { getThreadPathByContext, isReferent, isSponsor } from '~helpers'
import { useThreads } from '~hooks'

interface CardInfoProps {
  content: string
  illustration?: JSX.Element
  upperMessage?: string
}

const BackgroundImage = styled(Box)`
  ${() => css`
    background-image: ${`url('/images/desktop-todo.png')`};
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    background-color: transparent;
    border-radius: 10px;
    padding: 5px;
  `}
`

const StyledText = styled(Text)`
  ${() => css`
    font-size: 18px;
  `}
`

const ChatCard = (props: CardInfoProps) => {
  const { content, illustration, upperMessage = 'Discussions' } = props
  const { useGetThreads } = useThreads()
  const { data: threads, isLoading: threadsIsLoading } = useGetThreads()
  const {
    authState: { user },
  } = useAuth()

  const isUserSponsor = isEqual(isSponsor(user), true)
  const pathToMentor = getThreadPathByContext(threads, 'mentor', isUserSponsor)
  const pathToManager = getThreadPathByContext(
    threads,
    'mission-manager',
    false
  )

  if (threadsIsLoading) return <Loader isCentered />
  return (
    <>
      <BackgroundImage mb="20px">
        <Box ac="center" jc="sb" ai="center" pl="16px" di="flex" fd="row">
          <Box jc="sb" fd="column" w="80%">
            <Text textColor="yellow" textTransform="uppercase">
              {upperMessage}
            </Text>
            <StyledText>{content}</StyledText>
            <Box di="flex" fd="row">
              {pathToMentor && (
                <Box
                  as={Link}
                  to={pathToMentor}
                  ai="center"
                  mt="16px"
                  mr="10px"
                  mb="16px"
                  di="flex"
                  fd="row"
                >
                  {isSponsor(user) || isReferent(user) ? (
                    <Button>Discuter avec mon jeune</Button>
                  ) : (
                    <Button>Discuter avec mon mentor</Button>
                  )}
                </Box>
              )}
              {pathToManager && (
                <Box
                  as={Link}
                  to={pathToManager}
                  ai="center"
                  mt="16px"
                  mb="16px"
                  di="flex"
                  fd="row"
                >
                  <Button variant="secondary">
                    Discuter avec mon chargé de mission
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          {illustration && illustration}
        </Box>
      </BackgroundImage>
    </>
  )
}

export default ChatCard
