import { Theme, css } from 'styled-components'

import { theme } from '~config'

export type DisplayProps = {
  /** Container property : 'align-content' CSS property. */
  ac?:
    | 'end'
    | 'e'
    | 'flex-start'
    | 'fs'
    | 'flex-end'
    | 'fe'
    | 'center'
    | 'ce'
    | 'space-between'
    | 'sb'
    | 'space-around'
    | 'sa'
    | 'start'
    | 'st'
    | 'stretch'
    | 's'
  /** Container property : 'align-items' CSS property. */
  ai?:
    | 'end'
    | 'e'
    | 'flex-start'
    | 'fs'
    | 'flex-end'
    | 'fe'
    | 'center'
    | 'ce'
    | 'baseline'
    | 'b'
    | 'start'
    | 'st'
    | 'stretch'
    | 's'
  /** Item property : 'align-self' CSS property. */
  als?:
    | 'auto'
    | 'end'
    | 'e'
    | 'flex-start'
    | 'fs'
    | 'flex-end'
    | 'fe'
    | 'center'
    | 'ce'
    | 'baseline'
    | 'b'
    | 'normal'
    | 'start'
    | 'st'
    | 'stretch'
    | 's'
  /** Display CSS property. */
  di?:
    | 'b'
    | 'block'
    | 'ib'
    | 'inline-block'
    | 'if'
    | 'inline-flex'
    | 'f'
    | 'flex'
    | 'none'
  /** Item property : 'flex' CSS property. */
  f?: number | string
  /** Item property : 'flex-basis' CSS property. */
  fb?: number | string | 'auto'
  /** Container property : 'flex-direction' CSS property. */
  fd?:
    | 'row'
    | 'r'
    | 'row-reverse'
    | 'rr'
    | 'column'
    | 'c'
    | 'column-reverse'
    | 'cr'
  /** Item property : 'flex-grow' CSS property. */
  fg?: number
  /** Item property : 'flex-shrink' CSS property. */
  fs?: number
  /** Container property : 'flex-wrap' CSS property. */
  fw?: 'nowrap' | 'nw' | 'wrap' | 'w' | 'wrap-reverse' | 'wr'
  /** Container property : 'gap' CSS property. */
  g?: string
  /** Container property : 'hide-after' add a 'display: none' CSS property with MIN media query. */
  ha?: string
  /** Container property : 'hide-under' add a 'display: none' CSS property with MAX media query. */
  hu?: string
  /** Container property : 'justify-content' CSS property. */
  jc?:
    | 'end'
    | 'e'
    | 'flex-start'
    | 'fs'
    | 'flex-end'
    | 'fe'
    | 'center'
    | 'ce'
    | 'space-between'
    | 'sb'
    | 'space-around'
    | 'sa'
    | 'space-evenly'
    | 'se'
    | 'start'
    | 'st'
  /** Item property : 'justify-self' CSS property. */
  js?:
    | 'auto'
    | 'end'
    | 'e'
    | 'flex-start'
    | 'fs'
    | 'flex-end'
    | 'fe'
    | 'center'
    | 'ce'
    | 'baseline'
    | 'b'
    | 'start'
    | 'st'
    | 'stretch'
    | 's'
  /** Item property : 'order' CSS property. */
  or?: number
  /** Item property : 'position' CSS property. */
  pos?: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky'
  p?: string
  /** Item property : 'top'  CSS property. */
  top?: keyof Theme['spaces'] | string
  /** Item property : 'right'  CSS property. */
  right?: keyof Theme['spaces'] | string
  /** Item property : 'bottom'  CSS property. */
  bottom?: keyof Theme['spaces'] | string
  /** Item property : 'left'  CSS property. */
  left?: keyof Theme['spaces'] | string
  /** Item property : 'z-index'  CSS property. */
  zIndex?: number
}

const displayMap = {
  b: 'block',
  ib: 'inline-block',
  if: 'inline-flex',
  f: 'flex',
}

const resolvedMap = {
  b: 'baseline',
  c: 'column',
  cr: 'column-reverse',
  ce: 'center',
  e: 'end',
  fe: 'flex-end',
  fs: 'flex-start',
  nw: 'nowrap',
  r: 'row',
  rr: 'row-reverse',
  s: 'stretch',
  sa: 'space-around',
  sb: 'space-between',
  se: 'space-evenly',
  st: 'start',
  w: 'wrap',
  wr: 'wrap-reverse',
  pos: 'position',
  p: 'padding',
}

/**
 * Inject this style into your component so it can support display props :
 * - ac for align-content
 * - ai for align-items
 * - als for align-self
 * - di for display type
 * - f for flex
 * - fb for flex-basis
 * - fd for flex-direction
 * - fg for flex-grow
 * - fs for flex-shrink
 * - fw for flew-wrap
 * - g for gap
 * - hu for hide under (display none after given size)
 * - hu for hide under (display none under given size)
 * - jc for justify-content
 * - or for order
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  ac,
  ai,
  als,
  di,
  f,
  fb,
  fd,
  fg,
  fs,
  fw,
  g,
  ha,
  hu,
  jc,
  js,
  or,
  pos,
  p,
  top,
  right,
  bottom,
  left,
  zIndex,
}: DisplayProps) => css`
  ${ac &&
  `align-content: ${resolvedMap[ac as keyof typeof resolvedMap] || ac}`};
  ${ai && `align-items: ${resolvedMap[ai as keyof typeof resolvedMap] || ai}`};
  ${als &&
  `align-self: ${resolvedMap[als as keyof typeof resolvedMap] || als}`};
  ${di && `display: ${displayMap[di as keyof typeof displayMap] || di}`};
  ${f && `flex: ${f}`};
  ${fb && `flex-basis: ${fb}`};
  ${fd &&
  `flex-direction: ${resolvedMap[fd as keyof typeof resolvedMap] || fd}`};
  ${fg && `flex-grow: ${fg}`};
  ${fs && `flex-shrink: ${fs}`};
  ${fw && `flex-wrap: ${resolvedMap[fw as keyof typeof resolvedMap] || fw}`};
  ${g && `gap: ${g}`};
  ${ha &&
  `@media (min-width: ${ha}) {
      display: none;
    }`};
  ${hu &&
  `@media (max-width: ${hu}) {
      display: none;
    }`};
  ${jc &&
  `justify-content: ${resolvedMap[jc as keyof typeof resolvedMap] || jc}`};
  ${js && `justify-self: ${resolvedMap[js as keyof typeof resolvedMap] || js}`};
  ${or && `order: ${or}`};
  ${pos && `position: ${resolvedMap[js as keyof typeof resolvedMap] || pos}`};
  ${p && `padding: ${resolvedMap[js as keyof typeof resolvedMap] || p}`};
  ${top && `top: ${theme.spaces[top as keyof Theme['spaces']] || top}`};
  ${right && `right: ${theme.spaces[right as keyof Theme['spaces']] || right}`};
  ${bottom &&
  `bottom: ${theme.spaces[bottom as keyof Theme['spaces']] || bottom}`};
  ${left && `left: ${theme.spaces[left as keyof Theme['spaces']] || left}`};
  ${zIndex && `z-index: ${zIndex}`};
`
