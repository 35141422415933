import styled, { css } from 'styled-components'

import { SpacingProps, spacing } from '../../helpers'

type Props = SpacingProps

const InputBaseContainer = styled('div')<Props>`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spaces[5]};
    flex-grow: 1;
    ${spacing};
  `}
`

export default InputBaseContainer
