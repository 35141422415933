import styled, { css } from 'styled-components'

const NavContainer = styled('ul')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: ${theme.spaces[10]};
  `}
`

export default NavContainer
