import {
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  Ref,
  forwardRef,
} from 'react'

import Button from '../Button'
import Icon from '../Icon'
import { Label, TinyText } from '../Typographies'
import { DisplayProps, LayoutProps, SpacingProps } from '../helpers'
import {
  Input,
  InputBaseContainer,
  InputContainer,
  RightComponentContainer,
  UpperComponentContainer,
} from './styles'

export type InputBaseProps = {
  children?: ReactNode
  icon?: ReactElement<typeof Icon>
  id?: string
  errorMessage?: string | undefined
  label?: ReactNode
  rightComponent?: ReactElement<typeof Button>
  upperComponent?: ReactNode
  backgroundColor?: string
} & InputHTMLAttributes<HTMLInputElement> &
  SpacingProps &
  LayoutProps &
  DisplayProps

const InputBase = forwardRef(
  (
    {
      children,
      icon,
      id,
      errorMessage = undefined,
      label,
      mb,
      ml,
      mr,
      mt,
      pb,
      pl,
      pr,
      pt,
      rightComponent,
      upperComponent,
      type = 'text',
      ...rest
    }: InputBaseProps,
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <InputBaseContainer
        mb={mb}
        ml={ml}
        mr={mr}
        mt={mt}
        pb={pb}
        pl={pl}
        pr={pr}
        pt={pt}
      >
        {label && (
          <Label htmlFor={id} mb={2}>
            {label}
          </Label>
        )}
        {upperComponent && (
          <UpperComponentContainer>{upperComponent}</UpperComponentContainer>
        )}
        <InputContainer isError={!!errorMessage?.length}>
          <Input id={id} ref={ref} type={type} {...rest} />
          {rightComponent && (
            <RightComponentContainer>{rightComponent}</RightComponentContainer>
          )}
        </InputContainer>
        {errorMessage && (
          <TinyText textColor="danger" mt={2} data-testid={`error-${id}`}>
            {errorMessage}
          </TinyText>
        )}
      </InputBaseContainer>
    )
  }
)

export default InputBase
