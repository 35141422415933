import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const ActiveNotif = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.colors.danger};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    right: 3px;
  `}
`

export default ActiveNotif
