import styled from 'styled-components'

import { getParseBodyParams, getType } from '~components/Chat/helpers'
import PeConnectButton from '~components/PeConnectButton'
import { Box } from '~design-system'

type Props = {
  dialog: Stepper
  index: number
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const Container = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`

const PeConnect = ({ dialog, index, setAnswers, handleAnswer }: Props) => {
  const button = getType(dialog, 'pe_connect')

  const handleUserOptionAnswer = (answer: Answer) => {
    setAnswers(answer.label)
    handleAnswer(answer.action, getParseBodyParams(answer))
  }

  return (
    <Container>
      <PeConnectButton
        key={'pe_connect' + index}
        onClick={() => handleUserOptionAnswer(button[0])}
      />
    </Container>
  )
}

export default PeConnect
