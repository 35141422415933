import { Controller, useFormContext } from 'react-hook-form'

import { Box, Dropzone, Icon, InputText, Label, Text } from '~design-system'
import { documentMimeTypes } from '~helpers'

const DegreeForm = () => {
  // Define Form
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <InputText
        id="nom_diplome"
        label="Nom du diplôme"
        autoFocus
        placeholder="Exemple : Baccalauréat Scientifique"
        mt={3}
        errorMessage={errors?.nom_diplome?.message}
        {...register('nom_diplome', { required: true })}
      />
      <InputText
        id="nom_etablissement"
        label="Nom du l’établissement d’obtention"
        placeholder="Exemple : Lycée Édouard Vaillant"
        mt={3}
        errorMessage={errors?.nom_etablissement?.message}
        {...register('nom_etablissement', { required: true })}
      />
      <InputText
        id="annee_obtention"
        label="Année de l’obtention"
        placeholder="Exemple: 2021"
        minLength={4}
        maxLength={4}
        type="text"
        mt={3}
        errorMessage={errors?.annee_obtention?.message}
        {...register('annee_obtention', {
          required: true,
          pattern: /^(19[0-9]\d|20[0-9]\d)$/,
        })}
      />
      <Controller
        control={control}
        name="degree"
        rules={{ validate: (value) => !!value }}
        render={({ field: { onChange, value } }) => (
          <>
            <Label>Justificatif</Label>
            <Dropzone
              multiple={false}
              accept={documentMimeTypes}
              file={value ? [value] : undefined}
              setFile={(val?: string[] | null[]) =>
                onChange(val ? val[0] : undefined)
              }
            >
              <Box di="f" fd="column" ai="center" js="center">
                <Icon name="camera" color="primary" />
                <Text textColor="primary">Importe ton diplôme</Text>
              </Box>
            </Dropzone>
          </>
        )}
      />
    </>
  )
}

export default DegreeForm
