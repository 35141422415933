import styled, { css } from 'styled-components'

const PanelsContainer = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: ${theme.spaces[8]};
  `}
`

export default PanelsContainer
