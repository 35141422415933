export const formatList = (list: string[]): string => {
  if (list.length === 1) return list[0]
  const firsts = list.slice(0, -1)
  const last = list.slice(-1)

  return firsts.join(', ') + ' & ' + last[0]
}

export const formatMentorName = (mentor: {
  firstname: string
  id: number
  lastname: string
}): string =>
  `${mentor?.firstname} ${
    !!mentor?.lastname && mentor.lastname.substr(0, 1).toUpperCase()
  }.`

export const labelShortener = (label: string, maxLength: number): string =>
  label &&
  (label.length > maxLength ? label.substr(0, maxLength) + '...' : label)

/**
 * Returns the last component of an URL
 * @param url - the URL to work with
 * @returns The last component of an URL - returns an empty string
 * if the parameter is empty, and return the entire string
 * if not an URL.
 */
export const redactURL = (url: string): string => {
  if (url.trim().length === 0) {
    return ''
  }
  const [item] = url.split('/').slice(-1)

  return item
}

export const capitalize = (l: string) => l.charAt(0).toUpperCase() + l.slice(1)
