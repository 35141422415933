import styled, { css } from 'styled-components'

type Props = {
  active: Boolean
}

const Step = styled('div')<Props>`
  ${({ theme, active }) => css`
    background-color: ${active
      ? theme.colors.primary
      : theme.colors.blueLighter};
    border-radius: 5px;
    height: 4px;
    width: 32px;
    & + & {
      margin-left: ${theme.spaces[1]};
    }
  `}
`

export default Step
