import { Controller, useFormContext } from 'react-hook-form'

import { Box, Label, Loader, TinyText } from '~design-system'
import Select from '~design-system/Select'
import { getTransformSectorList } from '~helpers'
import { useData } from '~hooks'

interface Props {
  sectorID: number
}

const SubsectorSelect = ({ sectorID }: Props) => {
  const { watch, control } = useFormContext()
  const { useGetSubSectors } = useData()
  const { data: subsectors, isLoading } = useGetSubSectors(sectorID)
  const currentSector = watch('domain')
  const currentSubSector = watch('sector')

  return currentSector ? (
    <Box w="100%">
      <Controller
        name="sector"
        control={control}
        rules={{
          required: true,
          pattern: /^[1-9]\d*$/,
          validate: (value) => value !== 0,
        }}
        render={({ field: { onChange } }) => {
          return (
            <Box>
              <TinyText
                as={Label}
                textColor="greyDarkest"
                uppercase
                mb={2}
                ml={2}
                htmlFor="subdomain"
              >
                Choisissez un sous-secteur
              </TinyText>
              {currentSector && isLoading ? (
                <Loader />
              ) : (
                <Select
                  inputId="subdomain"
                  placeholder="Sélectionner un sous-secteur"
                  isSearchable={false}
                  isDisabled={!subsectors?.length || !currentSector}
                  maxMenuHeight={200}
                  options={
                    subsectors &&
                    getTransformSectorList(subsectors, 'subsector')
                  }
                  value={
                    currentSubSector &&
                    subsectors &&
                    getTransformSectorList(subsectors, 'subsector').find(
                      (subsector: { value: number | string; label: string }) =>
                        subsector.value === currentSubSector
                    )
                  }
                  onChange={(e) => onChange(e?.value)}
                />
              )}
            </Box>
          )
        }}
      />
    </Box>
  ) : null
}

export default SubsectorSelect
