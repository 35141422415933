const colors = {
  appleGreen: '#A7C70E',
  appleGreenLight: '#F8FBEC',
  black: '#28282A',
  black50: '#28282a85',
  blue: '#00C2FF',
  blueDark: '#3367CD',
  blueDarker: '#28276D',
  blueLight: '#DFE8FB',
  blueLighter: '#EBF0FA',
  blueLightest: '#F7F8FD',
  green: '#50BD89',
  greenLight: '#F0FAF6',
  grey: '#E6E6E6',
  greyDark: '#E0E0E0',
  greyDarker: '#BDBDBD',
  greyDarkest: '#8D8D8D',
  greyLight: '#F0F0F0',
  greyLighter: '#F3F3F3',
  greyLightest: '#F9F9F9',
  yellow: '#F9AF1E',
  orange: '#ED984F',
  orangeLight: '#FFF7EB',
  red: '#DB394C',
  redLight: '#fff0f6',
  pink: '#FF66a9',
  pinkLight: '#FDEBF3',
  purple: '#9B51E0',
  purpleLight: '#F7F1FD',
  white: '#FFFFFF',
  transparent: '#ffffff00',
}

const spaces = {
  0: '0rem',
  0.5: '0.125rem',
  0.75: '0.1875rem',
  1: '0.25rem',
  2: '0.5rem',
  3: '0.75rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  10: '2.5rem',
  12: '3rem',
  16: '4rem',
  18: '4.5rem',
  20: '5rem',
  24: '6rem',
  32: '8rem',
  40: '10rem',
  48: '12rem',
  64: '16rem',
  80: '20rem',
  96: '24rem',
  112: '28rem',
  128: '32rem',
  144: '36rem',
  192: '48rem',
  224: '56rem',
}

const theme = {
  colors: {
    appleGreen: colors.appleGreen,
    appleGreenLight: colors.appleGreenLight,
    black: colors.black,
    black50: colors.black50,
    blue: colors.blue,
    blueDark: colors.blueDark,
    blueDarker: colors.blueDarker,
    blueLight: colors.blueLight,
    blueLighter: colors.blueLighter,
    blueLightest: colors.blueLightest,
    danger: colors.red,
    greenLight: colors.greenLight,
    redLight: colors.redLight,
    grey: colors.grey,
    greyDark: colors.greyDark,
    greyDarker: colors.greyDarker,
    greyDarkest: colors.greyDarkest,
    greyLight: colors.greyLight,
    greyLighter: colors.greyLighter,
    greyLightest: colors.greyLightest,
    orange: colors.orange,
    orangeLight: colors.orangeLight,
    pink: colors.pink,
    pinkLight: colors.pinkLight,
    primary: colors.blueDark,
    purple: colors.purple,
    purpleLight: colors.purpleLight,
    secondary: colors.blueLight,
    success: colors.green,
    transparent: colors.transparent,
    white: colors.white,
    yellow: colors.yellow,
  },
  components: {
    avatar: {
      sizes: {
        default: '32px',
        medium: '40px',
        big: '85px',
      },
      dots: {
        default: '10px',
        medium: '10px',
        big: '20px',
      },
    },
    button: {
      fontSize: '1rem',
      padding: `${spaces[2]} ${spaces[6]}`,
      sizes: {
        small: {
          height: spaces[8],
        },
        medium: {
          height: spaces[10],
        },
        big: {
          height: spaces[12],
        },
      },
      weight: 500,
    },
    grid: {
      gap: spaces[4],
    },
    headerBanner: {
      height: '5rem',
    },
    header1: {
      fontSize: '2.625rem',
    },
    header2: {
      fontSize: '1.425rem',
    },
    header3: {
      fontSize: '1.125rem',
      weights: {
        regular: 400,
        medium: 500,
      },
    },
    icon: {
      sizes: {
        verytiny: '0.35rem',
        tiny: '0.625rem',
        smaller: '0.75rem',
        small: '0.875rem',
        default: '1rem',
        medium: '1.125rem',
        big: '1.25rem',
        bigger: '1.375rem',
        giant: '1.5rem',
      },
    },
    label: {
      color: colors.greyDarkest,
      fontSize: '0.75rem',
    },
    loader: {
      small: {
        size: 20,
        strokeWidth: 2,
      },
      default: {
        size: 40,
        strokeWidth: 4,
      },
      big: {
        size: 60,
        strokeWidth: 6,
      },
    },
    messagingSidebar: {
      width: '300px',
    },
    messagingInput: {
      maxHeight: '90px',
    },
    message: {
      answer: {
        backgroundColor: colors.blueDark,
        borderRadius: '8px 8px 0 8px',
      },
      question: {
        backgroundColor: colors.white,
        borderRadius: '0px 8px 8px 8px',
      },
    },
    smallText: {
      fontSize: '0.875rem',
      weights: {
        regular: 400,
        medium: 500,
        bold: 600,
      },
    },
    tag: {
      fontSize: '0.875rem',
      weight: 500,
    },
    text: {
      fontSize: '1rem',
      weights: {
        regular: 400,
        medium: 500,
        bold: 700,
      },
      align: {
        left: 'left',
        right: 'right',
        center: 'center',
        justify: 'justify',
      },
    },
    tinyText: {
      fontSize: '0.750rem',
      weights: {
        regular: 400,
        medium: 500,
      },
    },
  },
  fonts: {
    primary: 'Circular',
  },
  radii: {
    sm: '0.125rem',
    md: '0.25rem',
    lg: '0.5rem',
    xlg: '1.5rem',
  },
  spaces: {
    ...spaces,
  },
  pxToRem: (px: number) => {
    return `${px / 16}rem` // 16 is the basefont size
  },
  weights: {
    regular: 400,
    medium: 500,
    bold: 600,
    black: 900,
  },
  zIndex: {
    layout: {
      informations: 1000,
    },
    popin: {
      backdrop: 1100,
      container: 1100,
    },
  },
}

export default theme
