import styled from 'styled-components'

import Box from '../../Box'

const PopInContent = styled(Box).attrs({
  ai: 'ce',
  di: 'f',
  fd: 'c',
  fg: 1,
  jc: 'ce',
})`
  width: 100%;
`

export default PopInContent
