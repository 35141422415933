import { createContext, useContext } from 'react'

type PopInContextState = {
  onClose?: () => void
}

type Props = {
  children: React.ReactNode
} & PopInContextState

const PopInContext = createContext({} as PopInContextState)

const PopInProvider = ({ children, onClose }: Props) => {
  return (
    <PopInContext.Provider value={{ onClose }}>
      {children}
    </PopInContext.Provider>
  )
}

const usePopin = () => {
  const context = useContext(PopInContext)
  if (context === undefined) {
    throw new Error('usePopin must be used within a PopInProvider')
  }

  return context
}

export { PopInProvider, usePopin }
