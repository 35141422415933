import { Box, Icon, Panel, SmallText } from '~design-system'
import { SpacingProps } from '~design-system/helpers'

type Props = {
  icon?: string
  label: string
  link: string | undefined
  linkLabel?: string | undefined
} & SpacingProps

const CardLink = ({ icon, label, link, linkLabel, ...rest }: Props) => (
  <Panel as="a" href={link} target="_blank" {...rest}>
    <Box ai="ce" di="f">
      {icon && <Icon color="primary" mr={4} name={icon} size="medium" />}
      <Box fg={1} overflow="hidden">
        <SmallText weight="medium">{label}</SmallText>
        <SmallText textColor="greyDarkest">{linkLabel || link}</SmallText>
      </Box>
      <Icon color="greyDarker" name="chevron-right" size="small" />
    </Box>
  </Panel>
)

export default CardLink
