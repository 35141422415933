import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

import Box from '~design-system/Box'

type Props = InputHTMLAttributes<HTMLInputElement>

const Container = styled(Box)<Props>`
  &:hover,
  & > *:hover {
    cursor: pointer;
  }
`

export default Container
