import { format } from 'date-fns'

import { EditIcon } from '~components'
import { Box, Divider, SmallText, Text } from '~design-system'
import { getFrenchCivility, isYoung } from '~helpers'

import { TagList } from '../components'

interface Props {
  handleEditInfos?: Function
  isEditable?: boolean
  user: User
}

const LeftPanelContent = ({
  user,
  isEditable = false,
  handleEditInfos,
}: Props) => {
  const desiredProfessions = user.desiredProfessions?.map((job) => job.libelle)
  const sectors = [user.sector?.domain, user.sector?.subDomain].filter(
    (e) => !!e
  )

  if (!isYoung(user)) {
    return (
      <>
        <Box di="f" fd="c" pt={4} pb={4}>
          <Box di="f" ai="fs" jc="sb">
            <Text weight="medium" mb={2}>
              Civilité
            </Text>
            <EditIcon
              isVisible={isEditable}
              label="Modifier votre civilité"
              onClick={() =>
                handleEditInfos &&
                handleEditInfos({
                  slug: 'civility',
                  title: 'Modifier votre civilité',
                })
              }
            />
          </Box>
          {user.civility ? (
            <SmallText>{getFrenchCivility(user.civility)}</SmallText>
          ) : (
            <SmallText textColor="greyDarker">
              Aucune civilité renseignée
            </SmallText>
          )}
        </Box>
        <Divider />
        <Box di="f" fd="c" pt={4} pb={4}>
          <Box di="f" ai="fs" jc="sb">
            <Text weight="medium" mb={2}>
              Adresse mail
            </Text>
            <EditIcon
              isVisible={isEditable}
              label="Modifier votre adresse email"
              onClick={() =>
                handleEditInfos &&
                handleEditInfos({
                  slug: 'email',
                  title: 'Modifier votre adresse email',
                })
              }
            />
          </Box>
          {user.email ? (
            <SmallText>{user.email}</SmallText>
          ) : (
            <SmallText textColor="greyDarker">Aucun email renseigné</SmallText>
          )}
        </Box>
        <Divider />
        {isEditable && ( // These Personnal Data are only visible by the sponsor
          <Box di="f" fd="c" pt={4} pb={4}>
            <Box di="f" ai="fs" jc="sb">
              <Text weight="medium" mb={2}>
                Date de naissance
              </Text>
              <EditIcon
                isVisible={isEditable}
                label="Modifier votre date de naissance"
                onClick={() =>
                  handleEditInfos &&
                  handleEditInfos({
                    slug: 'birthDate',
                    title: 'Modifier votre date de naissance',
                  })
                }
              />
            </Box>
            {user.birthDate ? (
              <SmallText>
                {format(new Date(user.birthDate as string), 'dd/MM/yyyy')}
              </SmallText>
            ) : (
              <SmallText textColor="greyDarker">
                Aucune date de naissance renseignée
              </SmallText>
            )}
          </Box>
        )}

        <Divider />
        <Box di="f" fd="c" pt={4} pb={4}>
          <Box di="f" ai="fs" jc="sb">
            <Text weight="medium" mb={2}>
              Entrée dans la vie pro
            </Text>
            <EditIcon
              isVisible={isEditable}
              label="Modifier votre année d'entrée dans la vie pro"
              onClick={() =>
                handleEditInfos &&
                handleEditInfos({
                  slug: 'firstWorkYear',
                  title: "Modifier votre année d'entrée dans la vie pro",
                })
              }
            />
          </Box>
          {user.firstWorkYear ? (
            <SmallText>{user.firstWorkYear}</SmallText>
          ) : (
            <SmallText textColor="greyDarker">Aucune date renseignée</SmallText>
          )}
        </Box>
      </>
    )
  } else {
    return (
      <>
        <Box di="f" ai="fs" jc="sb" pt={4} pb={4}>
          <TagList label="Métiers recherchés" tags={desiredProfessions} />
          <EditIcon
            isVisible={isEditable}
            mt={1}
            label="Modifier vos métiers recherchés"
            onClick={() =>
              handleEditInfos &&
              handleEditInfos({
                slug: 'desiredProfessions',
                title: 'Modifier vos métiers recherchés',
              })
            }
          />
        </Box>
        <Divider />
        <Box di="f" ai="fs" jc="sb" pt={4} pb={4}>
          <TagList label="Secteur et sous-secteur" tags={sectors} />
          <EditIcon
            isVisible={isEditable}
            mt={1}
            label="Modifier vos secteurs et sous-secteurs"
            onClick={() =>
              handleEditInfos &&
              handleEditInfos({
                slug: 'sector',
                title: 'Modifier vos secteurs et sous-secteurs',
              })
            }
          />
        </Box>
        <Divider />
        <Box di="f" ai="fs" jc="sb" pt={4} pb={4}>
          <TagList label="Centres d'intérêts" tags={user.hobbies} />
          <EditIcon
            isVisible={isEditable}
            mt={1}
            label="Modifier vos centres d’intérêts"
            onClick={() =>
              handleEditInfos &&
              handleEditInfos({
                slug: 'hobbies',
                title: 'Modifier vos centres d’intérêts',
              })
            }
          />
        </Box>
        <Divider />
        <Box di="f" ai="fs" jc="sb" pt={4} pb={4}>
          <TagList label="Thèmes d'accompagnement" tags={user.skills} />
          <EditIcon
            isVisible={isEditable}
            mt={1}
            label="Modifier vos thèmes d'accompagnement"
            onClick={() =>
              handleEditInfos &&
              handleEditInfos({
                slug: 'skills',
                title: "Modifier vos thèmes d'accompagnement",
              })
            }
          />
        </Box>
      </>
    )
  }
}

export default LeftPanelContent
