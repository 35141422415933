import { find, includes, isEmpty, isNil } from 'lodash'
import { generatePath } from 'react-router-dom'

import { NQT_ROLES, routes } from '~config'

export const getParticipantsNames = (thread: Thread) => {
  return thread.participants.map((user) => user.fullName).join(', ')
}

export const getRoleByContext = (
  context: ThreadContext,
  isSponsor: boolean
): Role => {
  switch (context) {
    case 'mentor':
      return isSponsor ? 'jeune' : 'parrain'
    case 'mission-manager':
      return 'mission-manager'
  }
}

export const getThread = (
  threads: Thread[] | undefined,
  context: ThreadContext,
  isSponsor: boolean
) => {
  const role = getRoleByContext(context, isSponsor)

  return find(threads, (thread) => {
    const hasParticipants = !isEmpty(thread.participants)
    const partcipantsWithRole =
      hasParticipants && includes(thread.participants[0].role, role)
    return partcipantsWithRole && thread.isActive
  })
}

export const getThreadPathByContext = (
  threads: Thread[] | undefined,
  context: ThreadContext,
  isSponsor: boolean
) => {
  const thread = getThread(threads, context, isSponsor)

  if (!isNil(thread))
    return generatePath(routes.authenticated.messaging, {
      id: `${thread.id}`,
    })

  return null
}

export const getAvatarTypeByRole = (roles: Role[] | undefined): AvatarType => {
  const isNQT = roles?.some((role) => NQT_ROLES.includes(role))

  if (isNQT) return 'nqt'
  return 'avatar'
}
