import { camelize } from 'humps'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Label, Select } from '~design-system'

type Props = {
  id: string
  label: string
  onSubmit: () => void
  options: { label: string; value: string }[]
  placeholder?: string
}

const Dropdown = ({ id, label, options, onSubmit }: Props) => {
  const { control } = useFormContext()
  const name = camelize(id)

  return (
    <Box di="f" fd="c" ai="end">
      <Label mb={4} htmlFor={id}>
        {label}
      </Label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <Select
              inputId={id}
              maxMenuHeight={1000}
              onChange={(e) => {
                if (e) {
                  onChange(e)
                  onSubmit()
                }
              }}
              options={options}
            />
          )
        }}
      />
    </Box>
  )
}

export default Dropdown
