import styled from 'styled-components'

import { Header2 } from '~design-system'

const Title = styled(Header2)`
  white-space: nowrap;

  @media (max-width: 700px) {
    font-size: 1.2rem;
  }
`

export default Title
