import { Fragment } from 'react'
import { useForm } from 'react-hook-form'

import { REQUIRED_VALIDATION, theme } from '~config'
import { useAuth } from '~context/AuthContext'
import {
  Box,
  Button,
  Label,
  PopIn,
  Switch,
  Text,
  Textarea,
} from '~design-system'
import { useGetEnums } from '~hooks'

import useMatching from '../../hooks/useMatching'
import { ModalTitle, WeatherInput, WeatherLabel } from './styles'

type Props = {
  onClose: () => void | Function
  open: boolean
  matchId: number
}

const WeatherModal = ({ matchId, open, onClose }: Props) => {
  const {
    authState: { user },
  } = useAuth()
  const {
    handleSubmit,
    register,
    formState: { isValid },
    getValues,
    watch,
  } = useForm({ mode: 'onChange' })
  const { useAddMatchFeeling } = useMatching()
  const { mutate: addFeeling, isLoading, isError } = useAddMatchFeeling()

  const { getMatchingEnums } = useGetEnums()
  const matchingEnums = getMatchingEnums()

  const currentMatch = user?.matchs?.find(
    (match) => match.matchingId === matchId
  )

  const onSubmit = () => {
    const values = getValues()
    addFeeling({
      id: matchId,
      data: {
        feeling: values.weather,
        comment: values.comment,
        wants_to_continue_matching: values.wants_to_continue_matching,
      },
    })
  }

  if (isError) {
    onClose()
  }

  if (!matchingEnums) {
    return null
  }

  return (
    <PopIn open={open} closable onClose={onClose} padding={theme.spaces[6]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalTitle>
          Qu’avez-vous pensé de votre parrainage avec {currentMatch?.fullName}{' '}
          ces {currentMatch?.feelingWeekFrequency} dernières semaines ?
        </ModalTitle>
        <Text textColor="greyDarkest">Un seul choix possible</Text>
        <Box di="f" mt={7} mb={4} ai="center" jc="center">
          {Object.entries(matchingEnums).map(([key, { label, icon }]) => {
            return (
              <Fragment key={key}>
                <WeatherInput
                  type="radio"
                  id={key}
                  value={key}
                  {...register('weather', { ...REQUIRED_VALIDATION })}
                />
                <WeatherLabel title={label} htmlFor={key}>
                  <img src={icon} alt={label} />
                </WeatherLabel>
              </Fragment>
            )
          })}
        </Box>
        <Textarea
          id="comment"
          placeholder="Saisis ton commentaire"
          label="Commentaire"
          autoFocus
          {...register('comment')}
        >
          {watch('comment')}
        </Textarea>
        <fieldset>
          <Label id="wants-to-continue-matching-label" mb={3} mt={4}>
            Je souhaite continuer mon parrainage
          </Label>
          <Switch
            label="Je souhaite continuer mon parrainage"
            leftText="Non"
            rightText="Oui"
            id="wants_to_continue_matching"
            {...register('wants_to_continue_matching')}
            defaultChecked={true}
          />
        </fieldset>
        <Box di="f" mt={12} mb={4} ai="center" jc="end" g="8px">
          <Button variant="secondary" onClick={onClose}>
            Revenir en arrière
          </Button>
          <Button type="submit" disabled={!isValid || isLoading}>
            Envoyer ma note
          </Button>
        </Box>
      </form>
    </PopIn>
  )
}

export default WeatherModal
