import styled from 'styled-components'

const Image = styled('img')`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(237, 41, 38, 0.16);
  margin-right: 16px;
`

export default Image
