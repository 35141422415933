import styled, { css, Theme } from 'styled-components'

import {
  display,
  DisplayProps,
  layout,
  LayoutProps,
  spacing,
  SpacingProps,
} from '../helpers'

type Props = {
  backgroundColor?: keyof Theme['colors']
  borderColor?: keyof Theme['colors']
  radius?: keyof Theme['radii']
  textColor?: keyof Theme['colors']
} & DisplayProps &
  LayoutProps &
  SpacingProps &
  React.ComponentProps<'p'>

const Tag = styled('span').attrs((props: Props) => ({
  backgroundColor: props.backgroundColor || 'white',
  borderColor: props.borderColor || 'primary',
  radius: props.radius || 'md',
  textColor: props.textColor || 'primary',
}))<Props>`
  ${({ backgroundColor, borderColor, radius, textColor, theme }) => css`
    background-color: ${theme.colors[backgroundColor]};
    border: 1px solid ${theme.colors[borderColor]};
    border-radius: ${theme.radii[radius]};
    color: ${theme.colors[textColor]};
    display: inline-flex;
    font-family: ${theme.fonts.primary};
    font-size: ${theme.components.tag.fontSize};
    font-weight: ${theme.components.tag.weight};
    line-height: 1.3;
    margin-right: ${theme.spaces[2]};
    margin-bottom: ${theme.spaces[3]};
    padding: ${theme.spaces[1]} ${theme.spaces[2]};
  `}
  ${display};
  ${layout};
  ${spacing};
`

export default Tag
