import styled from 'styled-components'

import { Box } from '~design-system'

export const Card = styled(Box)`
  background-color: white;
  border-radius: 10px;
  padding: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 30px;

  @media (max-width: 900px) {
    padding: 30px;
  }
`

export const LeftCardContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 20px;

  h1 {
    margin-bottom: 1.5rem;
  }

  @media (min-width: 475px) {
    min-width: 300px;
  }

  @media (min-width: 900px) {
    min-width: 375px;
  }
`

export const RightCardContainer = styled('div')`
  border-radius: 10px;
  border: 1px solid #ebf0fa;
  flex: 1;
`

export const TodoBeforeHeader = styled('div')`
  display: flex;
  padding: 15px;
  border-radius: 5px;
  flex: 1;
  justify-content: space-between;
`

export const TodoBeforeContent = styled('div')`
  padding: 15px;
`
export const TodoBeforeItems = styled('div')`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

export const IconAndTextContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const IconsContainer = styled('div')`
  display: flex;
  background-color: #ebf0fa;
  margin-right: 15px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
`
export const GreenIconsContainer = styled('div')`
  display: flex;
  background-color: #dcf2e7;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
`

export const ArrowOrCheckContainer = styled('div')`
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
`
