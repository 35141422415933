import styled, { css } from 'styled-components'

import Header3 from '../../../../design-system/Typographies/Header3/index'

const Container = styled('div')`
  ${({ theme }) => css`
    background-color: #f3f3f3;
    padding: 32px 48px;
    border-radius: ${theme.radii.lg};
  `}
`

const Title = styled(Header3)`
  ${({ theme }) => css`
    font-weight: 500;
    font-size: ${theme.pxToRem(18)};
    margin: 0 0 32px;
  `}
`

const List = styled('ul')`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    z-index: 1;

    @media screen and (max-width: 48rem) {
      grid-template-columns: 1fr;
    }
  `}
`

const ListItem = styled('li')`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: ${theme.pxToRem(14)};
    color: ${theme.colors.black};
    display: flex;
    align-items: center;
    span {
      opacity: 0.64;
    }

    a {
      color: inherit;
    }
  `}
`

export { Container, Title, List, ListItem }
