import { generatePath } from 'react-router-dom'

import { routes } from '~config'
import { useAuth } from '~context/AuthContext'
import { useThreads } from '~hooks'

import {
  Bubble,
  ChatLike,
  Container,
  Logo,
  Section,
  SectionButton,
  SectionText,
  SectionTitle,
} from './styles'

const Footer = () => {
  const {
    authState: { user },
  } = useAuth()
  const { useGetThreads } = useThreads()
  const { data: threads } = useGetThreads()

  const thread = threads?.find((th) =>
    th.participants.find(
      (participant) => !participant.role?.includes(user?.role[0] || 'jeune')
    )
  )

  return (
    <Container>
      <Section>
        <ChatLike>
          <Logo src="/images/logo-white.svg" alt="logo nqt" />
          <Bubble>Une question ? Nous sommes là pour vous aider</Bubble>
        </ChatLike>
      </Section>
      <Section>
        <SectionTitle>Vous ne trouvez pas votre réponse ?</SectionTitle>
        <SectionText>
          Contactez votre chargé de mission et posez lui votre question, il
          pourra y répondre facilement et rapidement
        </SectionText>
        {thread && (
          <SectionButton
            to={generatePath(routes.authenticated.messaging, {
              id: `${thread.id}`,
            })}
          >
            Contacter votre chargé de mission
          </SectionButton>
        )}
      </Section>
    </Container>
  )
}

export default Footer
