import { formatDistanceStrict } from 'date-fns'
import { fr } from 'date-fns/locale'
import { useState } from 'react'

import { Box, Divider, Icon, Panel, SmallText, Text } from '~design-system'

import JobDeletePopin from './JobDeletePopIn'

interface Props {
  job: PEJob
}

const PEJobCard = ({ job }: Props) => {
  const [deletePopin, setDeletePopin] = useState<boolean>(false)

  const date = formatDistanceStrict(new Date(), new Date(job.dateCreation), {
    unit: 'day',
    locale: fr,
  })

  return (
    <>
      <Panel h="100%" as="a" di="f" jc="fs" href={job.origineOffre.urlOrigine}>
        <Box di="f" fd="c">
          <Box di="f" ai="b" jc="sb">
            <SmallText textColor="greyDarkest">
              {job.lieuTravail?.libelle}
            </SmallText>
          </Box>

          <Text weight="medium">{job.intitule}</Text>
          <Divider mt={3} mb={3} />
          {(job.typeContrat || job.salaire?.libelle) && (
            <Box di="f" ai="ce" mb={2} g="5px">
              <Icon name="briefcase" color="primary" />
              <SmallText textColor="greyDarkest">
                {job.typeContrat && `${job.typeContrat}`}
                {job.typeContrat && job.salaire?.libelle && ' - '}
                {job.salaire?.libelle && `${job.salaire?.libelle}`}
              </SmallText>
            </Box>
          )}
          {job.dateCreation && (
            <Box di="f" ai="ce" mb={2} g="5px">
              <Icon name="pin" color="primary" />
              <SmallText textColor="greyDarkest">
                {date === '0 jours'
                  ? `Publiée aujourd'hui`
                  : `Publiée il y a ${date}`}
              </SmallText>
            </Box>
          )}
        </Box>
      </Panel>
      {deletePopin && (
        <JobDeletePopin jobID={job.id.toString()} onClose={setDeletePopin} />
      )}
    </>
  )
}

export default PEJobCard
