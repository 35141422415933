import { InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

type Props = InputHTMLAttributes<HTMLInputElement>

const Radio = styled('input')<Props>`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    appearance: none;
    border: 1px solid ${theme.colors.grey};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: relative;

    &:checked {
      border: 1px solid ${theme.colors.primary};
    }

    &:checked:after {
      content: '';
      width: 14px;
      height: 14px;
      background-color: ${theme.colors.primary};
      position: absolute;
      border-radius: 50%;
      top: 2px;
      left: 2px;
    }
  `}
`

export default Radio
