import { Box, SmallText, Tag, Text } from '~design-system'

type TagsObject = { id: number; name: string }

type Props = {
  label: string
  tags: TagsObject[] | string[]
}

const TagList = ({ label, tags }: Props) => {
  // Remove Start/End White Spaces
  const cleanTags = tags?.map((tag: TagsObject | string) =>
    typeof tag === 'object' ? tag.name.trim() : tag.trim()
  )
  // Remove Duplicate
  const tagList = Array.from(new Set(cleanTags))

  return (
    <Box>
      <Text mb={4} weight="medium">
        {label}
      </Text>
      {tagList?.map((tag: TagsObject | string) => {
        const tagName = typeof tag === 'object' ? tag.name : tag
        return <Tag key={`tag-${tagName}`}>{tagName}</Tag>
      })}
      {!tagList.length && (
        <SmallText textColor="greyDarker" mt={4}>
          Information non renseignée
        </SmallText>
      )}
    </Box>
  )
}

export default TagList
