import * as Sentry from '@sentry/react'
import { ReactElement, Suspense } from 'react'
import {
  Navigate,
  Route,
  Routes,
  generatePath,
  useLocation,
  useParams,
} from 'react-router-dom'

import { GlobalLoader, Layout } from '~components'
import { env, routes } from '~config'
import { useAuth } from '~context/AuthContext'
import { Loader } from '~design-system'
import {
  canAccessPrematching,
  isReferent,
  isSponsor,
  isYoung,
  lazyRetry,
} from '~helpers'
import { useGetEnums } from '~hooks'

import Unauthorized from './Unauthorized'

const NotFound = lazyRetry(() => import('./NotFound'))
const ExpiredSession = lazyRetry(() => import('./ExpiredSession'))
const Maintenance = lazyRetry(() => import('./Maintenance'))

const Home = lazyRetry(() => import('./Authenticated/Home'))
const Company = lazyRetry(() => import('./Authenticated/Company'))
const CompanyForm = lazyRetry(() => import('./Authenticated/Company/Form'))

const Events = lazyRetry(() => import('./Authenticated/Events'))
const Event = lazyRetry(() => import('./Authenticated/Event'))
const PublicEvent = lazyRetry(() => import('./Unauthenticated/PublicEvent'))
const EventForm = lazyRetry(() => import('./Authenticated/Event/Form'))

const Jobs = lazyRetry(() => import('./Authenticated/Jobs'))
const Job = lazyRetry(() => import('./Authenticated/Job'))
const JobForm = lazyRetry(() => import('./Authenticated/Job/Form'))
const Messaging = lazyRetry(() => import('./Authenticated/Messaging'))

const Notifications = lazyRetry(() => import('./Authenticated/Notifications'))

const Invite = lazyRetry(() => import('./Authenticated/Invite/invite'))

const Profile = lazyRetry(() => import('./Authenticated/Profile'))
const Sponsor = lazyRetry(() => import('./Authenticated/Sponsor'))
const PreMatching = lazyRetry(() => import('./Authenticated/PreMatching'))
const Settings = lazyRetry(() => import('./Authenticated/Settings'))
const Young = lazyRetry(() => import('./Authenticated/Young'))
const FollowedYoungs = lazyRetry(() => import('./Authenticated/FollowedYoungs'))

const Report = lazyRetry(() => import('./Authenticated/Report'))
const Documents = lazyRetry(() => import('./Authenticated/Documents'))

const Service = lazyRetry(() => import('./Authenticated/Service'))
const Services = lazyRetry(() => import('./Authenticated/Services'))

const EmailVerification = lazyRetry(
  () => import('./Unauthenticated/EmailVerification')
)
const Inscription = lazyRetry(() => import('./Unauthenticated/Inscription'))
const ReOnboarding = lazyRetry(() => import('./Unauthenticated/ReOnboarding'))

const ForgotPassword = lazyRetry(
  () => import('./Unauthenticated/ForgotPassword')
)

const ForgotPasswordVerification = lazyRetry(
  () => import('./Unauthenticated/ForgotPasswordVerification')
)
const Eligibility = lazyRetry(() => import('./Authenticated/Eligibility'))

const EligibilityForm = lazyRetry(
  () => import('./Authenticated/Eligibility/EligibilityForm')
)
const EligibilityResult = lazyRetry(
  () => import('./Authenticated/Eligibility/Result')
)
const Faq = lazyRetry(() => import('./Authenticated/Faq'))
const FaqCategory = lazyRetry(() => import('./Authenticated/FaqCategory'))
const FaqQuestion = lazyRetry(() => import('./Authenticated/FaqQuestion'))

const Register = lazyRetry(() => import('./Unauthenticated/Register'))

const RegisterForm = lazyRetry(
  () => import('./Unauthenticated/Register/RegisterForm')
)
const ResetPassword = lazyRetry(() => import('./Unauthenticated/ResetPassword'))

const ResetPasswordConfirmation = lazyRetry(
  () => import('./Unauthenticated/ResetPasswordConfirmation')
)
const Signin = lazyRetry(() => import('./Unauthenticated/Signin'))
const Honor = lazyRetry(() => import('./Authenticated/SigninHonor'))
const Summary = lazyRetry(() => import('./Unauthenticated/Register/Summary'))
const SummaryPE = lazyRetry(
  () => import('./Unauthenticated/Register/SummaryPE')
)

const Verification = lazyRetry(() => import('./Authenticated/Verification'))

const Welcome = lazyRetry(() => import('./Unauthenticated/Welcome'))
const ReferentInvitation = lazyRetry(
  () => import('./Unauthenticated/ReferentInvitation')
)
const ReferentConfirmation = lazyRetry(
  () => import('./Unauthenticated/ReferentConfirmation')
)

function RequireAuth({
  children,
  checkOnboarding = true,
}: {
  children: ReactElement
  checkOnboarding?: boolean
}) {
  let location = useLocation()
  const params = useParams()
  const {
    isAuthenticated,
    authState: { user },
  } = useAuth()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, isLoading } = useGetEnums() // Launch call to access all enum in the app

  if (isLoading) return <Loader isCentered />

  // 1 - check if we are in maintenance
  if (env.MAINTENANCE_MODE) {
    return <Navigate to="/maintenance" state={{ from: location }} replace />
  }

  // 2 - check if user is authenticated
  if (!isAuthenticated()) {
    localStorage.removeItem('token')
    if (location.pathname.startsWith('/events/')) {
      return (
        <Navigate
          to={generatePath(routes.unauthenticated.signin, params)}
          state={{ isFromEvent: true, eventId: params.id }}
          replace
        />
      )
    }
    // For public events
    if (location.pathname.startsWith('/public-events/')) {
      return (
        <Navigate
          to={generatePath(routes.unauthenticated.publicEventDetails, params)}
          state={{ from: location }}
          replace
        />
      )
    }

    if (location.pathname === '/') {
      return <Navigate to="/welcome" replace />
    }

    return <Navigate to="/signin" state={{ from: location }} replace />
  }

  // 3 - check optional condition, if route requires user to have finished its onboarding
  if (checkOnboarding) {
    // Redirect to Signin Honor if user has not check
    if (
      location.pathname !== routes.authenticated.signinHonor &&
      user?.honourDeclarationOptin === false
    ) {
      return <Navigate to={routes.authenticated.signinHonor} replace />
    }

    // Redirect to onboarding if user has not finished it
    if (
      location.pathname !== routes.authenticated.eligibility &&
      user?.onboardingNeeded !== 'false'
    ) {
      return (
        <Navigate
          to={`${routes.authenticated.eligibility}?path=/api/v1/auth/stepper?step=${user?.onboardingStep}&type=${user?.onboardingNeeded}`}
          replace
        />
      )
    }
  }

  return children
}

const RequireRights = ({
  isAuthorized,
  children,
}: {
  isAuthorized: boolean | undefined
  children: ReactElement
}) => {
  let location = useLocation()

  if (!isAuthorized) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />
  }
  return children
}

const RoutesApp = () => {
  const {
    authState: { user },
  } = useAuth()

  return (
    <Routes>
      <Route
        path={routes.authenticated.home}
        element={
          <RequireAuth>
            <Layout.Dashboard />
          </RequireAuth>
        }
      >
        {/* ----->Les composants dans cette route contiendront le layout classique <-----*/}
        <Route
          index
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.sponsor}
          element={
            <RequireRights isAuthorized={isYoung(user)}>
              <Suspense fallback={<GlobalLoader />}>
                <Sponsor />
              </Suspense>
            </RequireRights>
          }
        />
        <Route
          path={routes.authenticated.preMatching}
          element={
            <RequireRights isAuthorized={canAccessPrematching(user)}>
              <Suspense fallback={<GlobalLoader />}>
                <PreMatching />
              </Suspense>
            </RequireRights>
          }
        />
        <Route
          path={routes.authenticated.youngprofile}
          element={
            <RequireRights isAuthorized={isSponsor(user)}>
              <Suspense fallback={<GlobalLoader />}>
                <Young />
              </Suspense>
            </RequireRights>
          }
        />
        <Route
          path={routes.authenticated.followedYoungs}
          element={
            <RequireRights isAuthorized={isSponsor(user)}>
              <Suspense fallback={<GlobalLoader />}>
                <FollowedYoungs />
              </Suspense>
            </RequireRights>
          }
        />
        <Route
          path={routes.authenticated.events}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Events />
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.eventDetails}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Event />
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.eventAdd}
          element={
            <RequireRights isAuthorized={!isYoung(user)}>
              <Suspense fallback={<GlobalLoader />}>
                <EventForm />
              </Suspense>
            </RequireRights>
          }
        />
        <Route
          path={routes.authenticated.eventEdit}
          element={
            <RequireRights isAuthorized={!isYoung(user)}>
              <Suspense fallback={<GlobalLoader />}>
                <EventForm />
              </Suspense>
            </RequireRights>
          }
        />
        <Route
          path={routes.authenticated.jobs}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <RequireAuth>
                <Jobs />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.jobAdd}
          element={
            <RequireRights isAuthorized={!isYoung(user)}>
              <Suspense fallback={<GlobalLoader />}>
                <RequireAuth>
                  <JobForm />
                </RequireAuth>
              </Suspense>
            </RequireRights>
          }
        />
        <Route
          path={routes.authenticated.jobEdit}
          element={
            <RequireRights isAuthorized={!isYoung(user)}>
              <Suspense fallback={<GlobalLoader />}>
                <RequireAuth>
                  <JobForm />
                </RequireAuth>
              </Suspense>
            </RequireRights>
          }
        />
        <Route
          path={routes.authenticated.documents}
          element={
            <RequireRights isAuthorized={isYoung(user)}>
              <Suspense fallback={<GlobalLoader />}>
                <RequireAuth>
                  <Documents />
                </RequireAuth>
              </Suspense>
            </RequireRights>
          }
        />
        <Route
          path={routes.authenticated.jobDetails}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Job />
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.messaging}
          element={
            <RequireAuth>
              <Suspense fallback={<GlobalLoader />}>
                <Messaging />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path={routes.authenticated.notifications}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Notifications />
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.invite}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Invite />
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.profile}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.settings}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Settings />
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.company}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Company />
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.companyForm}
          element={
            <RequireRights isAuthorized={isReferent(user)}>
              <Suspense fallback={<GlobalLoader />}>
                <CompanyForm />
              </Suspense>
            </RequireRights>
          }
        />
        <Route
          path={routes.authenticated.report}
          element={
            <RequireRights isAuthorized={!isReferent(user)}>
              <Suspense fallback={<GlobalLoader />}>
                <Report />
              </Suspense>
            </RequireRights>
          }
        />
        <Route
          path={routes.authenticated.services}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Services />
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.serviceDetail}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Service />
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.faq}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Faq />
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.faqCategory}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <FaqCategory />
            </Suspense>
          }
        />
        <Route
          path={routes.authenticated.faqQuestion}
          element={
            <Suspense fallback={<GlobalLoader />}>
              <FaqQuestion />
            </Suspense>
          }
        />

        {/* ----->Fin des composants qui contiennent le layout classique <-----*/}
      </Route>

      <Route
        path={routes.authenticated.verification}
        element={
          <RequireAuth checkOnboarding={false}>
            <Suspense fallback={<GlobalLoader />}>
              <Layout.Onboarding>
                <Verification />
              </Layout.Onboarding>
            </Suspense>
          </RequireAuth>
        }
      />
      <Route
        path={routes.authenticated.signinHonor}
        element={
          <RequireAuth checkOnboarding={false}>
            <Suspense fallback={<GlobalLoader />}>
              <Layout.Onboarding>
                <Honor />
              </Layout.Onboarding>
            </Suspense>
          </RequireAuth>
        }
      />

      <Route
        path={routes.authenticated.eligibility}
        element={
          <RequireAuth checkOnboarding={false}>
            <Suspense fallback={<GlobalLoader />}>
              <EligibilityForm>
                <Layout.Authentication />
              </EligibilityForm>
            </Suspense>
          </RequireAuth>
        }
      >
        <Route index element={<Eligibility />} />
      </Route>
      <Route
        path={routes.authenticated.eligibilityResult}
        element={
          <RequireAuth checkOnboarding={false}>
            <Suspense fallback={<GlobalLoader />}>
              <Layout.Informations>
                <EligibilityResult />
              </Layout.Informations>
            </Suspense>
          </RequireAuth>
        }
      />

      <Route
        path={routes.unauthenticated.welcome}
        element={
          <Suspense fallback={<GlobalLoader />}>
            <Layout.Default>
              <Welcome />
            </Layout.Default>
          </Suspense>
        }
      />
      <Route
        path={routes.unauthenticated.signin}
        element={
          <Suspense fallback={<GlobalLoader />}>
            <Layout.Onboarding>
              <Signin />
            </Layout.Onboarding>
          </Suspense>
        }
      />

      <Route
        path={routes.unauthenticated.register}
        element={
          <Suspense fallback={<GlobalLoader />}>
            <RegisterForm>
              <Layout.Authentication />
            </RegisterForm>
          </Suspense>
        }
      >
        <Route index element={<Register />} />
        <Route
          path={routes.unauthenticated.registerSummary}
          element={<Summary />}
        />
        <Route
          path={routes.unauthenticated.registerSummaryPE}
          element={<SummaryPE />}
        />
      </Route>
      <Route
        path={routes.unauthenticated.referentConfirmation}
        element={
          <Suspense fallback={<GlobalLoader />}>
            <Layout.Informations>
              <ReferentConfirmation />
            </Layout.Informations>
          </Suspense>
        }
      />
      <Route
        path={routes.unauthenticated.forgotPassword}
        element={
          <Suspense fallback={<GlobalLoader />}>
            <Layout.Onboarding>
              <ForgotPassword />
            </Layout.Onboarding>
          </Suspense>
        }
      />
      <Route
        path={routes.unauthenticated.forgotPasswordVerification}
        element={
          <Suspense fallback={<GlobalLoader />}>
            <Layout.Informations>
              <ForgotPasswordVerification />
            </Layout.Informations>
          </Suspense>
        }
      />
      <Route
        path={routes.unauthenticated.resetPassword}
        element={
          <Suspense fallback={<GlobalLoader />}>
            <Layout.Onboarding>
              <ResetPassword />
            </Layout.Onboarding>
          </Suspense>
        }
      />
      <Route
        path={routes.unauthenticated.inscription}
        element={
          <Suspense fallback={<GlobalLoader />}>
            <Layout.Informations>
              <Inscription />
            </Layout.Informations>
          </Suspense>
        }
      />
      <Route
        path={routes.unauthenticated.reonboarding}
        element={
          <Suspense fallback={<GlobalLoader />}>
            <Layout.Informations>
              <ReOnboarding />
            </Layout.Informations>
          </Suspense>
        }
      />
      <Route
        path={routes.unauthenticated.resetPasswordConfirmation}
        element={
          <Suspense fallback={<GlobalLoader />}>
            <Layout.Informations>
              <ResetPasswordConfirmation />
            </Layout.Informations>
          </Suspense>
        }
      />
      <Route
        path={routes.unauthenticated.emailVerification}
        element={
          <Suspense fallback={<GlobalLoader />}>
            <Layout.Informations>
              <EmailVerification />
            </Layout.Informations>
          </Suspense>
        }
      />
      <Route
        path={routes.unauthenticated.referentInvitation}
        element={
          <Suspense fallback={<GlobalLoader />}>
            <ReferentInvitation />
          </Suspense>
        }
      />
      <Route
        path={routes.unauthenticated.publicEventDetails}
        element={
          <Suspense fallback={<GlobalLoader />}>
            <PublicEvent />
          </Suspense>
        }
      />

      <Route
        path="*"
        element={
          <Suspense fallback={<GlobalLoader />}>
            <NotFound />
          </Suspense>
        }
      />
      <Route
        path="/expired"
        element={
          <Suspense fallback={<GlobalLoader />}>
            <ExpiredSession />
          </Suspense>
        }
      />
      <Route
        path="/unauthorized"
        element={
          <Suspense fallback={<GlobalLoader />}>
            <Unauthorized />
          </Suspense>
        }
      />
      <Route
        path="/maintenance"
        element={
          <Suspense fallback={<GlobalLoader />}>
            <Maintenance />
          </Suspense>
        }
      />
    </Routes>
  )
}

export default Sentry.withSentryReactRouterV6Routing(RoutesApp)
