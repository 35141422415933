import { EventWidget, JobWidget } from '~components'

import ServicesWidget from './ServicesWidget'
import YoungWidget from './YoungWidget'

type Props = {
  user: User
}

const DashboardSponsor = ({ user }: Props) => {
  return (
    <>
      <YoungWidget matchs={user.matchs} />
      <EventWidget />
      <JobWidget />
      <ServicesWidget />
    </>
  )
}

export default DashboardSponsor
