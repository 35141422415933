import { Box } from '~design-system'
import { SpacingProps } from '~design-system/helpers'

import { Step } from './styles'

type Props = {
  activeStep?: number
  totalStep: number
} & SpacingProps

const Stepper = ({ totalStep, activeStep = 1, ...props }: Props) => {
  const renderSteps = () => {
    const steps = []
    for (let i = 1; i <= totalStep; i++) {
      steps.push(<Step key={i} active={activeStep >= i} />)
    }
    return steps
  }

  return (
    <Box di="f" fd="row" {...props}>
      {renderSteps()}
    </Box>
  )
}

export default Stepper
