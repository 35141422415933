import styled, { css } from 'styled-components'

const Nav = styled('nav')`
  ${() => css`
    flex-grow: 1;
    overflow-y: auto;
  `}
`

export default Nav
