import { Box, Icon, Text } from '~design-system'

import { ErrorContainer } from './styles'

export default function ErrorBox({ error }: { error: string }) {
  return (
    <ErrorContainer>
      <Box pr="2">
        <Text textColor="danger">{error}</Text>
      </Box>
      <Icon name="warning" color="danger" />
    </ErrorContainer>
  )
}
