import { SmallText } from '~design-system'
import { getDateSincePublication } from '~helpers'

interface Props {
  date: string
}

const Publication = ({ date }: Props) => {
  const since = getDateSincePublication(date)
  const publication = since ? `Publiée ${since}` : 'Publiée'

  return <SmallText textColor="greyDarkest">{publication}</SmallText>
}

export default Publication
