import { useMemo } from 'react'

import { Box, Icon, Text } from '~design-system'
import { DisplayProps, LayoutProps, SpacingProps } from '~design-system/helpers'

import { PaginationButton, PaginationItem } from './styles'

type Props = {
  currentPage: number
  totalPage: number
  onChange: (page: number) => void
} & SpacingProps &
  DisplayProps &
  LayoutProps

const Pagination = ({ currentPage, totalPage, onChange, ...rest }: Props) => {
  // Check if current page is the first one
  const isFirst = useMemo(() => {
    return currentPage === 1
  }, [currentPage])

  // Check if current page is the last one
  const isLast = useMemo(() => {
    return currentPage === totalPage
  }, [currentPage, totalPage])

  // Render Pages Number
  const renderPagesNumber = () => {
    const pagesNumber = []

    // Define Number of Pages to Show after/before the CurrentPage
    // In general, we show one page before and one page after, but :
    // - if currentPage is the first one, we need to show 2 pages after
    // - if currentPage is the last one, we need to show 2 pages before
    const pagesBeforeCurrent = isLast ? currentPage - 2 : currentPage - 1
    const pagesAfterCurrent = isFirst ? currentPage + 2 : currentPage + 1

    // We Only Show PaginationItems Between pagesBeforeCurrent AND pagesAfterCurrent
    for (let page = 1; page <= totalPage; page++) {
      page >= pagesBeforeCurrent &&
        page <= pagesAfterCurrent &&
        pagesNumber.push(
          <PaginationItem
            key={page}
            aria-current={currentPage ? 'page' : 'false'}
          >
            <Text
              textAlign="center"
              textColor={page === currentPage ? 'primary' : 'greyDarkest'}
              weight={page === currentPage ? 'medium' : 'regular'}
            >
              {page}
            </Text>
          </PaginationItem>
        )
    }
    return pagesNumber
  }

  return (
    <Box di="f" ai="ce" jc="ce" g="5px" {...rest}>
      <PaginationButton
        type="button"
        onClick={() => onChange(currentPage - 1)}
        disabled={currentPage <= 1}
      >
        <Icon
          name="chevron-left"
          size="smaller"
          color={isFirst ? 'greyDark' : 'primary'}
        />
      </PaginationButton>

      {renderPagesNumber()}

      <PaginationButton
        type="button"
        onClick={() => onChange(currentPage + 1)}
        disabled={currentPage >= totalPage}
      >
        <Icon
          name="chevron-right"
          size="smaller"
          color={isLast ? 'greyDark' : 'primary'}
        />
      </PaginationButton>
    </Box>
  )
}

export default Pagination
