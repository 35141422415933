import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const Container = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-bottom: 1px solid ${theme.colors.blueLighter};
    height: ${theme.components.headerBanner.height};
    position: fixed;
    width: calc(100vw - ${theme.spaces[64]});
    z-index: 5;
    padding-right: ${theme.spaces[6]};
    top: 0;

    @media (max-width: 700px) {
      width: 100vw;
      padding-right: ${theme.spaces[3]};
    }
  `}
`

export default Container
