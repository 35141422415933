import { useFormContext } from 'react-hook-form'

import {
  CheckboxOptionsProps,
  MultipleOptions,
} from '~components/Chat/components'
import { getType } from '~components/Chat/helpers'

type Props = {
  dialog: Stepper
  dialogType: ApiGetStepResponse['data'][0]['type']
  index: number
  setAnswers: (answer: string[]) => void
  handleAnswer: Function
}

const OptionMultipleProfessionalSituations = ({
  dialog,
  dialogType,
  index,
  setAnswers,
  handleAnswer,
}: Props) => {
  const { getValues } = useFormContext()

  const dialogTypes = dialog.map(({ type }) => type)
  const isLastOfType = (dialogType: string, dialogTypeIndex: number) => {
    return dialogTypes[dialogTypeIndex - 1] !== dialogType
  }
  if (!isLastOfType(dialogType, index)) return null

  const answer = getType(dialog, 'option_multiple_professional_situations')
  const rawLabel = dialog.filter((dialog) => dialog.type === 'label')

  const label =
    rawLabel && rawLabel.length >= 1
      ? rawLabel[0].label
      : 'Choisis une ou plusieurs option(s)'

  const options = answer.map(({ label, bodyparam }) => {
    if (!bodyparam) {
      return {
        id: label,
        title: label.trim(),
        value: false,
      }
    } else {
      const parsedBodyParam = JSON.parse(bodyparam)
      const key = Object.values(parsedBodyParam)[0] as string
      const value = Object.values(parsedBodyParam)[0] as string

      return {
        id: key,
        title: label.trim(),
        value:
          (value as string).toString().toLowerCase() === 'oui' ||
          (value as string).toString().toLowerCase() === 'true',
      }
    }
  })

  const handleProfessionalSituationAnswer =
    (answer: Answer[]) => (options: CheckboxOptionsProps[]) => {
      const optionValues = options.filter(({ id }) => getValues(id))
      const formatedAnswers = {
        professional_situations: optionValues.map(({ id }) => id),
      }

      setAnswers(optionValues.map(({ title }) => title))
      handleAnswer(answer[0].action, formatedAnswers)
    }

  return (
    <MultipleOptions
      key={dialogType + index}
      label={label}
      onSubmit={handleProfessionalSituationAnswer(answer)}
      options={options}
    />
  )
}

export default OptionMultipleProfessionalSituations
