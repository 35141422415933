import { ComponentProps, InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import {
  DisplayProps,
  LayoutProps,
  SpacingProps,
  display,
  layout,
  spacing,
} from '~design-system/helpers'

import { SmallText } from '../../Typographies'

type Props = ComponentProps<typeof SmallText> &
  InputHTMLAttributes<HTMLInputElement> &
  SpacingProps &
  DisplayProps &
  LayoutProps

const Input = styled(SmallText).attrs({ as: 'input' })<Props>`
  ${({ theme, backgroundColor }) => css`
    outline: none;
    padding: ${theme.spaces[3]} ${theme.spaces[4]};
    transition: border-color 0.3s;
    flex: 1;
    background-color: ${backgroundColor || theme.colors.white};
    border-radius: ${theme.radii.lg};
    ${spacing};

    &::placeholder {
      color: ${theme.colors.greyDarkest};
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
      background-color: ${theme.colors.greyLighter};
    }
  `}
  ${display};
  ${layout};
`

export default Input
