import { useMutation, useQuery } from 'react-query'

import { UserService, queryClient } from '~api'

function useUser() {
  const useGetUser = (token: string | null) =>
    useQuery(['user'], UserService.apiGetUser, { enabled: !!token })

  const useUpdateUser = () =>
    useMutation(UserService.apiUpdateUser, {
      onSuccess: (data) => {
        queryClient.setQueryData(['user'], { user: data.user })
      },
    })

  const useVerifyAddress = () =>
    useMutation(UserService.apiVerifyAddress, {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
      },
    })

  const useGetUserById = (id: string) =>
    useQuery(['user', id], () => UserService.apiGetUserById(id))

  const useUploadDocument = () =>
    useMutation(UserService.apiUploadUserDoc, {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
        queryClient.invalidateQueries('documents')
      },
    })

  const useGetDocument = () =>
    useQuery(['documents'], UserService.apiGetUserDocument)

  const useDeleteDocument = () =>
    useMutation(UserService.apiDeleteDocument, {
      onSuccess: () => {
        queryClient.invalidateQueries('documents')
      },
    })

  const useUpdatePassword = () =>
    useMutation(UserService.apiUpdatePassword, {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
      },
    })

  const useReferentBecomeSponsor = () =>
    useMutation(UserService.apiReferentBecomeSponsor, {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
      },
    })
  return {
    useGetUser,
    useUpdateUser,
    useVerifyAddress,
    useGetUserById,
    useUploadDocument,
    useDeleteDocument,
    useUpdatePassword,
    useGetDocument,
    useReferentBecomeSponsor,
  }
}

export default useUser
