import styled, { css } from 'styled-components'

export type ImageProps = {
  url?: string
}

const ServiceImage = styled('div')<ImageProps>`
  ${({ url, theme }) => css`
    border-radius: 50%;
    border: 1px solid ${theme.colors.grey};
    height: ${theme.pxToRem(40)};
    width: ${theme.pxToRem(40)};
    background: ${url && `url(${url}) no-repeat center/cover`};
    margin-right: ${theme.spaces[4]};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  `}
`

export default ServiceImage
