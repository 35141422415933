import { useState } from 'react'

import WeatherModal from '~components/WeatherModal'

function useWeatherModal(matchId?: number) {
  const [open, setOpen] = useState<boolean>(false)

  if (!matchId) {
    return null
  }

  const renderModal = () => {
    if (!open) return null

    return (
      <WeatherModal open matchId={matchId} onClose={() => setOpen(false)} />
    )
  }

  return { setOpen, renderModal }
}

export default useWeatherModal
