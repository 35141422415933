import styled from 'styled-components'

const Container = styled('div')`
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  padding-inline: 15px;
`

export default Container
