import { fr } from 'date-fns/locale'
import { DateRange, DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'

import { theme } from '~config'
import { Box } from '~design-system'

type Props = {
  dateRange: DateRange | undefined
  onChange: (dates: DateRange) => void
}

const DatePicker = ({ dateRange, onChange }: Props) => (
  <Box>
    <DayPicker
      defaultMonth={dateRange?.from || undefined}
      mode="range"
      locale={fr}
      modifiersStyles={{
        selected: { backgroundColor: theme.colors.primary },
      }}
      styles={{
        caption: {
          color: theme.colors.primary,
          fontFamily: theme.fonts.primary,
          fontWeight: theme.weights.regular,
          fontSize: theme.spaces['3'],
        },
        cell: {
          width: '38px',
          height: '38px',
        },
        day: {
          width: '35px',
          height: '35px',
        },
        root: {
          margin: 0,
        },
      }}
      selected={dateRange}
      onSelect={(dates) => dates && onChange(dates)}
    />
  </Box>
)

export default DatePicker
