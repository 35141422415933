import styled from 'styled-components'

import { theme } from '~config'
import { Text } from '~design-system'

const SidebarItemTitle = styled(Text)`
  font-size: 0.8rem;
  color: ${theme.colors.black};
  font-weight: 500;
`

export default SidebarItemTitle
