import { routes } from '~config'

export const getRouteForNotification = (
  section: NQTNotification['section'],
  sectionId: NQTNotification['sectionId']
) => {
  switch (section) {
    case 'event':
      return {
        route: sectionId
          ? routes.authenticated.eventDetails
          : routes.authenticated.events,
        param: sectionId ? { id: sectionId.toString() } : undefined,
      }
    case 'job-offer':
      return {
        route: sectionId
          ? routes.authenticated.jobDetails
          : routes.authenticated.jobs,
        param: sectionId ? { id: sectionId.toString() } : undefined,
      }
    case 'document':
      return {
        route: routes.authenticated.documents,
        param: undefined,
      }
    case 'profile':
      return {
        route: routes.authenticated.profile,
        param: undefined,
      }
    case 'service':
      return {
        route: sectionId
          ? routes.authenticated.serviceDetail
          : routes.authenticated.services,
        param: sectionId ? { id: sectionId.toString() } : undefined,
      }
    case 'young':
      return {
        route: routes.authenticated.youngprofile,
        param: sectionId ? { id: sectionId.toString() } : undefined,
      }
    case 'sponsor':
      return {
        route: routes.authenticated.sponsor,
        param: sectionId ? { id: sectionId.toString() } : undefined,
      }
    case 'report':
      return {
        route: routes.authenticated.report,
        param: sectionId ? { id: sectionId.toString() } : undefined,
      }
    case 'message':
      return {
        route: routes.authenticated.messaging,
        param: sectionId ? { id: sectionId.toString() } : undefined,
      }
    default:
      return {
        route: routes.authenticated.home,
        param: undefined,
      }
  }
}
