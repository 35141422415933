import { useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { Box, Text } from '~design-system'

import Stepper from './components/Stepper'
import { usePasswordStrength } from './hooks/usePasswordStrength'

const Label = styled('span')`
  ${({ color }) => css`
    color: ${color};
  `}
`

type Props = {
  name: string
}

const PasswordStrength = ({ name }: Props) => {
  const { watch } = useFormContext()

  if (watch === undefined) {
    throw new Error('PasswordStrength must be used within a FormProvider')
  }

  const { strength, label, color } = usePasswordStrength(watch(name))

  return (
    <Box>
      <Box>
        <Text>
          Force du mot de passe :{' '}
          <Label as="span" color={color}>
            {label}
          </Label>
        </Text>
      </Box>
      <Box w="100%" maxW={80}>
        <Stepper strength={strength} />
      </Box>
    </Box>
  )
}

export default PasswordStrength
