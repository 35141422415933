import { useEffect, useState } from 'react'

import { KnownItemService } from '~api'
import { Button, Header3, PopIn, Select } from '~design-system'
import { useUser } from '~hooks'

type props = {
  source?: string
}

const KnowledgePopUp = (props: props) => {
  const [isVisible, setVisible] = useState(!props.source)
  const [selectedValue, setValue] = useState<string>()
  const [knowledgeList, setKnowledgeList] =
    useState<APIGetMiscKnownItemsResponse>()
  const { useUpdateUser } = useUser()

  const getList = async () => {
    try {
      const response = await KnownItemService.getKnownItems()
      setKnowledgeList(response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    isVisible && getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mappedList = () => {
    if (knowledgeList)
      return knowledgeList.map((item) => ({
        value: item.valueSF,
        label: item.labelSF,
      }))
    return []
  }

  const { mutate: updateUser } = useUpdateUser()

  return (
    <PopIn open={isVisible} closable onClose={() => setVisible(false)}>
      <PopIn.Picture>
        <img src="/images/validation.svg" alt="" />
      </PopIn.Picture>
      <PopIn.Content>
        <Header3 weight="medium">Je suis curieux...</Header3>
        <Header3 weight="medium" mb={6}>
          Comment as-tu connu NQT? 😊
        </Header3>
        <Select options={mappedList()} onChange={(e) => setValue(e?.value)} />
      </PopIn.Content>
      <PopIn.Actions
        actions={[
          <Button
            key="validate-knowledge-of-NQT"
            onClick={() => {
              updateUser({ source: selectedValue })
              setVisible(false)
            }}
          >
            Valider
          </Button>,
        ]}
      />
    </PopIn>
  )
}

export default KnowledgePopUp
