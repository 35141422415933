import styled, { css, keyframes } from 'styled-components'

import Box from '~design-system/Box'

type Props = {
  isOpen: boolean
}

const accordion = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
    `

const AccordionContent = styled(Box).attrs((props: Props) => ({
  isOpen: props.isOpen,
}))<Props>`
  ${({ isOpen }) => css`
    cursor: default;
    animation: ${accordion} 0.5s ease-in-out;
  `}
`

export default AccordionContent
