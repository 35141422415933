import { formatDistanceStrict } from 'date-fns'
import { fr } from 'date-fns/locale'
import { FormEvent, useState } from 'react'
import { Link, generatePath, useNavigate } from 'react-router-dom'

import { routes } from '~config'
import {
  Box,
  Button,
  Divider,
  Icon,
  Panel,
  SmallText,
  Text,
} from '~design-system'

import JobDeletePopin from './JobDeletePopIn'

interface Props {
  job: Job
}

const JobCard = ({ job }: Props) => {
  const navigate = useNavigate()
  const [deletePopin, setDeletePopin] = useState<boolean>(false)

  const date = formatDistanceStrict(new Date(), new Date(job.createdAt), {
    unit: 'day',
    locale: fr,
  })

  const onClickEdit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    navigate(
      generatePath(routes.authenticated.jobEdit, {
        id: job.id.toString(),
      })
    )
  }

  const onClickDelete = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setDeletePopin(true)
  }

  return (
    <>
      <Panel
        h="100%"
        di="f"
        jc="fs"
        as={Link}
        to={generatePath(routes.authenticated.jobDetails, {
          id: job.id.toString(),
        })}
      >
        <Box di="f" fd="c">
          <Box di="f" ai="b" jc="sb">
            <SmallText textColor="greyDarkest">
              {job.companyName}
              {job.address?.properties?.city &&
                ` - ${job.address?.properties?.city}`}
            </SmallText>
            {job.canEdit && (
              <Box di="f" ai="ce">
                <Button
                  onClick={(e: FormEvent<HTMLFormElement>) => onClickEdit(e)}
                  size="small"
                  option="unfilled"
                  pr="0"
                  pl="0"
                  pt={1}
                  pb={1}
                  h="auto"
                >
                  <Icon name="pencil" color="primary" size="smaller" />
                </Button>
                <Button
                  onClick={(e: FormEvent<HTMLFormElement>) => onClickDelete(e)}
                  size="small"
                  option="unfilled"
                  pr="0"
                  pl="0"
                  pt={1}
                  pb={1}
                  h="auto"
                >
                  <Icon name="trash" color="primary" size="smaller" />
                </Button>
              </Box>
            )}
          </Box>

          <Text weight="medium">
            {job?.title?.charAt(0).toUpperCase() +
              job?.title?.toLowerCase().slice(1)}
          </Text>
          <Divider mt={3} mb={3} />
          {(job.contractType || job.grossAnnualSalary) && (
            <Box di="f" ai="ce" mb={2} g="5px">
              <Icon name="briefcase" color="primary" />
              <SmallText textColor="greyDarkest">
                {job.contractType && `${job.contractType}`}
                {job.contractType && job.grossAnnualSalary && ' - '}
                {job.grossAnnualSalary && `${job.grossAnnualSalary} € / an`}
              </SmallText>
            </Box>
          )}
          {job.createdAt && (
            <Box di="f" ai="ce" mb={2} g="5px">
              <Icon name="pin" color="primary" />
              <SmallText textColor="greyDarkest">
                {date === '0 jours'
                  ? `Publiée aujourd'hui`
                  : `Publiée il y a ${date}`}
              </SmallText>
            </Box>
          )}
          {job.numberAttendees !== null && (
            <Box di="f" ai="b" g="5px">
              <Icon name="team" color="primary" />
              <SmallText textColor="greyDarkest">
                {`${job.numberAttendees} candidature(s)`}
              </SmallText>
            </Box>
          )}
        </Box>
      </Panel>
      {deletePopin && (
        <JobDeletePopin jobID={job.id.toString()} onClose={setDeletePopin} />
      )}
    </>
  )
}

export default JobCard
