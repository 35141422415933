import api from '../api'

const apiPostMatchingFeeling = ({
  id,
  data,
}: {
  id: number
  data: WeatherForm
}) =>
  api
    .post<ApiResponse<null>>(`/api/v1/user/matching/${id}/feeling`, data)
    .then((response) => response.data)
    .catch((error) => {
      return Promise.reject(error.response.data.message)
    })
const MatchingService = {
  apiPostMatchingFeeling,
}
export default MatchingService
