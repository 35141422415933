import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { JobSelector } from '~components'
import { JobSelectorProps } from '~components/Selectors/Jobs'
import { Box, Button } from '~design-system'

type Props = {
  name: JobSelectorProps['name']
  onSubmit: () => void
}

export default function JobSearch({ name, onSubmit }: Props) {
  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
  } = useFormContext()

  useEffect(() => {
    setValue(name, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <JobSelector name={name} />
      <Box mt={4}>
        <Button
          type="submit"
          data-testid="job-submit"
          disabled={!isValid || !isDirty}
        >
          Valider
        </Button>
      </Box>
    </form>
  )
}
