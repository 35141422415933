import { format, sub } from 'date-fns'
import { KeyboardEvent, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { REQUIRED_VALIDATION } from '~config'
import { Button, Icon, InputText } from '~design-system'
import { birthdayRegex } from '~helpers'

type Props = {
  id: string
  onSubmit: () => void
  placeholder: string
  pattern?: RegExp
}

const InputDate = ({
  id,
  onSubmit,
  placeholder,
  pattern = birthdayRegex,
}: Props) => {
  const {
    setFocus,
    formState: { dirtyFields, errors, isValid },
    register,
  } = useFormContext()

  useEffect(() => {
    setFocus(id)
  }, [id, setFocus])

  const submit = () => {
    if (dirtyFields[id] && isValid) {
      onSubmit()
    }
  }

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submit()
    }
  }

  return (
    <InputText
      id={id}
      type="text"
      onKeyUp={handleKeyUp}
      errorMessage={errors[id] && errors[id].message}
      placeholder={placeholder}
      {...register(id, {
        ...REQUIRED_VALIDATION,
        pattern: {
          value: pattern,
          message: 'Veuillez entrer une date au bon format',
        },
        validate: (value) =>
          (value.slice(6, 10) <= format(new Date(), 'yyyy') &&
            value.slice(6, 10) >
              format(
                sub(new Date(), {
                  years: 99,
                }),
                'yyyy'
              )) ||
          'Veuillez entrer une date au bon format',
      })}
      rightComponent={
        <Button
          disabled={!dirtyFields[id] || errors[id]}
          onClick={submit}
          size="small"
          square
          title="Envoyer"
        >
          <Icon name="send" />
        </Button>
      }
    />
  )
}

export default InputDate
