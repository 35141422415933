import { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Box, Button, Icon, InputText } from '~design-system'

const SearchBar = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  // Handle OnSubmit (Set Query)
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    const value = (e.currentTarget.elements[0] as HTMLInputElement).value
    if (!value) {
      searchParams.delete('search')
      setSearchParams(searchParams)
    } else {
      setSearchParams({
        search: value,
      })
    }
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <InputText
        placeholder="Rechercher ici votre question"
        type="text"
        name="search"
        defaultValue={searchParams.get('search') || ''}
        mb={4}
        rightComponent={
          <Button type="submit" option="unfilled" pr={2} pl={2}>
            <Icon name="search" color="primary" mr={2} />
          </Button>
        }
        autoFocus
      />
      <Box ha="1024px" mb={8}>
        <Button type="submit" fullWidth>
          Rechercher
        </Button>
      </Box>
    </form>
  )
}

export default SearchBar
