import { useFormContext } from 'react-hook-form'

import { ChecklistSelector } from '~components'
import { Box, Button, PopIn } from '~design-system'
import { useData } from '~hooks'

type Props = {
  onClose: () => void
  onSubmit: () => void
}

export default function SkillsPopin({ onClose, onSubmit }: Props) {
  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useFormContext()
  const { useGetSkills } = useData()
  const { data: skills, isLoading } = useGetSkills()

  const handleRequest = () => {
    onSubmit()
  }

  return (
    <PopIn open closable onClose={onClose}>
      <form onSubmit={handleSubmit(handleRequest)}>
        <PopIn.Title textAlign="left">
          <Box mb={12}>Thèmes d’accompagnement</Box>
        </PopIn.Title>
        <PopIn.Content>
          {isLoading || !skills ? null : (
            <ChecklistSelector name="skills" options={skills} />
          )}
        </PopIn.Content>
        <PopIn.Actions
          actions={[
            <Button
              key="save"
              disabled={!isValid || isSubmitting}
              type="submit"
              data-testid="skills-submit"
              isLoading={isLoading}
            >
              Enregistrer
            </Button>,
          ]}
        />
      </form>
    </PopIn>
  )
}
