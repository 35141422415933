import styled, { Theme, css } from 'styled-components'

import {
  DisplayProps,
  LayoutProps,
  SpacingProps,
  display,
  layout,
  spacing,
} from '../helpers'

type Props = {
  color?: keyof Theme['colors']
  textColor?: keyof Theme['colors']
} & DisplayProps &
  LayoutProps &
  SpacingProps &
  React.ComponentProps<'p'>

const Badge = styled('p').attrs((props: Props) => ({
  color: props.color || 'greyLight',
  textColor: props.textColor || 'primary',
}))<Props>`
  ${({ theme, color, textColor }) => css`
    border-radius: 23px;
    height: 23px;
    width: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    font-weight: 600;
    color: ${theme.colors[textColor]};
    background-color: ${theme.colors[color]};
    margin-left: ${theme.spaces[2]};
  `}
  ${display};
  ${layout};
  ${spacing};
`

export default Badge
