import { useState } from 'react'

import { Box } from '~design-system'

import SectorSelect from './SectorSelect'
import SubsectorSelect from './SubsectorSelect'

const SectorSelector = () => {
  const [sectorID, setSectorID] = useState<number>(1)

  return (
    <Box w="100%">
      <SectorSelect handleSectorChange={setSectorID} />
      <SubsectorSelect sectorID={sectorID} />
    </Box>
  )
}

export default SectorSelector
