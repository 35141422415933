import { EditIcon } from '~components'
import { Box, Panel, SmallText, Text, TinyText } from '~design-system'
import { getLanguageLevelLabel } from '~helpers'

interface Props {
  isEditable?: boolean
  language: Language
  setEditInfos: Function
}

const LanguageCard = ({
  language,
  isEditable = false,
  setEditInfos,
}: Props) => {
  return (
    <Panel pb={4} pt={4}>
      <Box di="f" fd="r" ai="ce" jc="sb">
        <Box>
          <Text weight="medium">{language.name}</Text>
          <SmallText textColor="greyDarkest">
            {getLanguageLevelLabel(language.level)}
          </SmallText>
          <TinyText textColor="primary">{language.certification}</TinyText>
        </Box>
        <EditIcon
          isVisible={isEditable}
          label="Modifier votre langue"
          onClick={() =>
            setEditInfos({
              slug: 'languages',
              title: 'Modifier votre langue',
              data: language,
            })
          }
        />
      </Box>
    </Panel>
  )
}

export default LanguageCard
