import styled, { css, keyframes } from 'styled-components'

const jump = keyframes`
0% {
  transform: translate3d(0, 0, 0);
}

15% {
  transform: translate3d(0, -8px, 0);
}

30% {
  transform: translate3d(0, 0, 0);
}

100% {
  transform: translate3d(0, 0, 0);
}
`

type Props = {
  index: number
}

const Dot: any = styled('i').attrs((props: Props) => ({
  index: props.index,
}))<Props>`
  ${({ theme, index }) => css`
    background-color: ${index % 2 === 1
      ? theme.colors.blue
      : theme.colors.primary};
    display: inline-flex;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin: 0 ${theme.spaces[1]};
    animation: ${jump} 1s cubic-bezier(0.17, 0.67, 0.83, 0.67) ${index * 0.15}s
      infinite;
  `}
`

const TypingDots = () => {
  const renderDots = () => {
    const dots = []
    for (let i = 0; i < 3; i++) {
      dots.push(<Dot key={i} index={i} />)
    }
    return dots
  }
  return <>{renderDots()}</>
}

export default TypingDots
