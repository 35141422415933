import { useMutation } from 'react-query'

import { UserService, queryClient } from '~api'

function useYoungQualification() {
  const useUpdateYoungQualification = () =>
    useMutation(UserService.apiUpdateYoungQualification, {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
      },
    })

  return {
    useUpdateYoungQualification,
  }
}

export default useYoungQualification
