import { ReactNode, createContext, useContext, useState } from 'react'

import { Toast } from '~components'
import { Box } from '~design-system'

type ToastContextState = {
  addToast: (toast: Toast) => void
}

const ToastContext = createContext({} as ToastContextState)

type Props = {
  children: ReactNode
}

export function ToastProvider({ children }: Props) {
  const [toasts, setToasts] = useState<Toast[]>([])

  const addToast = (toast: Toast) => {
    setToasts((toasts) => [...toasts, toast])
    setTimeout(() => setToasts((toasts) => toasts.slice(1)), 4500)
  }

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <Box pos="fixed" top="24" right="4" zIndex={1000}>
        {toasts.map(({ title, subtitle, type }, index) => (
          <Toast
            title={title}
            subtitle={subtitle}
            type={type}
            key={`toast-${index}`}
          />
        ))}
      </Box>
    </ToastContext.Provider>
  )
}

export default ToastContext

export function useToast() {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}
