import api from '~api/api'

const apiGetPreMatchings = () =>
  api
    .get<ApiResponse<PreMatching[]>>(`api/v1/user/pre-matchings`)
    .then((response) => {
      return response.data.data
    })

const apiPostConfirmPreMatching = ({
  payload,
  id,
}: {
  payload: {
    message: string
  }
  id: number
}) => {
  return api
    .post<ApiResponse<ApiPostConfirmPreMatching>>(
      `api/v1/user/pre-matchings/${id}/confirm`,
      payload
    )
    .then((response) => response.data)
}

const PreMatchingService = {
  apiGetPreMatchings,
  apiPostConfirmPreMatching,
}

export default PreMatchingService
