import { ReactNode, useEffect, useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

import { Box, Panel } from '~design-system'
import Icon from '~design-system/Icon'
import { SmallText, TinyText } from '~design-system/Typographies'

import {
  CloseButton,
  DropzoneContainer,
  InputContainer,
  Thumbnail,
} from './styles'

type Props = {
  children: ReactNode
  file?: string[]
  setFile: (file?: string[] | null[]) => void
  formError?: string
} & DropzoneOptions

const DropZone = ({
  children,
  file = [],
  setFile,
  formError,
  ...rest
}: Props) => {
  const [thumbnailIsLoaded, setThumbnailIsLoaded] = useState<boolean>(true)
  const [errors, setErrors] = useState('')
  const { getRootProps, getInputProps } = useDropzone({
    ...rest,
    maxSize: 2000000, //2M max filesize
    onDrop: function (acceptedFiles, rejected, e) {
      setErrors('')
      rejected.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setErrors(`Le fichier est trop volumineux (max: 2Mo)`)
          }

          if (err.code === 'file-invalid-type') {
            setErrors(
              `Le type de fichier est incorrect (acceptés: pdf, jpg, gif, png)`
            )
          }
        })
      })

      if (rejected.length === 0) {
        var reader = new FileReader()
        reader.onload = function (e) {
          setFile([e.target?.result as string])
        }

        reader.readAsDataURL(acceptedFiles[0])
        rest.onDrop && rest.onDrop(acceptedFiles, rejected, e)
      }
    },
  })

  useEffect(() => {
    if (formError) {
      setErrors(formError)
    }
  }, [formError])

  const renderFile = () => {
    return (
      <Box pos="relative" di="flex" fd="column">
        <CloseButton
          type="button"
          onClick={(e) => {
            e.stopPropagation()
            setFile([null])
          }}
        >
          <Icon name="close" color="primary" size="tiny" />
        </CloseButton>
        {thumbnailIsLoaded ? (
          <Thumbnail
            src={file[0]}
            alt="file"
            onError={() => setThumbnailIsLoaded(false)}
          />
        ) : (
          <Panel mt={4} w={40} minH="5rem" backgroundColor="greyLightest">
            <SmallText textAlign="center">Aucun aperçu disponible</SmallText>
          </Panel> // Show Empty Box if Image can't be loaded
        )}
      </Box>
    )
  }

  return file?.length > 0 ? (
    renderFile()
  ) : (
    <>
      <DropzoneContainer>
        <InputContainer {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} data-testid="upload-file" />
          {children}
        </InputContainer>
      </DropzoneContainer>
      {errors && (
        <TinyText textColor="danger" mt={2}>
          {errors}
        </TinyText>
      )}
    </>
  )
}

export default DropZone
