import styled, { css } from 'styled-components'

import Conversation from '~components/DiscussionPanel/Conversation'
import { useAuth } from '~context/AuthContext'
import { Box, TinyText } from '~design-system'
import { useThreads } from '~hooks'

const StyledBox = styled(Box)`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.greyLight};
    padding: ${theme.spaces[5]};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 50%;
  `}
`

const StyledList = styled('ul')`
  overflow: scroll;
`

const DiscussionPanel = () => {
  const {
    authState: { user },
  } = useAuth()

  const { useGetThreads } = useThreads()
  const { data: threads } = useGetThreads()

  return !!threads?.length ? (
    <StyledBox>
      <TinyText textColor="greyDarkest" mb={2} uppercase>
        Discussions
      </TinyText>
      <StyledList>
        {threads?.map((thread) => (
          <li key={thread.id}>
            <Conversation thread={thread} />
          </li>
        ))}
        {user?.waitingMatchs.map((wm) => (
          <li key={wm.id}>
            <Conversation
              thread={{
                id: wm.id,
                subject: 'conversation en attente',
                participants: [
                  {
                    id: wm.id,
                    fullName: wm.fullName,
                  },
                ],
                isActive: true,
              }}
              isWaiting
            />
          </li>
        ))}
      </StyledList>
    </StyledBox>
  ) : null
}

export default DiscussionPanel
