import styled, { css } from 'styled-components'

type Props = {
  withText: boolean
}

const SwitchLabel = styled('label')<Props>`
  ${({ theme, withText }) => css`
    background: ${withText ? theme.colors.primary : theme.colors.grey};
    border-radius: 20px;
    cursor: pointer;
    display: block;
    height: 20px;
    position: relative;
    transition: 0.2s;
    width: 35px;
    order: 2;

    &:before {
      background: #fff;
      border-radius: 15px;
      border: 2px solid ${withText ? theme.colors.primary : theme.colors.grey};
      content: '';
      height: 16px;
      left: 0;
      position: absolute;
      top: 0;
      transition: 0.2s;
      width: 16px;
    }

    input:focus + &:after {
      content: '';
      position: absolute;
      left: -4px;
      right: -4px;
      top: -4px;
      bottom: -4px;
      border: 2px solid ${theme.colors.primary};
      border-radius: 20px;
    }

    input[type='checkbox']:checked + & {
      background: ${theme.colors.primary};
      &:before {
        border-color: ${theme.colors.primary};
        left: calc(100%);
        transform: translateX(-100%);
      }
    }
  `}
`

export default SwitchLabel
