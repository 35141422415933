import { KeyboardEvent, useEffect, useState } from 'react'

import { AuthService } from '~api'
import { Box, Button, Icon, InputText, Option } from '~design-system'

import TinyText from '../Typographies/TinyText/index'

type Props = {
  id: string
  onChoice: Function
  label: string
  placeholder?: string
  disabled?: boolean
  errorMessage?: string
  value?: AddressDetails
}

const InputAddress = ({
  id,
  disabled = false,
  label,
  placeholder,
  onChoice,
  errorMessage,
  value,
}: Props) => {
  const [search, setSearch] = useState<string>('')
  const [addresses, setAddresses] = useState<GetAddressesResponse | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchAddresses = async () => {
    setIsLoading(true)
    try {
      const response = await AuthService.getAddresses({
        addressToLookFor: search,
        limit: 5,
      })
      if (response) {
        setAddresses(response.data)
      }
      setIsLoading(false)
    } catch (error) {
      setAddresses(null)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setSearch(value ? value.properties.label : '')
  }, [value])

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      fetchAddresses()
    }
  }

  const handleSearchAddress = () => {
    fetchAddresses()
  }

  const handleOnChoice = (address: AddressDetails) => {
    setAddresses(null)
    setSearch(address.properties.label)
    onChoice(address)
  }
  return (
    <>
      <InputText
        id={id}
        label={label}
        type="text"
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        value={search}
        onChange={(e) => setSearch(e.currentTarget.value)}
        disabled={isLoading || disabled}
        errorMessage={errorMessage}
        rightComponent={
          <Button
            disabled={search.length < 3}
            onClick={handleSearchAddress}
            isLoading={isLoading}
            size="small"
            square
          >
            <Icon name="search" />
          </Button>
        }
      />

      <Box ai="end" di={addresses ? 'f' : 'none'} fd="c">
        {addresses ? (
          <>
            {addresses.features.map((address) => (
              <Option
                id={address.properties.id}
                key={address.properties.id}
                label={address.properties.label}
                onChoice={() => {
                  handleOnChoice(address)
                }}
              />
            ))}
          </>
        ) : (
          <TinyText>Aucune adresse trouvée</TinyText>
        )}
      </Box>
    </>
  )
}

export default InputAddress
