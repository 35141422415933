import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import { queryClient } from '~api'
import { AppContainer, ErrorBoundary, StyleProvider } from '~components'
import env from '~config/env'
import { AuthProvider } from '~context/AuthContext'
import { OnlineProvider } from '~context/OnlineContext'
import { ToastProvider } from '~context/ToastContext'

import App from './App'

if (env.GTM_ID) {
  TagManager.initialize({
    gtmId: env.GTM_ID,
  })
}

/**
 * Setting up LogRocket for remote environments
 */
if (env.LOGROCKET_URL) {
  LogRocket.init(env.LOGROCKET_URL, {
    console: {
      isEnabled: {
        log: false, // Do not allow to log / debug anything
        debug: true,
      },
      shouldAggregateConsoleErrors: true, // all console.error should be reported
    },
  })

  setupLogRocketReact(LogRocket)

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL)
    })
  })
}

if (env.APP_ENV && env.APP_ENV !== 'development') {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    environment: env.SENTRY_ENV,
    release: env.VERSION,
    debug: false,
    integrations: [
      new Sentry.Replay(),
      new BrowserTracing({
        tracePropagationTargets: [
          /api.nqt.fr\/api/,
          /preprod.api.nqt.fr\/api/,
          /nqt-backend.test\/api/,
        ],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    // To set a uniform sample rate
    tracesSampleRate: 0.5,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyleProvider>
        <Sentry.ErrorBoundary fallback={<ErrorBoundary />} showDialog={true}>
          <AuthProvider>
            <OnlineProvider>
              <ToastProvider>
                <AppContainer>
                  <App />
                </AppContainer>
              </ToastProvider>
            </OnlineProvider>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </Sentry.ErrorBoundary>
      </StyleProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
