import { Box, Panel, SmallText } from '~design-system'

interface Props {
  label: string
  value: string | number
}

const InformationCard = ({ label, value }: Props) => {
  return (
    <Panel pb={3} pt={3}>
      <Box di="f" jc="sb" ai="ce" g="1rem">
        <SmallText>{label}</SmallText>
        <SmallText textAlign="right" textColor="greyDarkest">
          {value}
        </SmallText>
      </Box>
    </Panel>
  )
}

export default InformationCard
