import { useMutation, useQuery } from 'react-query'

import { CompanyService, queryClient } from '~api'
import { isReferent } from '~helpers'

function useCompany() {
  const useGetCompanyById = (id: string) =>
    useQuery(['company', id], () => CompanyService.apiGetCompanyById(id), {
      retry: false,
      refetchOnWindowFocus: false,
    })

  const useUpdateCompany = () =>
    useMutation(CompanyService.apiUpdateCompany, {
      onSuccess: (data) => {
        queryClient.setQueryData(['company', data.company.id], data.company)
      },
    })

  const useGetCompanySponsors = (user: User | undefined) =>
    useQuery('company-sponsors', () => CompanyService.apiGetCompanySponsors(), {
      enabled: isReferent(user),
    })

  return {
    useGetCompanyById,
    useUpdateCompany,
    useGetCompanySponsors,
  }
}

export default useCompany
