import styled, { css } from 'styled-components'

const PopInTitle = styled('h1')`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.components.header2.fontSize};
    font-weight: ${theme.weights.bold};
    line-height: 1.2;
    max-width: 744px;
    margin-bottom: ${theme.spaces[10]};
    text-align: center;
    width: 100%;
  `}
`

export default PopInTitle
