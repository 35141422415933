import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { Sidebar, SwitchRole } from '~components'
import ButtonSponsor from '~components/ButtonSponsor'
import { routes } from '~config'
import { useAuth } from '~context/AuthContext'
import { Avatar, Box, Button, Icon, PopIn, SmallText } from '~design-system'
import { isReferent, isSponsorReferent } from '~helpers'
import { useOnClickOutside } from '~hooks'
import useNotifications from '~hooks/useNotifications'

import { DropdownMenu } from './components'
import {
  ActiveNotif,
  AvatarBox,
  Container,
  LeftBox,
  RightBox,
  Title,
} from './styles'

interface Props {
  title?: string
  asMainTitle?: boolean
}

const HeaderBanner = ({ title, asMainTitle = false }: Props) => {
  const route = useLocation()
  const { id } = useParams()
  const {
    logout,
    authState: { user },
  } = useAuth()
  const navigate = useNavigate()
  const { useHasUnreadNotifications } = useNotifications()
  const { data: hasUnreadNotifs } = useHasUnreadNotifications()

  const isHome = useMemo(() => {
    const paths = route.pathname.substring(1).split('/')
    return paths[0] === ''
  }, [route])

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const DropdownMenuRef = useRef<HTMLButtonElement>(null)

  useOnClickOutside(DropdownMenuRef, () => {
    setIsMenuOpen(false)
  })

  // Close Responsive Sidebar on Window Resize
  useEffect(() => {
    function handleResize() {
      setIsSidebarOpen(false)
    }
    window.addEventListener('resize', handleResize)
  })

  // Close Responsive Sidebar on ID Change (for Multiple Conversation)
  useEffect(() => {
    id && setIsSidebarOpen(false)
  }, [id])

  const handleLogout = () => {
    setIsLoading(true)
    try {
      logout()
      setIsLoading(false)
      navigate('/')
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  return (
    <Container ai="ce" jc="sb" di="f">
      <LeftBox>
        <Button
          option="unfilled"
          fullWidth
          pl={1}
          pr={1}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          {isSidebarOpen ? (
            <Icon name="cross" color="primary" />
          ) : (
            <SmallText textAlign="center" textColor="primary" weight="bold">
              MENU
            </SmallText>
          )}
        </Button>
        {isSidebarOpen && <Sidebar isResponsive />}
      </LeftBox>
      <Box ai="ce" di="f" f={1} overflow="hidden" pl={4} pr={4}>
        {!isHome && window.history.length > 1 && (
          <Button
            size="small"
            option="unfilled"
            onClick={() => navigate(-1)}
            pr={2}
            pl={1}
            aria-label={'Retour en arrière'}
          >
            <Icon color="primary" name="chevron-left" aria-hidden="true" />
          </Button>
        )}
        {title && <Title as={asMainTitle ? 'h1' : 'p'}>{title}</Title>}
        {isReferent(user) && !isSponsorReferent(user) && (
          <ButtonSponsor hu="900px" />
        )}
        {isSponsorReferent(user) && <SwitchRole hu="900px" />}
      </Box>
      <RightBox ai="ce" jc="ce" di="f">
        <Link
          to={routes.authenticated.notifications}
          title={
            hasUnreadNotifs?.hasUnreadNotifications
              ? 'Vous avez des notifications non lues'
              : 'Page des notifications'
          }
        >
          <Icon color="primary" name="notification" size="big" mr={8}>
            {hasUnreadNotifs?.hasUnreadNotifications && (
              <Box pos="relative">
                <ActiveNotif />
              </Box>
            )}
          </Icon>
        </Link>
        <AvatarBox
          ref={DropdownMenuRef}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <Avatar src={user?.profilePhoto} alt="logo" mr={2} online />
          <Icon color="primary" name="chevron-down" size="tiny" />
          {isMenuOpen && (
            <DropdownMenu handleShowConfirmation={setShowConfirmation} />
          )}
        </AvatarBox>
      </RightBox>
      {showConfirmation && (
        <PopIn open closable onClose={() => setShowConfirmation(false)}>
          <PopIn.Title textAlign="left">
            Êtes-vous sûr de vouloir vous déconnecter ?
          </PopIn.Title>
          <PopIn.Actions
            actions={[
              <Button
                key="cancel"
                variant="secondary"
                disabled={isLoading}
                onClick={() => setShowConfirmation(false)}
              >
                Non, revenir en arrière
              </Button>,
              <Button
                key="save"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={() => handleLogout()}
              >
                Oui, me déconnecter
              </Button>,
            ]}
          />
        </PopIn>
      )}
    </Container>
  )
}

export default HeaderBanner
