const imageMimeTypes = ['image/jpeg', 'image/png', 'image/gif']

const mimeTypes = [
  ...imageMimeTypes,
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const documentMimeTypes = mimeTypes.join(',')

export const isImage = (mimeType: typeof imageMimeTypes[number]) =>
  imageMimeTypes.includes(mimeType)
