import styled, { css } from 'styled-components'

import { Box, Loader } from '~design-system'

type Props = {
  transparent?: boolean
}

const Container = styled(Box)<Props>`
  ${({ theme, transparent }) => css`
    height: 100vh;
    background-color: ${!transparent ? theme.colors.white : 'transparent'};
  `}
`

const GlobalLoader = ({ transparent = false }: Props) => (
  <Container ai="ce" di="f" jc="ce" transparent={transparent}>
    <Loader />
  </Container>
)

export default GlobalLoader
