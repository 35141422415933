import { Icon } from '~design-system'
import { SpacingProps } from '~design-system/helpers'

import { EditIconContainer } from './styles'

type Props = {
  isVisible?: boolean
  onClick?: () => void
  withBackground?: boolean
  label?: string
} & SpacingProps

const EditIcon = ({
  withBackground = false,
  isVisible = false,
  label,
  onClick,
  ...props
}: Props) => {
  return isVisible ? (
    <EditIconContainer
      onClick={onClick}
      withBackground={withBackground}
      aria-label={label || 'éditer'}
      {...props}
    >
      <Icon color="primary" name="pencil" size="small" />
    </EditIconContainer>
  ) : null
}

export default EditIcon
