import styled, { css } from 'styled-components'

type Props = {
  shadow?: boolean
  padding?: string
}

const PopInContainer = styled('div')<Props>`
  ${({ shadow, theme, padding }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.radii.lg};
    box-shadow: ${shadow && '0px 60px 40px rgba(0, 0, 0, 0.08)'};
    display: flex;
    flex-direction: column;
    max-width: 744px;
    padding: ${padding || `${theme.spaces[12]} ${theme.spaces[16]}`};
    position: relative;
    width: '100%';
    z-index: ${theme.zIndex.popin.container};

    @media (max-width: 500px) {
      padding: ${theme.spaces[10]} ${theme.spaces[6]} ${theme.spaces[6]}
        ${theme.spaces[6]};
    }
  `}
`

export default PopInContainer
