import { Outlet } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Sidebar } from '~components'
import { Box } from '~design-system'

const Main = styled('main')`
  ${({ theme }) => css`
    background-color: ${theme.colors.greyLightest};
    min-height: 100vh;
    padding-left: ${theme.spaces[64]};
    display: flex;
    flex: 1;

    @media (max-width: 700px) {
      padding-left: 0;
    }
  `}
`

const Dashboard = () => {
  return (
    <Main>
      <Sidebar />
      <Box fg={1} di="f" fd="column" overflow="hidden auto">
        <Outlet />
      </Box>
    </Main>
  )
}

export default Dashboard
