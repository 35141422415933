import styled, { css } from 'styled-components'

import { theme } from '~config'
import { Box } from '~design-system'

import { Strength } from '../hooks/usePasswordStrength'

const Step = styled(Box)`
  ${({ theme, color }) => css`
    border-radius: ${theme.spaces[2]};
    background-color: ${color};
    flex: 1;
    margin-right: 5px;
    height: 4px;
  `}
`

type Props = {
  strength: Strength
}

const getStrengthColor = (strength: Strength, stepperNumber: number) => {
  if (strength === 'weak' && stepperNumber === 1) return theme.colors.pink
  if (strength === 'medium' && (stepperNumber === 2 || stepperNumber === 1))
    return theme.colors.blue
  if (
    strength === 'strong' &&
    (stepperNumber === 2 || stepperNumber === 1 || stepperNumber === 3)
  )
    return theme.colors.primary

  return theme.colors.grey
}

const Stepper = ({ strength }: Props) => {
  return (
    <Box di="f" jc="sb" mt={2} mb={4}>
      <Step color={getStrengthColor(strength, 1)} />
      <Step color={getStrengthColor(strength, 2)} />
      <Step color={getStrengthColor(strength, 3)} />
    </Box>
  )
}

export default Stepper
