import { CompanyBanner, Widget as WidgetWrapper } from '~components'
import { Loader, Text } from '~design-system'
import { LayoutProps, SpacingProps } from '~design-system/helpers'
import { useCompany } from '~hooks'

export type Props = {
  companyID: number
} & LayoutProps &
  SpacingProps

const CompanyWidget = ({ companyID, ...props }: Props) => {
  const { useGetCompanyById } = useCompany()
  const { data: companyData, isFetching } = useGetCompanyById(`${companyID}`)

  return (
    <WidgetWrapper
      title={`Du côté de ${
        companyData?.name ? companyData?.name : 'votre entreprise'
      }`}
      {...props}
    >
      {isFetching ? (
        <Loader isCentered />
      ) : !!companyData ? (
        <CompanyBanner company={companyData} />
      ) : (
        <Text>Aucune information sur votre entreprise</Text>
      )}
    </WidgetWrapper>
  )
}

export default CompanyWidget
