import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const LeftBox = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${theme.colors.blueLighter};
    height: 5rem;
    width: 70px;

    @media (min-width: 701px) {
      display: none;
    }
  `}
`

export default LeftBox
