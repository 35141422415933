import styled, { css } from 'styled-components'

const WeatherLabel = styled('label')`
  ${({ theme }) => css`
    width: ${theme.pxToRem(50)};
    height: ${theme.pxToRem(50)};
    border-radius: 50%;
    background-color: ${theme.colors.blueLight};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 ${theme.spaces[1]};
    position: relative;
    overflow: hidden;
    cursor: pointer;

    input:checked + &:after {
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      border-radius: 50%;
      border: 2px solid ${theme.colors.primary};
    }
  `}
`

export default WeatherLabel
