import api from '../api'

const apiGetHobbies = () =>
  api
    .get<ApiResponse<Hobbies[]>>(`api/v1/qualification/hobbies`)
    .then((response) => response.data.data)

const apiGetJobs = () =>
  api
    .get<ApiResponse<Profession[]>>(`api/v1/qualification/profession`)
    .then((response) =>
      response.data.data.sort((a, b) => a.libelle.localeCompare(b.libelle))
    )

const apiGetLanguages = () =>
  api
    .get<
      ApiResponse<
        {
          id: number
          langue: string
          createdAt: Date
        }[]
      >
    >(`/api/v1/qualification/languages`)
    .then((response) => response.data.data)

const apiGetSectors = () =>
  api
    .get<ApiResponse<{ name: string; id: number }[]>>(
      `api/v1/qualification/secteurs`
    )
    .then((response) => response.data.data)

const apiGetSkills = () =>
  api
    .get<ApiResponse<Hobbies[]>>(`api/v1/qualification/skills`)
    .then((response) => response.data.data)

const apiGetSubSectors = (id: number) =>
  api
    .get<ApiResponse<{ name: string; id: number }[]>>(
      `api/v1/qualification/soussecteurs/${id}`
    )
    .then((response) => response.data.data)

const apiGetDepartments = () =>
  api
    .get<GetDepartmentResponse>(`api/v1/departments`)
    .then((response) => response.data.data.departments)

const apiGetKeyNumbers = (dateStart: string) =>
  api
    .get<ApiResponse<KeyNumber[]>>(`api/v1/dashboard?start_date=${dateStart}`)
    .then((response) => response.data.data)

const DataService = {
  apiGetHobbies,
  apiGetJobs,
  apiGetLanguages,
  apiGetSectors,
  apiGetSkills,
  apiGetSubSectors,
  apiGetDepartments,
  apiGetKeyNumbers,
}
export default DataService
