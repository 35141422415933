import styled, { css } from 'styled-components'

export type Props = {
  keyNumber: number
}

const color = (keyNumber: number) => {
  switch (true) {
    case keyNumber > 3 && keyNumber <= 7:
      return 'danger'
    case keyNumber > 7 && keyNumber <= 11:
      return 'blue'
    case keyNumber > 11 && keyNumber <= 17:
      return 'success'
    case keyNumber > 17 && keyNumber <= 23:
      return 'yellow'
    case keyNumber > 23 && keyNumber <= 35:
      return 'orange'
    case keyNumber > 35 && keyNumber <= 50:
      return 'pink'
    default:
      return 'primary'
  }
}

const Circle = styled('div')<Props>`
  ${({ keyNumber, theme }) => css`
    background-color: ${theme.colors[color(keyNumber)]};
    border-radius: 100%;
    width: 15rem;
    height: 15rem;
    position: absolute;
    top: -20%;
    left: calc(100% - 125px);
  `}
`

export default Circle
