import { useMutation } from 'react-query'

import { MatchingService, queryClient } from '~api'

import { useToast } from '../context/ToastContext'

function useMatching() {
  const { addToast } = useToast()

  const useAddMatchFeeling = () =>
    useMutation(MatchingService.apiPostMatchingFeeling, {
      onSuccess: (data) => {
        addToast({ type: 'success', title: data.message })
        queryClient.invalidateQueries('user')
      },
      onError: (error: any) => {
        addToast({ type: 'danger', title: error })
      },
    })

  return { useAddMatchFeeling }
}

export default useMatching
