import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const Container = styled(Link)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: 12px 24px;
    border-radius: ${theme.radii.lg};
    position: relative;
    display: flex;
    justify-content: space-between;
    & + & {
      margin-top: 4px;
    }
  `}
`

export { Container }
