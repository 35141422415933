import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const IconContainer = styled(Box)`
  ${({ theme }) => css`
    border-radius: 100%;
    border: 1px solid ${theme.colors.blueDark};
    height: ${theme.components.avatar.sizes.medium};
    width: ${theme.components.avatar.sizes.medium};
    background-color: ${theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

export default IconContainer
