import React from 'react'

import { ProgressBarContainer, ProgressBarFiller } from '../styles/ProgressBar'

type Props = {
  progress: number
  max: number
}

export const ProgressBarTest = ({ progress, max }: Props) => {
  const numbersToPercentage = () => (progress / max) * 100

  return (
    <ProgressBarContainer className="progress-bar">
      <ProgressBarFiller progress={numbersToPercentage()}></ProgressBarFiller>
    </ProgressBarContainer>
  )
}
