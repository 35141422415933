import styled from 'styled-components'

import { theme } from '~config'

const EventImage = styled('img')`
  border-radius: 8px;
  margin-bottom: ${theme.spaces[8]};
  max-width: 100%;
  height: auto;
`

export default EventImage
