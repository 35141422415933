import { Link } from 'react-router-dom'

import { Box, Icon, SmallText } from '~design-system'

import { DropdownAvatar } from '../styles'

interface Props {
  handleEditInfos: (infos: EditInfos) => void
  handleConfirmation: (bool: boolean) => void
}

const AvatarMenu = ({ handleEditInfos, handleConfirmation }: Props) => (
  <DropdownAvatar>
    <Box
      as={Link}
      to={''}
      di="f"
      ai="ce"
      mb={4}
      onClick={() =>
        handleEditInfos({
          slug: 'profilePhoto',
          title: 'Changer la photo de profil',
        })
      }
    >
      <Icon color="primary" name="upload" mr={4} />
      <SmallText>Importer une photo</SmallText>
    </Box>
    <Box
      as={Link}
      to={''}
      di="f"
      ai="ce"
      onClick={() => handleConfirmation(true)}
    >
      <Icon color="primary" name="trash-delete" mr={4} />
      <SmallText>Supprimer</SmallText>
    </Box>
  </DropdownAvatar>
)

export default AvatarMenu
