import { camelize } from 'humps'
import { Fragment } from 'react'
import { useFormContext } from 'react-hook-form'

import { getParseBodyParams, getType } from '~components/Chat/helpers'
import { DepartmentSelector } from '~components/Selectors'
import { Box, Button } from '~design-system'

type Props = {
  dialog: Stepper
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const PicklistDepartments = ({ dialog, setAnswers, handleAnswer }: Props) => {
  const { getValues, watch } = useFormContext()

  const answer = getType(dialog, 'picklist_departements')[0]
  const id = camelize(Object.keys(getParseBodyParams(answer))[0])

  const handleDepAnswer = (answer: Answer, id: string) => {
    setAnswers(getValues(id))
    handleAnswer(answer.action, { [id]: getValues(id) })
  }

  return (
    <Fragment key={id}>
      <DepartmentSelector />
      <Box di="flex" jc="flex-end" fd="row">
        <Button
          onClick={() => handleDepAnswer(answer, id)}
          data-testid="dep-submit"
          disabled={!watch('registrationDepartment')}
        >
          Valider
        </Button>
      </Box>
    </Fragment>
  )
}

export default PicklistDepartments
