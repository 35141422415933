import { Controller, useFormContext } from 'react-hook-form'

import { LINKEDIN_REGEX } from '~config'
import { useAuth } from '~context/AuthContext'
import { Box, Dropzone, Icon, InputText, Label, Text } from '~design-system'
import { documentMimeTypes, isYoung } from '~helpers'

const EditLinkedIn = () => {
  const {
    authState: { user },
  } = useAuth()
  // Define Form
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Box w="100%">
      <InputText
        id="linkedinUrl"
        placeholder="Saisi ici l'url de ton profil LinkedIn"
        label="Profil LinkedIn"
        type="text"
        autoFocus
        errorMessage={errors?.linkedinUrl?.message}
        defaultValue={watch('linkedinUrl')}
        {...register('linkedinUrl', {
          pattern: LINKEDIN_REGEX,
        })}
      />

      {isYoung(user) && (
        <Controller
          control={control}
          name="resume"
          render={({ field: { onChange, value } }) => (
            <>
              <Label htmlFor="resume" mb={2}>
                Curriculum Vitae
              </Label>
              <Dropzone
                multiple={false}
                accept={documentMimeTypes}
                file={value && [value]}
                setFile={(val?: string[] | null[]) =>
                  onChange(val ? val[0] : undefined)
                }
              >
                <Box di="f" fd="column" ai="center" js="center">
                  <Icon name="camera" color="primary" />
                  <Text textColor="primary">Importe ici ton CV</Text>
                </Box>
              </Dropzone>
            </>
          )}
        />
      )}
    </Box>
  )
}

export default EditLinkedIn
