import styled, { Theme, css } from 'styled-components'

import { theme } from '~config'

import {
  DisplayProps,
  LayoutProps,
  SpacingProps,
  display,
  layout,
  spacing,
} from '../helpers'

type Props = {
  overflow?: string
  backgroundColor?: keyof Theme['colors']
} & DisplayProps &
  LayoutProps &
  SpacingProps

const Box = styled('div')<Props>`
  ${({ overflow, backgroundColor }) => css`
    ${overflow && `overflow: ${overflow}`};
    ${backgroundColor && `background-color: ${theme.colors[backgroundColor]}`};
  `}
  ${display};
  ${layout};
  ${spacing};
`

export default Box
