import { useMutation, useQuery } from 'react-query'

import { PreMatchingService } from '~api'

import queryClient from '../api/queryClient'

function usePreMatching() {
  const useGetPreMatchings = (isEnabled: boolean) =>
    useQuery(['preMatchings'], () => PreMatchingService.apiGetPreMatchings(), {
      enabled: isEnabled,
    })

  const useConfirmPreMatching = () =>
    useMutation(PreMatchingService.apiPostConfirmPreMatching, {
      onSuccess: (data) => {
        queryClient.invalidateQueries('thread-' + data.data.threadId)
        queryClient.invalidateQueries('threads')
        queryClient.invalidateQueries('user')
      },
    })

  return {
    useGetPreMatchings,
    useConfirmPreMatching,
  }
}

export default usePreMatching
