import styled, { css, keyframes } from 'styled-components'

type ContainerProps = {
  time?: number
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Container = styled('svg').attrs((props: ContainerProps) => ({
  time: props.time || 2,
}))<ContainerProps>`
  ${({ time }) => css`
    animation: ${spin} ${time}s linear infinite;
    width: 366px;
    height: 343px;
    max-width: 100%;
    & + & {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  `}
`

export default Container
