import { useFormContext } from 'react-hook-form'

import { Input } from '~components/Chat/components'
import { getParseBodyParams, getType } from '~components/Chat/helpers'
import { POLE_EMPLOI_REGEX, REQUIRED_VALIDATION } from '~config'

type Props = {
  dialog: Stepper
  dialogType: ApiGetStepResponse['data'][0]['type']
  index: number
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const PoleEmploiNumber = ({
  dialog,
  dialogType,
  index,
  setAnswers,
  handleAnswer,
}: Props) => {
  const { getValues } = useFormContext()

  const answer = getType(dialog, 'pole_emploi_number')[0]
  const parsedBodyParam = getParseBodyParams(answer)
  const id = Object.keys(parsedBodyParam)[0]

  const handleTextInputValue = (a: Answer, answerId: string) => {
    const value = getValues(answerId)
    setAnswers(value)
    handleAnswer(a.action, { [`${answerId}`]: value })
  }

  return (
    <Input
      key={dialogType + index}
      id={id}
      type="text"
      placeholder={answer.label}
      onSubmit={() => handleTextInputValue(answer, id)}
      validator={{
        ...REQUIRED_VALIDATION,
        pattern: {
          value: POLE_EMPLOI_REGEX,
          message: "L'identifiant doit être une suite de 12 chiffres",
        },
      }}
    />
  )
}

export default PoleEmploiNumber
