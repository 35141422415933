import { useMutation } from 'react-query'

import { ExperienceService, queryClient } from '~api'

function useExperiences() {
  const useAddExperience = () =>
    useMutation(ExperienceService.apiAddExperience, {
      onSuccess: (data) => {
        queryClient.setQueryData('user', data)
      },
    })

  const useDeleteExperience = () =>
    useMutation(ExperienceService.apiDeleteExperience, {
      onSuccess: (data, variables) => {
        // Remove experience inside user object
        queryClient.setQueryData('user', (user: any) => {
          const experiences = [...user.user.experiences].filter(
            (exp) => exp.id !== variables
          )

          return {
            user: {
              ...user.user,
              experiences,
            },
          }
        })
      },
    })

  const useUpdateExperience = () =>
    useMutation(ExperienceService.apiUpdateExperience, {
      onSuccess: (data) => {
        // Update experience inside user object
        queryClient.setQueryData('user', (user: any) => {
          const experiences = [...user.user.experiences].map((exp) =>
            exp.id === data.user.id ? data.user : exp
          )

          return {
            user: {
              ...user.user,
              experiences,
            },
          }
        })
      },
    })

  return {
    useAddExperience,
    useDeleteExperience,
    useUpdateExperience,
  }
}

export default useExperiences
