import styled, { Theme, css } from 'styled-components'

import { DisplayProps, SpacingProps, display, spacing } from '../../helpers'

type Props = {
  textAlign?: keyof Theme['components']['text']['align']
  textColor?: keyof Theme['colors']
  weight?: keyof Theme['components']['smallText']['weights']
} & SpacingProps &
  DisplayProps

const SmallText = styled('p').attrs((props: Props) => ({
  textAlign: props.textAlign || 'left',
  textColor: props.textColor || 'black',
  weight: props.weight || 'regular',
}))<Props>`
  ${({ textAlign = 'left', textColor, theme, weight }) => css`
    color: ${theme.colors[textColor]};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.components.smallText.fontSize};
    font-weight: ${theme.components.smallText.weights[weight]};
    text-align: ${theme.components.text.align[textAlign]};
    line-height: 1.3;
    text-overflow: ellipsis;
    overflow: hidden;
  `}
  ${spacing}
  ${display}
`

export default SmallText
