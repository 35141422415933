import { Controller, useFormContext } from 'react-hook-form'

import { Select } from '~design-system'
import { getFrenchCivility } from '~helpers'

const EditCivility = () => {
  // Define Form
  const { control } = useFormContext()

  return (
    <Controller
      name="civility"
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <Select
            id="civility"
            placeholder="Sélectionner votre civilité"
            isSearchable={false}
            options={[
              { label: getFrenchCivility('Mr.'), value: 'Mr.' },
              { label: getFrenchCivility('Mrs.'), value: 'Mrs.' },
            ]}
            defaultValue={{
              value: value,
              label: getFrenchCivility(value as 'Mr.' | 'Mrs.'),
            }}
            onChange={(e) => {
              onChange(e?.value)
            }}
          />
        )
      }}
    />
  )
}

export default EditCivility
