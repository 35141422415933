import styled from 'styled-components'

import { Box } from '~design-system'

const ResponsiveContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 10px;
  }
`

export default ResponsiveContainer
