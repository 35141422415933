import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Box, Button, Header2, Icon, PopIn, Text } from '~design-system'
import { getLanguageLevelLabel } from '~helpers'

import Form from './Form'

type Props = {
  onClose: () => void
  onSubmit: (languages: Language[]) => void
  open: boolean
}

const LanguagesPopin = ({ onClose, onSubmit, open }: Props) => {
  const { reset } = useFormContext()
  const [process, setProcess] = useState<'add' | 'summary'>('summary')
  const [selectedLanguages, setSelectedLanguages] = useState<Language[]>([])

  const handleShowForm = () => {
    setProcess('add')
    reset()
  }
  const handleShowSummary = () => setProcess('summary')

  const handleClose = () => {
    setProcess('summary')
    setSelectedLanguages([])
    onClose()
  }

  const handleSubmit = () => {
    onSubmit(selectedLanguages)
    handleClose()
  }

  const renderSelected = useMemo(
    () =>
      selectedLanguages ? (
        <Box mb={2}>
          {selectedLanguages.map(({ id, level, name }) => (
            <Button
              fullWidth
              mb={2}
              key={`selected-language-${id}`}
              onClick={() => handleDeleteLanguage(id)}
            >
              <Box ai="ce" di="f" fg={1} jc="space-between">
                <Text textColor="white">
                  {name} - niveau {getLanguageLevelLabel(level)}
                </Text>
                <Icon color="white" name="close" size="small" />
              </Box>
            </Button>
          ))}
        </Box>
      ) : null,
    [selectedLanguages]
  )

  const handleDeleteLanguage = (languageId: number) => {
    setSelectedLanguages((languages) =>
      languages?.filter(({ id }) => languageId !== id)
    )
  }

  return (
    <PopIn backdropBgColor="white" open={open}>
      <Header2 mb={4}>Ajouter une langue</Header2>
      <Text mb={8}>
        Ajoute les langues que tu parles ou que tu es en train d’apprendre !
      </Text>

      <>
        {process === 'summary' && (
          <Box>
            {renderSelected}
            <Button
              fullWidth
              option="dashed"
              variant="secondary"
              onClick={handleShowForm}
              mb={4}
              data-testid="languages-add"
              disabled={selectedLanguages.length >= 5}
            >
              <Box di="f" fg={1} jc="space-between">
                Ajouter une langue
                <Icon color="primary" name="add" />
              </Box>
            </Button>
            <Box>
              <Button
                onClick={handleSubmit}
                data-testid="languages-submit"
                disabled={selectedLanguages.length <= 0}
              >
                Valider
              </Button>
              <Button option="unfilled" onClick={handleClose}>
                Annuler
              </Button>
            </Box>
          </Box>
        )}

        {process === 'add' && (
          <Form
            onSubmit={handleShowSummary}
            selectedLanguages={selectedLanguages}
            setSelectedLanguages={setSelectedLanguages}
          />
        )}
      </>
    </PopIn>
  )
}

export default LanguagesPopin
