import styled, { css } from 'styled-components'

type Props = { isError?: boolean }

const InputContainer = styled('div')<Props>`
  ${({ theme, isError, hidden }) => css`
    border: 1px solid ${isError ? theme.colors.danger : theme.colors.greyLight};
    background-color: ${theme.colors.white};
    border-radius: ${theme.radii.lg};
    display: flex;
    align-items: center;
  `}
`

export default InputContainer
