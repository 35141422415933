import styled, { css } from 'styled-components'

const RightComponentContainer = styled('div')`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin-right: ${theme.spaces[1]};
  `}
`
export default RightComponentContainer
