import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Title = styled('div')`
  ${({ theme }) => css`
    font-size: ${theme.pxToRem(18)};
    font-weight: 500;
    margin: ${theme.pxToRem(32)} 0 ${theme.pxToRem(24)};
  `}
`

export const List = styled('ul')`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    z-index: 1;

    @media screen and (max-width: 48rem) {
      grid-template-columns: 1fr;
    }
  `}
`

export const Category = styled(Link)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.radii.lg};
    padding: ${theme.pxToRem(24)};
    display: flex;
    justify-content: space-between;
    color: ${theme.colors.black};
  `}
`
