import { TextareaHTMLAttributes } from 'react'
import { Ref, forwardRef } from 'react'

import { Box, Label, TinyText } from '~design-system'

import { LayoutProps, SpacingProps } from '../helpers'
import { CharLeftContainer, StyledTextarea, TextareaContainer } from './styles'

type TextareaProps = {
  label: string
  errorMessage?: string | undefined
  charatersLeft?: number
} & TextareaHTMLAttributes<HTMLTextAreaElement> &
  SpacingProps &
  LayoutProps

const Textarea = forwardRef(
  (
    {
      children,
      label,
      maxLength = 1000,
      charatersLeft,
      errorMessage = undefined,
      ...rest
    }: TextareaProps,
    ref: Ref<HTMLTextAreaElement>
  ) => {
    return (
      <Box>
        <Label mb={2}>{label}</Label>
        <TextareaContainer isError={!!errorMessage?.length}>
          <StyledTextarea
            isError={!!errorMessage?.length}
            ref={ref}
            maxLength={maxLength}
            // Set Focus at the end of the text
            onFocus={(e: any) => {
              const val = e.target.value
              e.target.value = ''
              e.target.value = val
            }}
            {...rest}
          >
            {children}
          </StyledTextarea>
          {charatersLeft !== undefined && (
            <CharLeftContainer>
              <TinyText textColor={'greyDarker'}>
                Caractères restants : {charatersLeft}
              </TinyText>
            </CharLeftContainer>
          )}
        </TextareaContainer>
        {errorMessage && (
          <TinyText textColor="danger" mt={2}>
            {errorMessage}
          </TinyText>
        )}
      </Box>
    )
  }
)

export default Textarea
