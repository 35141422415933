import styled from 'styled-components'

import Text from '~design-system/Typographies/Text'

const AccordionTitle = styled(Text)`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.2rem;
  margin-left: 1rem;
`

export default AccordionTitle
