import { InputHTMLAttributes, forwardRef } from 'react'
import styled, { css } from 'styled-components'

import Icon from '../Icon'
import { SpacingProps, spacing } from '../helpers'

type Props = {
  id: string
  label: string | React.ReactNode
  value?: string | number
  transparent?: boolean
  disabled?: boolean
  small?: boolean
} & SpacingProps &
  InputHTMLAttributes<HTMLInputElement>

const Container = styled('label')<Partial<Props>>`
  ${({ theme, disabled, small, transparent }) => css`
    align-items: center;
    background-color: ${!transparent && theme.colors.blueLighter};
    border-radius: ${theme.radii.lg};
    color: ${!transparent && theme.colors.primary};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    display: flex;
    justify-content: space-between;
    padding: ${small ? theme.spaces[0.5] : theme.spaces[4]};
    font-size: ${small ? theme.components.tinyText.fontSize : 'initial'};
    opacity: ${disabled && 0.3};
    ${spacing};

    @media (max-width: 500px) {
      word-break: break-word;
    }
  `}
`

const Check = styled('div')`
  ${({ theme }) => css`
    align-items: baseline;
    border: 1px solid ${theme.colors.primary};
    border-radius: ${theme.radii.md};
    background-color: ${theme.colors.white};
    display: flex;
    height: 24px;
    flex-shrink: 0;
    justify-content: center;
    margin-left: ${theme.spaces[4]};
    transition: background-color 0.3s;
    width: 24px;

    ${Icon} {
      opacity: 0;
      transition: color 0.3s, opacity 0.3s;
    }
  `}
`

const Input = styled('input')`
  ${({ theme }) => css`
    display: none;

    &:checked {
      + ${Check} {
        background-color: ${theme.colors.primary};
        ${Icon} {
          color: ${theme.colors.white};
          opacity: 1;
        }
      }
    }
  `}
`

const Checkbox = forwardRef(
  (
    { disabled, label, id, small = false, transparent, mb, mt, ...rest }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <Container
        htmlFor={id}
        mb={mb}
        mt={mt}
        transparent={transparent}
        small={small}
        disabled={disabled}
      >
        {label}
        <Input
          id={id}
          ref={ref}
          type="checkbox"
          disabled={disabled}
          {...rest}
        />
        <Check>
          <Icon name="check" />
        </Check>
      </Container>
    )
  }
)

export default Checkbox
