import { parse } from 'date-fns'
import { format, zonedTimeToUtc } from 'date-fns-tz'

export const getTransformSectorList = (
  list: { name: string; id: number }[],
  type: 'sector' | 'subsector'
) => {
  return list?.map((sector: { name: string; id: number }) => ({
    value: type === 'sector' ? sector.name : sector.id,
    label: sector.name,
  }))
}

export const getDateFormattedForAPI = (
  date: string,
  originalFormat: string
) => {
  const formattedDate = zonedTimeToUtc(
    parse(date, originalFormat, new Date()),
    'Europe/Paris'
  )
  return format(formattedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX")
}
