import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { ErrorBox } from '~components'
import { EMAIL_REGEX, PHONE_REGEX, REQUIRED_VALIDATION, routes } from '~config'
import { useToast } from '~context/ToastContext'
import {
  Box,
  Button,
  Checkbox,
  Header2,
  InputText,
  SmallText,
  Text,
} from '~design-system'
import { useEvents } from '~hooks'
import { Anchor } from '~pages/Unauthenticated/Register/Summary/styles'

type Props = {
  close: () => void
  eventId: number
}

const RegisterForm = ({ close, eventId }: Props) => {
  const {
    register,
    formState: { errors, isValid },
    getValues,
    setError,
    handleSubmit,
  } = useForm({ mode: 'onChange' })
  const { useRegisterPublicEvent } = useEvents()
  const { addToast } = useToast()
  const [error, setGenericError] = useState<string>()
  const { mutateAsync: registerEvent, isLoading } = useRegisterPublicEvent()

  const submit = async () => {
    try {
      await registerEvent({ id: eventId, data: getValues() })
      addToast({
        title: 'Votre inscription a bien été prise en compte',
        type: 'success',
      })
      close()
    } catch (e: any) {
      setGenericError(e.response.data.message)
      Object.entries(e.response.data.data).forEach(
        ([key, value]: [key: string, value: any]) => {
          setError(key, {
            type: 'custom',
            message: value[0],
          })
        }
      )
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box di="f" jc="sb" ai="center" mb={4}>
        <Header2>S'inscrire</Header2>

        <SmallText textColor="greyDarkest">
          Vous avez déjà un compte ?{' '}
          <Link to={routes.unauthenticated.signin}>
            <SmallText textColor="primary" as={'span'}>
              Se connecter
            </SmallText>
          </Link>
        </SmallText>
      </Box>
      <Box>
        <InputText
          {...register('lastname', { required: true })}
          width="100%"
          id="lastname"
          label="Nom"
          errorMessage={errors?.lastname?.message}
        />
        <InputText
          {...register('firstname', { required: true })}
          width="100%"
          id="firstname"
          label="Prénom"
          errorMessage={errors?.firstname?.message}
        />
        <InputText
          {...register('email', {
            ...REQUIRED_VALIDATION,
            pattern: {
              value: EMAIL_REGEX,
              message: 'Le champ doit être un email valide',
            },
          })}
          width="100%"
          id="email"
          label="Email"
          errorMessage={errors?.email?.message}
        />
        <InputText
          {...register('phone', {
            ...REQUIRED_VALIDATION,
            pattern: {
              value: PHONE_REGEX,
              message: 'Le champ doit être un n° de téléphone valide',
            },
          })}
          width="100%"
          id="phone"
          label="N° de téléphone"
          errorMessage={errors?.phone?.message}
        />
        <Checkbox
          id="cgu"
          label={
            <Text textColor="primary">
              <Anchor target="_blank" href="https://jeunes.nqt.fr/cgu/">
                J’accepte les termes et conditions
              </Anchor>
            </Text>
          }
          mt={2}
          {...register('cgu', { required: true })}
        />
        <Checkbox
          id="pc"
          label={
            <Text textColor="primary">
              <Anchor
                target="_blank"
                href="https://www.nqt.fr/politique-de-confidentialite/"
              >
                J’accepte la politique de confidentialité
              </Anchor>
            </Text>
          }
          mt={2}
          {...register('pc', { required: true })}
        />
        {error && <ErrorBox error={error as string} />}
      </Box>
      <Box>
        <Button
          mt={4}
          fullWidth
          variant="secondary"
          disabled={isLoading}
          onClick={close}
        >
          Annuler
        </Button>
        <Button
          mt={4}
          fullWidth
          type="submit"
          disabled={isLoading || !isValid}
          isLoading={isLoading}
        >
          S'inscrire
        </Button>
      </Box>
    </form>
  )
}

export default RegisterForm
