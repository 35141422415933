import { useMutation, useQuery } from 'react-query'

import { MessagingService } from '~api'

import queryClient from '../api/queryClient'

function useThreads() {
  const useGetThreads = () =>
    useQuery(['threads'], MessagingService.getThreads, {
      refetchInterval: 60000,
    })

  const useGetThread = (threadId: number) =>
    useQuery(
      ['thread-' + threadId],
      async () => await MessagingService.getThread(threadId)
    )

  const useAddMessage = (threadId: number) =>
    useMutation(MessagingService.postMessage, {
      onSuccess: () => {
        queryClient.invalidateQueries(['thread-' + threadId])

        const threads:Thread[]|undefined = queryClient.getQueryData(['threads'])

        if (threads && threads[0].id !== threadId) {
          queryClient.invalidateQueries('threads');
        }
      },
    })

  const useDeleteFile = (threadId: number, fileId: string) =>
    useMutation(async () => MessagingService.deleteFile({ threadId, fileId }), {
      onSuccess: () => {
        queryClient.invalidateQueries('thread-' + threadId)
      },
    })

  return {
    useGetThreads,
    useAddMessage,
    useGetThread,
    useDeleteFile,
  }
}

export default useThreads
