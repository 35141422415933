import { useRef, useState } from 'react'
import { generatePath } from 'react-router-dom'

import { routes } from '~config'
import { useOnlineUsers } from '~context/OnlineContext'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Header3,
  Icon,
  PopIn,
  SmallText,
  Tag,
  Text,
} from '~design-system'
import {
  formatList,
  formatMentorName,
  isSponsor,
  isYoung,
  labelShortener,
} from '~helpers'
import { useOnClickOutside, useUser } from '~hooks'

import { AvatarMenu } from '../components'
import { CompanyLink, IconAvatarEdition, InfosContainer } from '../styles'

interface Props {
  handleEditInfos: (infos: EditInfos) => void
  isEditable?: boolean
  user: User
}

const LeftPanelHeader = ({
  user,
  isEditable = false,
  handleEditInfos,
}: Props) => {
  const { useUpdateUser } = useUser()
  const { isOnline } = useOnlineUsers()
  const { mutate: updateUser, isLoading } = useUpdateUser()
  const [isAvatarMenuOpen, setIsAvatarMenuOpen] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const AvatarMenuRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(AvatarMenuRef, () => setIsAvatarMenuOpen(false))

  // Delete Avatar
  const handleDeleteAvatar = () => {
    updateUser(
      { profilePhoto: '' },
      {
        onSuccess() {
          setShowConfirmation(false)
        },
      }
    )
  }

  return (
    <Box ai="ce" di="f" fd="c" pb={2} pos="relative">
      <Avatar src={user.profilePhoto} size="big" online={isOnline(user.id)} />
      {isEditable && (
        <IconAvatarEdition
          ref={AvatarMenuRef}
          onClick={() => setIsAvatarMenuOpen(!isAvatarMenuOpen)}
        >
          <Icon color="white" name="add-photo" size="medium" />
          {isAvatarMenuOpen && (
            <AvatarMenu
              handleEditInfos={handleEditInfos}
              handleConfirmation={setShowConfirmation}
            />
          )}
        </IconAvatarEdition>
      )}

      <Header3 weight="medium" mt={4}>
        {user.fullName}
      </Header3>

      {/* YOUNG */}
      {isYoung(user) && !!user.matchs?.length && (
        <Text
          textAlign="center"
          textColor="greyDarkest"
          weight="regular"
          mt={1}
        >
          Filleul.e de{' '}
          {formatList(user.matchs.map((match) => formatMentorName(match)))}
        </Text>
      )}
      {!isSponsor(user) && user.professionalSituations?.length > 0 && (
        <Tag
          textColor="primary"
          borderColor="white"
          backgroundColor="blueLight"
          radius="xlg"
          mt={2}
        >
          {user.professionalSituations.join(' / ')}
        </Tag>
      )}

      {/* SPONSOR */}
      {!isYoung(user) &&
        user.profession &&
        !user.company?.name &&
        !user.company?.id && (
          <Text
            textAlign="center"
            textColor="greyDarkest"
            weight="regular"
            mt={1}
          >
            {user.profession.libelle}
          </Text>
        )}
      {!isYoung(user) &&
        user.profession &&
        user.company?.name &&
        user.company?.id && (
          <Text
            textAlign="center"
            textColor="greyDarkest"
            weight="regular"
            mt={1}
          >
            {user.profession.libelle} chez{' '}
            <CompanyLink
              to={generatePath(routes.authenticated.company, {
                id: user.company.id.toString(),
              })}
            >
              {user.company.name}
            </CompanyLink>
          </Text>
        )}
      {!isYoung(user) && !user.profession && (
        <Text
          textAlign="center"
          textColor="greyDarkest"
          weight="regular"
          mt={1}
        >
          Aucune profession renseignée
        </Text>
      )}

      <Box w="100%" mt={4}>
        <Divider />
        {(user.city || user.yearsOfExperience) && (
          <>
            <InfosContainer di="f" g="1rem" pb={4} pt={4}>
              {user.city && (
                <Box ai="center" di="f">
                  <Icon color="primary" mr={2} name="poi" size="medium" />
                  <SmallText weight="medium">
                    {labelShortener(user.city, 30)}
                  </SmallText>
                </Box>
              )}
              {!isYoung(user) && user.yearsOfExperience && (
                <Box ai="center" di="f">
                  <Icon color="primary" mr={2} name="star" size="medium" />
                  <SmallText weight="medium">
                    {user.yearsOfExperience} ans d'expérience
                  </SmallText>
                </Box>
              )}
            </InfosContainer>
            <Divider />
          </>
        )}
      </Box>

      {showConfirmation && (
        <PopIn open closable onClose={() => setShowConfirmation(false)}>
          <PopIn.Title textAlign="left">
            {`${
              !isYoung(user) ? 'Êtes-vous' : 'Es-tu'
            } sûr de vouloir supprimer votre photo de profil ?`}
          </PopIn.Title>
          <PopIn.Actions
            actions={[
              <Button
                key="cancel"
                variant="secondary"
                disabled={isLoading}
                onClick={() => setShowConfirmation(false)}
              >
                Non, revenir en arrière
              </Button>,
              <Button
                key="save"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={() => handleDeleteAvatar()}
              >
                Oui, supprimer
              </Button>,
            ]}
          />
        </PopIn>
      )}
    </Box>
  )
}

export default LeftPanelHeader
