import { useEffect } from 'react'
import { ChangeEvent, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { theme } from '~config'
import { Box, Button, Checkbox, InputText, Loader, Text } from '~design-system'
import { useData } from '~hooks'

import { JobsListContainer } from '../styles'

export interface JobSelectorProps {
  name: 'desiredProfessions' | 'profession'
}

const JobSelector = ({ name }: JobSelectorProps) => {
  const { register, watch } = useFormContext()
  const { useGetJobs } = useData()
  const { data: jobList, isLoading } = useGetJobs()

  // Define Max Selected items
  const maxItems = name === 'profession' ? 1 : 5

  // Define the Number of Jobs to show Initialy and when 'Voir Plus' Button is Pressed
  const initialNumberOfItems = 20

  // Watch Current Values
  const currentValues = watch(name) || []

  // State for FilteredList and Number of Jobs to Show
  const [jobsToShow, setJobsToShow] = useState<number>(initialNumberOfItems)
  const [filteredList, setFilteredList] = useState<Profession[]>()

  // Get Initial Job List
  useEffect(() => {
    !!jobList?.length && setFilteredList(jobList)
  }, [jobList])

  // Filter Job List on Input change
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setJobsToShow(initialNumberOfItems)

    const splitValue = value.split(' ')
    const jobs = jobList?.filter((job: Profession) =>
      splitValue.every((value) =>
        job.libelle
          .toLowerCase()
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '')
          .includes(
            value
              .toLowerCase()
              .normalize('NFD')
              .replace(/\p{Diacritic}/gu, '')
          )
      )
    )
    if (jobs) {
      setFilteredList(jobs?.sort((a, b) => a.libelle.localeCompare(b.libelle)))
    }
  }

  return (
    <Box w="100%">
      <JobsListContainer mb={4}>
        {/* SEARCHBAR */}
        <InputText
          placeholder="Rechercher un métier..."
          type="search"
          onChange={handleSearch}
          mt={4}
          autoFocus
          backgroundColor={theme.colors.blueLighter}
        />
        {/* SELECTED JOBS */}
        {!!currentValues.length && (
          <Box pr={4} pl={4} pb={4}>
            <Text textColor="primary" weight="medium" pb={2}>
              Métier(s) Sélectionné(s)
            </Text>
            {jobList?.map(
              (job: Profession) =>
                currentValues.includes(job.code.toString()) && (
                  <Checkbox
                    key={job.code}
                    value={job.code}
                    id={job.code}
                    label={job.libelle}
                    small
                    {...register(name)}
                  />
                )
            )}
          </Box>
        )}
      </JobsListContainer>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!!filteredList?.length ? (
            <JobsListContainer p={'4'}>
              <Box maxH={96} overflow="auto" w="100%">
                {filteredList
                  ?.slice(0, jobsToShow)
                  .map(
                    (job: Profession) =>
                      !currentValues.includes(job.code.toString()) && (
                        <Checkbox
                          key={job.code}
                          value={job.code}
                          id={job.code}
                          label={job.libelle}
                          transparent
                          disabled={
                            currentValues.length >= maxItems &&
                            !currentValues.includes(job.code.toString())
                          }
                          {...register(name, { required: true })}
                        />
                      )
                  )}
                {/* SHOW MORE BUTTON */}
                {jobsToShow < filteredList?.length && (
                  <Box di="flex" jc="center">
                    <Button
                      outlined
                      color="primary"
                      mt={6}
                      mb={6}
                      onClick={() =>
                        setJobsToShow(jobsToShow + initialNumberOfItems)
                      }
                    >
                      Voir plus de professions
                    </Button>
                  </Box>
                )}
              </Box>
            </JobsListContainer>
          ) : null}
        </>
      )}
    </Box>
  )
}

export default JobSelector
