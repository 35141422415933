import Pusher from 'pusher-js'

import env from '~config/env'

const getPusher = () => {
  return new Pusher(env.PUSHER_KEY || '', {
    cluster: env.PUSHER_CLUSTER,
    authEndpoint: env.API_URL + '/broadcasting/auth',
    auth: {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
      },
    },
  })
}

export default getPusher
