import { Link, generatePath } from 'react-router-dom'

import { routes } from '~config'
import { Icon } from '~design-system'

import { Container, List, ListItem, Title } from './styles'

type Props = {
  questions: QuestionTeaser[]
  title: string
}

const QuestionsBlock = ({ questions, title }: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      <List>
        {questions.map(({ question, id }) => (
          <ListItem key={id}>
            <Link
              to={generatePath(routes.authenticated.faqQuestion, {
                id: `${id}`,
              })}
            >
              <Icon name="document" color="primary" size="small" />
              <span>{question}</span>
            </Link>
          </ListItem>
        ))}
      </List>
    </Container>
  )
}

export default QuestionsBlock
