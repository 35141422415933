import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const InputContainer = styled(Box)`
  ${({ theme }) => css`
    cursor: pointer;
    padding: ${theme.spaces[10]};
    display: flex;
    flex: 1;
    min-height: 150px;
    align-items: center;
    justify-content: center;
  `}
`

export default InputContainer
