import { Options } from '~components/Chat/components'
import { getParseBodyParams, getType } from '~components/Chat/helpers'

type Props = {
  dialog: Stepper
  dialogType: ApiGetStepResponse['data'][0]['type']
  index: number
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const Option = ({
  dialog,
  dialogType,
  index,
  setAnswers,
  handleAnswer,
}: Props) => {
  const dialogTypes = dialog.map(({ type }) => type)
  const isLastOfType = (dialogType: string, dialogTypeIndex: number) => {
    return dialogTypes[dialogTypeIndex - 1] !== dialogType
  }

  if (!isLastOfType(dialogType, index)) return null
  const options = getType(dialog, 'option')

  const handleUserOptionAnswer = (answer: Answer) => {
    setAnswers(answer.label)
    handleAnswer(answer.action, getParseBodyParams(answer))
  }

  return (
    <Options
      key={dialogType + index}
      onChoice={handleUserOptionAnswer}
      options={options}
    />
  )
}

export default Option
