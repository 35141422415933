import { Theme } from 'styled-components'

import { Box } from '~design-system'

import { Bubble, Container, LogoContainer, Path } from './styles'

type Props = {
  pathColor?: keyof Theme['colors']
  imageSrc: string
  imageAlt?: string
  imageWidth?: string
  bubbleOneColor?: keyof Theme['colors']
  bubbleTwoColor?: keyof Theme['colors']
}

const MatchingLoader = ({
  pathColor = 'white',
  imageSrc,
  imageAlt = 'Image Loader',
  imageWidth = '73',
  bubbleOneColor = 'primary',
  bubbleTwoColor = 'blue',
}: Props) => {
  return (
    <Box pos="relative" maxW="100%">
      <LogoContainer>
        <img src={imageSrc} alt={imageAlt} width={imageWidth} />
      </LogoContainer>
      <Container
        viewBox="0 0 366 343"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        time={3}
      >
        <Path
          pathColor={pathColor}
          d="M352.331 171.5C352.331 265.94 275.615 342.5 180.978 342.5C86.3417 342.5 9.62523 265.94 9.62523 171.5C9.62523 77.0603 86.3417 0.5 180.978 0.5C275.615 0.5 352.331 77.0603 352.331 171.5Z"
        />
        <Bubble
          d="M364.503 191.231C364.503 199.336 357.919 205.908 349.795 205.908C341.67 205.908 335.086 199.336 335.086 191.231C335.086 183.126 341.67 176.554 349.795 176.554C357.919 176.554 364.503 183.126 364.503 191.231Z"
          color={bubbleOneColor}
        />
      </Container>
      <Container
        width="366"
        height="343"
        viewBox="0 0 366 343"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Bubble
          d="M29.9174 206.406C29.9174 214.511 23.3331 221.083 15.2087 221.083C7.08433 221.083 0.5 214.511 0.5 206.406C0.5 198.301 7.08433 191.729 15.2087 191.729C23.3331 191.729 29.9174 198.301 29.9174 206.406Z"
          color={bubbleTwoColor}
        />
      </Container>
    </Box>
  )
}

export default MatchingLoader
