import { useMutation, useQuery } from 'react-query'

import { JobOfferService, queryClient } from '~api'
import { isReferent, isSponsor, isYoung } from '~helpers'

function useJob() {
  const useGetJobOffer = (id?: number) => {
    return useQuery(
      ['job-offer', id],
      () => JobOfferService.apiGetJobOffer(id),
      {
        enabled: !!id,
      }
    )
  }

  const useGetJobOffers = (filters?: string) =>
    useQuery(['job-offers', filters], () =>
      JobOfferService.apiGetJobOffers(filters)
    )

  const useGetPEJobOffers = (filters?: string) =>
    useQuery(['pe-job-offers', filters], () =>
      JobOfferService.apiGetPEJobOffers(filters)
    )

  const useDeleteJobOffer = () =>
    useMutation(JobOfferService.apiDeleteJobOffer, {
      onSuccess: (data) => {
        queryClient.setQueryData(['job-offers'], data)
        queryClient.invalidateQueries('created-job-offers')
        queryClient.invalidateQueries('company-job-offers')
      },
    })

  const useUpdateJobOffer = (id?: number) =>
    useMutation(JobOfferService.apiUpdateJobOffer, {
      onSuccess: (v: any) => {
        queryClient.setQueryData(['job-offer', id], v)
      },
    })

  const useGetCreatedJobOffers = (user: User | undefined) => {
    return useQuery(
      ['created-job-offers'],
      JobOfferService.apiGetCreatedJobOffers,
      {
        enabled: user && isSponsor(user),
      }
    )
  }

  const useGetCompanyJobOffers = (user: User | undefined) => {
    return useQuery(
      ['company-job-offers'],
      JobOfferService.apiGetCompanyJobOffers,
      {
        enabled: user && isReferent(user),
      }
    )
  }

  const useGetUserJobOffers = () =>
    useQuery(['user-job-offers'], () => JobOfferService.apiGetUserJobOffers())

  const useLikeJobOffer = (id: number) =>
    useMutation(JobOfferService.apiLikeJobOffer, {
      onSuccess: () => {
        queryClient.invalidateQueries('kanban')
        queryClient.setQueryData(['job-offer', id], (oldData: any) => ({
          ...oldData,
          selected: !oldData.selected,
        }))
      },
    })

  const useGetKanban = (id: number) =>
    useQuery(['kanban', id], () => JobOfferService.apiGetKanban(id), {
      enabled: !!id,
    })

  const useUpdateKanban = (id: number) =>
    useMutation(JobOfferService.apiUpdateKanban, {
      onSuccess: (data) => {
        queryClient.setQueryData(['kanban', id], data)
      },
      onError: () => {
        queryClient.invalidateQueries('kanban')
      },
    })

  const useAddJobOffer = () =>
    useMutation(JobOfferService.apiAddJobOffer, {
      onSuccess: (data) => {
        queryClient.setQueryData(['jobs', data.id], data)
        queryClient.invalidateQueries('created-job-offers')
        queryClient.invalidateQueries('company-job-offers')
      },
    })

  const useGetRecommendedJobOffers = (
    user: User | undefined,
    filters?: string
  ) =>
    useQuery(
      ['recommended-job-offers', filters],
      () => JobOfferService.apiGetRecommendedJobOffers(filters),
      {
        enabled: isYoung(user) || isSponsor(user),
      }
    )

  return {
    useGetJobOffer,
    useDeleteJobOffer,
    useUpdateJobOffer,
    useGetJobOffers,
    useGetPEJobOffers,
    useGetCreatedJobOffers,
    useGetCompanyJobOffers,
    useGetUserJobOffers,
    useLikeJobOffer,
    useGetKanban,
    useUpdateKanban,
    useAddJobOffer,
    useGetRecommendedJobOffers,
  }
}

export default useJob
