import { useFormContext } from 'react-hook-form'

import { Box, Textarea } from '~design-system'

const EditProfessionalProject = () => {
  // Define Form
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()

  return (
    <Box w="100%">
      <Textarea
        id="professionalProject"
        placeholder="Saisis ici ton projet professionnel"
        label="Projet professionnel"
        autoFocus
        errorMessage={errors?.professionalProject?.message}
        {...register('professionalProject', {
          required: true,
          validate: (v) => v.trim().length !== 0, // Avoid only spaces value
        })}
      >
        {watch('professionalProject')}
      </Textarea>
    </Box>
  )
}

export default EditProfessionalProject
