import styled from 'styled-components'

import Box from '~design-system/Box'

const SubtitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`

export default SubtitleContainer
