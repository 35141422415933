import api from '../api'

const apiGetCategories = (filters?: QuestionFilters) =>
  api
    .get<ApiPaginatedResponse<{ categories: QuestionCategory[] }>>(
      `/api/v1/faq/categories`,
      {
        params: filters,
      }
    )
    .then((response) => response.data.data.categories)

const apiGetQuestion = (id: number) =>
  api
    .get<ApiResponse<Question>>(`api/v1/faq/${id}`)
    .then((response) => response.data.data)

const apigGetPopularQuestions = (currentRole: LocalRole) =>
  api
    .get<ApiPaginatedResponse<QuestionTeaser[]>>(`api/v1/faq`, {
      params: {
        order_by: 'popularity',
        limit: 6,
        currentRole,
      },
    })
    .then((response) => response.data.data)

const apiGetQuestions = (filters?: QuestionFilters) =>
  api
    .get<ApiPaginatedResponse<QuestionTeaser[]>>('api/v1/faq', {
      params: filters,
    })
    .then((response) => response.data)

const FaqService = {
  apiGetCategories,
  apiGetQuestion,
  apigGetPopularQuestions,
  apiGetQuestions,
}
export default FaqService
