import styled from 'styled-components'

import Box from '../Box'
import Button from '../Button'
import Icon from '../Icon'
import { Text } from '../Typographies'

type Props = {
  id: string
  label: string
  onChoice: () => void
  variant?: 'outlined'
}

const Radio = styled('input')`
  display: none;
`

const Option = ({ id, label, onChoice, variant }: Props) => {
  return (
    <Button
      as="label"
      htmlFor={id}
      mb={1}
      variant="secondary"
      outlined={variant === 'outlined'}
    >
      <Radio id={id} onClick={onChoice} type="radio" value={id} />
      <Box ai="ce" di="f" fg={1} jc="space-between">
        <Text textColor="primary">{label}</Text>
        <Icon color="primary" name="arrow-right" ml={2} />
      </Box>
    </Button>
  )
}

export default Option
