import { format } from 'date-fns'
import { camelize } from 'humps'
import { FormProvider, useForm } from 'react-hook-form'

import {
  ChecklistSelector,
  JobSelector,
  SectorSelector,
} from '~components/Selectors'
import { useAuth } from '~context/AuthContext'
import { Box, Button, PopIn } from '~design-system'
import { getDateFormattedForAPI } from '~helpers'
import { useData, useUser } from '~hooks'

import { FormContainer } from '../styles'
import {
  EditAvatar,
  EditBirthday,
  EditCivility,
  EditDescription,
  EditEmail,
  EditFirstWorkYear,
  EditInfos,
  EditLinks,
  EditProfessionalProject,
} from './components'

interface Props {
  data: EditInfos
  onClose: () => void
}

const EditPopIn = ({ data, onClose }: Props) => {
  const { useUpdateUser, useUploadDocument, useDeleteDocument } = useUser()
  const { useGetHobbies, useGetSkills } = useData()
  const { mutate: updateUser, isLoading: isUserLoading } = useUpdateUser()
  const { mutate: updloadCV, isLoading: isCVLoading } = useUploadDocument()
  const { mutate: deleteCV, isLoading: isCVDeleting } = useDeleteDocument()
  const {
    authState: { user },
  } = useAuth()
  const { data: hobbies } = useGetHobbies()
  const { data: skills } = useGetSkills()
  const slug = data?.slug

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...user,
      birthDate: user && format(new Date(user?.birthDate), 'dd/MM/yyyy'),
      hobbies: user?.hobbies?.map((hobbie) => hobbie.id.toString()),
      skills: user?.skills?.map((skill) => skill.id.toString()),
      sector: user?.sector?.id,
      domain: user?.sector?.domain,
      desiredProfessions: user?.desiredProfessions?.map((job) => job.code),
      resume: !!user?.resumes?.length ? user?.resumes[0].url : undefined,
    },
  })

  // Define Form
  const {
    handleSubmit,
    getValues,
    setError,
    formState: { isDirty, isValid },
  } = methods

  // Format Value for API
  const valueToSend = () => {
    switch (slug) {
      case 'birthDate':
        return {
          [slug]: getDateFormattedForAPI(
            getValues(slug) as string,
            'dd/MM/yyyy'
          ),
        }
      case 'infos':
        return {
          rsaNumber: getValues('rsaNumber'),
          poleEmploiNumber: getValues('poleEmploiNumber'),
          phone: getValues('phone'),
        }
      case 'links':
        return {
          linkedinUrl: getValues('linkedinUrl'),
        }
      default:
        return { [slug]: getValues(slug) }
    }
  }

  // Handle Resume
  const handleResume = () => {
    // Add Resume (if no resume saved AND one is added on the form)
    if (!user?.resumes?.length && getValues('resume')) {
      updloadCV(
        { resume: getValues('resume') },
        {
          onSuccess() {
            onClose()
          },
        }
      )
    }
    // Delete Last Resume and Add a New Resume
    else if (
      !!user?.resumes?.length &&
      getValues('resume') &&
      user?.resumes[0].url !== getValues('resume')
    ) {
      deleteCV(user?.resumes[0].id.toString(), {
        onSuccess() {
          updloadCV(
            { resume: getValues('resume') },
            {
              onSuccess() {
                onClose()
              },
            }
          )
        },
      })
    }
    // Delete Last Resume
    else if (!!user?.resumes?.length && !getValues('resume')) {
      deleteCV(user?.resumes[0].id.toString(), {
        onSuccess() {
          onClose()
        },
      })
    } else {
      return null
    }
  }

  // Handle Edit
  const handleRequest = () => {
    // Edit a Resume
    if (slug === 'links') {
      handleResume()
    }

    // Update User
    updateUser(valueToSend(), {
      onSuccess() {
        onClose()
      },
      onError(err: any) {
        const fieldName = camelize(
          Object.keys(err.response.data.data)[0]
        ) as keyof User
        setError(
          fieldName,
          {
            type: 'custom',
            message:
              err.response.data.message ||
              'Oups, une erreur est survenue, veuillez réessayer plus tard...',
          },
          { shouldFocus: true }
        )
      },
    })
  }

  // Get PopIn Content depending of type
  const renderContent = () => {
    switch (slug) {
      case 'birthDate':
        return <EditBirthday />
      case 'civility':
        return <EditCivility />
      case 'description':
        return <EditDescription />
      case 'desiredProfessions':
        return <JobSelector name="desiredProfessions" />
      case 'email':
        return <EditEmail />
      case 'firstWorkYear':
        return <EditFirstWorkYear />
      case 'hobbies':
        return hobbies && <ChecklistSelector name="hobbies" options={hobbies} />
      case 'infos':
        return <EditInfos />
      case 'links':
        return <EditLinks />
      case 'professionalProject':
        return <EditProfessionalProject />
      case 'profilePhoto':
        return <EditAvatar />
      case 'sector':
        return <SectorSelector />
      case 'skills':
        return skills && <ChecklistSelector name="skills" options={skills} />
      default:
        return null
    }
  }

  return (
    <PopIn open closable onClose={onClose}>
      <FormProvider {...methods}>
        <FormContainer onSubmit={handleSubmit(handleRequest)}>
          <PopIn.Title textAlign="left">
            <Box mb={12}>{data.title}</Box>
          </PopIn.Title>
          <PopIn.Content>{renderContent()}</PopIn.Content>
          <PopIn.Actions
            actions={[
              <Button
                key="cancel"
                variant="secondary"
                onClick={onClose}
                disabled={isUserLoading || isCVLoading || isCVDeleting}
              >
                Annuler
              </Button>,
              <Button
                key="save"
                disabled={!isDirty || !isValid}
                type="submit"
                isLoading={isUserLoading || isCVLoading || isCVDeleting}
              >
                Enregistrer
              </Button>,
            ]}
          />
        </FormContainer>
      </FormProvider>
    </PopIn>
  )
}

export default EditPopIn
