import { isToday, isYesterday, parseISO } from 'date-fns'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Content, NotificationCard, Pagination } from '~components'
import { Box, Loader, Text } from '~design-system'
import { useNotifications } from '~hooks'

type OrderedNotifs = {
  [key: string]: NQTNotification[]
}

export default function Notifications() {
  const [searchParams, setSearchParams] = useSearchParams()

  const { useGetNotifications } = useNotifications()
  const {
    data: notifications,
    isLoading,
    isFetching,
  } = useGetNotifications(
    !!searchParams.toString() ? `?${searchParams.toString()}` : undefined
  )

  const reorderedNotifications = useMemo(() => {
    if (!notifications?.data || notifications?.data?.length === 0)
      return undefined
    const notifs: OrderedNotifs = {
      "Aujourd'hui": [],
      Hier: [],
      'Plus anciens': [],
    }
    notifications?.data.forEach((notif) => {
      if (isToday(parseISO(notif.createdAt))) {
        notifs["Aujourd'hui"].push(notif)
        return
      }
      if (isYesterday(parseISO(notif.createdAt))) {
        notifs['Hier'].push(notif)
        return
      }
      notifs['Plus anciens'].push(notif)
    })
    return notifs
  }, [notifications])

  const clickHandler = (key: string, value: string) => {
    searchParams.set(key, value)
    setSearchParams(searchParams)
  }

  if (isLoading) {
    return <Loader isCentered />
  }

  if (!isLoading && notifications?.data.length === 0) {
    return (
      <Content>
        <p>Aucune notification pour le moment </p>
      </Content>
    )
  }

  return (
    <>
      <Content>
        <>
          {reorderedNotifications &&
            Object.entries(reorderedNotifications).map(
              ([key, value], index) => {
                if (value.length > 0) {
                  return (
                    <Box key={`notification-date-${index}`} mb={8}>
                      <Text textColor="primary" weight="medium" mb={4}>
                        {key}
                      </Text>
                      {value.map((notif) => (
                        <Box mb={4} key={notif.id}>
                          <NotificationCard
                            {...notif}
                            isFetching={isFetching}
                          />
                        </Box>
                      ))}
                    </Box>
                  )
                }
                return null
              }
            )}
          {notifications?.meta.lastPage && (
            <Pagination
              mt={8}
              currentPage={notifications?.meta.currentPage}
              totalPage={notifications?.meta.lastPage}
              onChange={(page: number) => clickHandler('page', page.toString())}
            />
          )}
        </>
      </Content>
    </>
  )
}
