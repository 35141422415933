import api from '../api'

const apiGetUser = () =>
  api
    .get<GetUserResponse>('/api/v1/user/me')
    .then((response) => response.data)
    .then((response) => response.data)

const apiUpdateUser = (body: Partial<User>) =>
  api
    .put<GetUserResponse>('/api/v1/user', { ...body })
    .then((response) => response.data)
    .then((response) => response.data)

const apiUploadUserDoc = (args: UploadDocumentProps) =>
  api.post('/api/v1/user/document', args).then((response) => response.data)

const apiGetUserDocument = () =>
  api
    .get<GetUserDocumentResponse>(`/api/v1/user/document`)
    .then((response) => response.data.data)

const apiDeleteDocument = (id: string) =>
  api
    .delete<ApiResponse<Document>>(`api/v1/user/document/${id}`)
    .then((response) => response.data)

const apiVerifyAddress = ({ address, url }: PostAddressRequest) =>
  api
    .post<PostAddressResponse>(url, { address })
    .then((response) => response.data)

const apiGetUserById = (id: string) =>
  api
    .get<GetUserResponse>(`/api/v1/user/${id}`)
    .then((response) => response.data.data)

const apiUpdatePassword = (data: UpdatePasswordRequest) =>
  api
    .post<UpdatePasswordRequest>('api/v1/user/change-password', data)
    .then((response) => response.data)

const apiGetSponsor = (id: string) =>
  api
    .get<GetSponsorResponse>(`api/v1/sponsor/${id}`)
    .then((response) => response.data.data.user)

const apiUpdateSponsorQualification = (data: QualificationDataToUpdate) =>
  api
    .patch(`api/v1/sponsor/qualification`, { ...data, type: 'parrain' })
    .then((response) => response.data)

const apiUpdateYoungQualification = (data: QualificationDataToUpdate) =>
  api
    .patch(`api/v1/young/qualification`, data)
    .then((response) => response.data)

const apiReferentBecomeSponsor = () =>
  api
    .post<GetUserResponse>('/api/v1/company-referent/become-sponsor')
    .then((response) => response.data)

const apiReferentGetRseReport = () =>
  api.post('/api/v1/export/csr-report').then((response) => response.data)

const UserService = {
  apiGetSponsor,
  apiGetUser,
  apiUpdateUser,
  apiUploadUserDoc,
  apiGetUserDocument,
  apiDeleteDocument,
  apiVerifyAddress,
  apiGetUserById,
  apiUpdatePassword,
  apiUpdateSponsorQualification,
  apiUpdateYoungQualification,
  apiReferentBecomeSponsor,
  apiReferentGetRseReport,
}

export default UserService
