import { PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

import { LinkText } from './components/LinkText'

const Container = styled('div')`
  position: relative;

  ${LinkText}:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const ParentLink = ({ children }: PropsWithChildren<ReactNode>) => {
  return <Container>{children}</Container>
}

export default ParentLink
