import Button from '~design-system/Button'
import { WelcomeTourContainer } from '~pages/Authenticated/Home/styles/WelcomeTour'

type Props = {
  closeWelcomeTour: () => void | Function
  isYoung?: boolean
}

const WelcomeTour = ({ closeWelcomeTour, isYoung }: Props) => {
  const windowSize = window.matchMedia('(max-width: 800px)')

  if (isYoung) {
    if (windowSize.matches)
      return (
        <WelcomeTourContainer onClick={closeWelcomeTour}>
          <iframe
            src="https://embed.getsmartcue.com/IQIZD3SU"
            loading="lazy"
            allowFullScreen
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '80vw',
              height: '80vh',
            }}
            title="Welcome tour - Desktop- Parrain"
          ></iframe>
          <Button onClick={closeWelcomeTour}>Quitter le Welcome tour</Button>
        </WelcomeTourContainer>
      )
    return (
      <WelcomeTourContainer onClick={closeWelcomeTour}>
        <iframe
          src="https://embed.getsmartcue.com/IQIZD3SU"
          loading="lazy"
          allowFullScreen
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '80vw',
            height: '80vh',
          }}
          title="Welcome tour - NQT - desktop"
        ></iframe>
        <Button onClick={closeWelcomeTour}>Quitter le Welcome tour</Button>
      </WelcomeTourContainer>
    )
  }
  if (!isYoung && windowSize.matches)
    return (
      <WelcomeTourContainer onClick={closeWelcomeTour}>
        <iframe
          src="https://embed.getsmartcue.com/ILVG3Q0T"
          loading="lazy"
          allowFullScreen
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '80vw',
            height: '80vh',
          }}
          title="Welcome tour - Desktop- Parrain"
        ></iframe>
        <Button onClick={closeWelcomeTour}>Quitter le Welcome tour</Button>
      </WelcomeTourContainer>
    )

  return (
    <WelcomeTourContainer onClick={closeWelcomeTour}>
      <iframe
        src="https://embed.getsmartcue.com/IO219EP4"
        loading="lazy"
        allowFullScreen
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '80vw',
          height: '80vh',
        }}
        title="Welcome tour - Desktop- Parrain"
      ></iframe>
      <Button onClick={closeWelcomeTour}>Quitter le Welcome tour</Button>
    </WelcomeTourContainer>
  )
}

export default WelcomeTour
