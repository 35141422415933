import { generatePath } from 'react-router-dom'

import { routes } from '~config'
import { Icon } from '~design-system'

import { Category, List, Title } from './styles'

type Props = {
  categories: QuestionCategory[]
}

const CategoriesBlock = ({ categories }: Props) => {
  return (
    <>
      <Title>Sélectionnez la catégorie qui vous correspond</Title>
      <List>
        {categories.map(({ name, id }) => (
          <Category
            key={id}
            to={generatePath(routes.authenticated.faqCategory, { id: `${id}` })}
          >
            {name}
            <Icon name="chevron-right" size="small" color="greyDarker" />
          </Category>
        ))}
      </List>
    </>
  )
}

export default CategoriesBlock
