import { useQuery } from 'react-query'

import { HaloService } from '~api'

function useServices() {
  const useGetService = (id?: number) => {
    return useQuery(['services', id], async () => {
      const service = await HaloService.apiGetService(id);
      if (service) {
        if (service.isFrance && service.address?.properties) {
          service.address.properties.city = 'Dans toute la France';
        }
      }

      return service;
    }, {
      enabled: !!id,
    })
  }

  const useGetServices = (filters?: string) =>
    useQuery(['services', filters], () => HaloService.apiGetServices(filters))

  return {
    useGetService,
    useGetServices,
  }
}

export default useServices
