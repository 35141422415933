import styled, { Theme, css } from 'styled-components'

type BubbleProps = {
  color?: keyof Theme['colors']
}

const Bubble = styled('path').attrs((props: BubbleProps) => ({
  color: props.color || 'primary',
}))<BubbleProps>`
  ${({ color, theme }) => css`
    fill: ${theme.colors[color]};
    stroke: ${theme.colors.white};
    stroke-width: 1px;
  `}
`

export default Bubble
