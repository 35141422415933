import Select, { GroupBase, Props } from 'react-select'

import { theme } from '~config'
import { TinyText } from '~design-system/Typographies'

const getBorderColor = (inverted: boolean, isDisabled: boolean) => {
  if (isDisabled) {
    return theme.colors.greyLight
  }

  return inverted ? theme.colors.white : theme.colors.blueLighter
}

const customStyles = (inverted: boolean, error: boolean) => ({
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: inverted ? theme.colors.white : theme.colors.blueLighter,
    border: `1px solid ${theme.colors.greyLight}`,
    borderRadius: theme.radii.lg,
    boxShadow: 0,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: state.isDisabled
      ? theme.colors.greyDarker
      : inverted
      ? theme.colors.greyDarkest
      : theme.colors.primary,
    fontWeight: theme.components.text.weights.regular,
    fontSize: '0.875rem',
    lineHeight: 1.3,
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: state.isDisabled
      ? theme.colors.greyDarker
      : inverted
      ? theme.colors.black
      : theme.colors.primary,
    '&:hover': {
      color: theme.colors.primary,
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: inverted ? theme.colors.black : theme.colors.primary,
    fontWeight: theme.components.text.weights.regular,
    fontSize: '0.875rem',
    lineHeight: 1.3,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? theme.colors.primary : 'transparent',
    color: state.isSelected
      ? theme.colors.white
      : inverted
      ? theme.colors.black
      : theme.colors.primary,
    cursor: 'pointer',
    fontWeight: theme.components.text.weights.regular,
    fontSize: '0.875rem',
    lineHeight: 1.3,
    '&:hover': {
      backgroundColor: state.isSelected
        ? theme.colors.primary
        : theme.colors.blueLight,
      color: state.isSelected ? theme.colors.white : theme.colors.primary,
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: getBorderColor(inverted, state.isDisabled),
    border: `1px solid ${error ? theme.colors.danger : theme.colors.greyLight}`,
    borderRadius: theme.radii.lg,
    color: theme.colors.primary,
    cursor: 'pointer',
    marginBottom: theme.spaces[2],
    padding: `${theme.spaces[0.75]} ${theme.spaces[1]}`,
  }),
  container: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
})

function CustomSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group> & {
    inverted?: boolean
    errorMessage?: string
  }
) {
  return (
    <>
      <Select
        {...props}
        placeholder={
          props.placeholder ? props.placeholder : 'Sélectionner un élément'
        }
        styles={customStyles(!!props.inverted, !!props.errorMessage)}
      />
      {props.errorMessage && (
        <TinyText textColor="danger" mt={2}>
          {props.errorMessage}
        </TinyText>
      )}
    </>
  )
}

export default CustomSelect
