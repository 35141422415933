import { MouseEvent } from 'react'
import styled from 'styled-components'

import { theme } from '~config'
import { Loader } from '~design-system'

interface Props {
  isFavorite: boolean
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
  disabled: boolean
  withText?: boolean
}

const Button = styled('button')`
  cursor: pointer;
`

const FavoriteButton = ({
  isFavorite,
  onClick,
  disabled,
  withText = true,
}: Props) => {
  return (
    <Button
      disabled={disabled}
      aria-label={isFavorite ? 'Retirer des favoris' : 'Ajouter aux favoris'}
      onClick={onClick}
    >
      {disabled ? (
        <Loader size="small" />
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.31256 3.88496L10 4.53568L10.6875 3.88494C12.2125 2.44133 14.3308 2.00974 16.0186 2.57132C17.6296 3.10733 19 4.60171 19 7.40473C19 8.73551 18.4792 10.042 17.6151 11.2912C16.7509 12.5406 15.5786 13.6803 14.3721 14.6536C13.1697 15.6236 11.9619 16.4055 11.052 16.9457C10.6211 17.2015 10.2594 17.4017 9.99999 17.5406C9.74059 17.4017 9.37891 17.2016 8.948 16.9457C8.03807 16.4055 6.83028 15.6237 5.62786 14.6537C4.42136 13.6804 3.24913 12.5408 2.38492 11.2914C1.52081 10.0421 1 8.73561 1 7.40473C1 4.60188 2.37034 3.1075 3.98135 2.57145C5.66918 2.00985 7.7875 2.44138 9.31256 3.88496Z"
            fill={isFavorite ? theme.colors.primary : 'none'}
            stroke="#3367CD"
            stroke-width="2"
          />
        </svg>
      )}

      {withText && 'Favoris'}
    </Button>
  )
}

export default FavoriteButton
