import { ReactNode } from 'react'

import Box from '../Box'
import { Text } from '../Typographies'
import { Container } from './styles'

interface Props {
  children: ReactNode
  variant?: 'answer' | 'question'
}

const Message = ({ children, variant = 'question' }: Props) => {
  const isAnswer = variant === 'answer'

  return (
    <Box di="f" fg={1} jc={isAnswer ? 'end' : 'start'}>
      <Container variant={variant}>
        <Text
          textColor={isAnswer ? 'white' : 'black'}
          whiteSpace={isAnswer ? 'normal' : 'pre-line'}
          ai="ce"
          di="f"
          jc="sb"
        >
          {children}
        </Text>
      </Container>
    </Box>
  )
}

export default Message
