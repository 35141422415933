import styled, { css } from 'styled-components'

const PopInElement = styled('div')`
  ${({ theme }) => css`
    left: 50%;
    min-height: 100vh;
    max-width: 800px;
    width: 100%;
    padding: ${theme.spaces[12]};
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-50%);
    z-index: ${theme.zIndex.popin.container};

    @media (max-width: 500px) {
      padding: ${theme.spaces[4]};
    }
  `}
`

export default PopInElement
