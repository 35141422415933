import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import NavIcon from './NavIcon'

export const NavItem = styled(Link)`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.black};
    padding: ${theme.spaces[5]};
    display: flex;
    &:visited {
      color: currentColor;
    }
    &:active,
    &:hover,
    &.selected,
    &[aria-current='true'] {
      color: ${theme.colors.primary};
      background-color: ${theme.colors.greyLightest};

      ${NavIcon} {
        color: ${theme.colors.primary};
      }
    }
  `}
`
export const WelcomeTourItem = styled('div')`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.black};
    padding: ${theme.spaces[5]};
    display: flex;
    &:visited {
      color: currentColor;
    }
    &:active,
    &:hover,
    &.selected,
    &[aria-current='true'] {
      color: ${theme.colors.primary};
      background-color: ${theme.colors.greyLightest};
      cursor: pointer;
      ${NavIcon} {
        color: ${theme.colors.primary};
      }
    }
  `}
`
