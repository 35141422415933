import { useEffect } from 'react'
import { Link, generatePath } from 'react-router-dom'

import { ampli } from '~ampli'
import { ShareButton } from '~components'
import { routes } from '~config'
import { useAuth } from '~context/AuthContext'
import {
  Box,
  Button,
  Header3,
  Icon,
  Panel,
  Text,
  TinyText,
} from '~design-system'
import { formatIsoTo, isSponsor, isYoung } from '~helpers'
import { useGetEnums, useJob } from '~hooks'

import { HTMLDescription, JobTitle } from './styles'

const JobDetail = ({ job }: { job: Job }) => {
  const {
    authState: { user },
  } = useAuth()
  const { getJobEnumLabel } = useGetEnums()
  const { useLikeJobOffer } = useJob()
  const { mutate: addToSelection, isLoading: isAddingToSelection } =
    useLikeJobOffer(job.id)

  useEffect(() => {
    ampli.offerViewed({
      offer_id: job.id.toString(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Panel as="main">
      <Box di="f" ai="stretch" jc="space-between">
        <Box di="f" fd="c" jc="sb">
          <TinyText weight="regular" textColor="greyDarkest">
            {job.companyName && job.companyName + ' · '}
            {job?.address?.properties.city}
          </TinyText>
          <JobTitle mt={4}>{job.title}</JobTitle>
        </Box>

        <Box di="f" fd="c" ai="fe">
          {(isYoung(user) || isSponsor(user)) && !!user?.matchs?.length && (
            <ShareButton type="job" idToShare={job.id.toString()} user={user} />
          )}
          {job.createdAt && (
            <TinyText
              textAlign="right"
              weight="regular"
              textColor="greyDarkest"
            >
              Publiée le : {formatIsoTo(job.createdAt, 'dd/MM/yyyy')}
            </TinyText>
          )}
          {job.referenceNumber && (
            <TinyText
              textAlign="right"
              weight="regular"
              textColor="greyDarkest"
            >
              Référence : {job.referenceNumber}
            </TinyText>
          )}
        </Box>
      </Box>

      <Box di="f" mb={5} mt={5} g="17px" fw="w">
        <Box di="f" ai="center">
          <Icon name="briefcase" color="blueDark" />
          <TinyText weight="regular" textColor="greyDarkest">
            {job.contractType &&
              getJobEnumLabel('contract_type', job.contractType) + ' / '}
            {job.grossAnnualSalary} € / an
          </TinyText>
        </Box>
        {job.requiredEducationalLevel && (
          <Box di="f" ai="center">
            <Icon name="degree" color="blueDark" />
            <TinyText weight="regular" textColor="greyDarkest">
              {getJobEnumLabel(
                'educational_level',
                job.requiredEducationalLevel
              )}
            </TinyText>
          </Box>
        )}
        {job.requiredExperienceLevel && (
          <Box di="f" ai="center">
            <Icon name="degree" color="blueDark" />
            <TinyText weight="regular" textColor="greyDarkest">
              {getJobEnumLabel('experience_level', job.requiredExperienceLevel)}
            </TinyText>
          </Box>
        )}
        {job.teleworking && (
          <Box di="f" ai="center">
            <Icon name="building" color="blueDark" />
            <TinyText weight="regular" textColor="greyDarkest">
              Télétravail possible
            </TinyText>
          </Box>
        )}
        {job.sector && (
          <Box di="f" ai="center">
            <Icon name="briefcase" color="blueDark" />
            <TinyText weight="regular" textColor="greyDarkest">
              {job.sector}
            </TinyText>
          </Box>
        )}
      </Box>
      <Box mt={8}>
        <Header3 as="h2" weight="medium" mb={2}>
          Descriptif du poste
        </Header3>
        {job.jobDescription?.startsWith('<') ? ( // <- Siemens sends html in english
          <HTMLDescription
            dangerouslySetInnerHTML={{ __html: job.jobDescription }}
            lang="en"
          ></HTMLDescription>
        ) : (
          <Text textColor="greyDarkest">{job.jobDescription}</Text>
        )}
      </Box>
      {job.profileDescription && (
        <Box mt={8}>
          <Header3 as="h2" weight="medium" mb={2}>
            Profil recherché
          </Header3>
          <Text textColor="greyDarkest">{job.profileDescription}</Text>
        </Box>
      )}
      {job.requiredSkills && (
        <Box mt={8}>
          <Header3 as="h2" weight="medium" mb={2}>
            Compétences recherchées
          </Header3>
          <Text textColor="greyDarkest">{job.requiredSkills}</Text>
        </Box>
      )}
      {job.additionalInformations && (
        <Box mt={8}>
          <Header3 as="h2" weight="medium" mb={2}>
            Informations supplémentaires
          </Header3>
          <Text textColor="greyDarkest">{job.additionalInformations}</Text>
        </Box>
      )}
      <Box di="f" mt={10} g="10px" fw="w">
        {isYoung(user) && (
          <Button as="a" href={`mailto:${job.email}`}>
            Soumettre ma candidature par email
          </Button>
        )}
        <Button as="a" href={job.link} target="_blank">
          Consulter l'offre sur le site
        </Button>
        {isYoung(user) && (
          <Button
            variant="secondary"
            onClick={() => addToSelection(job.id.toString())}
            isLoading={isAddingToSelection}
          >
            {job.selected
              ? 'Retirer de ma sélection'
              : 'Ajouter à ma sélection'}
          </Button>
        )}
        {job.canEdit && (
          <Button
            variant="secondary"
            as={Link}
            to={generatePath(routes.authenticated.jobEdit, {
              id: job.id.toString(),
            })}
          >
            Editer cette offre
          </Button>
        )}
      </Box>
    </Panel>
  )
}

export default JobDetail
