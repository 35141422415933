export const contractTypeOptions = [
  { value: 'business_creation', label: "Création d'entreprise" },
  { value: 'cdd', label: 'CDD' },
  { value: 'cdi', label: 'CDI' },
  { value: 'civic_service', label: 'Service civique' },
  { value: 'international_volunteering_in_companies', label: 'VIE' },
  { value: 'stage', label: 'Stage' },
  { value: 'interim', label: 'Intérim' },
  { value: 'other', label: 'Autre' },
]

export const getContractTypeByValue = (val: string) =>
  contractTypeOptions.find(({ value }) => val === value)
