import { Link, useLocation, useNavigate } from 'react-router-dom'

import { routes } from '~config'
import { useAuth } from '~context/AuthContext'
import { Box } from '~design-system'
import { useSurvey } from '~hooks'

import { getNavMenu } from './content/navItems'
import { Nav, NavContainer, NavIcon, NavItem, WelcomeTourItem } from './styles'

const NavMenu = () => {
  const {
    authState: { user },
  } = useAuth()
  const route = useLocation()
  const navigate = useNavigate()

  const { useGetMatchingsWithSurvey } = useSurvey()
  const { data: matchings } = useGetMatchingsWithSurvey()

  const menuItems = getNavMenu({ user, matchings })

  const isActive = (link: string) => {
    const currentPath = route.pathname.split('/')[1]
    return `/${currentPath}` === link
  }

  const resetWelcomeTour = () => {
    localStorage.removeItem('hasSeenWelcomeTour')
    return navigate({
      pathname: routes.authenticated.home,
    })
  }

  return (
    <Nav>
      <NavContainer>
        {menuItems &&
          menuItems.map(({ link, icon, label }) => (
            <li key={`nav-item-${icon}`}>
              <NavItem
                to={!link?.startsWith('http') ? link : undefined}
                href={link?.startsWith('http') ? link : undefined}
                as={link?.startsWith('http') ? 'a' : Link}
                title={label}
                reloadDocument={link?.startsWith('http')}
                aria-current={isActive(link)}
              >
                <Box as="span" w={10} jc="ce" ai="ce" di="f">
                  <NavIcon name={icon} />
                </Box>
                {label}
              </NavItem>
            </li>
          ))}
        <WelcomeTourItem onClick={() => resetWelcomeTour()}>
          <Box as="span" w={10} jc="ce" ai="ce" di="f">
            <NavIcon name={'video'} />
          </Box>
          Revoir le Welcome Tour
        </WelcomeTourItem>
      </NavContainer>
    </Nav>
  )
}

export default NavMenu
