import { format } from 'date-fns'
import { camelize } from 'humps'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useAuth } from '~context/AuthContext'
import { Box, Button, PopIn, TinyText } from '~design-system'
import { isYoung } from '~helpers'
import { useExperiences } from '~hooks'

import { ConfirmationPopin } from '../components'
import { ExperienceForm } from '../forms'
import { FormContainer } from '../styles'

interface Props {
  data: EditInfos
  onClose: () => void
}

const ExperiencesPopIn = ({ data, onClose }: Props) => {
  const { useAddExperience, useDeleteExperience, useUpdateExperience } =
    useExperiences()
  const { mutate: updateExperience, isLoading: isLoadingUpdate } =
    useUpdateExperience()
  const { mutate: deleteExperience, isLoading: isLoadingDelete } =
    useDeleteExperience()
  const { mutate: addExperience, isLoading: isLoadingAdd } = useAddExperience()
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const experienceData = data?.data as Experience
  const {
    authState: { user },
  } = useAuth()

  // Define Form Methods and DefaultValues
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...experienceData,
      endDate: experienceData?.endDate
        ? format(new Date(experienceData?.endDate), 'dd/MM/yyyy')
        : '',
      startDate: experienceData?.startDate
        ? format(new Date(experienceData?.startDate), 'dd/MM/yyyy')
        : '',
    },
  })

  // Define Form
  const {
    handleSubmit,
    getValues,
    setError,
    formState: { isDirty, isValid },
  } = methods

  // Delete Experience
  const onDeleteExperience = () => {
    deleteExperience(experienceData.id, {
      onSuccess() {
        onClose()
      },
    })
  }

  // Update/Create Experience
  const handleRequest = () => {
    const actions = {
      onSuccess() {
        onClose()
      },
      onError(err: any) {
        const fieldName = camelize(
          Object.keys(err.response.data.data)[0]
        ) as keyof Experience
        setError(
          fieldName,
          {
            type: 'custom',
            message:
              err.response.data.message ||
              'Oups, une erreur est survenue, veuillez réessayer plus tard...',
          },
          { shouldFocus: true }
        )
      },
    }
    experienceData
      ? updateExperience({ ...getValues(), id: experienceData.id }, actions)
      : addExperience(getValues(), actions)
  }

  if (!showConfirmation) {
    return (
      <PopIn open closable onClose={onClose}>
        <FormProvider {...methods}>
          <FormContainer onSubmit={handleSubmit(handleRequest)}>
            <Box di="flex" ai="center" jc="space-between" mb={12}>
              <PopIn.Title textAlign="left">{data.title}</PopIn.Title>
              {data?.data && (
                <Button
                  textColor="danger"
                  option="unfilled"
                  pr={1}
                  pl={1}
                  pt={1}
                  pb={1}
                  size="small"
                  isLoading={isLoadingDelete}
                  disabled={isLoadingAdd || isLoadingUpdate}
                  onClick={() => setShowConfirmation(true)}
                >
                  <TinyText textColor="danger">Supprimer</TinyText>
                </Button>
              )}
            </Box>
            <PopIn.Content>
              <Box w="100%">
                <ExperienceForm isSponsor={!isYoung(user)} />
              </Box>
            </PopIn.Content>
            <PopIn.Actions
              actions={[
                <Button
                  key="cancel"
                  variant="secondary"
                  onClick={onClose}
                  disabled={isLoadingAdd || isLoadingDelete || isLoadingUpdate}
                >
                  Annuler
                </Button>,
                <Button
                  key="save"
                  disabled={!isDirty || !isValid || isLoadingDelete}
                  type="submit"
                  isLoading={isLoadingAdd || isLoadingUpdate}
                >
                  Enregistrer
                </Button>,
              ]}
            />
          </FormContainer>
        </FormProvider>
      </PopIn>
    )
  } else {
    return (
      <ConfirmationPopin
        title="Supprimer l’expérience professionnelle"
        content="Cette action est définitive, tous les éléments associés à ce service seront supprimés..."
        isLoading={isLoadingDelete}
        onCancel={() => setShowConfirmation(false)}
        onValidate={onDeleteExperience}
      />
    )
  }
}

export default ExperiencesPopIn
