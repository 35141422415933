import api from '../api'

const apiGetMatchingsWithSurvey = () =>
  api
    .get<ApiResponse<Matchings[]>>(`/api/v1/user/matchings`)
    .then((response) =>
      response.data.data.filter(
        (matching) => matching.survey && matching.endedSponsorship
      )
    )

const apiGetMatchings = () =>
  api
    .get<ApiResponse<Matchings[]>>(`/api/v1/user/matchings`)
    .then((response) => response.data.data)

const apiSendSurvey = (data: any) =>
  api
    .post<ApiResponse<Survey>>(
      `api/v1/user/matching/${data.matching_id}/survey`,
      data
    )
    .then((response) => response.data.data)

const apiEnumSurvey = () =>
  api
    .get<ApiResponse<MatchingsReportEnum>>(`api/v1/enum/survey`)
    .then((response) => response.data.data)

const SurveyService = {
  apiGetMatchingsWithSurvey,
  apiGetMatchings,
  apiSendSurvey,
  apiEnumSurvey,
}

export default SurveyService
