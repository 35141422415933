export const getParseBodyParams = (answer: Answer) =>
  // answer.bodyparam ? JSON.parse(answer.bodyparam.replace(/'/g, '"')) : null
  answer.bodyparam
    ? JSON.parse(answer.bodyparam.replace(/(?:\r\n|\r|\n)/g, ''))
    : null

export const getType = (
  dialog: Stepper,
  type: ApiGetStepResponse['data'][0]['type']
) => dialog.filter((d) => d.type === type)
