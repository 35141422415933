import { createElement } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { MarkdownCSS } from './styles'

type MarkdownRenderProps = {
  children: string
}

const MarkdownRender = ({ children }: MarkdownRenderProps) => {
  return (
    <>
      <MarkdownCSS />
      <ReactMarkdown
        className="markdown-render"
        remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
        children={children}
        components={{
          p: ({ ...props }) => {
            const content = props.children[0]

            if (content && typeof content === 'string') {
              if (content.startsWith('^') && content.endsWith('^'))
                return <sup>{content.substring(1).slice(0, -1)}</sup>

              if (content.startsWith('~') && content.endsWith('~'))
                return <sub>{content.substring(1).slice(0, -1)}</sub>
            }

            return createElement('p', props)
          },
        }}
      ></ReactMarkdown>
    </>
  )
}

export default MarkdownRender
