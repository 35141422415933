import styled, { css, Theme } from 'styled-components'

import { spacing, SpacingProps } from '../../helpers'

type Props = {
  textColor?: keyof Theme['colors']
  weight?: keyof Theme['components']['header3']['weights']
} & SpacingProps

const Header3 = styled('h3').attrs((props: Props) => ({
  textColor: props.textColor || 'black',
  weight: props.weight || 'regular',
}))<Props>`
  ${({ textColor, theme, weight }) => css`
    color: ${theme.colors[textColor]};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.components.header3.fontSize};
    font-weight: ${theme.components.header3.weights[weight]};
    line-height: 1.2;
    ${spacing};
  `}
`

export default Header3
