import { format, sub } from 'date-fns'
import { useFormContext } from 'react-hook-form'

import { Box, InputText } from '~design-system'

const EditFirstWorkYear = () => {
  // Define Form
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <Box w="100%">
      <InputText
        id="firstWorkYear"
        placeholder="Saisis ici l'année de ton entrée dans la vie active"
        label="Entrée dans la vie pro"
        maxLength={4}
        autoFocus
        errorMessage={errors?.firstWorkYear?.message}
        {...register('firstWorkYear', {
          pattern: /^(19[0-9]\d|20[0-9]\d)$/,
          validate: (value) =>
            value &&
            value.length === 4 &&
            value <= format(new Date(), 'yyyy') &&
            value >
              format(
                sub(new Date(), {
                  years: 99,
                }),
                'yyyy'
              ),
        })}
      />
    </Box>
  )
}

export default EditFirstWorkYear
