import styled, { Theme, css } from 'styled-components'

import { DisplayProps, SpacingProps, display, spacing } from '../../helpers'

type Props = {
  lineHeight?: number | string
  textAlign?: keyof Theme['components']['text']['align']
  textColor?: keyof Theme['colors']
  fontSize?: string
  weight?: keyof Theme['components']['text']['weights']
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line'
  textTransform?:
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'none'
    | 'full-width'
    | 'full-size-kana'
} & SpacingProps &
  DisplayProps

const Text = styled('p').attrs((props: Props) => ({
  lineHeight: props.lineHeight || 1.5,
  textAlign: props.textAlign || 'left',
  textColor: props.textColor || 'black',
  textTransform: props.textTransform || 'none',
  fontSize: props.fontSize || '1rem',
  weight: props.weight || 'regular',
  whiteSpace: props.whiteSpace || 'normal',
}))<Props>`
  ${({
    lineHeight,
    textAlign,
    textColor,
    textTransform,
    fontSize,
    theme,
    weight,
    whiteSpace,
  }) => css`
    color: ${theme.colors[textColor]};
    font-family: ${theme.fonts.primary};
    font-size: ${fontSize};
    font-weight: ${theme.components.text.weights[weight]};
    line-height: ${lineHeight};
    text-align: ${theme.components.text.align[textAlign]};
    white-space: ${whiteSpace};
    text-transform: ${textTransform};
  `}
  ${spacing}
  ${display}
`

export default Text
