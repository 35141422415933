import styled, { Theme, css } from 'styled-components'

import {
  DisplayProps,
  LayoutProps,
  SpacingProps,
  display,
  layout,
  spacing,
} from '../helpers'

export type Props = {
  backgroundColor?: keyof Theme['colors']
  overflow?: string
  noBorder?: boolean
  noPadding?: boolean
} & LayoutProps &
  SpacingProps &
  DisplayProps

const Panel = styled('div')<Props>`
  ${({
    backgroundColor = 'white',
    overflow = 'visible',
    theme,
    noBorder = false,
    noPadding = false,
  }) => css`
    background-color: ${theme.colors[backgroundColor]};
    border-radius: ${theme.radii.lg};
    border: ${!noBorder ? `1px solid ${theme.colors.blueLighter}` : 0};
    display: flex;
    flex-direction: column;
    padding: ${!noPadding ? `${theme.spaces[5]}` : 0};
    position: relative;
    text-decoration: none;
    overflow: ${overflow};
  `}
  ${layout};
  ${spacing};
  ${display};
`

export default Panel
