import styled from 'styled-components'

import { theme } from '~config'

const EventSidebar = styled('div')`
  background-color: ${theme.colors.white};
  border-radius: ${theme.radii.lg};
`

export default EventSidebar
