import useGetEnums from './useGetEnums'

function useEventsEnum() {
  const { getEventEnums } = useGetEnums()
  const eventEnum = getEventEnums()

  const transformTypesIntoOptions = () => {
    if (!eventEnum?.type) return null
    const keys = Object.entries(eventEnum?.type).map(([key, value]) => {
      return { value: key, label: value } as NQTEventEnumOptions
    })
    return keys
  }

  return {
    optionTypes: transformTypesIntoOptions(),
    types: eventEnum?.type,
  }
}

export default useEventsEnum
