import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { AuthService } from '~api'
import { Box, Button, Icon, InputText, Option } from '~design-system'
import { useUser } from '~hooks'

type Props = {
  autoFocus?: boolean
  id: string
  onValidate?: (address: AddressDetails) => void
  type?: string
  postUrl?: string
  placeholder: string
  disabled?: boolean
  onValidateYoung?: (
    address: AddressDetails,
    response: PostAddressResponse
  ) => void
}

const InputAddress = ({
  autoFocus = false,
  id,
  disabled = false,
  onValidate,
  placeholder,
  type,
  postUrl,
  onValidateYoung,
}: Props) => {
  const { setFocus, watch, setValue, control } = useFormContext()

  const [search, setSearch] = useState<string>('') // <- on crée le state local
  const [addresses, setAddresses] = useState<GetAddressesResponse | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const inputField = useRef() as React.MutableRefObject<HTMLInputElement>
  const { useVerifyAddress } = useUser()
  const { mutate: verifyAddress } = useVerifyAddress()

  useEffect(() => {
    if (inputField && autoFocus) {
      inputField.current.focus()
    }
  }, [inputField, setFocus, autoFocus])

  const fetchAddresses = async () => {
    setIsLoading(true)
    try {
      const response = await AuthService.getAddresses({
        addressToLookFor: search,
        limit: 5,
      })
      if (response) {
        setAddresses(response.data)
      }
      setIsLoading(false)
    } catch (error) {
      setAddresses(null)
      setIsLoading(false)
    }
  }

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') fetchAddresses()
  }

  const handleSearchAddress = () => {
    fetchAddresses()
  }

  const handleVerifyAddress = (address: AddressDetails) => {
    setIsLoading(true)
    if (postUrl && onValidateYoung) {
      verifyAddress(
        { address, url: postUrl },
        {
          onSuccess(response) {
            onValidateYoung(address, response)
            setIsLoading(false)
          },
          onError(error) {
            console.error(error)
            setIsLoading(false)
          },
        }
      )
    }
  }

  return (
    <>
      <InputText
        id={id}
        ref={inputField}
        type="text"
        onKeyUp={handleKeyUp}
        onKeyDown={() => setValue('address', undefined)}
        placeholder={placeholder}
        value={search || watch('address')?.properties?.label}
        onChange={(e) => setSearch(e.currentTarget.value)}
        disabled={isLoading || disabled}
        rightComponent={
          <Button
            disabled={search.length < 3}
            onClick={handleSearchAddress}
            isLoading={isLoading}
            size="small"
            square
          >
            <Icon name="search" />
          </Button>
        }
      />

      <Box ai="end" di={addresses ? 'f' : 'none'} fd="c">
        <Controller
          name={id}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => {
            if (addresses) {
              return (
                <>
                  {addresses.features.map((address) => (
                    <Option
                      id={address.properties.id}
                      key={address.properties.id}
                      label={address.properties.label}
                      onChoice={() => {
                        onChange(address)
                        onValidate && onValidate(address)
                        type && handleVerifyAddress(address)
                        setSearch('')
                        setAddresses(null)
                      }}
                    />
                  ))}
                </>
              )
            } else {
              return <></>
            }
          }}
        />
      </Box>
    </>
  )
}

export default InputAddress
