import { ReactNode } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled, { css } from 'styled-components'

import { Box, Header2, Panel, Text } from '~design-system'

import './styles.css'

type Props = {
  children?: ReactNode
}

const StyledPanel = styled(Panel)`
  ${({ theme }) => css`
    align-items: center;
    box-shadow: 0 10px 10px ${theme.colors.greyLight};
    padding: ${theme.spaces[8]};
    display: flex;
    align-items: center;
    justify-content: space-around;
  `}
`

const Logo = styled.img`
  ${({ theme }) => css`
    display: block;
    margin: 0 auto ${theme.spaces[10]};
  `}
`

const SlidesBox = styled(Box)`
  ${({ theme }) => css`
    margin: 0 auto;
    width: ${theme.spaces[64]};
    height: ${theme.spaces[64]};
    max-width: 100%;
  `}
`

const Img = styled.img`
  max-width: 100%;
  height: auto;
`

const slides = [
  {
    title: 'Accompagner les jeunes de moins de 30 ans',
    subtitle: 'pour concrétiser leur projet professionnel',
    text: "Trouve le job de tes rêves, décroche ton alternance, monte ta boîte avec l'aide de ton mentor et grâce au réseau NQT.",
    image: '/images/welcome/onboarding-1.png',
    imageAlt: "des visages autour d'un cercle",
  },
  {
    title: 'Une expérience digitalisée,',
    subtitle: ' fluide et efficace',
    text: 'Accédez simplement à des outils clés en main pour booster votre projet.',
    image: '/images/welcome/onboarding-2.png',
    imageAlt: "un aperçu de l'application",
  },
  {
    title: 'Un accompagnement sur mesure',
    subtitle: 'par un parrain expérimenté',
    text: 'Profitez de conseils avisés et personnalisés de la part d’un cadre supérieur en activité.',
    image: '/images/welcome/onboarding-3.png',
    imageAlt: "des visages autour d'un cercle",
  },
]

const Onboarding = ({ children }: Props) => {
  return (
    <StyledPanel h="100%" w="100%">
      <Box w="100%" mb={2} maxW={128} h="100%">
        <Logo src="/images/logo.svg" alt="logo NQT" />

        <Slider
          dots={true}
          slidesToShow={1}
          arrows={false}
          infinite
          autoplay
          swipe
          autoplaySpeed={10000}
        >
          {slides.map((slide, index) => (
            <Box key={`slide-${index}`}>
              <SlidesBox di="f" jc="ce" ai="ce">
                <Img src={slide.image} alt={slide.imageAlt} />
              </SlidesBox>
              <Box mt={10} mb={5}>
                <Header2 mb={5}>
                  <Header2 as="span" textColor="primary">
                    {slide.title}
                  </Header2>{' '}
                  {slide.subtitle}
                </Header2>
                <Text>{slide.text}</Text>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
      {children && (
        <Box maxW={80} w="100%" mt={10}>
          {children}
        </Box>
      )}
    </StyledPanel>
  )
}

export default Onboarding
