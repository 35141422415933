import { InputHTMLAttributes, Ref, forwardRef } from 'react'

import { Label, Text } from '~design-system'

import { Container, Radio } from './styles'

type Props = {
  label: string
  id: string
  value?: 'true' | 'false' | string
} & InputHTMLAttributes<HTMLInputElement>

const InputRadio = forwardRef(
  (
    { label, checked, id, value = label, ...rest }: Props,
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <Container di="f" ai="ce" g="5px">
        <Label htmlFor={`${id}_${label}`}>
          <Text weight="medium">{label}</Text>
        </Label>
        <Radio
          ref={ref}
          type="radio"
          name={id}
          id={`${id}_${label}`}
          value={value}
          {...rest}
        />
      </Container>
    )
  }
)

export default InputRadio
