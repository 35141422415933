import { Ref, forwardRef } from 'react'
import styled from 'styled-components'

import InputBase, { InputBaseProps } from '../InputBase'

const InputText = forwardRef(
  ({ ...props }: InputBaseProps, ref: Ref<HTMLInputElement>) => {
    return <InputBase ref={ref} {...props} data-testid={props.id} />
  }
)
InputText.displayName = 'InputText'

export default styled(InputText)``
