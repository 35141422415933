import api from '../api'

const apiGetNotifications = (filters?: string) =>
  api
    .get<ApiPaginatedResponse<NQTNotification[]>>(
      `/api/v1/user/notifications${filters || ''}`
    )
    .then((response) => response.data)

const apiDeleteNotification = (notifId: string) =>
  api
    .delete<ApiResponse<Message[]>>(`/api/v1/user/notifications/${notifId}`)
    .then((response) => response.data)

const apiMarkAsReadNotification = (notifId: string) =>
  api
    .post<ApiResponse<Message[]>>(
      `/api/v1/user/notifications/${notifId}/markasread`
    )
    .then((response) => response.data)

const apiHasUnreadNotifications = () =>
  api
    .get<ApiResponse<{ hasUnreadNotifications: boolean }>>(
      '/api/v1/user/has-unread-notifications'
    )
    .then((response) => response.data.data)

const NotificationService = {
  apiGetNotifications,
  apiDeleteNotification,
  apiMarkAsReadNotification,
  apiHasUnreadNotifications,
}

export default NotificationService
