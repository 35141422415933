import styled, { Theme, css } from 'styled-components'

import { LayoutProps, SpacingProps, layout, spacing } from '../helpers'

type Props = {
  color?: keyof Theme['colors']
  name: string
  size?: keyof Theme['components']['icon']['sizes']
} & SpacingProps &
  LayoutProps &
  React.ComponentProps<'i'>

const Icon = styled('i').attrs((props: Props) => ({
  color: props.color,
  className: `icon-${props.name}`,
  size: props.size || 'default',
}))<Props>`
  ${({ color, size, theme }) => css`
    color: ${color ? theme.colors[color] : 'currentColor'};
    display: inline-flex;
    font-size: ${theme.components.icon.sizes[size]};
    line-height: 1.2;
    ${spacing};
    ${layout};
  `}

  &:before {
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default Icon
