import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const Container = styled('div')`
  ${({ theme }) => css`
    background-color: ${theme.colors.blueLight};
    padding: 32px 18px;
    border-radius: ${theme.radii.lg};
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-gap: 1rem;
    z-index: 1;
    margin-top: 8px;

    @media screen and (max-width: 48rem) {
      grid-template-columns: 1fr;
    }

    &:before {
      position: absolute;
      content: '';
      border: 1px solid rgba(0, 194, 255, 0.16);
      width: 240px;
      height: 240px;
      border-radius: 50%;
      left: 230px;
      top: -122px;
      z-index: -1;
    }

    &:after {
      position: absolute;
      content: '';
      border: 1px solid rgba(0, 194, 255, 0.16);
      width: 240px;
      height: 240px;
      border-radius: 50%;
      right: -55px;
      bottom: -100px;
      z-index: -1;
    }
  `}
`

const Section = styled('div')`
  ${({ theme }) => css`
    margin: 0 auto;
  `}
`

const ChatLike = styled('div')`
  ${({ theme }) => css`
    margin-bottom: ${theme.spaces[2]};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${theme.colors.white};
    img + & {
      background-color: ${theme.colors.white};
      color: ${theme.colors.black};
      border-top-left-radius: 0%;
      border-bottom-right-radius: ${theme.radii.lg};
    }
  `}
`

const Bubble = styled('p')`
  ${({ theme }) => css`
    padding: 12px;
    border-radius: ${theme.radii.lg};
    border-bottom-right-radius: 0%;
    font-size: ${theme.pxToRem(14)};
    display: inline-block;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};

    img + & {
      background-color: ${theme.colors.white};
      color: ${theme.colors.black};
      border-top-left-radius: 0%;
      border-bottom-right-radius: ${theme.radii.lg};
    }
  `}
`

const Logo = styled('img')`
  ${({ theme }) => css`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 10px;
    background-color: ${theme.colors.primary};
    border: 1px solid ${theme.colors.white};
    margin-right: ${theme.spaces[2]};
  `}
`

const SectionTitle = styled('h3')`
  ${({ theme }) => css`
    font-size: ${theme.pxToRem(20)};
    font-weight: 700;
    color: ${theme.colors.primary};
  `}
`

const SectionText = styled('p')`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    opacity: 0.6;
    margin: 16px 0;
    font-size: ${theme.pxToRem(14)};
  `}
`

const SectionButton = styled(Link)`
  ${({ theme }) => css`
    font-size: ${theme.pxToRem(14)};
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    padding: 0.5em 0.8em;
    border-radius: ${theme.radii.md};
  `}
`

export {
  Logo,
  ChatLike,
  Container,
  Bubble,
  Section,
  SectionTitle,
  SectionText,
  SectionButton,
}
