import styled, { Theme, css, keyframes } from 'styled-components'

import { Box } from '~design-system'

type Props = {
  size?: keyof Theme['components']['loader']
  color?: keyof Theme['colors']
  isCentered?: boolean
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const getCoordinate = (value: number) => value / 2
const getRadius = (diameter: number, strokeWidth: number) =>
  getCoordinate(diameter) - strokeWidth

const getCircumference = (radius: number) => Math.PI * radius

const Circle = styled('circle').attrs((props: Props) => ({
  color: props.color || 'primary',
  size: props.size || 'default',
}))<Props>`
  ${({ color, size, theme }) => css`
    cx: ${getCoordinate(theme.components.loader[size].size)}px;
    cy: ${getCoordinate(theme.components.loader[size].size)}px;
    fill: none;
    r: ${getRadius(
      theme.components.loader[size].size,
      theme.components.loader[size].strokeWidth
    )}px;
    stroke-width: ${theme.components.loader[size].strokeWidth};
    stroke: ${theme.colors[color]};
  `}
`

const Background = styled(Circle)`
  opacity: 0.2;
`

const Container = styled('div').attrs((props: Props) => ({
  size: props.size || 'default',
}))<Props>`
  ${({ size, theme }) => css`
    animation: ${spin} 1s linear infinite;
    height: ${theme.components.loader[size].size}px;
    width: ${theme.components.loader[size].size}px;
  `}
`

const Indicator = styled(Circle)`
  ${({ size, theme }) => css`
    stroke-dasharray: ${getCircumference(theme.components.loader[size].size)};
    stroke-dashoffset: ${getCircumference(theme.components.loader[size].size) *
    0.8};
  `}
`

const Loader = ({ size, color, isCentered }: Props) => {
  const renderLoader = (
    <Container size={size}>
      <svg height="100%" width="100%">
        <Background color={color} size={size} />
        <Indicator color={color} size={size} />
      </svg>
    </Container>
  )
  return isCentered ? (
    <Box w="100%" h="100%" di="flex" jc="center">
      {renderLoader}
    </Box>
  ) : (
    renderLoader
  )
}

export default Loader
