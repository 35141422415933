export const getSummaryLabels = (role: Role) => {
  switch (role) {
    case 'jeune':
      return [
        ['civility', 'Civilité'],
        ['firstname', 'Prénom'],
        ['lastname', 'Nom'],
        ['email', 'Adresse e-mail'],
        ['password', 'Mot de passe'],
        ['passwordConfirmation', 'Confirmation du mot de passe'],
        ['phone', 'Téléphone'],
        ['registrationDepartment', 'Département'],
      ]
    case 'parrain':
      return [
        ['civility', 'Civilité'],
        ['firstname', 'Prénom'],
        ['lastname', 'Nom'],
        ['email', 'Adresse e-mail'],
        ['password', 'Mot de passe'],
        ['passwordConfirmation', 'Confirmation du mot de passe'],
        ['company', 'Entreprise'],
        ['address', 'Adresse'],
        ['isFromNqtMemberCompany', 'Entreprise adhérente'],
        ['birthDate', 'Date de naissance'],
        ['firstWorkYear', 'Année d’entrée dans la vie active'],
        ['phone', 'Téléphone'],
        ['alreadyFollowed', 'Déjà suivi par NQT'],
      ]
    // Referent
    default:
      return [
        ['civility', 'Civilité'],
        ['firstname', 'Prénom'],
        ['lastname', 'Nom'],
        ['phone', 'Téléphone'],
        ['password', 'Mot de passe'],
        ['passwordConfirmation', 'Confirmation du mot de passe'],
        ['address', 'Adresse'],
        ['title', 'Fonction'],
        ['birthDate', 'Date de naissance'],
        ['firstWorkYear', 'Année d’entrée dans la vie active'],
      ]
  }
}
