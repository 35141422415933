import { camelize } from 'humps'
import { useFormContext } from 'react-hook-form'

import { Dropdown } from '~components/Chat/components'
import { getParseBodyParams, getType } from '~components/Chat/helpers'

type Props = {
  dialog: Stepper
  dialogType: ApiGetStepResponse['data'][0]['type']
  index: number
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const Picklist = ({
  dialog,
  dialogType,
  index,
  setAnswers,
  handleAnswer,
}: Props) => {
  const { getValues } = useFormContext()

  const answer = getType(dialog, 'picklist')[0]
  const parsedBodyParam = getParseBodyParams(answer)
  const id = Object.keys(parsedBodyParam)[0]
  const options = Object.values(parsedBodyParam)[0]

  const handlePicklistAnswer = (answer: Answer, id: string) => {
    setAnswers(getValues(camelize(id)).label)
    handleAnswer(answer.action, { [id]: getValues(camelize(id)).value })
  }

  return (
    <Dropdown
      key={dialogType + index}
      id={id}
      label={answer.label}
      options={
        options as {
          label: string
          value: string
        }[]
      }
      onSubmit={() => handlePicklistAnswer(answer, id)}
    />
  )
}

export default Picklist
