import styled, { css } from 'styled-components'

import { Onboarding } from '~components'
import { Box } from '~design-system'

const Main = styled('main')`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    min-height: 100vh;
    display: flex;
    padding: ${theme.spaces[8]};
  `}
`

const Left = styled(Box)`
  ${({ theme }) => css`
    left: 0;
    top: ${theme.spaces[8]};
  `}
`

const Onbarding: React.FC = ({ children }) => (
  <Main>
    <Box di="f" w="100%" jc="space-between" ai="flex-start">
      <Left maxW={96} w="100%" hu="1212px" mr={32} pos="sticky">
        <Onboarding />
      </Left>
      <Box di="f" fg={1} jc="center" maxH="100%" h="100%">
        <Box w="100%" maxW="700px">
          {children}
        </Box>
      </Box>
    </Box>
  </Main>
)

export default Onbarding
