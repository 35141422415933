import { useState } from 'react'
import { Tab, TabPanel, Tabs } from 'react-tabs'

import {
  Box,
  Button,
  Grid,
  Header2,
  MarkdownRender,
  SmallText,
  Text,
  TinyText
} from '~design-system'
import { formatIsoTo, isDayBeforeNow, isSameIsoDay } from '~helpers'
import { useEventsEnum } from '~hooks'

import { RegisterForm, SidebarItem, SpeakerCard } from './components'
import {
  Container,
  EventImage,
  EventSidebar,
  Sidebar,
  SidebarContainer,
  TabsContainer
} from './styles'

const placeholder = '/images/event_placeholder.png'
const layoutBreakpoint = 1100

const EventDetail = ({ event }: { event: NQTEvent }) => {
  const [showForm, setShowForm] = useState<boolean>(false)
  const { types } = useEventsEnum()

  return (
    <Box>
      <Box di="f" ai="center" jc="sb" pt={8} pb={8}>
        <Container>
          <Header2 as="h1" textColor="white">
            {event.name}
          </Header2>
        </Container>
      </Box>
      <Box
        backgroundColor="white"
        mb={16}
        zIndex={10}
        pos="relative"
        pt={16}
        pb={16}
      >
        <Container>
          <Grid
            columnGap={10}
            gridAutoRows="1fr"
            templateColums="1fr 1fr"
            columnBreakpoint={layoutBreakpoint}
            mb={8}
          >
            <Box>
              <EventImage
                src={event.image || placeholder}
                alt="illustration de l'évènement"
              />
            </Box>
            <Sidebar>
              {showForm ? (
                <RegisterForm
                  eventId={event.id}
                  close={() => setShowForm(false)}
                />
              ) : (
                <SidebarContainer columnBreakpoint={layoutBreakpoint}>
                  <EventSidebar>
                    <Text ml={6} mr={6} mb={4} weight="medium">
                      Informations
                    </Text>
                    {event.venue && (
                      <SidebarItem icon="poi" title={event.venue}>
                        {event.address && `${event.address.properties.label}`}
                      </SidebarItem>
                    )}
                    <SidebarItem
                      icon="calendar"
                      title={formatIsoTo(event.dateStart, 'dd MMMM yyyy')}
                    >
                      Commence à {formatIsoTo(event.dateStart, 'HH:mm')}
                      {event.dateEnd &&
                        ` et se termine à 
                 ${formatIsoTo(event.dateEnd, 'HH:mm')}
                 ${
                   !isSameIsoDay(event.dateStart, event.dateEnd)
                     ? ` le ${formatIsoTo(event.dateEnd, 'dd MMMM yyyy')}`
                     : ''
                 }`}
                      .
                    </SidebarItem>
                    {types && (
                      <SidebarItem icon="building" title="Salon">
                        {types[event.type]}
                      </SidebarItem>
                    )}
                    {!!event.currentAttendees && (
                      <SidebarItem icon="team" title="Nombre de participants">
                        {event.currentAttendees} participants
                      </SidebarItem>
                    )}
                    {!!event.promoter?.length && (
                      <SidebarItem
                        icon="avatar"
                        title="Organisateur(s) de l'évènement"
                      >
                        {event.promoter?.map((promoter, index) => (
                          <SmallText
                            textColor="greyDarkest"
                            key={`promoter-${index}`}
                          >
                            {promoter}
                          </SmallText>
                        ))}
                      </SidebarItem>
                    )}

                    {event.ticketLink && (
                      <SidebarItem icon="devices" title="Lien d'inscription">
                        <a href={event.ticketLink}>{event.ticketLink}</a>
                      </SidebarItem>
                    )}

                    <SidebarItem icon="quote" title="Statut">
                      <TinyText textColor="orange">
                        {isDayBeforeNow(event.dateStart) ? 'Passé' : 'À venir'}
                      </TinyText>
                    </SidebarItem>
                  </EventSidebar>

                  <Button
                    mt={4}
                    fullWidth
                    option="outlined"
                    as="a"
                    rel="noreferrer noopener"
                    target="_blank"
                    download
                    role="button"
                    title="Télécharger le ficher .ics"
                    href={event.icalLink}
                  >
                    Ajouter à mon calendrier
                  </Button>

                  <Button mt={4} fullWidth onClick={() => setShowForm(true)}>
                    S'inscrire
                  </Button>
                </SidebarContainer>
              )}
            </Sidebar>
          </Grid>
          <Tabs>
            <TabsContainer>
              <Tab>Description</Tab>
              {event.speakers && event.speakers.length > 0 && (
                <Tab>Intervenants</Tab>
              )}
            </TabsContainer>
            <TabPanel>
              <Text textColor="greyDarkest">{event.description && (
                <MarkdownRender>{event.description}</MarkdownRender>
              )}</Text>
            </TabPanel>
            {event.speakers && event.speakers.length > 0 && (
              <TabPanel>
                <Grid
                  templateColums="repeat(auto-fill, minmax(345px, 1fr))"
                  gridAutoRows="1fr"
                >
                  {event.speakers.map((sp) => (
                    <SpeakerCard speaker={sp} />
                  ))}
                </Grid>
              </TabPanel>
            )}
          </Tabs>
        </Container>
      </Box>
    </Box>
  )
}

export default EventDetail
