import { Link, generatePath } from 'react-router-dom'

import { routes } from '~config'
import { Box, Icon, Panel, SmallText, Text } from '~design-system'
import { LayoutProps, SpacingProps } from '~design-system/helpers'

import { CardImage } from './styles'

export type Props = {
  service: ServiceTeaser
} & LayoutProps &
  SpacingProps

const CardService = ({ service, ...props }: Props) => {
  // Define Service Details Link
  const detailsLink = generatePath(routes.authenticated.serviceDetail, {
    id: service.id.toString(),
  })

  return (
    <Panel jc="ce" h="100%" as={Link} to={detailsLink} {...props}>
      <Box di="f" ai="ce">
        <CardImage url={service.image}>
          {!service.image && (
            <Icon name="formation" size="bigger" color="primary" />
          )}
        </CardImage>
        <Box fg={1}>
          <Text lineHeight={1} weight="medium">
            {service.name}
          </Text>
          <Box di="f" g="20px" ai="ce">
            <Box di="f" ai="ce" g="5px">
              <Icon name="news" size="small" color="primary" w={4} />
              <SmallText textColor="greyDarkest">{service.partner}</SmallText>
            </Box>
          </Box>
        </Box>
      </Box>
    </Panel>
  )
}

export default CardService
