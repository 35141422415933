import { EditIcon } from '~components'
import { Box, Panel, SmallText, Text } from '~design-system'
import { formatDateFromStringToString } from '~helpers'

interface Props {
  experience: Experience
  isEditable?: boolean
  setEditInfos: Function
}

const ExperienceCard = ({
  experience,
  isEditable = false,
  setEditInfos,
}: Props) => {
  const { startDate, endDate, companyName, name } = experience

  return (
    <Panel pb={4} pt={4}>
      <Box di="f" ai="ce" jc="sb">
        <Box>
          <Text weight="medium">{name}</Text>
          <SmallText>{companyName}</SmallText>

          {startDate &&
            (endDate ? (
              <SmallText textColor="greyDarkest">
                {formatDateFromStringToString(startDate) +
                  ' à ' +
                  formatDateFromStringToString(endDate)}
              </SmallText>
            ) : (
              <SmallText textColor="greyDarkest">
                {formatDateFromStringToString(startDate) + ` à aujourd'hui`}
              </SmallText>
            ))}
        </Box>
        <EditIcon
          isVisible={isEditable}
          label="Modifier votre expérience"
          onClick={() =>
            setEditInfos({
              slug: 'experiences',
              title: 'Modifier votre Expérience',
              data: experience,
            })
          }
        />
      </Box>
    </Panel>
  )
}

export default ExperienceCard
