import styled from 'styled-components'

export const ErrorContainer = styled.div`
  ${({ theme }) => `
  background-color: ${theme.colors.redLight};
  padding: ${theme.spaces[4]} ${theme.spaces[6]} ${theme.spaces[4]} ${theme.spaces[4]};
  margin: ${theme.spaces[2]} 0 ${theme.spaces[4]};
  border-radius: ${theme.radii.lg};
  display: flex;
  justify-content: space-between;
  align-items: center;
`}
`
