import styled, { css } from 'styled-components'

import { theme } from '~config'
import { Box } from '~design-system'

const getIconColor = (type: Toast['type']) => {
  switch (type) {
    case 'info':
      return theme.colors.blueLight
    case 'success':
      return theme.colors.greenLight
    case 'warning':
      return theme.colors.yellow
    case 'danger':
      return theme.colors.redLight

    default:
      return theme.colors.primary
  }
}

type Props = {
  type: Toast['type']
}

const IconWrapper = styled(Box)<Props>`
  ${({ theme, type }) => css`
    width: ${theme.pxToRem(40)};
    height: ${theme.pxToRem(40)};
    border-radius: 50%;
    background-color: ${getIconColor(type)};
    margin-right: ${theme.spaces[4]};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

export default IconWrapper
