import styled from 'styled-components'

import { Box, Icon, MarkdownRender, Panel, SmallText } from '~design-system'

type Props = {
  speaker: NQTEventSpeaker
}

const Desc = styled('div')`
  ${({ theme }) => `
  color: ${theme.colors.greyDarkest};
  font-size: ${theme.pxToRem(14)};
`}
`

const SpeakerCard = ({ speaker }: Props) => {
  return (
    <Box h="100%">
      <Panel h="100%">
        <Box di="f" ai="center" mb={2}>
          <Icon size="small" name="avatar" arian-hidden="true" />
          <SmallText ml={2}>
            {speaker.firstname} {speaker.lastname}
          </SmallText>
        </Box>
        <Box di="f" ai="center" mb={2}>
          <Icon size="small" name="briefcase" arian-hidden="true" />
          <SmallText ml={2}>{speaker.company}</SmallText>
        </Box>
        <Box di="f" ai="center" mb={2}>
          <Icon size="small" name="pin" arian-hidden="true" />
          <SmallText ml={2}>{speaker.title}</SmallText>
        </Box>
        {speaker.interventionArea && (
          <Desc>
            <MarkdownRender children={speaker.interventionArea} />
          </Desc>
        )}
      </Panel>
    </Box>
  )
}

export default SpeakerCard
