import { camelize } from 'humps'
import { useFormContext } from 'react-hook-form'

import { Input } from '~components/Chat/components'
import { getParseBodyParams, getType } from '~components/Chat/helpers'
import { LINKEDIN_REGEX, REQUIRED_VALIDATION } from '~config'

type Props = {
  dialog: Stepper
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const Url = ({ dialog, setAnswers, handleAnswer }: Props) => {
  const { getValues } = useFormContext()

  const answer = getType(dialog, 'url')[0]
  const parsedBodyParam = getParseBodyParams(answer)
  const id = camelize(Object.keys(parsedBodyParam)[0])

  const handleTextInputValue = (answer: Answer, id: string) => {
    const value = getValues(camelize(id))
    setAnswers(value)
    handleAnswer(answer.action, { [`${id}`]: value })
  }

  return (
    <Input
      id={id}
      placeholder={answer.label}
      onSubmit={() => handleTextInputValue(answer, id)}
      validator={{
        ...REQUIRED_VALIDATION,
        pattern: {
          value: LINKEDIN_REGEX,
          message: "L'url doit être celle d'un profil linkedin",
        },
      }}
    />
  )
}

export default Url
