import { useFormContext } from 'react-hook-form'

import { Box, Button, Checkbox, Label } from '~design-system'

export type CheckboxOptionsProps = {
  id: string
  title: string
  value: boolean
}

type Props = {
  label: string
  onSubmit: (options: CheckboxOptionsProps[]) => void
  options: CheckboxOptionsProps[]
}

const MultipleOptions = ({ label, onSubmit, options }: Props) => {
  const { register, watch } = useFormContext()

  const isValid = options.some((option) => watch(option.id) === true)

  return (
    <Box di="f" fd="c" ai="end">
      <Box>
        <Label mb={2}>{label}</Label>
        {options.map(({ id, title, value }) => (
          <Checkbox id={id} label={title} mb={2} {...register(id)} key={id} />
        ))}
        <Button fullWidth onClick={() => onSubmit(options)} disabled={!isValid}>
          Valider
        </Button>
      </Box>
    </Box>
  )
}
export default MultipleOptions
