import styled, { css } from 'styled-components'

type Props = {
  columnBreakpoint: number
}

const EventSidebar = styled('div')`
  ${({ columnBreakpoint }: Props) => css`
    width: 100%;
    @media screen and (min-width: ${columnBreakpoint + 1}) {
      max-width: 320px;
    }
  `}
`

export default EventSidebar
