import { useQuery } from 'react-query'

import { DataService } from '~api'

function useData() {
  const useGetHobbies = () =>
    useQuery(['data-hobbies'], DataService.apiGetHobbies)

  const useGetJobs = () => useQuery(['data-jobs'], DataService.apiGetJobs)

  const useGetLanguages = ({ enabled = true }: { enabled?: boolean }) =>
    useQuery(['data-languages'], DataService.apiGetLanguages, { enabled })

  const useGetSectors = () =>
    useQuery(['data-sectors'], DataService.apiGetSectors)

  const useGetSkills = () => useQuery(['data-skills'], DataService.apiGetSkills)

  const useGetDepartments = () =>
    useQuery(['data-departments'], DataService.apiGetDepartments)

  const useGetSubSectors = (id: number) =>
    useQuery(['data-subsectors', id], () => DataService.apiGetSubSectors(id))

  const useGetKeyNumbers = (dateStart: string) =>
    useQuery(['key-numbers', dateStart], () =>
      DataService.apiGetKeyNumbers(dateStart)
    )

  return {
    useGetHobbies,
    useGetJobs,
    useGetLanguages,
    useGetSectors,
    useGetSkills,
    useGetSubSectors,
    useGetDepartments,
    useGetKeyNumbers,
  }
}

export default useData
