import styled, { css } from 'styled-components'

import { theme } from '~config'
import { Box } from '~design-system'

type Props = { isError?: boolean }

const TextareaContainer = styled(Box)<Props>`
  ${({ isError }) => css`
    border: 1px solid ${isError ? theme.colors.danger : theme.colors.greyLight};
    border-radius: ${theme.radii.lg};
  `}
`

export default TextareaContainer
