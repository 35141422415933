import { Link, generatePath, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { routes } from '~config'
import { useAuth } from '~context/AuthContext'
import { Box, Icon, Text, TinyText } from '~design-system'
import { isReferent, isYoung } from '~helpers'
import { useSurvey } from '~hooks'

const Menu = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 0 0 ${theme.radii.lg} ${theme.radii.lg};
    border: 1px solid ${theme.colors.blueLighter};
    box-shadow: -5px 10px 10px ${theme.colors.greyLight};
    min-width: 12rem;
    position: absolute;
    right: 0;
    top: 100%;
  `}
`

const NavItem = styled(Box)`
  ${({ theme }) => css`
    padding: ${theme.spaces[2]} ${theme.spaces[6]};
    &:visited {
      color: currentColor;
    }
    &:active,
    &:hover,
    &.selected,
    &[aria-current='true'] {
      background-color: ${theme.colors.greyLightest};
      color: ${theme.colors.primary};

      ${Text}, ${Icon} {
        color: ${theme.colors.primary};
      }
    }
  `}
`

interface Props {
  handleShowConfirmation: Function
}

const DropdownMenu = ({ handleShowConfirmation }: Props) => {
  const {
    authState: { user },
  } = useAuth()
  const { useGetMatchingsWithSurvey } = useSurvey()
  const { data: surveys } = useGetMatchingsWithSurvey()
  const route = useLocation()

  const navItemsYoung = [
    {
      link: routes.authenticated.profile,
      icon: 'avatar',
      label: 'Mon profil',
    },
    {
      link: routes.authenticated.documents,
      icon: 'book',
      label: 'Mes Documents',
    },
    {
      link: routes.authenticated.settings,
      icon: 'settings',
      label: `Paramètres`,
    },
  ]

  const navItemsReferentSponsor = [
    {
      link: routes.authenticated.profile,
      icon: 'avatar',
      label: 'Mon profil',
    },
    ...(user?.company?.id
      ? [
          {
            link: generatePath(routes.authenticated.company, {
              id: user?.company?.id?.toString(),
            }),
            icon: 'building',
            label: 'Mon Entreprise',
          },
        ]
      : []),
    {
      link: routes.authenticated.settings,
      icon: 'settings',
      label: `Paramètres`,
    },
  ]

  const menuItems = !isYoung(user) ? navItemsReferentSponsor : navItemsYoung

  if (!!surveys?.length && !isReferent(user)) {
    const surveyLink = {
      link: generatePath(routes.authenticated.report, {
        id: surveys[surveys.length - 1].id.toString(),
      }),
      icon: 'study',
      label: 'Mon bilan de suivi',
    }
    menuItems.splice(1, 0, surveyLink)
  }

  const isActive = (link: string) => {
    return route.pathname === link
  }

  return (
    <Menu>
      <Box pt={6} pr={6} pl={6} mb={4}>
        <Text>{user?.fullName}</Text>
        <TinyText textColor="greyDarker">{user?.email}</TinyText>
      </Box>
      <ul>
        {menuItems.map(({ link, icon, label }) => (
          <li key={`nav-item-${icon}`}>
            <NavItem
              di="f"
              ai="ce"
              as={Link}
              to={link}
              aria-current={isActive(link)}
            >
              <Icon color="greyDarker" name={icon} />
              <Text textColor="greyDarkest" ml={2}>
                {label}
              </Text>
            </NavItem>
          </li>
        ))}
        <li key={`nav-item-off`}>
          <NavItem
            di="f"
            ai="ce"
            mt={2}
            mb={2}
            tabIndex={0}
            onClick={() => handleShowConfirmation(true)}
          >
            <Icon color="greyDarker" name="off" />
            <Text textColor="greyDarkest" ml={2}>
              Déconnexion
            </Text>
          </NavItem>
        </li>
      </ul>
    </Menu>
  )
}

export default DropdownMenu
