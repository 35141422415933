import { FC, ReactNode, useEffect } from 'react'

import { Icon } from '~design-system'

import Portal from '../Portal'
import { Actions } from './components'
import { PopInProvider } from './context/PopInContext'
import {
  PopInBackdrop,
  PopInCloseIcon,
  PopInContainer,
  PopInContent,
  PopInElement,
  PopInPicture,
  PopInTitle,
  PopInUpperTitle,
  PopInWrapper,
} from './styles'
import { PopInComponent } from './types'

type Props = {
  backdropBgColor?: 'black50' | 'white'
  padding?: string
  children: ReactNode
  closable?: boolean
  onClose?: () => void | Function
  open: boolean
  upperTitle?: string
}

const PopIn: FC<Props> & PopInComponent = ({
  backdropBgColor,
  children,
  closable = false,
  onClose,
  open,
  upperTitle,
  padding,
}: Props) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [open])

  const renderContainer = () => (
    <PopInContainer shadow={backdropBgColor === 'white'} padding={padding}>
      {closable && (
        <PopInCloseIcon onClick={onClose}>
          <Icon color="greyDarker" name="cross" />
        </PopInCloseIcon>
      )}
      {children}
    </PopInContainer>
  )

  return (
    <PopInProvider onClose={() => onClose?.()}>
      {open && (
        <Portal>
          <PopInWrapper>
            <PopInBackdrop bgColor={backdropBgColor} onClick={onClose} />

            <PopInElement>
              {upperTitle && <PopInUpperTitle>{upperTitle}</PopInUpperTitle>}
              {renderContainer()}
            </PopInElement>
          </PopInWrapper>
        </Portal>
      )}
    </PopInProvider>
  )
}

PopIn.Actions = Actions
PopIn.Content = PopInContent
PopIn.Picture = PopInPicture
PopIn.Title = PopInTitle

export default PopIn
