import { useRef, useState } from 'react'
import { Link, generatePath } from 'react-router-dom'

import FavoriteButton from '~components/FavoriteButton'
import { routes } from '~config'
import { Box, Panel, SmallText, Text } from '~design-system'
import { formatDateFromStringToString } from '~helpers'
import { useEvents, useOnClickOutside } from '~hooks'

import { Container, EventButton, EventImage, EventOptions } from './styles'

interface Props {
  event: NQTEventTeaser
  queryKey: (string | number)[]
}

const EventCard = ({ event, queryKey }: Props) => {
  const { useAddEventToFavorites, useRemoveEventFromFavorites } = useEvents()
  const { mutate: addToFavorites, isLoading: isAdding } =
    useAddEventToFavorites({ id: event.id, queryKey })
  const { mutate: removeFromFavorites, isLoading: isRemoving } =
    useRemoveEventFromFavorites({ id: event.id, queryKey })
  const [showMore, setShowMore] = useState<boolean>(false)
  const DropdownMenuRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(DropdownMenuRef, () => {
    setShowMore(false)
  })

  const dateStart = formatDateFromStringToString(
    event.dateStart,
    'EEEE dd MMMM'
  )
  const dateEnd = event.dateEnd
    ? formatDateFromStringToString(event.dateEnd, 'EEEE dd MMMM')
    : undefined
  const timeStart = formatDateFromStringToString(event.dateStart, 'HH:mm')
  const timeEnd = event.dateEnd
    ? formatDateFromStringToString(event.dateEnd, 'HH:mm')
    : undefined

  const handleMore = () => {
    setShowMore(!showMore)
  }

  const handleFavorite = () => {
    event.isFavorite ? removeFromFavorites() : addToFavorites()
  }

  return (
    <Container>
      <Panel
        backgroundColor="white"
        pr="0px"
        pl="0px"
        pt="0px"
        pb="0px"
        overflow="hidden"
        h="100%"
        as={Link}
        to={generatePath(routes.authenticated.eventDetails, {
          id: `${event.id}`,
        })}
      >
        <EventImage
          src={event.image || '/images/event_placeholder.png'}
          alt={`illustration de l'évènement ${event.name}`}
        />
        <Box pt={4} pb={4} pl={4} pr={4}>
          <Text mb={2} lineHeight={1} textColor="black" weight="medium">
            {event.name}{' '}
            {event.status === 'to_validate' && "(En cours d'approbation)"}
          </Text>
          <SmallText textColor="greyDarkest">
            {!dateEnd || dateEnd === dateStart
              ? dateStart
              : `Du ${dateStart} au ${dateEnd}`}
          </SmallText>
          <SmallText textColor="greyDarkest">
            {!timeEnd || timeStart === timeEnd
              ? `A partir de ${timeStart}`
              : `De ${timeStart} à ${timeEnd}`}
          </SmallText>
          {event.address && (
            <SmallText textColor="greyDarkest">
              {event.address.properties.city}
            </SmallText>
          )}
        </Box>
      </Panel>
      <EventButton
        title="Afficher les options"
        onClick={handleMore}
        aria-expanded={showMore}
        aria-haspopup="true"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18 10C19.1046 10 20 10.8954 20 12C20 13.1046 19.1046 14 18 14C16.8954 14 16 13.1046 16 12C16 10.8954 16.8954 10 18 10ZM14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12ZM6 10C7.10457 10 8 10.8954 8 12C8 13.1046 7.10457 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10Z"
            fill="#8D8D8D"
          />
        </svg>
      </EventButton>
      {showMore && (
        <EventOptions ref={DropdownMenuRef}>
          <ul>
            <li>
              <FavoriteButton
                disabled={isAdding || isRemoving}
                isFavorite={event.isFavorite}
                onClick={handleFavorite}
              />
            </li>
          </ul>
        </EventOptions>
      )}
    </Container>
  )
}

export default EventCard
