import { Theme } from 'styled-components'

/**
 * A day as ms, used in `getDateSincePublication`
 */
const dayInMs = 1000 * 60 * 60 * 24

/**
 * Based on a string date, which can be handled by JavaScript Date object,
 * returns a string that represents the number of days / months since
 * the original publication
 * @param publication - a string that can be used in a JavaScript Date object
 * @returns a string that represents the number of days / months since
 * the original publication, or null if the parameter can't be handled in
 * a JavaScript Date object
 */
export const getDateSincePublication = (publication: string): string | null => {
  if (!publication) {
    return null
  }
  const now = new Date()
  const publicationDate = new Date(publication)
  const diff = now.getTime() - publicationDate.getTime()

  const days = Math.floor(diff / dayInMs)
  if (days < 1) {
    return "aujourd'hui"
  }
  if (days > 30) {
    // Approximative way to compute this (as some months
    // do not have 30 full days)
    const months = Math.floor(days / 30)

    return `il y a ${months} mois`
  }

  if (days === 1) {
    return 'hier'
  }

  return `il y a ${days} jours`
}

export const formatEnumToOption = (enumObject: { [key: string]: string }) => {
  return Object.entries(enumObject).map(([key, value]) => {
    return { label: value, value: key }
  })
}

export const getKanbanColor = (
  index: number
): {
  normal: keyof Theme['colors']
  light: keyof Theme['colors']
} => {
  switch (index) {
    case 0:
      return { normal: 'primary', light: 'blueLight' }
    case 1:
      return { normal: 'purple', light: 'purpleLight' }
    case 2:
      return { normal: 'pink', light: 'pinkLight' }
    case 3:
      return { normal: 'orange', light: 'orangeLight' }
    case 4:
      return { normal: 'appleGreen', light: 'appleGreenLight' }
    case 5:
      return { normal: 'success', light: 'greenLight' }
    default:
      return { normal: 'danger', light: 'redLight' }
  }
}
