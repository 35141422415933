import { useSearchParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { EventCard, Pagination } from '~components'
import { Cell, Grid } from '~design-system'

interface Props {
  events: ApiPaginatedResponse<NQTEventTeaser[]>
  queryKey: (string | number)[]
  withPagination?: boolean
}

const EventCell = styled(Cell)`
  ${() => css`
    height: 100%;
  `}
`

const EventsList = ({ events, queryKey, withPagination = true }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const clickHandler = (key: string, value: string) => {
    searchParams.set(key, value)
    setSearchParams(searchParams)
  }
  return (
    <>
      <Grid
        ai="fs"
        templateColums="repeat(auto-fill, minmax(345px, 1fr))"
        gridAutoRows="1fr"
      >
        {events.data?.map((event) => {
          return (
            <EventCell key={event.id}>
              <EventCard event={event} queryKey={queryKey} />
            </EventCell>
          )
        })}
      </Grid>
      {withPagination && !!events?.meta.lastPage && (
        <Pagination
          mt={8}
          currentPage={events?.meta.currentPage}
          totalPage={events?.meta.lastPage}
          onChange={(page: number) => clickHandler('page', page.toString())}
        />
      )}
    </>
  )
}

export default EventsList
