import styled, { css } from 'styled-components'

import { display, DisplayProps } from '../../helpers'

type Props = {
  /** grid-column */
  $col?: number
  /** grid-column: span $cols */
  $cols?: number
  /** grid-column-end */
  $colsEnd?: number
  /** grid-column-start */
  $colsStart?: number
  /** grid-row */
  $row?: number
  /** grid-row-end */
  $rowEnd?: number
  /** grid-row-start */
  $rowStart?: number
  /** grid-row: span $rows */
  $rows?: number
} & DisplayProps

const Cell = styled('div')<Props>`
  ${({
    $col,
    $cols,
    $colsStart,
    $colsEnd,
    $row,
    $rows,
    $rowStart,
    $rowEnd,
  }) => css`
    grid-column: ${$col};
    grid-column-end: ${$colsEnd};
    grid-column-start: ${$colsStart};
    grid-row: ${$row};
    grid-row-start: ${$rowStart};
    grid-row-end: ${$rowEnd};

    ${$cols &&
    css`
      grid-column: span ${$cols};
    `}

    ${$rows &&
    css`
      grid-row: span ${$rows};
    `}
  `}
  ${display};
`

export default Cell
