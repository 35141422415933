import styled from 'styled-components'

import { Box } from '~design-system'

const InfosContainer = styled(Box)`
  justify-content: space-evenly;

  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

export default InfosContainer
