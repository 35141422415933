import { TabList } from 'react-tabs'
import styled from 'styled-components'

import { theme } from '~config'

const TabsContainer = styled(TabList)`
  border-bottom: 1px solid ${theme.colors.grey};
  margin-bottom: ${theme.spaces[8]};

  & > li {
    display: inline-block;
    font-weight: 500;
    color: ${theme.colors.greyDarker};
    margin-right: ${theme.spaces[3]};
    padding: ${theme.spaces[1]} 0 ${theme.spaces[3]} 0;
    position: relative;
    cursor: pointer;
    outline: 0; // Since color & border changes, it seems ok without outline

    &[aria-selected='true'] {
      color: ${theme.colors.blueDark};
    }

    &[aria-selected='true']:after {
      content: '';
      background-color: ${theme.colors.blueDark};
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      height: 2px;
    }
  }
`

export default TabsContainer
