import { isEmpty } from 'lodash';


// Check if the user is a 'Sponsor'
const isSponsor = (user?: User | UserTeaser) => {
  const roleStored = localStorage.getItem('currentRole')
  return (
    user && user.role?.includes('parrain') && roleStored !== 'company-referent'
  )
}

// Check if the user is a 'Company Referent'
const isReferent = (user?: User | UserTeaser) => {
  const roleStored = localStorage.getItem('currentRole')
  return (
    user && user.role?.includes('company-referent') && roleStored !== 'parrain'
  )
}

// Check if the user is a 'Sponsor' and 'Company Referent'
const isSponsorReferent = (user?: User | UserTeaser) =>
  user &&
  user.role?.includes('parrain') &&
  user.role?.includes('company-referent')

const getCurrentRole = (user?: User | UserTeaser) => {
  if (user) {
    return isSponsorReferent(user)
      ? (localStorage.getItem('currentRole') as LocalRole)
      : undefined
  }
  return undefined
}

// Check if the user is a 'Young'
const isYoung = (user?: User | UserTeaser) =>
  user && user.role?.includes('jeune')

const canLogIn = (user?: User | UserTeaser) =>
  user &&
  (user.role?.includes('jeune') ||
    user.role?.includes('parrain') ||
    user.role?.includes('company-referent'))

// Transform Civlity Slug
const getFrenchCivility = (slug: 'Mr.' | 'Mrs.') =>
  slug === 'Mrs.' ? 'Mme.' : slug

// Check if User Onboarding is Over
const isOnboarded = (user?: User) => user && user.onboardingNeeded === 'false'

// check if user has a matching
const hasMatching = (user?: User) => user && !isEmpty(user.matchs)

// check if user have a CV uploaded
const hasCV = (user?: User) => user && user.resumes && user.resumes.length > 0

// check if user is followed
const isFollowed = (user?: User) => user && user.status === 'followed'

// check if a user can access the prematching feature (ability to see prematch and then prematch)
// user must exist
// user must be a young
// user must have no match
// user must have finished his onboarding
// user must have a CV uploaded
// user must be "followed"
const canAccessPrematching = (user?: User) => {
  return (
    user &&
    isYoung(user) &&
    !hasMatching(user) &&
    isOnboarded(user) &&
    hasCV(user) &&
    isFollowed(user)
  )
}

// converts a user id to an amplitude id
const getAmplitudeId = (userId: string | number) => {
  return userId.toString().padStart(5, '0')
}

export {
  isSponsor,
  isYoung,
  isReferent,
  isSponsorReferent,
  getFrenchCivility,
  isOnboarded,
  canLogIn,
  getCurrentRole,
  canAccessPrematching,
  getAmplitudeId,
}