import { Widget as WidgetWrapper } from '~components'
import { routes } from '~config'
import { CardService, Grid, Loader, Text } from '~design-system'
import useServices from '~hooks/useServices'

const ServicesWidget = () => {
  const { useGetServices } = useServices()
  const { data: services, isLoading } = useGetServices('?limit=4')

  return (
    <WidgetWrapper
      title="Services"
      url={services?.data?.length ? routes.authenticated.services : undefined}
    >
      {isLoading ? (
        <Loader isCentered />
      ) : !!services?.data?.length ? (
        <Grid
          ai="fs"
          templateColums="repeat(auto-fill, minmax(345px, 1fr))"
          gridAutoRows="1fr"
        >
          {services?.data?.map((service: ServiceTeaser) => (
            <CardService key={service.id} service={service} />
          ))}
        </Grid>
      ) : (
        <Text>Aucun service pour le moment</Text>
      )}
    </WidgetWrapper>
  )
}

export default ServicesWidget
