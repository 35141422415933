import { Widget as WidgetWrapper } from '~components'
import { routes } from '~config'
import { CardUser, Grid, Loader, Text } from '~design-system'
import { LayoutProps, SpacingProps } from '~design-system/helpers'

export type Props = {
  matchs: Match[] | undefined
} & LayoutProps &
  SpacingProps

const YoungWidget = ({ matchs, ...props }: Props) => {
  const FollowedYoung = matchs?.filter((match) => !match.endedSponsorship)

  return (
    <WidgetWrapper
      title="Jeunes Suivis"
      badge={FollowedYoung?.length}
      url={
        FollowedYoung?.length ? routes.authenticated.followedYoungs : undefined
      }
      {...props}
    >
      {!matchs ? (
        <Loader isCentered />
      ) : !!FollowedYoung?.length ? (
        <Grid
          ai="fs"
          gridAutoRows="1fr"
          templateColums="repeat(auto-fill, minmax(345px, 1fr))"
        >
          {FollowedYoung.map((match) => (
            <CardUser key={match.id} user={match} />
          ))}
        </Grid>
      ) : (
        <Text>Aucun jeune suivi pour le moment</Text>
      )}
    </WidgetWrapper>
  )
}

export default YoungWidget
