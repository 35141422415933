import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Badge, Box, Header3, SmallText } from '~design-system'

import Card from './components/card'

interface Props {
  children: React.ReactNode
  title: string
  url?: string
  badge?: number
  rightComponent?: ReactNode
}

const Widget = ({ badge, children, rightComponent, url, title }: Props) => (
  <Box mb={8}>
    <Box di="f" ai="b" jc="sb" fw="wrap" g="10px" mt={8} mb={8}>
      <Box di="f" ai="ce">
        <Header3 textColor="primary" weight="medium">
          {title}
        </Header3>
        {badge ? <Badge>{badge}</Badge> : null}
      </Box>
      {url && (
        <Box as={Link} to={url}>
          <SmallText textColor="primary" weight="bold">
            Tout voir
          </SmallText>
        </Box>
      )}
      {rightComponent && rightComponent}
    </Box>
    <>{children}</>
  </Box>
)

Widget.Card = Card

export default Widget
