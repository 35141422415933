import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const RightBox = styled(Box)`
  ${({ theme }) => css`
    border-left: 1px solid ${theme.colors.blueLighter};
    height: 5rem;
    padding-left: ${theme.spaces[8]};
    padding-right: ${theme.spaces[4]};

    @media (max-width: 700px) {
      padding-left: ${theme.spaces[4]};
      padding-right: ${theme.spaces[2]};
    }
  `}
`

export default RightBox
