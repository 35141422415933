import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const Container = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-right: 1px solid ${theme.colors.greyLight};
    height: 100vh;
    left: 0;
    min-width: ${theme.spaces[64]};
    position: fixed;
    top: 0;
    width: ${theme.spaces[64]};
    z-index: 5;

    @media (max-width: 700px) {
      height: calc(100vh - 5rem);
      top: 5rem;
      width: 100vw;
    }
  `}
`

export default Container
