import { RegisterOptions } from 'react-hook-form'

export const CARDS_MAX_LIMIT_IN_BLOC = 9

export const STEPPER_PATH = '/api/v1/auth/stepper'
export const PASSWORD_REGEX =
  /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~])(?=.{12,})/g
export const PHONE_REGEX = /^(?:0)[1-9](?:\d{2}){4}$/
export const URL_REGEX =
  /https?:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi
export const LINKEDIN_REGEX =
  /^http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/gm
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const POLE_EMPLOI_REGEX = /\d{12}/gm
export const RSA_REGEX = /\d{7}/gm
export const UPPERCASE_LETTERS_REGEX = /[A-Z]/
export const LOWERCASE_LETTERS_REGEX = /[a-z]/
export const NUMERIC_REGEX = /\d/
export const SPECIAL_CHAR_REGEX = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/

export const REQUIRED_VALIDATION = { required: 'Ce champ est obligatoire' }

export const MESSAGING_PER_PAGE = 15

export const PASSWORD_VALIDATION: RegisterOptions['validate'] = {
  minLength: (value: string) => value?.length >= 12,
  uppercase: (value: string) => UPPERCASE_LETTERS_REGEX.test(value),
  lowercase: (value: string) => LOWERCASE_LETTERS_REGEX.test(value),
  numeric: (value: string) => NUMERIC_REGEX.test(value),
  specialChar: (value: string) => SPECIAL_CHAR_REGEX.test(value),
}

export const NQT_ROLES = [
  'mission-manager',
  'regional-admin',
  'development-manager',
  'national-admin',
  'phantom-nqt',
  'admin',
]

export const DEFAULT_TEXTAREA_MAXLENGTH = 5000
