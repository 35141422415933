import { useFormContext } from 'react-hook-form'

import { ChecklistSelector } from '~components'
import { Box, Button, PopIn } from '~design-system'
import { useData } from '~hooks'

type Props = {
  onClose: () => void
  onSubmit: () => void
}

export default function InterestsPopin({ onClose, onSubmit }: Props) {
  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useFormContext()
  const { useGetHobbies } = useData()
  const { data: hobbies, isLoading } = useGetHobbies()

  const handleRequest = () => {
    onSubmit()
  }

  return (
    <PopIn open closable onClose={onClose}>
      <form onSubmit={handleSubmit(handleRequest)}>
        <PopIn.Title textAlign="left">
          <Box mb={12}>Centres d’intérêts</Box>
        </PopIn.Title>
        <PopIn.Content>
          {isLoading || !hobbies ? null : (
            <ChecklistSelector name="hobbies" options={hobbies} />
          )}
        </PopIn.Content>
        <PopIn.Actions
          actions={[
            <Button
              key="save"
              disabled={!isValid || isSubmitting}
              type="submit"
              isLoading={isLoading}
              data-testid="hobbies-submit"
            >
              Enregistrer
            </Button>,
          ]}
        />
      </form>
    </PopIn>
  )
}
