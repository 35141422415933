import styled, { css } from 'styled-components'

import { LayoutProps, SpacingProps, layout, spacing } from '../../helpers'

type Props = { srOnly?: boolean } & SpacingProps & LayoutProps

const Label = styled('label')<Props>`
  ${({ theme, srOnly = false }) =>
    srOnly
      ? css`
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          border: 0;
        `
      : css`
          color: ${theme.components.label.color};
          display: inline-flex;
          font-family: ${theme.fonts.primary};
          font-size: ${theme.components.label.fontSize};
          font-weight: ${theme.weights.medium};
          letter-spacing: 0.04em;
          text-transform: uppercase;
          ${layout};
          ${spacing};
        `}
`

export default Label
