import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, InputText, Label, Loader, Select } from '~design-system'
import { levelOptions } from '~helpers'
import { useData } from '~hooks'

type Props = {
  selectedLanguages: Language[]
}

const LanguageForm = ({ selectedLanguages = [] }: Props) => {
  const { useGetLanguages } = useData()
  const { data: languages, isLoading } = useGetLanguages({ enabled: true })

  const languagesOptions = useMemo(() => {
    if (languages) {
      return languages.map((language) => ({
        label: language.langue,
        value: language.id.toString(),
      }))
    }
  }, [languages])

  const availableLanguages = useMemo(
    () =>
      languagesOptions?.filter(
        (language) =>
          selectedLanguages?.find(({ name }) => language.label === name) ===
          undefined
      ),
    [languagesOptions, selectedLanguages]
  )

  // Define Form
  const { register, watch, control } = useFormContext()

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {availableLanguages && (
        <Box mb={4}>
          <Label mb={2} htmlFor="lang-name">
            Langues
          </Label>
          <Controller
            control={control}
            name="languagesOptions"
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <Select
                inputId="lang-name"
                isSearchable={true}
                options={availableLanguages}
                defaultValue={watch('languagesOptions')}
                onChange={(value) => onChange(value)}
              />
            )}
          />
        </Box>
      )}
      <Box mb={4}>
        <Label mb={2} htmlFor="lang-level">
          Niveau de maîtrise
        </Label>
        <Controller
          control={control}
          name="levelOptions"
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <Select
              inputId="lang-level"
              options={levelOptions}
              defaultValue={watch('levelOptions')}
              onChange={(value) => onChange(value)}
            />
          )}
        />
      </Box>
      <InputText
        label="Certification obtenue"
        placeholder="Exemple : TOEIC"
        {...register('certification')}
      />
    </>
  )
}

export default LanguageForm
