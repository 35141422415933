import { isSponsor, isYoung } from './user'

const isSurveyCompleted = (survey?: Matchings) =>
  survey?.endedSponsorship &&
  survey?.survey?.youngComment &&
  survey?.survey?.sponsorComment

const isYoungSurveyToDo = (survey?: Matchings, user?: User) =>
  isYoung(user) && survey?.endedSponsorship && !survey?.survey?.youngComment

const isYoungSurveyCompleted = (survey?: Matchings, user?: User) =>
  isYoung(user) && survey?.endedSponsorship && survey?.survey?.youngComment

const isSponsorSurveyToDo = (survey?: Matchings, user?: User) =>
  isSponsor(user) && survey?.endedSponsorship && !survey?.survey?.sponsorComment

const isSponsorSurveyCompleted = (survey?: Matchings, user?: User) =>
  isSponsor(user) && survey?.endedSponsorship && survey?.survey?.sponsorComment

export {
  isSurveyCompleted,
  isYoungSurveyCompleted,
  isYoungSurveyToDo,
  isSponsorSurveyToDo,
  isSponsorSurveyCompleted,
}
