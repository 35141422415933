import styled, { css } from 'styled-components'

const Thumbnail = styled('img')`
  ${({ theme }) => css`
    max-width: ${theme.spaces[40]};
    background-color: ${theme.colors.greyLightest};
    border-radius: ${theme.radii.md};
    min-height: 5rem;
    height: auto;
    width: 100%;
    margin-top: ${theme.spaces[4]};
  `}
`

export default Thumbnail
