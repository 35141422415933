import { KeyboardEvent, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { REQUIRED_VALIDATION } from '~config'
import { Button, Icon, InputText } from '~design-system'

type Props = {
  id: string
  onSubmit: () => void
  placeholder: string
}

const InputYear = ({ id, onSubmit, placeholder }: Props) => {
  const {
    setFocus,
    formState: { dirtyFields, errors, isValid },
    register,
  } = useFormContext()

  useEffect(() => {
    setFocus(id)
  }, [id, setFocus])

  const submit = () => {
    if (dirtyFields[id] && isValid) {
      onSubmit()
    }
  }

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submit()
    }
  }

  return (
    <InputText
      id={id}
      label="Année d'entrée dans la vie active"
      errorMessage={errors[id] && errors[id].message}
      placeholder={placeholder}
      onKeyPress={handleKeyUp}
      type="text"
      mt={3}
      rightComponent={
        <Button
          disabled={!dirtyFields[id] || errors[id]}
          onClick={submit}
          size="small"
          square
          title="Envoyer"
        >
          <Icon name="send" />
        </Button>
      }
      {...register(id, {
        ...REQUIRED_VALIDATION,
        pattern: /^(19[5-9]\d|20[0-1]\d)$/,
      })}
    />
  )
}

export default InputYear
