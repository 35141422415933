import { isEqual } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { routes } from '~config'
import { useAuth } from '~context/AuthContext'
import { Button, PopIn, Text } from '~design-system'

const UpdatePasswordPopin = () => {
  const {
    isAuthenticated,
    authState: { user },
  } = useAuth()
  const navigate = useNavigate()
  const needToUpdatePassword =
    isAuthenticated() && isEqual(user?.hasValidPassword, false)
  const hasSeenUpdatePassword = localStorage.getItem('hasSeenUpdatePassword')
  const [isVisible, setIsVisible] = useState(
    !hasSeenUpdatePassword && needToUpdatePassword
  )

  const setHasSeenPasswordUpdate = () => {
    localStorage.setItem(
      'hasSeenUpdatePassword',
      new Date().toLocaleDateString()
    )
    return setIsVisible(false)
  }

  return (
    <PopIn open={isVisible} closable onClose={setHasSeenPasswordUpdate}>
      <PopIn.Title textAlign="left">
        Évolution de la politique de mot de passe
      </PopIn.Title>
      <PopIn.Content>
        <Text textColor="greyDarkest" mt={2}>
          La politique de mot de passe a évolué, nous vous invitons à vous
          rendre dans les paramètres et mettre à jour le votre
        </Text>
      </PopIn.Content>

      <PopIn.Actions
        actions={[
          <Button
            key="update-later"
            variant="secondary"
            onClick={setHasSeenPasswordUpdate}
          >
            Changer plus tard
          </Button>,
          <Button
            key="update-now"
            onClick={() => {
              navigate({
                pathname: routes.authenticated.settings,
              })
              setHasSeenPasswordUpdate()
            }}
          >
            Changer mon mot de passe
          </Button>,
        ]}
      />
    </PopIn>
  )
}

export default UpdatePasswordPopin
