import { useMutation, useQuery } from 'react-query'

import { EventService, queryClient } from '~api'
import { isYoung } from '~helpers'

function useEvents() {
  const useGetEvents = (filters?: string) =>
    useQuery(['events', filters], () => EventService.apiGetEvents(filters))

  const useGetCreatedEvents = (user: User | undefined) => {
    return useQuery(['created-events'], EventService.apiGetCreatedEvents, {
      enabled: !isYoung(user),
    })
  }

  const useGetSelectedEvents = () =>
    useQuery(['selected-events'], EventService.apiGetSelectedEvents)

  const useGetFavoritesEvents = () =>
    useQuery(['favorites-events'], EventService.apiGetFavoritesEvents)

  const useGetEvent = (id: number) =>
    useQuery(['event', id], () => EventService.apiGetEvent(id), {
      enabled: !!id,
    })

  const useGetPublicEvent = (id: number) =>
    useQuery(['event', id], () => EventService.apiGetPublicEvent(id), {
      enabled: !!id,
    })

  const useGetEventAttendees = (id: number) =>
    useQuery(['event-attendees', id], () =>
      EventService.apiGetEventAttendees(id)
    )

  const useAddEvent = () =>
    useMutation(EventService.apiAddEvent, {
      onSuccess: () => {
        queryClient.invalidateQueries('events')
        queryClient.invalidateQueries(['created-events'])
      },
    })

  const useUpdateEvent = (id?: number) =>
    useMutation(EventService.apiUpdateEvent, {
      onSuccess: (data) => {
        queryClient.setQueryData(['event', id], data)
      },
    })

  const useAddEventToFavorites = ({
    id,
    queryKey,
  }: {
    id: number
    queryKey: (string | number)[]
  }) =>
    useMutation(() => EventService.apiAddEventToFavorites(id), {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey)
        queryClient.invalidateQueries('favorites-events')
      },
    })

  const useRemoveEventFromFavorites = ({
    id,
    queryKey,
  }: {
    id: number
    queryKey: (string | number)[]
  }) =>
    useMutation(() => EventService.apiRemoveEventFromFavorites(id), {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey)
        queryClient.invalidateQueries('favorites-events')
      },
    })

  const useRegisterEvent = (eventID: number) =>
    useMutation(EventService.apiRegisterEvent, {
      onSuccess: () => {
        queryClient.invalidateQueries(['selected-events'])
        queryClient.invalidateQueries(['event-attendees', eventID])
        queryClient.setQueryData(['event', eventID], (event: any) => ({
          ...event,
          isUserRegistered: true,
        }))
      },
    })
  const useRegisterPublicEvent = () =>
    useMutation(EventService.apiRegisterPublicEvent)

  const useUnregisterEvent = (eventID: number) =>
    useMutation(EventService.apiUnregisterEvent, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['selected-events'])
        await queryClient.invalidateQueries(['event-attendees', eventID])
        await queryClient.invalidateQueries(['event', eventID])
      },
    })

  const useGetEnumEvents = () =>
    useQuery(['events-enum'], EventService.apiGetEventEnum)

  const useDeleteEvent = () =>
    useMutation(EventService.apiDeleteEvent, {
      onSuccess: () => {
        queryClient.invalidateQueries('events')
        queryClient.invalidateQueries('created-events')
      },
    })

  return {
    useGetEvent,
    useGetPublicEvent,
    useGetEvents,
    useGetCreatedEvents,
    useGetSelectedEvents,
    useGetFavoritesEvents,
    useGetEventAttendees,
    useAddEvent,
    useAddEventToFavorites,
    useRemoveEventFromFavorites,
    useUpdateEvent,
    useGetEnumEvents,
    useRegisterEvent,
    useRegisterPublicEvent,
    useUnregisterEvent,
    useDeleteEvent,
  }
}

export default useEvents
