import styled from 'styled-components'

import { theme } from '~config'
import { Box } from '~design-system'

const CharLeftContainer = styled(Box)`
  padding: ${theme.spaces[2]} ${theme.spaces[5]} ${theme.spaces[5]};
`

export default CharLeftContainer
