import { Controller, useForm } from 'react-hook-form'

import { Box, Button, Dropzone, Header2, Icon, Text } from '~design-system'
import { documentMimeTypes } from '~helpers'
import { useUser } from '~hooks'

import { UploadPopinProps } from '..'

const CV = ({ onClose, onSubmit }: UploadPopinProps) => {
  const {
    control,
    getValues,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
  })
  const { useUploadDocument } = useUser()
  const { mutateAsync: uploadDoc } = useUploadDocument()

  const onUpload = async () => {
    try {
      await uploadDoc(getValues())

      onSubmit([`CV envoyé`])
      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Header2 mb={4}>Ajouter un CV</Header2>
      <Text mb={8} textColor="greyDarkest">
        Ton CV est l’outil indispensable à la réussite de ton projet
        professionnel ! Ajoute-le pour que ton futur parrain puisse découvrir
        ton parcours et ton projet.
      </Text>
      <form onSubmit={handleSubmit(onUpload)}>
        <Box di="f" jc="space-between">
          <Text>CV</Text>
        </Box>
        <Text textColor="greyDarkest">
          Tu peux ajouter ton CV au format photo ou PDF !
        </Text>
        <Controller
          control={control}
          name="resume"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Dropzone
              multiple={false}
              accept={documentMimeTypes}
              file={value && [value]}
              setFile={(val?: string[] | null[]) =>
                onChange(val ? val[0] : undefined)
              }
            >
              <Box di="f" fd="column" ai="center" js="center">
                <Icon name="camera" color="primary" />
                <Text textColor="primary">Importe ici ton CV</Text>
              </Box>
            </Dropzone>
          )}
        />
        <Box>
          <Box di="f" mt={6}>
            <Button
              type="submit"
              disabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
            >
              Valider
            </Button>
            <Text as="button" color="greyDarkest" onClick={onClose}>
              Compléter plus tard
            </Text>
          </Box>
        </Box>
      </form>
    </>
  )
}

export default CV
