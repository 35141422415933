import { useQuery } from 'react-query'

import { EnumService } from '~api'

const useGetEnums = () => {
  const { data, isLoading } = useQuery(['enums'], EnumService.apiGetEnums)

  const getJobEnums = () => {
    if (!data) return
    return data['job-offer']
  }

  const getEventEnums = () => {
    if (!data) return
    return data['event']
  }

  const getUserEnums = () => {
    if (!data) return
    return data['user']
  }

  const getCompanyEnums = () => {
    if (!data) return
    return data['company']
  }

  const getSurveyEnums = () => {
    if (!data) return
    return data['survey']
  }

  const getMatchingEnums = () => {
    if (!data) return
    return data['matching'].feeling
  }

  const getJobEnumLabel = <
    T extends keyof Enums['job-offer'],
    R extends keyof Enums['job-offer'][T]
  >(
    property: T,
    key?: R
  ) => {
    if (key === undefined || !data) return
    return data['job-offer'][property][key]
  }

  const getCompanyEnumLabel = <
    T extends keyof Enums['company'],
    R extends keyof Enums['company'][T]
  >(
    property: T,
    key?: R
  ) => {
    if (key === undefined || !data) return
    return data.company[property][key]
  }

  const getServiceEnumLabel = <
    T extends keyof Enums['service'],
    R extends keyof Enums['service'][T]
  >(
    property: T,
    key?: R
  ) => {
    if (key === undefined || !data) return
    return data['service'][property][key]
  }

  return {
    data,
    isLoading,
    getJobEnums,
    getEventEnums,
    getCompanyEnums,
    getSurveyEnums,
    getJobEnumLabel,
    getCompanyEnumLabel,
    getServiceEnumLabel,
    getMatchingEnums,
    getUserEnums,
  }
}

export default useGetEnums
