import styled, { css } from 'styled-components'

type Props = {
  variant: 'answer' | 'question'
  isAttachment?: boolean
}

const Container = styled('div')<Props>`
  ${({ theme, variant, isAttachment = false }) => css`
    background-color: ${theme.components.message[variant].backgroundColor};
    border-radius: ${isAttachment
      ? '8px'
      : theme.components.message[variant].borderRadius};
    border: 1px solid ${theme.colors.grey};
    padding: ${theme.spaces[3]};
    margin: 0 ${isAttachment ? '6px' : 0};
  `}
`

export default Container
