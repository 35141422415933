import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const LogoContainer = styled(Box)`
  ${({ theme }) => css`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `}
`

export default LogoContainer
