import { Link, generatePath } from 'react-router-dom'

import { routes } from '~config'
import { Avatar, Box, Panel, SmallText, Text } from '~design-system'
import { LayoutProps, SpacingProps } from '~design-system/helpers'

export type Props = {
  user: Match
} & LayoutProps &
  SpacingProps

const CardUser = ({ user, ...props }: Props) => (
  <Panel
    as={Link}
    to={generatePath(routes.authenticated.youngprofile, {
      id: user.id.toString(),
    })}
    di="f"
    jc="ce"
    h="100%"
    {...props}
  >
    <Box di="f" ai="ce">
      <Avatar src={user.profilePhoto} alt="avatar-utilisateur" />
      <Box fg={1} pl={4}>
        <Text weight="medium">{user.fullName}</Text>
        <SmallText textColor="greyDarkest">Voir son profil</SmallText>
      </Box>
    </Box>
  </Panel>
)

export default CardUser
