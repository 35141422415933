import styled, { css } from 'styled-components'

import { PopInTitleProps } from '../types'

const PopInTitle = styled('h2').attrs((props: PopInTitleProps) => ({
  textAlign: props.textAlign || 'center',
}))<PopInTitleProps>`
  ${({ textAlign, theme }) => css`
    color: ${theme.colors.black};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.components.header2.fontSize};
    font-weight: ${theme.weights.bold};
    line-height: 1.2;
    text-align: ${textAlign};
  `}
`

export default PopInTitle
