import {
  createSearchParams,
  useNavigate,
  URLSearchParamsInit,
} from 'react-router-dom'

function useNavigateSearch() {
  const navigate = useNavigate()

  return (pathname: string, params: URLSearchParamsInit) =>
    navigate(`${pathname}?${createSearchParams(params)}`)
}

export default useNavigateSearch
