import api from '../api'

const apiGetEnums = () =>
  api
    .get<ApiResponse<Enums>>(`/api/v1/enums`)
    .then((response) => response.data.data)

const EnumService = {
  apiGetEnums,
}
export default EnumService
