import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import getPusher from '~services/pusher'

import { useAuth } from './AuthContext'

type OnlineContextState = {
  users: number[]
  isOnline: (id?: number) => boolean
}

const OnlineContext = createContext({} as OnlineContextState)

type Props = {
  children: ReactNode
}

const OnlineProvider = ({ children }: Props) => {
  const [users, setUsers] = useState([])
  const {
    authState: { user },
  } = useAuth()

  useEffect(() => {
    if (user) {
      const pusher = getPusher()
      const channel = pusher.subscribe('private-online-users')
      channel.bind('list', (payload: any) => {
        setUsers(payload)
      })

      pusher.subscribe(`presence-online.${user?.id}`)

      return () => {
        pusher.unsubscribe('private-online-users')
        pusher.unsubscribe(`online.${user?.id}`)
      }
    }
  }, [user])

  const isOnline = (id?: number) =>
     !!users.find((connectedUser) => connectedUser === id)

  return (
    <OnlineContext.Provider
      value={{
        users,
        isOnline,
      }}
    >
      {children}
    </OnlineContext.Provider>
  )
}

const useOnlineUsers = () => {
  const context = useContext(OnlineContext)
  if (context === undefined) {
    throw new Error('useOnline must be used within a OnlineProvider')
  }

  return context
}

export { OnlineProvider, useOnlineUsers }
