import styled, { css } from 'styled-components'

const AvatarBox = styled('button')`
  ${() => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

export default AvatarBox
