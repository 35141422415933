import styled, { css, keyframes } from 'styled-components'

import Icon from '~design-system/Icon'

type Props = {
  isOpen: boolean
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
    `

const restore = keyframes`
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0deg);
  }
    `

const AccordionIcon = styled(Icon).attrs((props: Props) => ({
  isOpen: props.isOpen,
}))<Props>`
  ${({ isOpen }) => css`
    cursor: default;
    animation: ${isOpen ? rotate : restore} 0.15s linear;
    animation-fill-mode: forwards;
  `}
`

export default AccordionIcon
