import styled, { css } from 'styled-components'

const HTMLDescription = styled('div')`
  ${({ theme }) => css`
    p,
    ul,
    ol {
      color: ${theme.colors.greyDarkest};
      font-family: ${theme.fonts.primary};
      font-size: ${theme.components.text.fontSize};
      line-height: 1.4;
    }
    a {
      color: ${theme.colors.greyDarkest};
      text-decoration: underline;
    }
    strong,
    b {
      font-weight: ${theme.components.text.weights.medium};
    }
  `}
`

export default HTMLDescription
