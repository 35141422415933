import api from '../api'

const apiAddExperience = (data: Experience) =>
  api
    .post<ApiResponse<User>>(`/api/v1/experience`, data)
    .then((response) => response.data.data)

const apiDeleteExperience = (id: string) =>
  api
    .delete<ApiResponse<Experience>>(`api/v1/experience/${id}`)
    .then((response) => response.data)

const apiUpdateExperience = (data: Experience) =>
  api
    .patch<ApiResponse<{ user: Experience }>>(
      `api/v1/experience/${data.id}`,
      data
    )
    .then((response) => response.data.data)

const ExperienceService = {
  apiAddExperience,
  apiDeleteExperience,
  apiUpdateExperience,
}
export default ExperienceService
