import { KeyboardEvent, useEffect } from 'react'
import { RegisterOptions, useFormContext } from 'react-hook-form'

import { Button, Icon, InputPassword, InputText } from '~design-system'

type Props = {
  id: string
  onSubmit: () => void
  placeholder: string
  type?: 'password' | 'text'
  validator?: RegisterOptions
}

const Input = ({ id, onSubmit, placeholder, type, validator }: Props) => {
  const {
    setFocus,
    formState: { dirtyFields, errors, isValid },
    register,
  } = useFormContext()

  useEffect(() => {
    setFocus(id)
  }, [id, setFocus])

  const submit = () => {
    if (dirtyFields[id] && isValid) {
      onSubmit()
    }
  }

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submit()
    }
  }

  return type === 'password' ? (
    <InputPassword
      id={id}
      onKeyPress={handleKeyUp}
      errorMessage={errors[id] && errors[id].message}
      placeholder={placeholder}
      {...register(id, validator)}
      withStrength
      rightComponent={
        <Button
          disabled={!dirtyFields[id] || errors[id]}
          onClick={submit}
          size="small"
          square
          type="submit"
          title="Envoyer"
        >
          <Icon name="send" />
        </Button>
      }
    />
  ) : (
    <InputText
      id={id}
      type="text"
      onKeyUp={handleKeyUp}
      placeholder={placeholder}
      errorMessage={errors[id] && errors[id].message}
      {...register(id, validator)}
      rightComponent={
        <Button
          disabled={!dirtyFields[id] || errors[id]}
          onClick={onSubmit}
          size="small"
          title="Envoyer"
          square
          type="submit"
        >
          <Icon name="send" />
        </Button>
      }
    />
  )
}

export default Input
