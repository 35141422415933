import { useQuery } from 'react-query'

import { FaqService } from '~api'
import { useAuth } from '~context/AuthContext'
import { getCurrentRole } from '~helpers'

function useFaq() {
  const {
    authState: { user },
  } = useAuth()
  const currentRole = getCurrentRole(user)

  const useGetQuestion = (id: number) =>
    useQuery(['question', id], () => FaqService.apiGetQuestion(id))

  const useGetPopularQuestions = () =>
    useQuery(['questionPopular', currentRole], () =>
      FaqService.apigGetPopularQuestions(currentRole)
    )
  const useGetQuestions = (filters?: QuestionFilters) =>
    useQuery(['questions', { ...filters, currentRole }], () =>
      FaqService.apiGetQuestions({ ...filters, currentRole })
    )
  const useGetCategories = (filters?: QuestionFilters) =>
    useQuery(['questionCategories'], () => FaqService.apiGetCategories(filters))

  return {
    useGetQuestion,
    useGetPopularQuestions,
    useGetQuestions,
    useGetCategories,
  }
}

export default useFaq
