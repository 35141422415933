import { PASSWORD_REGEX, theme } from '~config'

export type Strength = 'weak' | 'medium' | 'strong'

const strengthLabel = new Map<Strength, string>([
  ['weak', 'Faible'],
  ['medium', 'Moyenne'],
  ['strong', 'Forte'],
])

const strengthColor = new Map<Strength, string>([
  ['weak', theme.colors.pink],
  ['medium', theme.colors.blue],
  ['strong', theme.colors.primary],
])

const getStrength = (password: string) => {
  if (password.length <= 5) {
    return 'weak'
  }
  if (PASSWORD_REGEX.test(password)) {
    return 'strong'
  }

  return 'medium'
}

export const usePasswordStrength = (defaultValue = '') => {
  const strength = getStrength(defaultValue) as Strength
  const label = strengthLabel.get(strength) || ''
  const color = strengthColor.get(strength) || ''

  return {
    strength,
    label,
    color,
  }
}
