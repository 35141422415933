import styled, { Theme, css } from 'styled-components'

import { DisplayProps, SpacingProps, display, spacing } from '../../helpers'

type Props = {
  textColor?: keyof Theme['colors']
  textAlign?: keyof Theme['components']['text']['align']
} & SpacingProps &
  DisplayProps

const Header2 = styled('h2').attrs((props: Props) => ({
  textColor: props.textColor || 'black',
  textAlign: props.textAlign || 'left',
}))<Props>`
  ${({ textAlign, textColor, theme }) => css`
    color: ${theme.colors[textColor]};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.components.header2.fontSize};
    font-weight: ${theme.weights.bold};
    line-height: 1.2;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: ${theme.components.text.align[textAlign]};
  `}
  ${spacing};
  ${display};
`

export default Header2
