import { Link, generatePath } from 'react-router-dom'

import { routes } from '~config'
import { useOnlineUsers } from '~context/OnlineContext'
import { Avatar, Badge, Box, Text } from '~design-system'
import { getAvatarTypeByRole, getParticipantsNames } from '~helpers'

type ConversationProps = {
  thread: Thread
  isWaiting?: boolean
}

const Conversation = ({ thread, isWaiting = false }: ConversationProps) => {
  const { unreadCount, id, participants } = thread
  const { isOnline } = useOnlineUsers()

  const pathToMatch = !isWaiting
    ? generatePath(routes.authenticated.messaging, {
        id: `${id}`,
      })
    : generatePath(routes.authenticated.invite, {
        fullName: participants[0]?.fullName,
      })

  const avatarType: AvatarType = getAvatarTypeByRole(
    thread.participants[0]?.role
  )
  return thread.participants.length > 0 ? (
    <Box
      as={Link}
      to={pathToMatch}
      di="f"
      ai="ce"
      jc="sb"
      mb={2}
      mt={2}
      fd="row"
      pos="relative"
    >
      <Box di="f" ai="ce">
        <Avatar
          type={avatarType}
          src={thread.participants[0]?.profilePhoto}
          online={isOnline(thread.participants[0]?.id)}
        />
        <Text weight="regular" ml={3}>
          {getParticipantsNames(thread)}
        </Text>
      </Box>
      {!!unreadCount && unreadCount > 0 && <Badge>{unreadCount}</Badge>}
    </Box>
  ) : null
}

export default Conversation
