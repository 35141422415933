import { useRef } from 'react'

const useScrollAnchor = () => {
  const scrollContainerRef = useRef<HTMLHeadingElement>(null)

  const scrollToTop = () => {
    scrollContainerRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const scrollToBottom = () => {
    scrollContainerRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
  }

  return {
    scrollContainerRef,
    scrollToTop,
    scrollToBottom,
  }
}

export default useScrollAnchor
