import styled, { css } from 'styled-components'

import { spacing } from '../../helpers'

const Header1 = styled('h1')`
  ${({ theme, color }) => css`
    color: ${color ? color : theme.colors.black};
    font-size: ${theme.components.header1.fontSize};
    font-family: ${theme.fonts.primary};
    font-weight: ${theme.weights.bold};
    line-height: 1.1;
    ${spacing};
  `}
`

export default Header1
