import api from '../api'

const apiAddDegree = (data: Degree) =>
  api
    .post<Degree>(`api/v1/user/document`, data)
    .then((response) => response.data)

const apiDeleteDegree = (id: string) =>
  api
    .delete<ApiResponse<Degree>>(`api/v1/user/document/${id}`)
    .then((response) => response.data)

const apiUpdateDegree = (data: Partial<Degree>) =>
  api
    .patch<Degree>(`api/v1/user/document/${data.id}`, data)
    .then((response) => response.data)

const DegreeService = {
  apiAddDegree,
  apiDeleteDegree,
  apiUpdateDegree,
}

export default DegreeService
