import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const Content = styled(Box)`
  ${({ theme }) => css`
    max-width: 1860px;
    width: 100%;
    align-self: center;
    padding: ${theme.spaces[10]} ${theme.spaces[10]} ${theme.spaces[10]}
      ${theme.spaces[10]};
    margin-top: ${theme.components.headerBanner.height};

    @media (max-width: 700px) {
      width: 100vw;
      padding: ${theme.spaces[10]} ${theme.spaces[8]} ${theme.spaces[4]}
        ${theme.spaces[4]};
    }
  `}
`

export default Content
