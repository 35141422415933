import styled, { css } from 'styled-components'

import Text from '~design-system/Typographies/Text'

const SwitchText = styled(Text)`
  ${({ theme }) => css`
    input[type='checkbox']:checked ~ &:first-of-type,
    input[type='checkbox']:not(:checked) ~ &:nth-of-type(2) {
      color: ${theme.colors.greyDarker};
    }

    input[type='checkbox']:not(:checked) ~ &:first-of-type,
    input[type='checkbox']:checked ~ &:nth-of-type(2) {
      color: ${theme.colors.black};
    }
  `}
`

export default SwitchText
