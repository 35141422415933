import { Grid } from '~design-system'

import { PopInActionsContainer } from '../styles'
import { PopInActionsProps } from '../types'

const Actions = ({ actions }: PopInActionsProps) => (
  <PopInActionsContainer>
    <Grid
      rowGap={5}
      columnGap={5}
      templateColums="repeat(auto-fit, minmax(125px, 1fr))"
    >
      {actions.map((action) => action)}
    </Grid>
  </PopInActionsContainer>
)

export default Actions
