import { camelize } from 'humps'
import { useFormContext } from 'react-hook-form'

import InputYear from '~components/Chat/components/InputYear'
import { getParseBodyParams, getType } from '~components/Chat/helpers'

type Props = {
  dialog: Stepper
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const Year = ({ dialog, setAnswers, handleAnswer }: Props) => {
  const { getValues } = useFormContext()

  const answer = getType(dialog, 'year')[0]
  const parsedBodyParam = getParseBodyParams(answer)
  const id = camelize(Object.keys(parsedBodyParam)[0])

  const handleInputYearValue = (answer: Answer, id: string) => {
    const value = getValues(camelize(id))
    setAnswers(value)
    handleAnswer(answer.action, { [`${id}`]: value })
  }

  return (
    <InputYear
      id={id}
      placeholder={answer.label}
      onSubmit={() => handleInputYearValue(answer, id)}
    />
  )
}

export default Year
