import api from '../api'

const apiGetEvents = (filters?: string) =>
  api
    .get<ApiPaginatedResponse<NQTEventTeaser[]>>(
      `/api/v2/events${filters || ''}`
    )
    .then((response) => response.data)

const apiGetEvent = (id: number) =>
  api
    .get<ApiResponse<NQTEvent>>(`/api/v2/events/${id}`)
    .then((response) => response.data.data)

const apiGetPublicEvent = (id: number) =>
  api
    .get<ApiResponse<{ event: NQTEvent }>>(`/api/v2/events-public/${id}`)
    .then((response) => response.data.data.event)

const apiGetSelectedEvents = () =>
  api
    .get<ApiPaginatedResponse<NQTEventTeaser[]>>(`/api/v2/user/events`)
    .then((response) => response.data)

const apiGetCreatedEvents = () =>
  api
    .get<ApiPaginatedResponse<NQTEventTeaser[]>>(`/api/v2/user/events/created`)
    .then((response) => response.data)

const apiGetFavoritesEvents = () =>
  api
    .get<ApiPaginatedResponse<NQTEventTeaser[]>>(
      `/api/v2/user/events/favorites`
    )
    .then((response) => response.data)

const apiGetEventAttendees = (id: number) =>
  api
    .get<ApiPaginatedResponse<Partial<User>[]>>(
      `/api/v2/events/${id}/attendees`
    )
    .then((response) => response.data.data)

const apiDeleteEvent = (id: string) =>
  api
    .delete<ApiResponse<NQTEvent>>(`/api/v2/events/${id}`)
    .then((response) => response.data.data)

const apiAddEvent = (data: NQTEventForm) =>
  api
    .post<ApiResponse<NQTEvent>>(`/api/v2/events`, data)
    .then((response) => response.data.data)

const apiAddEventToFavorites = (id: number) =>
  api
    .post<ApiResponse<NQTEvent>>(`/api/v2/events/${id}/favorite`)
    .then((response) => response.data.data)

const apiRemoveEventFromFavorites = (id: number) =>
  api
    .post<ApiResponse<NQTEvent>>(`/api/v2/events/${id}/unfavorite`)
    .then((response) => response.data.data)

const apiUpdateEvent = (data: Partial<NQTEventForm>) =>
  api
    .patch<ApiResponse<{ event: NQTEvent }>>(`/api/v2/events/${data.id}`, {
      ...data,
      canDelete: undefined,
      canEdit: undefined,
      createdAt: undefined,
      id: undefined,
    })
    .then((response) => response.data.data.event)

const apiRegisterEvent = (id: number) =>
  api
    .post<ApiResponse<NQTEvent>>(`/api/v2/events/${id}/register`)
    .then((response) => response.data.data)

const apiRegisterPublicEvent = ({ id, data }: { id: number; data: any }) =>
  api
    .post<ApiResponse<NQTEvent>>(`/api/v2/events-public/${id}/register`, {
      ...data,
    })
    .then((response) => response.data.data)

const apiUnregisterEvent = ({
  eventID,
  userID,
}: {
  eventID: number
  userID?: number
}) =>
  api
    .post<ApiResponse<NQTEvent>>(
      `/api/v2/events/${eventID}/unregister${userID ? `/${userID}` : ''}`
    )
    .then((response) => response.data.data)
    .catch((error) => {
      return Promise.reject(error.response.data.message)
    })

const apiGetEventEnum = () =>
  api
    .get<ApiResponse<NQTEventEnum>>(`api/v1/enum/event`)
    .then((response) => response.data.data)

const EventService = {
  apiGetEvents,
  apiGetEvent,
  apiGetPublicEvent,
  apiGetSelectedEvents,
  apiGetCreatedEvents,
  apiGetEventAttendees,
  apiGetEventEnum,
  apiAddEvent,
  apiUpdateEvent,
  apiRegisterEvent,
  apiRegisterPublicEvent,
  apiUnregisterEvent,
  apiDeleteEvent,
  apiGetFavoritesEvents,
  apiAddEventToFavorites,
  apiRemoveEventFromFavorites,
}

export default EventService
