import { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import env from '~config/env'
import RoutesApp from '~pages'
import { UpdatePasswordPopin } from '~pages/Authenticated/Home/components'

import { ampli } from './ampli'

function App() {
  useEffect(() => {
    if (env.AMPLITUDE_TOKEN) {
      console.log('Starting Amplitude', env.AMPLITUDE_TOKEN)
      ampli.load({ client: { apiKey: env.AMPLITUDE_TOKEN } })
    } else {
      console.log('Amplitude token not found')
    }
  }, [])

  return (
    <Router>
      <UpdatePasswordPopin />
      <RoutesApp />
    </Router>
  )
}

export default App
