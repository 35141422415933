import api from '../api'

const forgotPassword = ({ email }: { email: string }) =>
  api.post<ApiUserForgotPasswordPostResponse>(
    '/api/v1/user/forgot-password',
    null,
    {
      params: { email },
    }
  )

const getAddresses = ({
  addressToLookFor,
  limit,
}: {
  addressToLookFor: string
  limit?: number
}) => {
  if (limit) {
    return api
      .get<ApiGetAddressesResponse>(
        `/api/v1/user/address?query="${addressToLookFor}"&limit=${limit}`
      )
      .then((response) => response.data)
  }
  return api
    .get<ApiGetAddressesResponse>(
      `/api/v1/user/address?query="${addressToLookFor}`
    )
    .then((response) => response.data)
}

const getStep = (step: string) =>
  api
    .get<ApiGetStepResponse>(`/api/v1/auth/stepper${step}`)
    .then((response) => response.data)

const patchStep = (path: string, body: any) =>
  api.patch<ApiPatchStepResponse>(path, body).then((response) => response.data)

const register = (path: string, body: ApiAuthRegisterPostRequest) => {
  return api.post(path, body).then((response) => response.data)
}

const registerPE = (body: ApiAuthRegisterPEPostRequest) => {
  return api
    .post('/api/v1/auth/register', body)
    .then((response) => response.data)
}

const login = ({
  email,
  password,
  response_type,
  client_id,
  redirect_uri,
}: ApiLoginProps) => {
  let additionalParams = {}
  if (response_type && client_id && redirect_uri) {
    additionalParams = {
      response_type,
      client_id,
      redirect_uri,
    }
  }
  return api
    .post<ApiLoginPostResponse>('/api/v1/auth/login', {
      email,
      password,
      ...additionalParams,
    })
    .then((response) => response.data)
}

const logout = () => api.post('/api/v1/auth/logout')

const resetPassword = ({ email, password, token }: ApiResetPasswordProps) =>
  api.post('/api/v1/user/reset-password', {
    email,
    password,
    token,
  })

const AuthService = {
  forgotPassword,
  getAddresses,
  getStep,
  login,
  logout,
  patchStep,
  register,
  registerPE,
  resetPassword,
}

export default AuthService
