import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const DropdownAvatar = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.radii.lg};
    border: 1px solid ${theme.colors.blueLighter};
    box-shadow: 0 0 10px ${theme.colors.greyLight};
    left: 55%;
    min-width: 12rem;
    padding: ${theme.spaces[4]};
    position: absolute;
    top: 6rem;

    @media (max-width: 500px) {
      left: 10%;
      width: 80%;
      min-width: 80%;
    }
  `}
`

export default DropdownAvatar
