import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const Logo = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    padding: ${theme.spaces[6]};
    height: 5rem;
    min-height: 5rem;

    @media (max-width: 700px) {
      padding: ${theme.spaces[3]};
    }
  `}
`

export default Logo
