import styled, { Theme, css } from 'styled-components'

type DotProps = {
  size?: keyof Theme['components']['avatar']['sizes']
}

const Dot = styled('div').attrs((props: DotProps) => ({
  size: props.size || 'default',
}))<DotProps>`
  ${({ theme, size }) => css`
    background-color: ${theme.colors.blue};
    border-radius: 100%;
    height: ${theme.components.avatar.dots[size]};
    width: ${theme.components.avatar.dots[size]};
    position: absolute;
    bottom: 1px;
    right: 1px;
    border: 2px solid ${theme.colors.greyLightest};
  `}
`

export default Dot
