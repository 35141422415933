import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useAuth } from '~context/AuthContext'
import { Box, Button, PopIn, Text } from '~design-system'
import { useUser } from '~hooks'

import { FormContainer } from '../styles'
import { BooleanRow } from './components'

const YoungSituationPopIn = () => {
  const { useUpdateUser } = useUser()
  const {
    authState: { user },
  } = useAuth()
  const { mutate: updateUser, isLoading } = useUpdateUser()
  const [isOpen, setIsOpen] = useState<boolean>(true)

  // Define Form Methods
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      eligibleToRsa: user?.eligibleToRsa?.toString(),
      obtainedAScholarship: user?.obtainedAScholarship?.toString(),
      handicapSituation: user?.handicapSituation?.toString(),
    },
  })

  // Define Form
  const {
    handleSubmit,
    getValues,
    formState: { isValid },
  } = methods

  // OnSubmit
  const handleRequest = () => {
    updateUser(
      {
        eligibleToRsa: getValues('eligibleToRsa') === 'true',
        obtainedAScholarship: getValues('obtainedAScholarship') === 'true',
        handicapSituation: getValues('handicapSituation') === 'true',
      },
      {
        onSuccess() {
          setIsOpen(false)
        },
      }
    )
  }

  return (
    <PopIn open={isOpen}>
      <FormProvider {...methods}>
        <FormContainer onSubmit={handleSubmit(handleRequest)}>
          <PopIn.Title textAlign="left">
            Informations complémentaires
          </PopIn.Title>
          <PopIn.Content>
            <Text textColor="greyDarkest" mt={4}>
              Aidez-nous à maintenir le service en nous indiquant si vous êtes
              dans une ou plusieurs des situations ci-dessous. Nos subventions
              en dépendent ! Un grand merci !
            </Text>
            <Box di="f" fd="c" g="10px" w="100%" mt={12}>
              <BooleanRow
                id="eligibleToRsa"
                title="Bénéficiaire du RSA ou de la Prime d’activité"
              />
              <BooleanRow
                id="obtainedAScholarship"
                title="J’ai été boursier pendant mes études"
              />
              <BooleanRow
                id="handicapSituation"
                title="En situation de handicap"
              />
            </Box>
          </PopIn.Content>
          <PopIn.Actions
            actions={[
              <Button
                key="save"
                disabled={!isValid || isLoading}
                type="submit"
                isLoading={isLoading}
              >
                Enregistrer
              </Button>,
            ]}
          />
        </FormContainer>
      </FormProvider>
    </PopIn>
  )
}

export default YoungSituationPopIn
