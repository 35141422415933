import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const CompanyLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.greyDarkest};
    cursor: pointer;
    text-decoration: underline dotted ${theme.colors.greyDarker};
  `}
`

export default CompanyLink
