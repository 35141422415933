import React from 'react'
import { generatePath } from 'react-router-dom'

import { routes } from '~config'
import { Icon, Text } from '~design-system'

import { Container } from './styles'

type Props = {
  question: QuestionTeaser
}
const Question = ({ question }: Props) => {
  return (
    <Container
      to={generatePath(routes.authenticated.faqQuestion, {
        id: `${question.id}`,
      })}
    >
      <Text>{question.question}</Text>
      <Icon name="chevron-right" size="small" color="greyDarker" />
    </Container>
  )
}

export default Question
