import { Controller, useForm } from 'react-hook-form'

import { Box, Button, Dropzone, Header2, Icon, Text } from '~design-system'
import { documentMimeTypes } from '~helpers'
import { useUser } from '~hooks'

import { UploadPopinProps } from '..'

const ProofOfAddress = ({ onClose, onSubmit }: UploadPopinProps) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid, isSubmitting },
  } = useForm<UploadDocumentProps>({
    mode: 'onChange',
  })
  const { useUploadDocument } = useUser()
  const { mutateAsync: uploadDoc } = useUploadDocument()

  const onUpload = async () => {
    try {
      await uploadDoc(getValues())
      onSubmit(['Justificatif envoyé'])
      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Header2 mb={4}>Justificatif</Header2>
      <Text mb={8} textColor="greyDarkest">
        Prépare un document qui justifie ton adresse de résidence actuelle 🗂
      </Text>

      <form onSubmit={handleSubmit(onUpload)}>
        <Box di="f" jc="space-between">
          <Text>Justificatif de domicile</Text>
        </Box>
        <Text textColor="greyDarkest">
          Importe ton justificatif de domicile ici en t’assurant qu’il soit
          facilement lisible.
        </Text>
        <Controller
          control={control}
          name="proof_of_address"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Dropzone
              multiple={false}
              accept={documentMimeTypes}
              file={value ? [value] : undefined}
              setFile={(val?: string[] | null[]) =>
                onChange(val ? val[0] : undefined)
              }
            >
              <Box di="f" fd="column" ai="center" js="center">
                <Icon name="camera" color="primary" />
                <Text textColor="primary">Importe ton justificatif</Text>
              </Box>
            </Dropzone>
          )}
        />
        <Box>
          <Box di="f" mt={6}>
            <Button
              type="submit"
              disabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
            >
              Valider
            </Button>
            <Text as="button" color="greyDarkest" onClick={onClose}>
              Compléter plus tard
            </Text>
          </Box>
        </Box>
      </form>
    </>
  )
}

export default ProofOfAddress
