import styled, { css } from 'styled-components'

import { Box, Icon } from '~design-system'

type Props = {
  icon: string
  label: string
  content: string
}

const Title = styled('p')`
  ${({ theme }) => css`
    font-size: ${theme.pxToRem(14)};
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: ${theme.pxToRem(4)}; ;
  `}
`

const Content = styled('p')`
  ${({ theme }) => css`
    font-size: ${theme.pxToRem(14)};
    color: ${theme.colors.greyDarker};
  `}
`

const Wrapper = styled('div')`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spaces[4]} ${theme.spaces[6]};
    border-bottom: 1px solid ${theme.colors.greyLighter};
    &:last-child {
      border-bottom: 0;
    }
  `}
`

const InformationItem = ({ icon, label, content }: Props) => {
  return (
    <Wrapper>
      <Icon mr={5} name={icon} color="primary" />
      <Box>
        <Title>{label}</Title>
        <Content>{content}</Content>
      </Box>
    </Wrapper>
  )
}

export default InformationItem
