import { Box, Button, PopIn, Text } from '~design-system'
import { useJob } from '~hooks'

interface Props {
  jobID: string
  onClose: Function
}

const JobDeletePopin = ({ jobID, onClose }: Props) => {
  const { useDeleteJobOffer } = useJob()
  const { mutate: deleteJob, isLoading } = useDeleteJobOffer()

  const handleDelete = () => {
    deleteJob(jobID, {
      onSuccess() {
        onClose(false)
      },
    })
  }

  return (
    <PopIn open closable onClose={() => onClose(false)}>
      <PopIn.Title textAlign="left">Supprimer une offre</PopIn.Title>
      <PopIn.Content>
        <Box w="100%">
          <Text textColor="greyDarkest" textAlign="left" mt={6}>
            Êtes-vous sûr de vouloir supprimer cette offre ?
          </Text>
        </Box>
      </PopIn.Content>
      <PopIn.Actions
        actions={[
          <Button
            key="cancel"
            variant="secondary"
            disabled={isLoading}
            onClick={() => onClose(false)}
          >
            Annuler
          </Button>,
          <Button
            key="save"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleDelete}
          >
            Valider
          </Button>,
        ]}
      />
    </PopIn>
  )
}

export default JobDeletePopin
