import styled, { css } from 'styled-components'

type Props = {
  withMenu?: boolean
}

const Main = styled('main')<Props>`
  ${({ withMenu = true, theme }) => css`
    background-color: ${theme.colors.greyLightest};
    min-height: 100vh;
    ${withMenu &&
    css`
      margin-left: ${theme.spaces[64]};
    `}
    ${!withMenu &&
    css`
      display: flex;
      height: 100vh;
      justify-content: center;
      padding: ${theme.spaces[8]};
    `}
  `}
`

export default Main
