import styled, { css } from 'styled-components'

const Blank = styled('main')`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.grey};
    display: flex;
    height: 100%;
    justify-content: center;
    min-height: 100vh;
    padding: ${theme.spaces[5]};
    position: relative;
  `}
`

export default Blank
