import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Label, Loader, TinyText } from '~design-system'
import Select from '~design-system/Select'
import { getTransformSectorList } from '~helpers'
import { useData } from '~hooks'

interface Props {
  handleSectorChange: Function
}

const SectorSelect = ({ handleSectorChange }: Props) => {
  const { resetField, watch, control } = useFormContext()
  const currentSector = watch('domain')
  const { useGetSectors } = useData()
  const { data: sectors, isLoading } = useGetSectors()

  /**
   * Set SectorID
   */
  useEffect(() => {
    currentSector &&
      sectors &&
      handleSectorChange(
        sectors?.find((sector) => sector.name === currentSector)?.id
      )
  }, [currentSector, handleSectorChange, sectors])

  return isLoading ? (
    <Loader />
  ) : (
    <Box w="100%">
      <Controller
        name="domain"
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <Box mb={4}>
              <TinyText
                as={Label}
                textColor="greyDarkest"
                uppercase
                mb={2}
                ml={2}
                htmlFor="domain"
              >
                Choisissez un secteur
              </TinyText>
              <Select
                inputId="domain"
                placeholder="Sélectionner un secteur"
                isSearchable={false}
                isDisabled={!sectors?.length}
                maxMenuHeight={200}
                options={sectors && getTransformSectorList(sectors, 'sector')}
                defaultValue={
                  currentSector && {
                    value: currentSector,
                    label: currentSector,
                  }
                }
                onChange={(e) => {
                  onChange(e?.value)
                  resetField('sector', { keepDirty: false, defaultValue: 0 })
                }}
              />
            </Box>
          )
        }}
      />
    </Box>
  )
}

export default SectorSelect
