import styled, { css } from 'styled-components'

const Subtitle = styled('h3')`
  ${({ theme }) => css`
    font-size: ${theme.pxToRem(18)};
    font-weight: 500;
    line-height: 1.4;
  `}
`

export default Subtitle
