import api from '../api'

const apiGetJobOffer = (id?: number) =>
  id
    ? api
        .get<ApiResponse<Job>>(`/api/v1/job-offers/${id}`)
        .then((response) => response.data.data)
    : undefined

const apiDeleteJobOffer = (id: string) =>
  api
    .delete<ApiResponse<Job>>(`/api/v1/job-offers/${id}`)
    .then((response) => response.data.data)

const apiUpdateJobOffer = (data: Partial<Job>) =>
  api
    .patch<ApiResponse<{ jobOffer: Job }>>(`/api/v1/job-offers/${data.id}`, {
      ...data,
      numberAttendees: undefined,
      canEdit: undefined,
      selected: undefined,
      createdAt: undefined,
      id: undefined,
    })
    .then((response) => response.data.data)
    .then((response) => response.jobOffer)
    .catch((error) => {
      return Promise.reject(error.response.data.message)
    })

const apiGetJobOffers = (filters?: string) => {
  try {
    return api
      .get<ApiPaginatedResponse<Job[]>>(`/api/v1/job-offers${filters || ''}`)
      .then((response) => response.data)
  } catch (e) {
    console.error(e)
  }
}

const apiGetPEJobOffers = (filters?: string) => {
  try {
    return api
      .get<ApiPaginatedResponse<PEJob[]>>(
        `/api/v1/poleemploi/jobs${filters || ''}`
      )
      .then((response) => response.data)
  } catch (e) {
    console.error(e)
  }
}

const apiGetCreatedJobOffers = () =>
  api
    .get<ApiPaginatedResponse<Job[]>>('/api/v1/user/job-offers/created')
    .then((response) => response.data)

const apiGetCompanyJobOffers = () =>
  api
    .get<ApiPaginatedResponse<Job[]>>('/api/v1/user/job-offers/company')
    .then((response) => response.data)

const apiGetUserJobOffers = () =>
  api
    .get<ApiPaginatedResponse<Job[]>>(`api/v1/user/job-offers`)
    .then((response) => response.data)

const apiLikeJobOffer = (id: string) =>
  api
    .post<ApiResponse<Job>>(`api/v1/user/job-offers/${id}/select`)
    .then((response) => response.data.data)

const apiGetKanban = (id: number) =>
  api
    .get<Jobboard>(`api/v1/user/job-offers/${id}`)
    .then((response) => response.data)

const apiUpdateKanban = (kanban: Jobboard) =>
  api
    .patch<ApiResponse<Jobboard>>(`api/v1/user/job-offers`, kanban)
    .then((response) => response.data)

const apiAddJobOffer = (data: Job) =>
  api
    .post<ApiResponse<Job>>(`/api/v1/job-offers`, data)
    .then((response) => response.data.data)
    .catch((error) => {
      return Promise.reject(error.response.data.message)
    })

const apiGetRecommendedJobOffers = (filters?: string) => {
  const apiRoute = `/api/v1/user/job-offers/recommended${filters || ''}`
  try {
    return api
      .get<ApiPaginatedResponse<Job[]>>(apiRoute)
      .then((response) => response.data)
  } catch (e) {
    console.error(e)
  }
}

const JobOfferService = {
  apiGetJobOffer,
  apiDeleteJobOffer,
  apiUpdateJobOffer,
  apiGetJobOffers,
  apiGetPEJobOffers,
  apiGetCreatedJobOffers,
  apiGetCompanyJobOffers,
  apiGetUserJobOffers,
  apiLikeJobOffer,
  apiGetKanban,
  apiUpdateKanban,
  apiAddJobOffer,
  apiGetRecommendedJobOffers,
}
export default JobOfferService
