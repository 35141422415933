import {
  addSeconds,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
} from 'date-fns'
import { useState } from 'react'

import { Widget as WidgetWrapper } from '~components'
import { Box, CardKeyNumber, Grid, Loader, Select, Text } from '~design-system'
import { useData } from '~hooks'

import { SelectContainer } from '../styles'

const KeyNumbersWidget = () => {
  // Define Date to Send to API for each filter
  const currentDate = new Date()
  const currentWeek = format(
    startOfWeek(currentDate, { weekStartsOn: 1 }),
    "yyyy-MM-dd'T'HH:mm:ss"
  )
  const currentMonth = format(
    addSeconds(startOfMonth(currentDate), 1), // We add 1 seconds to currentMonth to avoid to be the same as currentWeek/currentYear
    "yyyy-MM-dd'T'HH:mm:ss"
  )
  const currentHalfYear = format(
    subDays(currentDate, 182),
    "yyyy-MM-dd'T'HH:mm:ss"
  )
  const currentYear = format(
    addSeconds(startOfYear(currentDate), 2), // We add 2 seconds to currentMonth to avoid to be the same as currentWeek/currentMonth
    "yyyy-MM-dd'T'HH:mm:ss"
  )
  const allTime = format(new Date(2010, 1, 1), "yyyy-MM-dd'T'HH:mm:ss")

  // Define Select Option
  const availableFilters: FilterOption[] = [
    { label: 'Cette semaine', value: currentWeek },
    { label: 'Ce mois-ci', value: currentMonth },
    { label: 'Les 6 derniers mois', value: currentHalfYear },
    { label: "L'année en cours", value: currentYear },
    { label: 'Depuis le début', value: allTime },
  ]

  // Set Current Filter State
  const [filter, setFilter] = useState<string>(currentWeek)

  // Get KeyNumbers By Filters
  const { useGetKeyNumbers } = useData()
  const { data: keyNumbers, isLoading } = useGetKeyNumbers(filter)

  return (
    <WidgetWrapper
      title="Vos chiffres clés"
      rightComponent={
        <SelectContainer>
          <Select
            options={availableFilters}
            value={availableFilters.find((option) => option.value === filter)}
            onChange={(option) => setFilter(option?.value as string)}
          />
        </SelectContainer>
      }
    >
      {isLoading ? (
        <Loader isCentered />
      ) : !!keyNumbers?.length ? (
        <Box mb={8} di="f" fd="c" ai="fe" pos="relative">
          <Grid
            als="normal"
            rowGap={5}
            columnGap={5}
            templateColums="repeat(auto-fill, minmax(345px, 1fr))"
            gridAutoRows="1fr"
          >
            {keyNumbers?.map((card: KeyNumber) => (
              <CardKeyNumber
                key={card.id}
                keyNumber={card.value}
                title={card.label}
              />
            ))}
          </Grid>
        </Box>
      ) : (
        <Text>Aucun chiffre clé pour le moment</Text>
      )}
    </WidgetWrapper>
  )
}

export default KeyNumbersWidget
