import CategoriesBlock from './CategoriesBlock'
import Footer from './Footer'
import QuestionsBlock from './QuestionsBlock'
import QuestionsResults from './QuestionsResults'
import SearchBar from './SearchBar'

const Faq = {
  Footer,
  QuestionsBlock,
  SearchBar,
  CategoriesBlock,

  QuestionsResults,
}
export default Faq
