import { useMutation } from 'react-query'

import { UserService, queryClient } from '~api'

function useSponsorQualification() {
  const useUpdateSponsorQualification = () =>
    useMutation(UserService.apiUpdateSponsorQualification, {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
      },
    })

  return {
    useUpdateSponsorQualification,
  }
}

export default useSponsorQualification
