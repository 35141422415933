/* eslint-disable no-control-regex */
import * as yup from 'yup'

const boolean = yup.boolean().required('Le champs est requis!')
const email = yup.string().email().required('Le champs est requis!')
const password = yup.string().required('Le champs est requis!')
const text = yup.string().required('Le champs est requis!')

export type CredentialsValues = yup.InferType<typeof credentialsSchema>
export type HonorValues = yup.InferType<typeof honorSchema>
export type EmailValue = yup.InferType<typeof emailSchema>
export type PasswordValue = yup.InferType<typeof passwordSchema>
export type ProfessionValue = yup.InferType<typeof professionSchema>

export const birthdayRegex =
  /(?:0[1-9]|[12][0-9]|3[01])[/](?:0[1-9]|1[012])[/](?:19\d{2}|20[012][0-9])/

export const credentialsSchema = yup
  .object({
    email: email,
    password: password,
  })
  .defined()

export const emailSchema = yup
  .object({
    email: email,
  })
  .defined()

export const honorSchema = yup
  .object({
    honourDeclarationOptin: boolean,
  })
  .defined()

export const passwordSchema = yup
  .object({
    password: password,
  })
  .defined()

export const professionSchema = yup
  .object({
    profession: text,
  })
  .defined()
