import { useFormContext } from 'react-hook-form'

import { getParseBodyParams, getType } from '~components/Chat/helpers'
import { SectorSelector } from '~components/Selectors'
import { Box, Button } from '~design-system'

type Props = {
  dialog: Stepper
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const PicklistSubActivityArea = ({
  dialog,
  setAnswers,
  handleAnswer,
}: Props) => {
  const { getValues } = useFormContext()

  const answer = getType(dialog, 'picklist_sub_activity_area')[0]

  const handleSectorAnswer = (answer: Answer) => {
    setAnswers('Secteur et sous-secteur transmis')
    const id = Object.keys(getParseBodyParams(answer))[0]
    handleAnswer(answer.action, { [id]: getValues(id) })
  }

  return (
    <>
      <SectorSelector />
      <Box di="flex" jc="flex-end" fd="row">
        <Button
          onClick={() => handleSectorAnswer(answer)}
          disabled={!getValues('sector') || !getValues('domain')}
          title="valider"
        >
          Valider
        </Button>
      </Box>
    </>
  )
}

export default PicklistSubActivityArea
