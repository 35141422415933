import { isEqual } from 'lodash'
import styled, { css } from 'styled-components'

import { Box, ContextLink, Icon, Text } from '~design-system'
import { LinkText } from '~design-system/ContextLink/components/LinkText'
import { useWeatherModal } from '~hooks'

type CardInfoProps = {
  variant?: 'blue' | 'white'
  content: string
  illustration?: JSX.Element
  actionMessage: string
  upperMessage?: string
  route: string
  isChatInfo?: boolean
}

interface BackgroundImageProps {
  variant: CardInfoProps['variant']
}

const BackgroundImage = styled(Box)<BackgroundImageProps>`
  ${({ variant }) => css`
    background-image: ${() =>
      isEqual(variant, 'blue')
        ? 'url("/images/desktop-todo-blue.png")'
        : 'url("/images/desktop-todo.png")'};
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    background-color: transparent;
    border-radius: 10px;
    padding: 5px;
    position: relative;
  `}
`

const StyledText = styled(Text)`
  ${() => css`
    font-size: 18px;
  `}
`

const CardInfo = (props: CardInfoProps) => {
  const {
    variant = 'white',
    content,
    illustration,
    actionMessage,
    route,
    upperMessage = 'À faire',
  } = props

  // Modal is used for weather feeling in matches
  const matchId = route.split('weather/')[1]

  const modalActions = useWeatherModal(parseInt(matchId, 10))

  return (
    <ContextLink>
      <BackgroundImage mb="20px" variant={variant}>
        <Box ac="center" jc="sb" ai="center" pl="16px" di="flex" fd="row">
          <Box jc="sb" fd="column" w="80%">
            <Text textColor="yellow" textTransform="uppercase">
              {upperMessage}
            </Text>
            <StyledText>{content}</StyledText>
            <Box ai="center" mt="16px" di="flex" fd="row" as="button">
              <LinkText
                to={route}
                onClick={(e) => {
                  route.startsWith('weather') && e.preventDefault()
                  modalActions?.setOpen(true)
                }}
              >
                {actionMessage}
              </LinkText>
              <Icon color="primary" name="arrow-right" ml="5px" />
            </Box>
          </Box>
          {illustration && illustration}
        </Box>
      </BackgroundImage>
      {modalActions?.renderModal()}
    </ContextLink>
  )
}

export default CardInfo
