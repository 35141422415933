import { useFormContext } from 'react-hook-form'

import { EMAIL_REGEX } from '~config'
import { Box, InputText } from '~design-system'

const EditEmail = () => {
  // Define Form
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <Box w="100%">
      <InputText
        id="email"
        placeholder="Saisis ici ton adresse mail"
        label="Adresse mail"
        type="email"
        autoFocus
        errorMessage={errors?.email?.message}
        {...register('email', {
          pattern: EMAIL_REGEX,
        })}
      />
    </Box>
  )
}

export default EditEmail
