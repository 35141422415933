import styled from 'styled-components'

import { Box, Icon, Panel, SmallText, Text } from '~design-system'

interface Props {
  icon?: string
  metadata: string
  metadataIcon?: string
  title: string
}

const ImageBox = styled(Box)`
  flex-shrink: 0;
  height: 34px;
  width: 34px;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`

const Card = ({ icon, metadata, metadataIcon, title }: Props) => (
  <Panel h="100%">
    <Box ai="ce" di="f" h="100%">
      <ImageBox>{icon && <img src={icon} alt="" />}</ImageBox>
      <Box pl={3}>
        <Text>{title}</Text>
        <Box di="f" ai="b">
          {metadataIcon && <Icon color="primary" name={metadataIcon} mr={2} />}
          <SmallText textColor="greyDarkest">{metadata}</SmallText>
        </Box>
      </Box>
    </Box>
  </Panel>
)

export default Card
