import { Fragment, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { LanguagesPopin } from '~components/Chat/components'
import { getParseBodyParams, getType } from '~components/Chat/helpers'
import { Button, Label, SmallText } from '~design-system'

type Props = {
  dialog: Stepper
  setAnswers: (answer: string[]) => void
  handleAnswer: Function
}

const PicklistLanguages = ({ dialog, setAnswers, handleAnswer }: Props) => {
  const { setValue, getValues } = useFormContext()
  const [isLanguagesPopinOpen, setIsLanguagesPopinOpen] = useState(false)
  const answer = getType(dialog, 'picklist_languages')[0]
  const formattedAnswer = {
    ...answer,
    bodyparam: answer.bodyparam?.replace('langue', 'languages'),
  }

  const handleLanguagesOptions = (answer: Answer, languages: Language[]) => {
    const shortLanguages = languages.map(({ name }) => name)
    setAnswers(shortLanguages)
    const id = Object.keys(getParseBodyParams(answer))[0]
    handleAnswer(answer.action, { [id]: getValues(id) })
  }

  return (
    <Fragment key={formattedAnswer.label}>
      <Label mb={2}>Choisis une option</Label>
      <Button
        fullWidth
        rightComponent={<SmallText textColor="blueDark">+</SmallText>}
        option="dashed"
        variant="secondary"
        data-testid="languages-open"
        onClick={() => setIsLanguagesPopinOpen(true)}
      >
        {formattedAnswer.label}
      </Button>
      <LanguagesPopin
        onClose={() => setIsLanguagesPopinOpen(false)}
        onSubmit={(languages: Language[]) => {
          setValue('languages', languages)
          handleLanguagesOptions(formattedAnswer, languages)
        }}
        open={isLanguagesPopinOpen}
      />
    </Fragment>
  )
}

export default PicklistLanguages
