import { useCallback, useMemo, useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

import { DegreeForm } from '~components/EditPopIns/forms'
import { Box, Button, Header2, Icon, Text } from '~design-system'
import { useUser } from '~hooks'

import { UploadPopinProps } from '..'

type DiplomaPayload = {
  nom_diplome: string
  annee_obtention: string
  nom_etablissement: string
  degree: string
}

const Diploma = ({ onClose, onSubmit }: UploadPopinProps) => {
  const { handleSubmit } = useForm<DiplomaPayload>({
    mode: 'onChange',
  })
  const {
    getValues,
    reset,
    formState: { isValid, isSubmitting },
  } = useFormContext()
  const { useUploadDocument } = useUser()
  const { mutateAsync: uploadDoc, isLoading } = useUploadDocument()
  const [process, setProcess] = useState<'add' | 'summary'>('summary')
  const [diploma, setDiploma] = useState<DiplomaPayload[]>([])

  const handleShowForm = () => {
    setProcess('add')
    reset()
  }
  const handleShowSummary = () => setProcess('summary')

  const handleDeleteDiploma = useCallback(
    (index: number) => {
      const newDiploma = [...diploma]
      newDiploma.splice(index, 1)
      setDiploma(newDiploma)
    },
    [setDiploma, diploma]
  )

  const renderDiploma = useMemo(
    () =>
      diploma ? (
        <Box mb={2}>
          {diploma.map(
            ({ nom_diplome, annee_obtention }: any, index: number) => (
              <Button
                fullWidth
                mb={2}
                key={`selected-diploma-${index}`}
                onClick={() => handleDeleteDiploma(index)}
              >
                <Box ai="ce" di="f" fg={1} jc="space-between">
                  <Text textColor="white">
                    {nom_diplome} - {annee_obtention}
                  </Text>
                  <Icon color="white" name="close" size="small" />
                </Box>
              </Button>
            )
          )}
        </Box>
      ) : null,
    [diploma, handleDeleteDiploma]
  )

  const onFormSubmit = () => {
    setDiploma([...diploma, getValues() as DiplomaPayload])
    handleShowSummary()
  }

  const onUpload = async () => {
    try {
      const diplomaQueue: any = []
      diploma.map((item) => diplomaQueue.push(uploadDoc(item)))
      await Promise.all(diplomaQueue)
      onSubmit(diploma.map((di) => di.nom_diplome))
      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Header2 mb={4}>Ajouter un diplôme</Header2>
      <Text mb={8} textColor="greyDarkest">
        Prépare tes diplômes de plus haut niveau (Bac+3 ou supérieur)
      </Text>
      <>
        {process === 'summary' && (
          <Box>
            {renderDiploma}
            <Button
              fullWidth
              option="dashed"
              variant="secondary"
              onClick={handleShowForm}
              mb={4}
            >
              <Box di="f" fg={1} jc="space-between">
                Ajouter un diplome
                <Icon color="primary" name="add" />
              </Box>
            </Button>
            <Box>
              <Button
                onClick={onUpload}
                disabled={diploma.length === 0 || isLoading}
                isLoading={isLoading}
              >
                Valider
              </Button>
              <Button option="unfilled" onClick={onClose}>
                Annuler
              </Button>
            </Box>
          </Box>
        )}
        {process === 'add' && (
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Box>
              <DegreeForm />
            </Box>

            <Box>
              <Box di="f" mt={6}>
                <Button
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  isLoading={isSubmitting}
                >
                  Valider
                </Button>
                <Text
                  as="button"
                  color="greyDarkest"
                  onClick={handleShowSummary}
                >
                  Compléter plus tard
                </Text>
              </Box>
            </Box>
          </form>
        )}
      </>
    </>
  )
}

export default Diploma
