import { Fragment, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { SkillsPopin } from '~components/Chat/components'
import { getParseBodyParams, getType } from '~components/Chat/helpers'
import { Button, Label, SmallText } from '~design-system'

type Props = {
  dialog: Stepper
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const PicklistMultipleSkills = ({
  dialog,
  setAnswers,
  handleAnswer,
}: Props) => {
  const { getValues } = useFormContext()

  const [isSkillsPopinOpen, setIsSkillsPopinOpen] = useState(false)
  const answer = getType(dialog, 'picklist_multiple_skills')[0]

  const handleSkillsAnswer = (answer: Answer) => {
    setAnswers('Thématiques ajoutées')
    const id = Object.keys(getParseBodyParams(answer))[0]
    handleAnswer(answer.action, { [id]: getValues(id) })
  }

  return (
    <Fragment key={answer.label}>
      <Label mb={2}>Choisis une option</Label>
      <Button
        fullWidth
        option="dashed"
        rightComponent={<SmallText textColor="blueDark">+</SmallText>}
        variant="secondary"
        key={answer.label}
        data-testid="skills-open"
        onClick={() => setIsSkillsPopinOpen(true)}
      >
        {answer.label}
      </Button>
      {isSkillsPopinOpen && (
        <SkillsPopin
          onClose={() => setIsSkillsPopinOpen(false)}
          onSubmit={() => {
            handleSkillsAnswer(answer)
          }}
        />
      )}
    </Fragment>
  )
}

export default PicklistMultipleSkills
