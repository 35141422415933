import { JobList, Widget as WidgetWrapper } from '~components'
import { routes } from '~config'
import { Loader, Text } from '~design-system'
import { useJob } from '~hooks'

const JobWidget = () => {
  const { useGetJobOffers } = useJob()
  const { data: jobs, isLoading } = useGetJobOffers('?limit=4')

  return (
    <WidgetWrapper
      title="Offres d'emploi"
      url={jobs?.data?.length ? routes.authenticated.jobs : undefined}
    >
      {isLoading ? (
        <Loader isCentered />
      ) : !!jobs?.data?.length ? (
        <JobList jobs={jobs?.data} />
      ) : (
        <Text>Aucune offre d'emploi pour le moment</Text>
      )}
    </WidgetWrapper>
  )
}

export default JobWidget
