import { useQuery } from 'react-query'

import { UserService } from '~api'

function useSponsor() {
  const useGetSponsor = (id: string) =>
    useQuery(['sponsor', id], () => UserService.apiGetSponsor(id))

  return {
    useGetSponsor,
  }
}

export default useSponsor
