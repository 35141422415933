/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 2
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/appnqt/dev/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/appnqt/dev/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'dev';

export const ApiKey: Record<Environment, string> = {
  dev: '8ad48b20e4ffda922e75dc231aa0f678'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '2',
    branch: 'main',
    source: 'web',
    versionId: '04d93bc4-0dc3-4860-a19d-69620317b9ee'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface ChatSentMessageProperties {
  send_to_user_id: string;
  thread_id: string;
}

export interface EventSubscribedProperties {
  event_id: string;
}

export interface OfferViewedProperties {
  offer_id: string;
}

export interface PrematchingValidatedProperties {
  parrain_id: string;
  score: string;
}

export interface ServiceViewedProperties {
  service_id: string;
}

export class BoardViewed implements BaseEvent {
  event_type = 'BoardViewed';
}

export class ChatSentMessage implements BaseEvent {
  event_type = 'ChatSentMessage';

  constructor(
    public event_properties: ChatSentMessageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventSubscribed implements BaseEvent {
  event_type = 'EventSubscribed';

  constructor(
    public event_properties: EventSubscribedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventsViewed implements BaseEvent {
  event_type = 'EventsViewed';
}

export class OfferViewed implements BaseEvent {
  event_type = 'OfferViewed';

  constructor(
    public event_properties: OfferViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrematchingValidated implements BaseEvent {
  event_type = 'PrematchingValidated';

  constructor(
    public event_properties: PrematchingValidatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProposalsViewed implements BaseEvent {
  event_type = 'ProposalsViewed';
}

export class ServicesViewed implements BaseEvent {
  event_type = 'ServicesViewed';
}

export class ServiceViewed implements BaseEvent {
  event_type = 'ServiceViewed';

  constructor(
    public event_properties: ServiceViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * BoardViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/appnqt/dev/events/main/latest/BoardViewed)
   *
   * Viewed job board
   *
   * @param options Amplitude event options.
   */
  boardViewed(
    options?: EventOptions,
  ) {
    return this.track(new BoardViewed(), options);
  }

  /**
   * ChatSentMessage
   *
   * [View in Tracking Plan](https://data.amplitude.com/appnqt/dev/events/main/latest/ChatSentMessage)
   *
   * Records when a new message is sent in a chat conversation
   *
   * @param properties The event's properties (e.g. send_to_user_id)
   * @param options Amplitude event options.
   */
  chatSentMessage(
    properties: ChatSentMessageProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChatSentMessage(properties), options);
  }

  /**
   * EventSubscribed
   *
   * [View in Tracking Plan](https://data.amplitude.com/appnqt/dev/events/main/latest/EventSubscribed)
   *
   * Subscribed to an event
   *
   * @param properties The event's properties (e.g. event_id)
   * @param options Amplitude event options.
   */
  eventSubscribed(
    properties: EventSubscribedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventSubscribed(properties), options);
  }

  /**
   * EventsViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/appnqt/dev/events/main/latest/EventsViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  eventsViewed(
    options?: EventOptions,
  ) {
    return this.track(new EventsViewed(), options);
  }

  /**
   * OfferViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/appnqt/dev/events/main/latest/OfferViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. offer_id)
   * @param options Amplitude event options.
   */
  offerViewed(
    properties: OfferViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OfferViewed(properties), options);
  }

  /**
   * PrematchingValidated
   *
   * [View in Tracking Plan](https://data.amplitude.com/appnqt/dev/events/main/latest/PrematchingValidated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. parrain_id)
   * @param options Amplitude event options.
   */
  prematchingValidated(
    properties: PrematchingValidatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrematchingValidated(properties), options);
  }

  /**
   * ProposalsViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/appnqt/dev/events/main/latest/ProposalsViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  proposalsViewed(
    options?: EventOptions,
  ) {
    return this.track(new ProposalsViewed(), options);
  }

  /**
   * ServicesViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/appnqt/dev/events/main/latest/ServicesViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  servicesViewed(
    options?: EventOptions,
  ) {
    return this.track(new ServicesViewed(), options);
  }

  /**
   * ServiceViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/appnqt/dev/events/main/latest/ServiceViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. service_id)
   * @param options Amplitude event options.
   */
  serviceViewed(
    properties: ServiceViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ServiceViewed(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
