import {
  compareAsc,
  format,
  formatISO,
  isBefore,
  isFuture,
  isValid,
  parse,
  parseISO,
} from 'date-fns'
import isSameDay from 'date-fns/isSameDay'
import { fr } from 'date-fns/locale'

/**
 * Default formater in app: month then year.
 */
const DEFAULT_FORMATER = 'MMM yyyy'

/**
 * Formats and returns the date parameter in the correct formater.
 * Returns an empty string if the `date` parameter is not valid
 * as a Date initializer.
 * @param date the date to format
 * @param formater the format, default is [[DEFAULT_FORMATER]]
 * @returns the format based on `date` and `formater` parameters,
 * or an empty string if the `date` parameter is empty.
 */
export const formatDateFromStringToString = (
  date: string,
  formater = DEFAULT_FORMATER
): string => (date ? format(new Date(date), formater, { locale: fr }) : '')

/**
 * Formats and returns the date parameter in the correct formater.
 * Returns an empty string if the `date` parameter is not valid
 * as a Date initializer.
 * @param date the date to format
 * @param formater the format, default is [[DEFAULT_FORMATER]]
 * @returns the format based on `date` and `formater` parameters,
 * or an empty string if the `date` parameter is empty.
 */
export const formatIsoTo = (
  date: string,
  formater = DEFAULT_FORMATER
): string => (date ? format(parseISO(date), formater, { locale: fr }) : '')

/**
 * Formats and returns the date parameter in the correct formater.
 * Returns an empty string if the `date` parameter is not valid
 * as a Date initializer.
 * @param date the date to format
 * @param formater the format, default is [[DEFAULT_FORMATER]]
 * @returns the format based on `date` and `formater` parameters,
 * or an empty string if the `date` parameter is empty.
 */
export const isSameIsoDay = (date: string, date2: string): boolean =>
  isSameDay(parseISO(date), parseISO(date2))

/**
 * Compare the two dates and return 1 if the start
 * date is after the second, -1 if the first date
 * is before the second or 0 if dates are equal.
 * If an error happens, throws a TypeError exception.
 * @param startDate the date which is, normally, before or the same than `endDate`.
 * @param endDate the date which is, normally, after or the same than `startDate`.
 * @returns a number (-1, 0, or 1).
 * @throws TypeError, if one of the two strings is empty, or if the
 * `compareAsc` function returns the exception when comparing the two dates.
 */
export const compareDates = (
  startDate: string,
  endDate: string
): number | undefined => {
  if (!startDate || !endDate) {
    throw new TypeError('parameters are empty')
  }

  return compareAsc(new Date(startDate), new Date(endDate))
}

/**
 * Returns if the date, as parameter, is greater than `now` or not.
 * If an error happens, throws a TypeError exception.
 * @param date a string that represents a date.
 * @returns `true` if the date is after than `now`, else otherwise.
 * @throws TypeError, if the parameter string is empty.
 */
export const isFutureDate = (date: string): boolean => {
  if (!date) {
    throw new TypeError('parameter is empty')
  }

  return isFuture(new Date(date))
}

/**
 * Returns if the year, as parameter, is greater than `now` or not.
 * @param date a number which represents a year (like `2008`).
 * @returns `true` if the date is after than `now`, else otherwise.
 */
export const isFutureYear = (year: number): boolean => {
  const todayYear = new Date().getFullYear()

  return year > todayYear
}

/**
 * Returns false if argument is Invalid Date and true otherwise.
 * @param date a string that represents a date.
 * @returns false if argument is Invalid Date, true otherwise
 */
export const isValidDate = (date: string): boolean => {
  if (!date) {
    return false
  }

  return isValid(new Date(date))
}

export const convertDayTimeToIsoDate = (date: string, time: string) => {
  return formatISO(parse(`${date} ${time} `, 'dd/MM/yyyy HH:mm', new Date()))
}

export const convertDayToIsoDate = (date: string) => {
  return formatISO(parse(`${date}`, 'dd/MM/yyyy', new Date()))
}

export const isDayBeforeNow = (date: string) => {
  return isBefore(parseISO(date), new Date())
}
