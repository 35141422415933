import { ReactNode } from 'react'

import { Stepper } from '~components'
import { Box, Header2, Panel, Text } from '~design-system'

type Props = {
  activeStep?: number
  children: ReactNode
  subtitle?: string
  title: string
}
const UploadPanel = ({ title, subtitle, activeStep = 1, children }: Props) => {
  return (
    <Box pl={2} pr={2} maxW={192} w="100%">
      <Panel>
        <Box>
          <Stepper activeStep={activeStep} totalStep={2} mb={5} />

          <Header2 mb={4}>{title}</Header2>
          {subtitle && (
            <Box mb={2}>
              <Text>{subtitle}</Text>
            </Box>
          )}
          <Box di="f" jc="space-between">
            {children}
          </Box>
        </Box>
      </Panel>
    </Box>
  )
}

export default UploadPanel
