import styled from 'styled-components'

import { theme } from '~config'

const SidebarLine = styled('div')`
  padding: ${theme.spaces[4]} ${theme.spaces[6]};
  border-bottom: 1px solid ${theme.colors.blueLighter};
  display: flex;
  &:last-child {
    border-bottom: 0;
  }
`

export default SidebarLine
