import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface Props {
  children: ReactNode
}

const Circle = styled('div')`
  ${({ theme }) => css`
    border-radius: 50%;
    border: 1px solid ${theme.colors.blue};
    position: absolute;
    top: 0;
  `}
`

const LeftCircle = styled(Circle)`
  height: 250px;
  left: 0;
  transform: translate(-128px, -128px);
  width: 250px;
  @media (max-width: 500px) {
    display: none;
  }
`
const RightCircle = styled(Circle)`
  height: 376px;
  right: 0;
  transform: translate(104px, -104px);
  width: 376px;
  @media (max-width: 500px) {
    display: none;
  }
`

const Wrapper = styled('main')`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.primary};
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    flex-direction: column;
    position: fixed;
    top: 0;
    width: 100%;
    padding-block: 30px;
    padding-inline: 15px;
    z-index: ${theme.zIndex.layout.informations};
    overflow: hidden;
  `}
`

const Informations = ({ children }: Props) => (
  <Wrapper>
    <LeftCircle />
    <RightCircle />
    {children}
  </Wrapper>
)

export default Informations
