import { FormEvent, useMemo, useState } from 'react'

import api from '~api/api'
import { useToast } from '~context/ToastContext'
import { Box, Button, Icon, PopIn, Select, Text } from '~design-system'
import { Input } from '~design-system/InputBase/styles'
import { isReferent, isSponsor, isYoung } from '~helpers'

interface Props {
  idToShare: string
  type: 'event' | 'job'
  user: User
  companySponsors?: CompanySponsors
}

const ShareButton = ({ idToShare, type, user, companySponsors }: Props) => {
  const { addToast } = useToast()
  const [showSharePopin, setShowSharePopin] = useState<boolean>(false)
  const [isSharing, setIsSharing] = useState<boolean>(false)
  const [canSubmit, setCanSubmit] = useState<boolean>(
    isYoung(user) ? true : false
  )

  // Set Matching List
  const matchsList = useMemo<
    { label: string; value: string }[] | undefined
  >(() => {
    const listToUse = isReferent(user) ? companySponsors : user.matchs

    return !isYoung(user)
      ? listToUse?.map((match) => ({
          label: match.fullName,
          value: match.id.toString(),
        }))
      : undefined
  }, [companySponsors, user])

  // API Call on Validate
  const handleShare = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsSharing(true)
    const userID = event.currentTarget.user_id.value
    const apiRoute =
      type === 'job'
        ? `/api/v1/job-offers/${idToShare}/share`
        : `/api/v2/events/${idToShare}/share`

    try {
      await api.post(apiRoute, { user_id: userID })
      setShowSharePopin(false)
      addToast({
        title:
          type === 'job'
            ? "L'offre d'emploi a bien été partagée"
            : "L'évènement a bien été partagé",
        type: 'success',
      })
    } catch (error: any) {
      console.error(error)
    }
    setIsSharing(false)
  }

  return (
    <>
      <Button
        pr={1}
        pl={1}
        size="small"
        option="unfilled"
        onClick={() => setShowSharePopin(true)}
        aria-label="partager"
      >
        <Icon name="share" color="primary" size="giant" />
      </Button>
      {showSharePopin && (
        <PopIn open closable onClose={() => setShowSharePopin(false)}>
          <PopIn.Title textAlign="left">{`Partager ${
            type === 'event' ? "l'évènement" : "l'offre d'emploi"
          }`}</PopIn.Title>
          <form onSubmit={handleShare}>
            <PopIn.Content>
              <Box w="100%">
                <Text textColor="greyDarkest" textAlign="left" mt={6}>
                  {!isYoung(user)
                    ? `Vous êtes sur le point d'envoyer ${
                        type === 'job'
                          ? "cette offre d'emploi"
                          : 'cet évènement'
                      } à l'un  ${
                        isSponsor(user) ? 'de vos jeunes' : 'des parrains'
                      }. Souhaitez-vous réaliser cette action ?`
                    : `Tu es sur le point d'envoyer ${
                        type === 'job'
                          ? "cette offre d'emploi"
                          : 'cet évènement'
                      } à ton
                    parrain. Souhaites-tu réaliser cette action ?`}
                </Text>

                {!isYoung(user) ? (
                  <>
                    <Text weight="medium" mt={4}>
                      {`Sélectionner un ${
                        isSponsor(user) ? 'jeune' : 'parrain'
                      }`}
                    </Text>
                    <Box mt={4}>
                      <Select
                        name="user_id"
                        inputId="young"
                        placeholder={`Sélectionner un ${
                          isSponsor(user) ? 'jeune' : 'parrain'
                        }...`}
                        isSearchable={false}
                        options={matchsList}
                        onChange={(e) => e?.value && setCanSubmit(true)}
                      />
                    </Box>
                  </>
                ) : (
                  <Input
                    name="user_id"
                    value={user.matchs[0].id.toString()}
                    type="hidden"
                  />
                )}
              </Box>
            </PopIn.Content>
            <PopIn.Actions
              actions={[
                <Button
                  key="cancel"
                  variant="secondary"
                  disabled={isSharing}
                  onClick={() => setShowSharePopin(false)}
                >
                  Annuler
                </Button>,
                <Button
                  type="submit"
                  key="save"
                  disabled={!canSubmit}
                  isLoading={isSharing}
                >
                  Valider
                </Button>,
              ]}
            />
          </form>
        </PopIn>
      )}
    </>
  )
}

export default ShareButton
