import { useState } from 'react'

import { GenericEditPopIn } from '~components'
import DegreesPopIn from '~components/EditPopIns/Degrees'
import ExperiencesPopIn from '~components/EditPopIns/Experiences'
import LanguagesPopIn from '~components/EditPopIns/Languages'
import { Box, Panel } from '~design-system'

import { LeftPanelContent, LeftPanelHeader, RightPanel } from './layout'
import { Container } from './styles'

interface Props {
  isEditable?: boolean
  user: User
}

const Profile = ({ isEditable = false, user }: Props) => {
  const [editInfos, setEditInfos] = useState<EditInfos>()

  const showPopin = () => {
    if (editInfos) {
      switch (editInfos.slug) {
        case 'degrees':
          return (
            <DegreesPopIn
              onClose={() => setEditInfos(undefined)}
              data={editInfos}
            />
          )
        case 'experiences':
          return (
            <ExperiencesPopIn
              onClose={() => setEditInfos(undefined)}
              data={editInfos}
            />
          )
        case 'languages':
          return (
            <LanguagesPopIn
              onClose={() => setEditInfos(undefined)}
              data={editInfos}
            />
          )
        default:
          return (
            <GenericEditPopIn
              onClose={() => setEditInfos(undefined)}
              data={editInfos}
            />
          )
      }
    }
  }

  return (
    user && (
      <Box di="f" fw="w" g="3rem">
        <Container f={1}>
          <Panel>
            <LeftPanelHeader
              user={user}
              isEditable={isEditable}
              handleEditInfos={setEditInfos}
            />
            <LeftPanelContent
              user={user}
              isEditable={isEditable}
              handleEditInfos={setEditInfos}
            />
          </Panel>
        </Container>
        <RightPanel
          user={user}
          isEditable={isEditable}
          handleEditInfos={setEditInfos}
        />
        {editInfos && showPopin()}
      </Box>
    )
  )
}

export default Profile
