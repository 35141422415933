import { Controller, useFormContext } from 'react-hook-form'

import { useAuth } from '~context/AuthContext'
import { Box, Dropzone, Icon, Label, Text } from '~design-system'
import { isYoung } from '~helpers'

const EditAvatar = () => {
  // Define Form
  const { control } = useFormContext()
  const {
    authState: { user },
  } = useAuth()

  return (
    <Box w="100%">
      <Controller
        control={control}
        name="profilePhoto"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <>
            <Label>Photo de profil</Label>
            <Dropzone
              multiple={false}
              accept="image/jpeg,image/png"
              file={value ? [value] : undefined}
              setFile={(val?: string[] | null[]) =>
                onChange(val ? val[0] : undefined)
              }
            >
              <Box di="f" fd="column" ai="center" js="center">
                <Icon name="camera" color="primary" />
                <Text textColor="primary">{`${
                  !isYoung(user) ? 'Importez votre' : 'Importe ta'
                } photo de profil`}</Text>
              </Box>
            </Dropzone>
          </>
        )}
      />
    </Box>
  )
}

export default EditAvatar
