import { useFormContext } from 'react-hook-form'

import { Box, Textarea } from '~design-system'

const EditDescription = () => {
  // Define Form
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()

  return (
    <Box w="100%">
      <Textarea
        id="description"
        placeholder="Saisis ici ta description"
        label="Description"
        autoFocus
        errorMessage={errors?.description?.message}
        {...register('description', { required: true })}
      >
        {watch('description')}
      </Textarea>
    </Box>
  )
}

export default EditDescription
