import styled, { css } from 'styled-components'

import { theme } from '~config'

type Props = { isError?: boolean }

const StyledTextarea = styled('textarea').attrs({ as: 'textarea' })<Props>`
  ${({ isError }) => css`
    border-radius: ${theme.radii.lg};
    color: ${theme.colors.black};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.components.smallText.fontSize};
    line-height: 1.5;
    max-width: 100%;
    min-height: 200px;
    padding: ${theme.spaces[5]} ${theme.spaces[5]};
    width: 100%;

    &::placeholder {
      font-size: ${theme.components.smallText.fontSize};
      color: ${theme.colors.greyDarkest};
    }

    &:focus {
      border-color: ${isError ? theme.colors.danger : theme.colors.primary};
    }

    &::placeholder {
      font-size: ${theme.components.smallText.fontSize};
      color: ${theme.colors.greyDarkest};
    }

    &:disabled {
      background-color: ${theme.colors.greyLightest};
      border-color: ${theme.colors.greyLightest};
      min-height: auto;
    }
  `}
`

export default StyledTextarea
