import { Ref, forwardRef } from 'react'

import { Box } from '~design-system'
import { InputBaseProps } from '~design-system/InputBase'

import { SwitchInput, SwitchLabel, SwitchText } from './styles'

type Props = {
  leftText?: string
  rightText?: string
  label: string
} & InputBaseProps

const Switch = forwardRef(
  (
    { leftText, rightText, label, ...props }: Props,
    ref: Ref<HTMLInputElement>
  ) => {
    const isWithText = !!leftText && !!rightText

    return (
      <Box di="f" g="10px" ai="ce">
        <SwitchInput ref={ref} type="checkbox" {...props} aria-label={label} />
        <SwitchLabel htmlFor={props.id} withText={isWithText} />
        {leftText && rightText && (
          <>
            <SwitchText or={0} weight="medium">
              {leftText}
            </SwitchText>
            <SwitchText or={3} weight="medium">
              {rightText}
            </SwitchText>
          </>
        )}
      </Box>
    )
  }
)

export default Switch
