import styled, { css } from 'styled-components'

const Sidebar = styled('div')`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.blueLight};
    border-radius: 8px;
    width: 100%;
    padding: ${theme.spaces[10]};
  `}
`

export default Sidebar
