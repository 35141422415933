import styled, { Theme, css } from 'styled-components'

type PathProps = {
  pathColor?: keyof Theme['colors']
}

const Path = styled('path').attrs((props: PathProps) => ({
  pathColor: props.pathColor || 'white',
}))<PathProps>`
  ${({ pathColor, theme }) => css`
    stroke: ${theme.colors[pathColor]};
    stroke-width: 1px;
  `}
`

export default Path
