import styled, { css } from 'styled-components'

const Container = styled('div')`
  ${({ theme }) => css`
    max-width: ${theme.pxToRem(320)};
    margin: 32px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > p {
      text-align: center;
    }
  `}
`

export { Container }
