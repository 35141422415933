import { EditIcon } from '~components'
import { Box, Panel, SmallText, Text } from '~design-system'

interface Props {
  degree: Degree
  isEditable?: boolean
  setEditInfos: Function
}

const DegreeCard = ({ degree, isEditable = false, setEditInfos }: Props) => {
  const { etablishmentName, name, yearOfGraduation } = degree

  return (
    <Panel pb={4} pt={4}>
      <Box di="f" ai="ce" jc="sb">
        <Box>
          <Text weight="medium">{name}</Text>
          <SmallText>{etablishmentName}</SmallText>
          <SmallText textColor="greyDarkest">{yearOfGraduation}</SmallText>
        </Box>
        <EditIcon
          isVisible={isEditable}
          label="Modifier votre diplôme"
          onClick={() =>
            setEditInfos({
              slug: 'degrees',
              title: 'Modifier votre diplôme',
              data: degree,
            })
          }
        />
      </Box>
    </Panel>
  )
}

export default DegreeCard
