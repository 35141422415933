import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const PaginationItem = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.radii.sm};
    border: 1px solid ${theme.colors.greyLighter};
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

export default PaginationItem
