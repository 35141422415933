import { useFormContext } from 'react-hook-form'

import { Checkbox } from '~design-system'

import { Container } from '../styles'

interface Props {
  name: 'hobbies' | 'skills'
  options: Hobbies[] | Skills[]
}

const ChecklistSelector = ({ options, name }: Props) => {
  const { register, watch } = useFormContext()

  return (
    <Container maxH={96} overflow="auto" w="100%">
      {options?.map((option: Hobbies | Skills) => (
        <Checkbox
          key={option.id}
          value={option.id}
          id={option.id.toString()}
          label={option.name}
          transparent
          disabled={
            watch(name)?.length >= 5 &&
            !watch(name)?.includes(option.id.toString())
          }
          {...register(name, { required: true })}
        />
      ))}
    </Container>
  )
}

export default ChecklistSelector
