import { Rating } from 'react-simple-star-rating'

import { theme } from '~config'
import { Box, Text } from '~design-system'
import { DisplayProps, SpacingProps } from '~design-system/helpers'

type RatingStarsProps = {
  rating: RatingsSurveyNumbers
  onChange: Function
  title?: string
  readonly?: boolean
} & SpacingProps &
  DisplayProps

const RatingStars = ({
  readonly = false,
  rating,
  onChange,
  title,
  ...props
}: RatingStarsProps) => {
  return (
    <Box {...props}>
      {title && <Text mb={1}>{title}</Text>}
      <Rating
        onClick={(rate: number) => onChange(rate / 20)}
        ratingValue={(rating as number) * 20}
        readonly={readonly}
        size={30}
        fillColor={theme.colors.blue}
        emptyColor={theme.colors.grey}
        transition
        allowHover
      />
    </Box>
  )
}

export default RatingStars
