import { useState } from 'react'

import { Box, Button, PopIn } from '~design-system'
import { DisplayProps } from '~design-system/helpers'
import { useUser } from '~hooks'

const ButtonSponsor = ({ ...rest }: DisplayProps) => {
  const { useReferentBecomeSponsor } = useUser()
  const { mutate: becomeSponsor, isLoading } = useReferentBecomeSponsor()
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  const onBecomeSponsor = () => {
    try {
      becomeSponsor()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Box als="ce" mt={6} mb={6} {...rest}>
        <Button
          size="small"
          forceOneLine
          onClick={() => setShowConfirmation(true)}
        >
          Devenir parrain
        </Button>
      </Box>
      {showConfirmation && (
        <PopIn open closable onClose={() => setShowConfirmation(false)}>
          <PopIn.Title textAlign="center">
            Êtes-vous sûr de vouloir devenir parrain ?
          </PopIn.Title>
          <PopIn.Actions
            actions={[
              <Button
                key="cancel"
                variant="secondary"
                onClick={() => setShowConfirmation(false)}
                disabled={isLoading}
              >
                Non, revenir en arrière
              </Button>,
              <Button
                key="save"
                onClick={onBecomeSponsor}
                isLoading={isLoading}
              >
                Oui, devenir parrain
              </Button>,
            ]}
          />
        </PopIn>
      )}
    </>
  )
}

export default ButtonSponsor
