import { useMutation, useQuery } from 'react-query'

import { NotificationService, queryClient } from '~api'

function useNotifications() {
  const useGetNotifications = (filters?: string) =>
    useQuery(['notifications', filters], () =>
      NotificationService.apiGetNotifications(filters)
    )

  const useDeleteNotification = () =>
    useMutation(NotificationService.apiDeleteNotification, {
      onSuccess: () => {
        queryClient.invalidateQueries(['notifications'])
        queryClient.invalidateQueries(['unread-notifications'])
      },
    })

  const useMarkAsReadNotification = () =>
    useMutation(NotificationService.apiMarkAsReadNotification, {
      onSuccess: () => {
        queryClient.invalidateQueries(['unread-notifications'])
      },
    })

  const useHasUnreadNotifications = () =>
    useQuery(
      ['unread-notifications'],
      NotificationService.apiHasUnreadNotifications
    )

  return {
    useGetNotifications,
    useDeleteNotification,
    useMarkAsReadNotification,
    useHasUnreadNotifications,
  }
}

export default useNotifications
