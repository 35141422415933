import { ReactNode } from 'react'

import Icon from '~design-system/Icon'

import Box from '../Box'
import { Container, MessageText } from './styles'
import { format } from 'date-fns';

interface Props {
  children: Message['body'] | ReactNode
  variant?: 'answer' | 'question'
  attachment?: Message['attachment']
  timestamp?: string
}

const Message = ({ children, variant = 'question', attachment, timestamp = '' }: Props) => {
  const isAnswer = variant === 'answer'
  let formattedDate = ''
  if (timestamp) {
    const date = new Date(timestamp);
    formattedDate = format(date, 'dd/MM/yyyy HH:mm');
  }

  return (
    <Box
      di="f"
      ai="flex-start"
      fg={1}
      jc={isAnswer ? 'end' : 'start'}
      fd={isAnswer ? 'row-reverse' : 'row'}
    >
      <div>
        <Container variant={variant}>

          {typeof children === 'string' ? (
            //{formattedDate}
            <MessageText isAnswer={isAnswer}>{children}</MessageText>
          ) : (
            children
          )}

        </Container>
        <span style={{ display: 'block', textAlign: 'right', color: 'grey', fontSize: '12px'}}>{formattedDate}</span>
      </div>

      {
        attachment && (
          <Container variant={variant} isAttachment>
            <a href={attachment.url} target="_blank" download rel="noreferrer">
              <Icon
                name="download"
                size="small"
                color={isAnswer ? 'white' : 'black'}
              />
            </a>
          </Container>
        )}
    </Box>
  )
}

export default Message
