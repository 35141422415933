import { createGlobalStyle, css } from 'styled-components'

const MarkdownCSS = createGlobalStyle`
  ${({ theme }) => css`
    .markdown-render {
      h1 {
        font-size: 2em;
        margin: .67em 0 
      }
      
      h2 { 
        font-size: 1.5em; 
        margin: .75em 0 
      }
      
      h3 {
        font-size: 1.17em;
        margin: .83em 0 
      }

      h4 { 
        margin: 1.12em 0 
      }
      
      h5 { 
        font-size: .83em;
        margin: 1.5em 0 
      }
      
      h6 { 
        font-size: .75em; 
        margin: 1.67em 0 
      }
      
      p {
        margin-block-start: 1em;
        margin-block-end: 1em;
      }
      
      ul {
        list-style: disc;
        padding-inline-start: 40px;
      }

      ol {
        list-style: decimal;
        padding-inline-start: 50px;
      }

      sup {
        vertical-align: super;
      }

      sub {
        vertical-align: sub;
      }

      // BLOCKQUOTE
      blockquote {
        position: relative;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        border-left: 0.25em solid rgba(0, 0, 0, 0.075);
        padding-left: 1.5em;

        p {
          margin: 1em 0;
        }
      }

      // TABLES
      table {
        border-spacing: 0;
        border-collapse: collapse;
        margin: 1em 0;

        th,
        td {
          position: relative;
          border-width: 1px;
          border-style: solid;
          border-color: ${theme.colors.greyDarker};
          padding: 0.75em 1em;
          min-width: 2em;
          text-align: left;

          &:not(:last-child) {
            border-right-color: ${theme.colors.grey};
          }
        }

        thead {
          tr {
            background-color: ${theme.colors.greyLight};
          }
        }

        tbody {
          border-collapse: collapse;
        }
      }
     
     
      // CODE
      code {
        display: inline-block;
        color: ${theme.colors.black};
        background-color: ${theme.colors.greyLight};
        border: 1px solid ${theme.colors.greyDarker};
        border-radius: 0.25em;
        padding: 0.1375em 0.125em;
        font-size: 0.75em;
        vertical-align: baseline;
      }
      }
    }
  `}`

export default MarkdownCSS
