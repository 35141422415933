import { Fragment, useState } from 'react'

import { Options } from '~components/Chat/components'
import UploadPopin from '~components/Chat/components/UploadPopin'
import { getParseBodyParams, getType } from '~components/Chat/helpers'

type Props = {
  dialog: Stepper
  dialogType: ApiGetStepResponse['data'][0]['type']
  index: number
  setAnswers: (answer: string | string[]) => void
  handleAnswer: Function
  user: User | undefined
  fetchStep: (step: string) => void
}

const OptionUpload = ({
  dialog,
  dialogType,
  index,
  setAnswers,
  handleAnswer,
  user,
  fetchStep,
}: Props) => {
  const [uploadPopin, setUploadPopin] = useState<any>()

  const dialogTypes = dialog.map(({ type }) => type)
  const isLastOfType = (dialogType: string, dialogTypeIndex: number) => {
    return dialogTypes[dialogTypeIndex - 1] !== dialogType
  }

  if (!isLastOfType(dialogType, index)) return null

  // Map over the options to change base 64 to doc type, because key in option is set via bodyparams[0]
  const options = getType(dialog, dialogType).map((option) => ({
    ...option,
    bodyparam: option.bodyparam?.replace(
      '__BASE64__',
      Math.random().toString(36).substring(2, 15)
    ),
  }))

  const handleChoice = (option: Answer) => {
    if (option.bodyparam) {
      setUploadPopin(option)
    } else {
      handleUserUploadOption(option)
    }
  }

  const handleUserUploadOption = (answer: Answer, label?: string[]) => {
    if (!answer.bodyparam) {
      setAnswers(answer.label)
      handleAnswer(answer.action)
    } else {
      setAnswers(label || `${answer.label} envoyé(e)`)
      fetchStep(
        `?step=${answer.action?.split(`step=`)[1] as string}&type=${user?.role}`
      )
    }
  }

  return (
    <Fragment key={dialogType + index}>
      <Options onChoice={handleChoice} options={options} />
      {uploadPopin && (
        <UploadPopin
          onClose={() => setUploadPopin(undefined)}
          option={getParseBodyParams(uploadPopin)}
          onSubmit={(answer: string[]) =>
            handleUserUploadOption(uploadPopin, answer)
          }
        />
      )}
    </Fragment>
  )
}

export default OptionUpload
