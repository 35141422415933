import {
  Authentication,
  Blank,
  Dashboard,
  Default,
  Informations,
  Onboarding,
} from './components'

const Layout = {
  Authentication,
  Dashboard,
  Default,
  Blank,
  Informations,
  Onboarding,
}

export default Layout
