import { JobCard } from '~components'
import { Grid } from '~design-system'

interface Props {
  jobs: Job[]
}

const JobList = ({ jobs }: Props) => {
  return (
    <Grid
      ai="fs"
      templateColums="repeat(auto-fill, minmax(345px, 1fr))"
      gridAutoRows="1fr"
    >
      {jobs.map((job) => (
        <JobCard key={job.id} job={job} />
      ))}
    </Grid>
  )
}

export default JobList
