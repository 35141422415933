import { useMutation } from 'react-query'

import { DegreeService, queryClient } from '~api'

function useDegrees() {
  const useAddDegree = () =>
    useMutation(DegreeService.apiAddDegree, {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
      },
    })

  const useDeleteDegree = () =>
    useMutation(DegreeService.apiDeleteDegree, {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
      },
    })

  const useUpdateDegree = () =>
    useMutation(DegreeService.apiUpdateDegree, {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
      },
    })

  return {
    useAddDegree,
    useDeleteDegree,
    useUpdateDegree,
  }
}

export default useDegrees
