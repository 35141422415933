import { Link, generatePath } from 'react-router-dom'

import { routes } from '~config'
import { Avatar, Box, Icon, Panel, SmallText, Text } from '~design-system'
import { LayoutProps, SpacingProps } from '~design-system/helpers'

import { IconContainer, ResponsiveContainer, SubtitleContainer } from './styles'

export type Props = {
  company: Company
} & LayoutProps &
  SpacingProps

const CompanyBanner = ({ company, ...props }: Props) => (
  <Panel backgroundColor="blueLight" {...props}>
    <ResponsiveContainer>
      <Avatar
        type="company"
        src={company.logo as string | undefined}
        alt="avatar-entreprise"
        size="big"
      />
      <Box fg={1} ml={4}>
        <ResponsiveContainer>
          <Box di="f" fd="column">
            <Text weight="medium">{company.name}</Text>
            <SubtitleContainer>
              <SmallText textColor="black">
                {company.address?.properties.label}
              </SmallText>
              {company.address?.properties.city && (
                <Box di="f" ai="ce" g="2px">
                  <Icon name="poi" color="primary" />
                  <Text textColor="black" weight="medium">
                    {company.address?.properties.city}
                  </Text>
                </Box>
              )}
              {company.url && (
                <Box di="f" g="2px" ai="ce">
                  <Icon name="devices" color="primary" />
                  <a
                    href={company.url}
                    target="_blank"
                    title="url-entreprise"
                    rel="noreferrer"
                  >
                    <Text textColor="black" weight="medium">
                      {company.url?.replace(/^https?:\/\//, '')}
                    </Text>
                  </a>
                </Box>
              )}
            </SubtitleContainer>
          </Box>
          <Box di="f" g="10px" ai="ce" f={1} fw="w" jc="fe">
            <IconContainer
              as={Link}
              to={generatePath(routes.authenticated.company, {
                id: company.id.toString(),
              })}
            >
              <Icon name="eye" color="white" />
            </IconContainer>
            <IconContainer
              as={Link}
              to={generatePath(routes.authenticated.companyForm, {
                id: company.id.toString(),
              })}
            >
              <Icon name="pencil" color="white" />
            </IconContainer>
          </Box>
        </ResponsiveContainer>
      </Box>
    </ResponsiveContainer>
  </Panel>
)

export default CompanyBanner
