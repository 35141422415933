import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const JobsListContainer = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.colors.blueLighter};
    border-radius: ${theme.radii.md};
  `}
`

export default JobsListContainer
