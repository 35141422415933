import React from 'react'
import { ThemeProvider } from 'styled-components'

import { theme } from '~config'

import { ResetCSS } from './styles'

type Props = {
  children: React.ReactNode
}

const StyleProvider = ({ children }: Props) => (
  <ThemeProvider theme={theme}>
    <ResetCSS />
    {children}
  </ThemeProvider>
)

export const withStyleProvider =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P & Props> =>
  ({ ...props }: Props) =>
    (
      <StyleProvider>
        <Component {...(props as P)} />
      </StyleProvider>
    )

export default StyleProvider
