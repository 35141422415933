import styled from 'styled-components'

import { theme } from '~config'
import { Text } from '~design-system'

const SidebarItemContent = styled(Text)`
  font-size: 0.8rem;
  color: ${theme.colors.greyDarkest};
  font-weight: 500;

  a {
    color: ${theme.colors.blueDark};
  }
`

export default SidebarItemContent
