import styled, { css } from 'styled-components'

import { Panel } from '~design-system'

export const Container = styled('div')`
  position: relative;

  button {
    border: 0;
    background: transparent;
    cursor: pointer;
  }
`

export const EventButton = styled('button')`
  position: absolute;
  right: 16px;
  bottom: 16px;
`

export const EventOptions = styled(Panel)`
  ${({ theme }) => css`
    position: absolute;
    right: 16px;
    top: calc(100% - 16px);
    border-radius: 10px;
    padding: 0;
    background-color: white;
    overflow: hidden;
    z-index: 100;

    ul {
      margin: 0;
      padding: 0;
    }

    ul > li {
      list-style-type: none;

      button {
        padding: 16px;
        display: flex;
        align-items: center;

        &:hover,
        &:focus {
          background-color: ${theme.colors.blueLight};
        }
      }

      & > button > div,
      & > button > svg {
        margin-right: ${theme.pxToRem(12)};
      }
    }
  `}
`

export const EventImage = styled('img')`
  height: 125px;
  width: 100%;
  object-fit: cover;
`
