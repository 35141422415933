import { format, sub } from 'date-fns'
import { useFormContext } from 'react-hook-form'

import { Box, InputText } from '~design-system'
import { birthdayRegex } from '~helpers'

const EditBirthday = () => {
  // Define Form
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <Box w="100%">
      <InputText
        id="birthDate"
        placeholder="Saisis ici ta date de naissance"
        label="Date de naissance"
        autoFocus
        errorMessage={errors?.birthDate?.message}
        maxLength={10}
        {...register('birthDate', {
          pattern: birthdayRegex,
          validate: (value) =>
            value.slice(6, 10) <= format(new Date(), 'yyyy') &&
            value.slice(6, 10) >
              format(
                sub(new Date(), {
                  years: 99,
                }),
                'yyyy'
              ),
        })}
      />
    </Box>
  )
}

export default EditBirthday
