import { ReactElement } from 'react'
import styled, { css } from 'styled-components'

import { Box, Divider, Icon, Panel, SmallText } from '~design-system'
import { Header3 } from '~design-system/Typographies'
import { DisplayProps, LayoutProps, SpacingProps } from '~design-system/helpers'

type Props = {
  children: ReactElement
  icon: string
  subtitle?: string
  title: string
} & SpacingProps &
  LayoutProps &
  DisplayProps

const IconContainer = styled('div')`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.radii.lg};
    border: 1px solid ${theme.colors.greyLight};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  `}
`

const PanelWithHeader = ({
  children,
  icon,
  subtitle,
  title,
  ...props
}: Props) => {
  return (
    <Panel pl="0" pr="0" {...props}>
      <Box di="f" ai="ce" g="1.5rem" pl={5} pr={5}>
        <IconContainer>
          <Icon name={icon} color="primary" size="big" />
        </IconContainer>
        <Box di="f" fd="c" ai="fs" jc="ce">
          <Header3 weight="medium">{title}</Header3>
          {subtitle && (
            <SmallText textColor="greyDarkest" mt={0.5}>
              {subtitle}
            </SmallText>
          )}
        </Box>
      </Box>
      <Divider mt={5} mb={8} />
      <Box pl={5} pr={5}>
        {children}
      </Box>
    </Panel>
  )
}

export default PanelWithHeader
