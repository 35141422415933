import styled, { css } from 'styled-components'

type Props = {
  bgColor?: 'black50' | 'white'
}

const PopInBackdrop = styled('div').attrs((props: Props) => ({
  bgColor: props.bgColor || 'black50',
}))<Props>`
  ${({ bgColor, theme }) => css`
    background-color: ${bgColor ? theme.colors[bgColor] : 'transparent'};
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${theme.zIndex.popin.backdrop};
  `}
`

export default PopInBackdrop
