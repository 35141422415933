import { EventWidget, JobWidget } from '~components'
import YoungSituationPopIn from '~components/EditPopIns/YoungSituation'

import KnowledgePopUp from './KnowlegdePopup'
import ServicesWidget from './ServicesWidget'

type Props = {
  user: User
}

const DashboardYoung = ({ user }: Props) => {
  return (
    <>
      <EventWidget />
      <JobWidget />
      <ServicesWidget />
      {(user.eligibleToRsa == null ||
        user.handicapSituation == null ||
        user.obtainedAScholarship == null) && <YoungSituationPopIn />}
      <KnowledgePopUp source={user?.source} />
    </>
  )
}

export default DashboardYoung
