import { EventsList, Widget as WidgetWrapper } from '~components'
import { routes } from '~config'
import { Loader, Text } from '~design-system'
import { useEvents } from '~hooks'

const EventWidget = () => {
  const { useGetEvents } = useEvents()
  const { data: events, isLoading } = useGetEvents('?limit=4')

  return (
    <WidgetWrapper
      title="Évènements"
      url={events?.data?.length ? routes.authenticated.events : undefined}
    >
      {isLoading ? (
        <Loader isCentered />
      ) : !!events?.data?.length ? (
        <EventsList
          events={events}
          queryKey={['events', '?limit=4']}
          withPagination={false}
        />
      ) : (
        <Text>Aucun évènement pour le moment</Text>
      )}
    </WidgetWrapper>
  )
}

export default EventWidget
