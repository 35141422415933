import api from '../api'

const apiGetCompanyById = (id: string) =>
  api
    .get<ApiResponse<Company>>(`/api/v1/company/${id}`)
    .then((response) => response.data.data)

const apiUpdateCompany = (data: Partial<Company>) =>
  api
    .patch<ApiResponse<{ company: Company }>>(`api/v1/company/${data.id}`, data)
    .then((response) => response.data.data)

const apiGetCompanyEnum = () =>
  api
    .get<ApiResponse<CompanyEnum>>(`api/v1/enum/company`)
    .then((response) => response.data.data)

const apiGetCompanySponsors = () =>
  api
    .get<ApiResponse<CompanySponsors>>(`api/v1/company-referent/sponsors`)
    .then((response) => response.data.data)

const CompanyService = {
  apiGetCompanyById,
  apiUpdateCompany,
  apiGetCompanyEnum,
  apiGetCompanySponsors,
}

export default CompanyService
