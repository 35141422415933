import { useFormContext } from 'react-hook-form'

import { InputRadio, Panel, Text } from '~design-system'

interface Props {
  title: string
  id: keyof User
}

const BooleanRow = ({ title, id }: Props) => {
  // Define Form
  const { register } = useFormContext()

  return (
    <Panel
      di="f"
      fd="r"
      ai="ce"
      jc="sb"
      g="20px"
      backgroundColor="greyLightest"
    >
      <Text weight="medium" f="1">
        {title}
      </Text>
      <InputRadio
        id={id}
        label="Oui"
        value="true"
        {...register(id, {
          required: true,
        })}
      />
      <InputRadio
        id={id}
        label="Non"
        value="false"
        {...register(id, {
          required: true,
        })}
      />
    </Panel>
  )
}

export default BooleanRow
