import { useCallback, useMemo, useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

import { ExperienceForm } from '~components/EditPopIns/forms'
import { useAuth } from '~context/AuthContext'
import { Box, Button, Header2, Icon, Text } from '~design-system'
import { getContractTypeByValue, isSponsor } from '~helpers'
import useSponsorQualification from '~hooks/useSponsorQualification'
import useYoungQualification from '~hooks/useYoungQualification'

import { UploadPopinProps } from '..'

const ProfessionnalExperiences = ({ onClose, onSubmit }: UploadPopinProps) => {
  const { handleSubmit } = useForm<Experience>({
    mode: 'onChange',
  })
  const {
    authState: { user },
  } = useAuth()
  const {
    getValues,
    reset,
    formState: { isValid, isSubmitting },
  } = useFormContext()
  const { useUpdateYoungQualification } = useYoungQualification()
  const { mutateAsync: uploadYoungExperience, isLoading: isYoungLoading } =
    useUpdateYoungQualification()
  const { useUpdateSponsorQualification } = useSponsorQualification()
  const { mutateAsync: uploadSponsorExperience, isLoading: isSponsorLoading } =
    useUpdateSponsorQualification()
  const [process, setProcess] = useState<'add' | 'summary'>('summary')
  const [experiences, setExperiences] = useState<Experience[]>([])
  const step = isSponsor(user) ? 21 : 26

  const formatedExperience = experiences.map((experience) => ({
    company_name: experience.companyName,
    contract_type: experience.contractType,
    name: experience.name,
    end_date: experience.endDate,
    start_date: experience.startDate,
  }))

  const handleShowForm = () => {
    setProcess('add')
    reset()
  }
  const handleShowSummary = () => setProcess('summary')

  const handleDeleteExperience = useCallback(
    (index: number) => {
      const newExperience = [...experiences]
      newExperience.splice(index, 1)
      setExperiences(newExperience)
    },
    [setExperiences, experiences]
  )

  const onUpload = async () => {
    try {
      if (isSponsor(user)) {
        await uploadSponsorExperience({
          experiences: formatedExperience,
          step: step,
        })
      } else {
        await uploadYoungExperience({
          experiences: formatedExperience,
          step: step,
        })
      }
      onSubmit(['Expériences envoyées'])
      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  const renderExperiences = useMemo(
    () =>
      experiences ? (
        <Box mb={2}>
          {experiences.map(({ name, contractType }: any, index: number) => (
            <Button
              fullWidth
              mb={2}
              key={`selected-experience-${index}`}
              onClick={() => handleDeleteExperience(index)}
            >
              <Box ai="ce" di="f" fg={1} jc="space-between">
                <Text textColor="white">
                  {name} - {getContractTypeByValue(contractType)?.label}
                </Text>
                <Icon color="white" name="close" size="small" />
              </Box>
            </Button>
          ))}
        </Box>
      ) : null,
    [experiences, handleDeleteExperience]
  )
  const onFormSubmit = () => {
    setExperiences([...experiences, getValues() as Experience])
    handleShowSummary()
    reset()
  }

  return (
    <>
      <Header2 mb={4}>Ajouter une experience</Header2>
      <Text mb={8} textColor="greyDarkest">
        Renseigne tes experiences professionnelles
      </Text>
      <>
        {process === 'summary' && (
          <Box>
            {renderExperiences}
            <Button
              fullWidth
              option="dashed"
              variant="secondary"
              onClick={handleShowForm}
              mb={4}
            >
              <Box di="f" fg={1} jc="space-between">
                Ajouter une experience
                <Icon color="primary" name="add" />
              </Box>
            </Button>
            <Box>
              <Button
                onClick={onUpload}
                disabled={
                  experiences?.length === 0 ||
                  isYoungLoading ||
                  isSponsorLoading
                }
                isLoading={isYoungLoading || isSponsorLoading}
              >
                Valider
              </Button>
              <Button option="unfilled" onClick={onClose}>
                Annuler
              </Button>
            </Box>
          </Box>
        )}
        {process === 'add' && (
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Box>
              <ExperienceForm isSponsor={isSponsor(user)} />
            </Box>
            <Box>
              <Box di="f" mt={6}>
                <Button
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  isLoading={isSubmitting}
                >
                  Valider
                </Button>
                <Text
                  as="button"
                  color="greyDarkest"
                  onClick={handleShowSummary}
                >
                  Compléter plus tard
                </Text>
              </Box>
            </Box>
          </form>
        )}
      </>
    </>
  )
}

export default ProfessionnalExperiences
