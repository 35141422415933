import styled, { css } from 'styled-components'

import { Box, Icon, SmallText } from '~design-system'

interface Props {
  description: string
  icon?: string
  label: string
}

const StyledBox = styled(Box)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.blueLighter};
    padding: ${theme.spaces[4]} 0;
  `}
`

const Information = ({ description, icon, label }: Props) => (
  <StyledBox ai="ce" di="f">
    {icon && <Icon color="primary" mr={4} name={icon} size="big" />}
    <Box>
      <SmallText>{label}</SmallText>
      <SmallText textColor="greyDarkest">{description}</SmallText>
    </Box>
  </StyledBox>
)

export default Information
