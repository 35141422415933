import { camelize } from 'humps'
import { Fragment } from 'react'
import { useFormContext } from 'react-hook-form'

import { JobSearch } from '~components/Chat/components'
import { getParseBodyParams, getType } from '~components/Chat/helpers'
import { JobSelectorProps } from '~components/Selectors/Jobs'
import { Label } from '~design-system'

type Props = {
  dialog: Stepper
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const OptionSingleMetiersRome = ({
  dialog,
  setAnswers,
  handleAnswer,
}: Props) => {
  const { getValues } = useFormContext()

  const answer = getType(dialog, 'option_single_metiers_rome')[0]
  const parsedBodyParam = getParseBodyParams(answer)
  const id = camelize(
    Object.keys(parsedBodyParam)[0]
  ) as JobSelectorProps['name']

  const handleSingleMetierSubmit = (answer: Answer) => () => {
    setAnswers('Métier envoyé')
    handleAnswer(answer.action, { [`${id}`]: getValues(id)[0] })
  }

  return (
    <Fragment key={id}>
      <Label mb={2}>Choisissez une option</Label>
      <JobSearch name={id} onSubmit={handleSingleMetierSubmit(answer)} />
    </Fragment>
  )
}

export default OptionSingleMetiersRome
