import styled, { css } from 'styled-components'

const PaginationButton = styled('button')`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.radii.sm};
    border: 1px solid ${theme.colors.greyLighter};
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      cursor: auto;
    }
  `}
`

export default PaginationButton
