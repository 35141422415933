import styled, { css } from 'styled-components'

const PopInCloseIcon = styled('div')`
  ${({ theme }) => css`
    cursor: pointer;
    position: absolute;
    right: ${theme.spaces[4]};
    top: ${theme.spaces[4]};
  `}
`

export default PopInCloseIcon
