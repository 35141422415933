import styled, { Theme, css } from 'styled-components'

import { URL_REGEX } from '~config'

import { SpacingProps, spacing } from '../helpers'
import { AvatarContainer, Dot } from './styles'

type ImageProps = Omit<AvatarProps, 'online'>

type AvatarProps = {
  online?: boolean
  size?: keyof Theme['components']['avatar']['sizes']
  src?: string
  type?: AvatarType
  alt?: string
} & SpacingProps

const StyledImage = styled('div').attrs((props: ImageProps) => ({
  size: props.size || 'default',
}))<ImageProps>`
  ${({ theme, size, type }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.primary};
    border-radius: 100%;
    height: ${theme.components.avatar.sizes[size]};
    padding: ${type === 'nqt' ? '0.4em' : '0'};
    width: ${theme.components.avatar.sizes[size]};
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: ${type === 'nqt' ? 'contain' : 'cover'};
    }
  `}
  ${spacing};
`
const Avatar = ({
  online = false,
  type = 'avatar',
  src,
  size,
  alt,
  ...rest
}: AvatarProps) => {
  const defaultImage = () => {
    switch (type) {
      case 'bot':
        return '/images/bot@2x.png'
      case 'job':
      case 'company':
        return '/images/company.png'
      case 'nqt':
        return '/images/logo-white.svg'
      case 'avatar':
      default:
        return '/images/avatar.svg'
    }
  }

  return (
    <AvatarContainer {...rest}>
      <StyledImage size={size} type={type}>
        <img src={src?.match(URL_REGEX) ? src : defaultImage()} alt={alt} />
      </StyledImage>
      {online && <Dot size={size} />}
    </AvatarContainer>
  )
}

export default Avatar
