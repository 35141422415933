import React, { Ref, forwardRef, useState } from 'react'
import { FieldError } from 'react-hook-form'
import styled from 'styled-components'

import { Box, Panel, Text } from '~design-system'

import PasswordStrength from '../../components/PasswordStrength'
import Button from '../Button'
import Icon from '../Icon'
import InputBase, { InputBaseProps } from '../InputBase'

type Props = {
  withStrength?: Boolean
  displayRules?: boolean
  error?: FieldError
} & InputBaseProps

const InputPassword = forwardRef((props: Props, ref: Ref<HTMLInputElement>) => {
  const { error, displayRules, withStrength, name } = props
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => setVisible((current: boolean) => !current)

  const RULES = [
    {
      message: 'Longueur de 12 caractères minimum',
      error: error?.types?.minLength,
    },
    {
      message: 'Présence d’au minimum un caractère majuscule',
      error: error?.types?.uppercase,
    },
    {
      message: 'Présence d’au minimum un caractère minuscule',
      error: error?.types?.lowercase,
    },
    {
      message: ' Présence d’au minimum un caractère numérique',
      error: error?.types?.numeric,
    },
    {
      message: 'Présence d’au minimum un caractère spécial',
      error: error?.types?.specialChar,
    },
  ]

  return (
    <>
      <InputBase
        ref={ref}
        type={visible ? 'text' : 'password'}
        upperComponent={
          withStrength ? <PasswordStrength name={name as string} /> : null
        }
        {...props}
        rightComponent={
          <>
            <Button size="small" option="unfilled" onClick={toggleVisible}>
              <Icon name={visible ? 'eye-off' : 'eye'} color="primary" />
            </Button>
            {props.rightComponent}
          </>
        }
      />

      {displayRules && (
        <Box>
          {RULES.map((elem) => (
            <Panel
              di="flex"
              fd="row"
              mt={4}
              backgroundColor={elem.error ? 'redLight' : 'greenLight'}
              noBorder
            >
              <Icon
                mr={2}
                color={elem.error ? 'danger' : 'success'}
                name={elem.error ? 'cross' : 'check'}
              />
              <Text>{elem.message}</Text>
            </Panel>
          ))}
        </Box>
      )}
    </>
  )
})
InputPassword.displayName = 'InputPassword'

export default styled(InputPassword)``
