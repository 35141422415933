import styled from 'styled-components'

const AppContainer = styled('div')`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 100vh;
`

export default AppContainer
