import { Dispatch, SetStateAction } from 'react'
import { useFormContext } from 'react-hook-form'

import { LanguageForm } from '~components/EditPopIns/forms'
import { Button } from '~design-system'

type Props = {
  onSubmit: () => void
  selectedLanguages: Language[]
  setSelectedLanguages: Dispatch<SetStateAction<Language[]>>
}

const Form = ({
  onSubmit,
  selectedLanguages = [],
  setSelectedLanguages,
}: Props) => {
  const {
    handleSubmit,
    formState: { isDirty, isValid },
  } = useFormContext()

  const handleRequest = (values: any) => {
    const newValue = {
      certification: values.certification,
      id: parseInt(values.languagesOptions.value),
      name: values.languagesOptions.label,
      level: values.levelOptions.value,
    } as Language

    setSelectedLanguages([...selectedLanguages, newValue])
    onSubmit()
  }

  return (
    <form onSubmit={handleSubmit(handleRequest)}>
      <LanguageForm selectedLanguages={selectedLanguages} />
      <Button
        type="submit"
        disabled={!isDirty || !isValid}
        data-testid="language-add"
      >
        Valider
      </Button>
      <Button option="unfilled" onClick={onSubmit}>
        Annuler
      </Button>
    </form>
  )
}

export default Form
