import styled, { css } from 'styled-components'

type Props = {
  progress: number
}

export const ProgressBarContainer = styled('div')`
  height: 2px;
  width: 100%;
  background-color: #e6e6e6;
`

export const ProgressBarFiller = styled('div')<Props>`
  ${({ progress }) => css`
    height: 100%;
    width: ${progress}%;
    background-color: #50bd89;
    border-radius: inherit;
    text-align: right;
  `}
`
