import { useState } from 'react'

import { Avatar, Box, Button, Icon, Panel, PopIn, Text } from '~design-system'
import { useEvents } from '~hooks'

type Props = {
  attendee: Partial<User>
  eventID: number
  canBeUnregister?: boolean
}

const AttendeeCard = ({ attendee, eventID, canBeUnregister }: Props) => {
  const { useUnregisterEvent } = useEvents()
  const { mutate: unregister, isLoading } = useUnregisterEvent(eventID)
  const [isHover, setIsHover] = useState<boolean>(false)
  const [deletePopin, setDeletePopin] = useState<boolean>(false)

  // Keep Focus inside the Card with keyboard navigation
  const handleBlur = (event: FocusEvent) => {
    const parentElement = event.currentTarget as Node
    const focusedElement = event.relatedTarget as Node
    // The code below is launch only when focusedElement is outside the parentElement
    if (!parentElement.contains(focusedElement)) {
      setIsHover(false)
    }
  }

  // On Delete
  const handleDelete = async () => {
    try {
      unregister({ eventID, userID: attendee.id })
      setDeletePopin(false)
    } catch (error: any) {
      console.error(error)
      setDeletePopin(false)
    }
  }

  return (
    <>
      <Panel
        di="f"
        fd="r"
        ai="ce"
        jc="sb"
        pl={2}
        pr={2}
        pt={2}
        pb={2}
        mb={2}
        onMouseEnter={() => setIsHover(true)}
        onFocus={() => setIsHover(true)}
        onBlur={(e) => handleBlur(e as unknown as FocusEvent)}
        onMouseLeave={() => setIsHover(false)}
        tabIndex={0}
      >
        <Box di="f" fd="r" ai="ce">
          <Avatar src={attendee.profilePhoto} type="avatar" />
          <Text weight="medium" ml={3}>
            {attendee.fullName}
          </Text>
        </Box>

        {canBeUnregister && (
          <Button
            di={isHover ? 'f' : 'none'}
            size="small"
            pr={2}
            pl={2}
            option="unfilled"
            onClick={() => setDeletePopin(true)}
          >
            <Icon name="trash" color="primary" />
          </Button>
        )}
      </Panel>
      {deletePopin && (
        <PopIn open closable onClose={() => setDeletePopin(false)}>
          <PopIn.Title textAlign="left">
            Êtes-vous sûr de vouloir désinscrire cette personne ?
          </PopIn.Title>
          <PopIn.Actions
            actions={[
              <Button
                key="cancel"
                variant="secondary"
                disabled={isLoading}
                onClick={() => setDeletePopin(false)}
              >
                Non, revenir en arrière
              </Button>,
              <Button
                key="save"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={handleDelete}
              >
                Oui, désinscrire
              </Button>,
            ]}
          />
        </PopIn>
      )}
    </>
  )
}

export default AttendeeCard
