import { ReactNode } from 'react'

import { Box, Icon } from '~design-system'

import { SidebarItemContent, SidebarItemTitle, SidebarLine } from '../styles'

type SidebarItemProps = {
  title: string
  icon: string
  children?: ReactNode
}

const SidebarItem = ({ title, icon, children }: SidebarItemProps) => {
  if (!children) return null

  return (
    <SidebarLine>
      <Icon name={icon} color={'blueDark'} />
      <Box ml={2}>
        <SidebarItemTitle>{title}</SidebarItemTitle>
        <SidebarItemContent>{children}</SidebarItemContent>
      </Box>
    </SidebarLine>
  )
}

export default SidebarItem
