import styled, { css } from 'styled-components'

type Props = {
  variant: 'answer' | 'question'
}

const Container = styled('div')<Props>`
  ${({ theme, variant }) => css`
    background-color: ${theme.components.message[variant].backgroundColor};
    border-radius: ${theme.components.message[variant].borderRadius};
    border: 1px solid ${theme.colors.grey};
    margin-bottom: ${theme.spaces[4]};
    max-width: 70%;
    padding: ${theme.spaces[3]};
  `}
`

export default Container
