import api from '../api'

const apiGetService = (id?: number) =>
  id
    ? api
        .get<ApiResponse<Service>>(`/api/v1/services/${id}`)
        .then((response) => response.data.data)
    : undefined

const apiGetServices = (filters?: string) => {
  const apiRoute = filters ? `/api/v1/services${filters}` : `/api/v1/services`
  try {
    return api
      .get<ApiPaginatedResponse<ServiceTeaser[]>>(apiRoute)
      .then((response) => response.data)
  } catch (e) {
    console.error(e)
  }
}

const HaloService = {
  apiGetService,
  apiGetServices,
}
export default HaloService
