import { Link } from 'react-router-dom'

import { SwitchRole } from '~components'
import ButtonSponsor from '~components/ButtonSponsor'
import DiscussionPanel from '~components/DiscussionPanel'
import NavMenu from '~components/NavMenu'
import { routes } from '~config'
import { useAuth } from '~context/AuthContext'
import { isReferent, isSponsorReferent } from '~helpers'

import { Container, Logo } from './styles'

type Props = {
  isResponsive?: boolean
}

const Sidebar = ({ isResponsive = false }: Props) => {
  const {
    authState: { user },
  } = useAuth()

  return (
    <Container
      di="f"
      fd="c"
      jc="sb"
      hu={isResponsive ? undefined : '700px'}
      ha={isResponsive ? '701px' : undefined}
    >
      <Logo di="f" ai="ce">
        <Link to={routes.authenticated.home}>
          <img src="/images/logo-white.svg" alt="logo" />
        </Link>
      </Logo>
      <NavMenu />
      {isReferent(user) && !isSponsorReferent(user) && (
        <ButtonSponsor ha="901px" />
      )}
      {isSponsorReferent(user) && <SwitchRole ha="901px" als="ce" mb={4} />}
      <DiscussionPanel />
    </Container>
  )
}

export default Sidebar
