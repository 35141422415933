import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { routes } from '~config'
import { Avatar, Box, ChatMessage } from '~design-system'
import useScrollAnchor from '~hooks/useScrollAnchor'

import { Bot } from './components'
import useStepper from './hooks/useStepper'

type Props = {
  initialPath: string
}

const Chat = ({ initialPath }: Props) => {
  const navigate = useNavigate()
  const { scrollContainerRef, scrollToBottom } = useScrollAnchor()

  const { answers, getBotDialogs, isTyping, renderQuestion, steps } =
    useStepper(initialPath)

  useEffect(() => {
    scrollToBottom()
  }, [isTyping, scrollToBottom, steps])

  useEffect(() => {
    const lastStep = steps.length > 0 && steps[steps.length - 1]
    const isRecapStep =
      lastStep &&
      lastStep.find((order) => order.type === 'final' && order.action)

    if (isRecapStep) {
      navigate(routes.unauthenticated.registerSummary, {
        state: { path: lastStep[0].action },
      })
    }
  }, [navigate, steps])

  const renderAnswer = (answer: string, index: any = null) => (
    <ChatMessage variant="answer" key={`answer-${answer}-${index}`}>
      {answer}
    </ChatMessage>
  )

  return (
    <Box overflow="auto" h="100%">
      <Box pb={12} ref={scrollContainerRef}>
        {steps?.map((step, index) => {
          const botDialogs = getBotDialogs(step)
          const question = renderQuestion(step, index)

          return (
            <Box key={`${step[0].step}-${step[0].label}`}>
              <Box di="f" ai="start">
                <Avatar mr={3} size="medium" type="bot" online />
                <Box di="f" fg={1} fd="column">
                  {botDialogs.map((dialog) => (
                    <ChatMessage key={`dialog-${dialog.step}`}>
                      {dialog.label}
                    </ChatMessage>
                  ))}
                </Box>
              </Box>
              {answers[index] && (
                <Box di="f" jc="end">
                  {!Array.isArray(answers[index]) ? (
                    renderAnswer(answers[index] as string, index)
                  ) : (
                    <Box f={1}>
                      {Array.from(answers[index]).map((answer) =>
                        renderAnswer(answer, index)
                      )}
                    </Box>
                  )}
                </Box>
              )}
              <>{!isTyping && <Box ml="52px">{question}</Box>}</>
            </Box>
          )
        })}
        <>{isTyping && <Bot />}</>
      </Box>
    </Box>
  )
}

export default Chat
