import { ReactNode, useState } from 'react'

import { Box, Button, Divider, Icon, Panel } from '~design-system'
import { SpacingProps } from '~design-system/helpers'

import { AccordionContent, AccordionIcon, AccordionTitle } from './styles'

type AccordionProps = {
  children: ReactNode
  icon: string
  title: string
} & SpacingProps

const Accordion = ({ children, icon, title, ...rest }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const id = Math.floor(Math.random() * 100).toString()

  return (
    <Panel {...rest}>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        role="tab"
        aria-expanded={isOpen}
        aria-controls={`panel-${id}`}
        id={`tab-${id}`}
        option="unfilled"
        size="small"
        pr={0.5}
        pl={0.5}
        pt={0.5}
        pb={0.5}
      >
        <Box di="f" ai="ce" jc="sb" w="100%" overflow="hidden">
          <Box di="f" ai="ce" jc="fs" overflow="hidden">
            <Icon color="primary" name={icon} />
            <AccordionTitle>{title}</AccordionTitle>
          </Box>
          <AccordionIcon
            color="primary"
            isOpen={isOpen}
            name="chevron-right"
            size="tiny"
          />
        </Box>
      </Button>

      <AccordionContent
        onClick={(e) => e.stopPropagation()}
        id={`panel-${id}`}
        role="tabpanel"
        aria-labelledby={`tab-${id}`}
        aria-hidden={!isOpen}
        hidden={!isOpen}
        isOpen={isOpen}
      >
        <Divider mb={4} mt={4} />
        {children}
      </AccordionContent>
    </Panel>
  )
}

export default Accordion
