import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const Container = styled(Box)`
  ${({ theme }) => css`
    padding: ${theme.spaces[4]};

    @media (max-width: 500px) {
      padding: ${theme.spaces[0]};
    }
  `}
`

export default Container
