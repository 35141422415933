import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useAuth } from '~context/AuthContext'
import { Box, Button, PopIn, TinyText } from '~design-system'
import { getLanguageLevelLabel } from '~helpers'
import { useUser } from '~hooks'

import { ConfirmationPopin } from '../components'
import { LanguageForm } from '../forms'
import { FormContainer } from '../styles'

interface Props {
  data: EditInfos
  onClose: () => void
}

const LanguagesPopIn = ({ data, onClose }: Props) => {
  const { useUpdateUser } = useUser()
  const {
    authState: { user },
  } = useAuth()
  const { mutate: updateUser, isLoading } = useUpdateUser()
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const languageData = data?.data as Language

  // Define Form Methods and DefaultValues
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      certification: languageData?.certification,
      languagesOptions: languageData
        ? { value: languageData?.id, label: languageData?.name }
        : '',
      levelOptions: languageData
        ? {
            value: languageData?.level,
            label: getLanguageLevelLabel(languageData?.level),
          }
        : '',
    },
  })

  // Define Form
  const {
    handleSubmit,
    getValues,
    formState: { isDirty, isValid },
  } = methods

  // Filter Language to delete
  const deleteLanguage = () =>
    user &&
    [...user?.languages].filter((language) => language.id !== languageData.id)

  // Filter Language to add
  const addLanguage = (arr: Language[] | undefined) =>
    arr?.concat([
      {
        id: getValues('languagesOptions.value'),
        certification: getValues('certification'),
        level: getValues('levelOptions.value'),
        name: getValues('languagesOptions.label'),
      },
    ])

  // Delete Experience
  const onDeleteLanguage = () => {
    updateUser(
      {
        languages: deleteLanguage(),
      },
      {
        onSuccess() {
          onClose()
        },
      }
    )
  }

  // Update/Create Experience
  const handleRequest = () => {
    updateUser(
      {
        languages: addLanguage(
          languageData ? deleteLanguage() : user?.languages
        ),
      },
      {
        onSuccess() {
          onClose()
        },
      }
    )
  }

  if (!showConfirmation) {
    return (
      <PopIn open closable onClose={onClose}>
        <FormProvider {...methods}>
          <FormContainer onSubmit={handleSubmit(handleRequest)}>
            <Box di="flex" ai="center" jc="space-between" mb={12}>
              <PopIn.Title textAlign="left">{data.title}</PopIn.Title>
              {data?.data && (
                <Button
                  textColor="danger"
                  option="unfilled"
                  pr={1}
                  pl={1}
                  pt={1}
                  pb={1}
                  size="small"
                  isLoading={isLoading}
                  disabled={isLoading}
                  onClick={() => setShowConfirmation(true)}
                >
                  <TinyText textColor="danger">Supprimer</TinyText>
                </Button>
              )}
            </Box>
            <PopIn.Content>
              <Box w="100%">
                <LanguageForm selectedLanguages={user?.languages || []} />
              </Box>
            </PopIn.Content>
            <PopIn.Actions
              actions={[
                <Button
                  key="cancel"
                  variant="secondary"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  Annuler
                </Button>,
                <Button
                  key="save"
                  disabled={!isDirty || !isValid || isLoading}
                  type="submit"
                  isLoading={isLoading}
                >
                  Enregistrer
                </Button>,
              ]}
            />
          </FormContainer>
        </FormProvider>
      </PopIn>
    )
  } else {
    return (
      <ConfirmationPopin
        title="Supprimer la langue"
        content="Cette action est définitive, tous les éléments associés à ce service seront supprimés..."
        isLoading={isLoading}
        onCancel={() => setShowConfirmation(false)}
        onValidate={onDeleteLanguage}
      />
    )
  }
}

export default LanguagesPopIn
