import api from '../api'

const getKnownItems = () =>
  api
    .get<APIGetMiscKnownItemsResponse>('/api/v1/getKnown_items')
    .then((response) => response.data)

const KnowledgeService = {
  getKnownItems,
}
export default KnowledgeService
