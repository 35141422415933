import { Box, Icon, Panel, Text } from '~design-system'

import { IconWrapper } from './styles'

const Toast = ({ title, subtitle, type = 'info' }: Toast) => {
  return (
    <Panel di="f" ai="center" fd="row" role="alert" aria-live="polite">
      <IconWrapper type={type}>
        <Icon name={type} size="big" aria-hidden="true" />
      </IconWrapper>
      <Box>
        <Text>{title}</Text>
        {subtitle && (
          <Text textColor="greyDarkest" weight="regular">
            {subtitle}
          </Text>
        )}
      </Box>
    </Panel>
  )
}

export default Toast
