import TypingDots from '~components/TypingDots'
import { Avatar, Box } from '~design-system'

const Bot = () => {
  return (
    <Box di="f">
      <Avatar mr={3} size="medium" type="bot" online />
      <Box di="f" ai="end">
        <TypingDots />
      </Box>
    </Box>
  )
}
export default Bot
