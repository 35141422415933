import api from '../api'

const getThreads = () =>
  api
    .get<ApiResponse<ThreadTeaser[]>>('/api/v1/user/threads')
    .then((response) => response.data)
    .then((response) => response.data)

const getThread = (threadId: number) =>
  api
    .get<ApiResponse<Thread>>(`/api/v1/user/thread/${threadId}`)
    .then((response) => response.data)
    .then((response) => response.data)

const postMessage = ({
  payload,
  threadId,
}: {
  payload: {
    message?: string
    attachments?: File[]
  }
  threadId: number
}) => {
  var bodyFormData = new FormData()

  if (payload.message) {
    bodyFormData.append('message', payload.message)
  }
  if (payload.attachments) {
    bodyFormData.append('attachments[]', payload.attachments[0])
  }
  return api
    .post<ApiResponse<null>>(`/api/v1/user/thread/${threadId}`, bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => response.data)
}

const deleteFile = ({
  threadId,
  fileId,
}: {
  threadId: number
  fileId: string
}) =>
  api
    .delete<ApiResponse<Message[]>>(
      `/api/v1/user/thread/${threadId}/file/${fileId}`
    )
    .then((response) => response.data)

const MessagingService = {
  getThreads,
  getThread,
  postMessage,
  deleteFile,
}

export default MessagingService
