import styled, { css } from 'styled-components'

const PopInActionsContainer = styled('div')`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${theme.spaces[3]};
    margin-top: ${theme.spaces[12]};

    /* one item */
    > :first-child:nth-last-child(1) {
      grid-column: span 2;
    }
  `}
`

export default PopInActionsContainer
