import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { routes } from '~config'
import { useAuth } from '~context/AuthContext'
import { Box, Switch } from '~design-system'
import { DisplayProps, SpacingProps } from '~design-system/helpers'

const SwitchRole = ({ ...rest }: SpacingProps & DisplayProps) => {
  const navigate = useNavigate()
  const {
    setAuthState,
    authState: { token, user, localRole },
  } = useAuth()
  const [isReferent, setIsReferent] = useState<boolean>(
    localRole === 'company-referent'
  )

  // onSwitch
  const onSwitch = () => {
    setIsReferent(!isReferent)
    setAuthState({
      token,
      user,
      localRole: !isReferent ? 'company-referent' : 'parrain',
    })
    navigate(routes.authenticated.home)
  }

  return (
    <Box {...rest}>
      <Switch
        label="Passer en référent"
        type="checkbox"
        id={Math.random().toString().substring(2, 8)}
        value="roles-toggle"
        leftText="Parrain"
        rightText="Référent"
        checked={isReferent}
        onChange={onSwitch}
      />
    </Box>
  )
}

export default SwitchRole
