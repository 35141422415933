import styled, { css } from 'styled-components'

const CloseButton = styled('button')`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.blueLighter};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: ${theme.spaces[8]};
    justify-content: center;
    padding: ${theme.spaces[1]};
    position: absolute;
    left: 9rem;
    top: 0rem;
    right: ${theme.spaces[32]};
    top: ${theme.spaces[1]};
    width: ${theme.spaces[8]};
    z-index: 1;
  `}
`

export default CloseButton
