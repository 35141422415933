import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { InterestsPopin, Options } from '~components/Chat/components'
import { getParseBodyParams } from '~components/Chat/helpers'
import { Box, Button, Label, SmallText } from '~design-system'

type Props = {
  dialog: Stepper
  index: number
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const PicklistMultipleHobbies = ({
  dialog,
  index,
  setAnswers,
  handleAnswer,
}: Props) => {
  const { getValues } = useFormContext()

  const [isInterestsPopinOpen, setIsInterestsPopinOpen] = useState(false)
  const answer = dialog[index]

  const handleInterestsAnswer = (answer: Answer) => {
    if (answer.bodyparam) {
      setAnswers("Centres d'intérêts ajoutés")
      const id = Object.keys(getParseBodyParams(answer))[0]
      handleAnswer(answer.action, { [id]: getValues(id) })
    } else {
      setAnswers(answer.label)
      handleAnswer(answer.action)
    }
  }

  return answer.bodyparam ? (
    <Box key={answer.label} mb="2">
      <Label mb={2}>Choisis une option</Label>
      <Button
        fullWidth
        option="dashed"
        variant="secondary"
        rightComponent={<SmallText textColor="blueDark">+</SmallText>}
        key={answer.label}
        data-testid="hobbies-open"
        onClick={() => setIsInterestsPopinOpen(true)}
      >
        {answer.label}
      </Button>
      {isInterestsPopinOpen && (
        <InterestsPopin
          onClose={() => setIsInterestsPopinOpen(false)}
          onSubmit={() => {
            handleInterestsAnswer(answer)
          }}
        />
      )}
    </Box>
  ) : (
    <Options
      key={'picklist_multiple_hobbies_' + index}
      onChoice={() => {
        handleInterestsAnswer(answer)
      }}
      options={[answer]}
      withoutLabel
    />
  )
}

export default PicklistMultipleHobbies
