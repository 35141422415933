import { camelize } from 'humps'
import { Fragment } from 'react'
import { useFormContext } from 'react-hook-form'

import { JobSearch } from '~components/Chat/components'
import { getParseBodyParams, getType } from '~components/Chat/helpers'
import { JobSelectorProps } from '~components/Selectors/Jobs'
import { Label } from '~design-system'

type Props = {
  dialog: Stepper
  setAnswers: (answer: string) => void
  handleAnswer: Function
}

const OptionMultipleMetiersRome = ({
  dialog,
  setAnswers,
  handleAnswer,
}: Props) => {
  const { getValues } = useFormContext()

  const answer = getType(dialog, 'option_multiple_metiers_rome')[0]
  const { label } = getType(dialog, 'label')[0]
  const parsedBodyParam = getParseBodyParams(answer)
  const id = camelize(
    Object.keys(parsedBodyParam)[0]
  ) as JobSelectorProps['name']

  const handleMetiersSubmit = (answer: Answer) => () => {
    setAnswers('Metiers envoyés')
    handleAnswer(answer.action, { [id]: getValues(id) })
  }

  return (
    <Fragment key={id}>
      <Label mb={2}>{label}</Label>
      <JobSearch
        name="desiredProfessions"
        onSubmit={handleMetiersSubmit(answer)}
      />
    </Fragment>
  )
}

export default OptionMultipleMetiersRome
