import { format, isPast, parseISO, sub } from 'date-fns'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Checkbox, Grid, InputText, Label, Select } from '~design-system'
import {
  birthdayRegex,
  contractTypeOptions,
  getContractTypeByValue,
} from '~helpers'

const ExperienceForm = ({ isSponsor = false }: { isSponsor?: boolean }) => {
  const [isCurrentJob, setIsCurrentJob] = useState<boolean>()

  // Define Form
  const {
    register,
    getValues,
    setValue,
    resetField,
    watch,
    control,
    formState: { errors },
  } = useFormContext()

  // Set isCurrentJob on Mount
  useEffect(() => {
    !getValues('endDate')?.length
      ? setIsCurrentJob(true)
      : setIsCurrentJob(false)
  }, [getValues])

  // Set EndDate Value when isCurrentJob Change
  useEffect(() => {
    isCurrentJob
      ? setValue('endDate', '', {
          shouldDirty: true,
        })
      : resetField('endDate')
  }, [isCurrentJob, resetField, setValue])

  return (
    <>
      <InputText
        id="name"
        placeholder={
          isSponsor
            ? 'Saisissez ici votre intitulé de poste'
            : 'Saisis ici ton intitulé de poste'
        }
        label="Intitulé de poste"
        type="text"
        errorMessage={errors?.name?.message}
        autoFocus
        {...register('name', {
          required: true,
          maxLength: {
            value: 100,
            message: 'Ce champ doit contenir 100 caractères maximum.',
          },
          minLength: {
            value: 3,
            message: 'Ce champ doit contenir 3 caractères minimum.',
          },
        })}
      />

      <InputText
        id="companyName"
        placeholder={
          isSponsor
            ? "Saisissez ici le nom de l'entreprise"
            : "Saisis ici le nom de l'entreprise"
        }
        label="Nom de l'entreprise / organisme / structure"
        type="text"
        errorMessage={errors?.companyName?.message}
        {...register('companyName', {
          required: true,
          maxLength: {
            value: 100,
            message: 'Ce champ doit contenir 100 caractères maximum.',
          },
          minLength: {
            value: 3,
            message: 'Ce champ doit contenir 3 caractères minimum.',
          },
        })}
      />

      <Controller
        name="contractType"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange } }) => {
          return (
            <Box mb={5}>
              <Label mb={2}>Type de contrat</Label>
              <Select
                id="contractType"
                placeholder={
                  isSponsor
                    ? 'Sélectionnez votre type de contrat'
                    : 'Sélectionne ton type de contrat'
                }
                isSearchable={false}
                options={contractTypeOptions}
                defaultValue={getContractTypeByValue(watch('contractType'))}
                onChange={(e) => {
                  onChange(e?.value)
                }}
              />
            </Box>
          )
        }}
      />

      <Grid
        rowGap={0}
        columnGap={5}
        templateColums="repeat(auto-fit, minmax(125px, 1fr))"
      >
        <InputText
          id="startDate"
          placeholder="Ex: 01/01/2000"
          label="Date de début"
          type="text"
          errorMessage={errors?.startDate?.message}
          maxLength={10}
          minLength={10}
          {...register('startDate', {
            required: true,
            pattern: {
              value: birthdayRegex,
              message: 'Ce champ doit être au format JJ/MM/AAAA.',
            },
            validate: (value) =>
              !value?.length ||
              isPast(parseISO(value)) ||
              (value.slice(6, 10) <= format(new Date(), 'yyyy') &&
                value.slice(6, 10) >
                  format(
                    sub(new Date(), {
                      years: 99,
                    }),
                    'yyyy'
                  )),
          })}
        />

        <InputText
          id="endDate"
          placeholder="Ex: 01/01/2000"
          label="Date de fin"
          type="text"
          errorMessage={!isCurrentJob && errors?.endDate?.message}
          maxLength={10}
          minLength={10}
          disabled={isCurrentJob}
          {...register('endDate', {
            pattern: {
              value: birthdayRegex,
              message: 'Ce champ doit être au format JJ/MM/AAAA.',
            },
            validate: (value) => {
              return (
                !value?.length ||
                isPast(parseISO(value)) ||
                (value.slice(6, 10) <= format(new Date(), 'yyyy') &&
                  value.slice(6, 10) >
                    format(
                      sub(new Date(), {
                        years: 99,
                      }),
                      'yyyy'
                    ))
              )
            },
          })}
        />
      </Grid>

      <Box di="flex" fd="row" ai="end" jc="flex-start">
        <Checkbox
          ml={0}
          pr={10}
          label={<Label>Expérience en cours</Label>}
          id="current"
          small
          transparent
          checked={isCurrentJob}
          onChange={() => setIsCurrentJob(!isCurrentJob)}
        />
      </Box>
    </>
  )
}

export default ExperienceForm
