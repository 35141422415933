import styled, { css } from 'styled-components'

const MetaContainer = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: ${theme.spaces[8]};
    border-bottom: 1px solid ${theme.colors.greyLight};
    padding-bottom: ${theme.spaces[6]};
    margin-bottom: ${theme.spaces[6]};
  `}
`

export default MetaContainer
