import { Link, generatePath } from 'react-router-dom'

import { env } from '~config'
import { Box, Icon, Loader, Panel, Text } from '~design-system'
import { getRouteForNotification } from '~helpers'
import { useWeatherModal } from '~hooks'
import useNotifications from '~hooks/useNotifications'

import { IconButton } from './styles'

const NotificationCard = ({
  id,
  title,
  description,
  section,
  sectionId,
  isFetching,
}: NQTNotification & { isFetching: boolean }) => {
  const { useDeleteNotification, useMarkAsReadNotification } =
    useNotifications()
  const { mutate: deleteNotification, isLoading: isDeleting } =
    useDeleteNotification()
  const { mutate: markAsRead } = useMarkAsReadNotification()

  const matchId = section === 'matching' ? sectionId : undefined

  const modalActions = useWeatherModal(matchId)

  const getLink = () => {
    if (section === 'club') {
      return env.LEVELUP_URL + (sectionId ? `/${sectionId}` : '')
    }
    const { route, param } = getRouteForNotification(section, sectionId)
    return generatePath(route, param)
  }

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (section === 'matching') {
      e.preventDefault()
      modalActions?.setOpen(true)
    }
    markAsRead(id)
  }

  const handleDelete = () => {
    deleteNotification(id)
  }

  return (
    <Box pos="relative">
      {section !== 'club' ? (
        <Panel to={getLink()} as={Link} onClick={handleClick} noBorder pr={12}>
          <Text>{title}</Text>
          <Text textColor="greyDarkest">{description}</Text>
        </Panel>
      ) : (
        <Panel as="a" noBorder pr={12} href={getLink()}>
          <Text>{title}</Text>
          <Text textColor="greyDarkest">{description}</Text>
        </Panel>
      )}

      <IconButton
        aria-label="Marquer la notification comme lue"
        onClick={handleDelete}
        disabled={isFetching || isDeleting}
      >
        {isDeleting ? (
          <Loader size="small" />
        ) : (
          <Icon name="trash" color={isFetching ? 'grey' : 'primary'} />
        )}
      </IconButton>
      {modalActions?.renderModal()}
    </Box>
  )
}

export default NotificationCard
