import { Editable, useEditor } from '@wysimark/react'
import React from 'react'

import { Label, TinyText } from '~design-system'

type Props = {
  value: string
  label?: string
  placeholder?: string
  errorMessage?: string
  onChange: (val: string) => void
}

const MarkdownEditor = ({
  value,
  errorMessage,
  onChange,
  placeholder,
  label,
}: Props) => {
  const editor = useEditor({})

  return (
    <>
      {label && <Label mb={2}>{label}</Label>}
      <Editable
        placeholder={placeholder}
        editor={editor}
        value={value}
        onChange={onChange}
      />
      {errorMessage && (
        <TinyText textColor="danger" mt={2}>
          {errorMessage}
        </TinyText>
      )}
    </>
  )
}

export default MarkdownEditor
