import styled from 'styled-components'

import { Box } from '~design-system'

const Container = styled(Box)`
  min-width: 20rem;

  @media (max-width: 400px) {
    min-width: 10rem;
    width: 100%;
  }
`

export default Container
