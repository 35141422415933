import { Button, PopIn, Text } from '~design-system'

interface Props {
  title: string
  content: string
  onValidate: () => void
  onCancel: () => void
  isLoading: boolean
}

const ConfirmationPopin = ({
  isLoading,
  title,
  content,
  onValidate,
  onCancel,
}: Props) => (
  <PopIn open closable onClose={onCancel}>
    <PopIn.Title textAlign="left">{title}</PopIn.Title>
    <PopIn.Content>
      <Text textColor="greyDarkest" mt={10}>
        {content}
      </Text>
    </PopIn.Content>
    <PopIn.Actions
      actions={[
        <Button
          key="cancel"
          variant="secondary"
          onClick={onCancel}
          disabled={isLoading}
        >
          Non, revenir en arrière
        </Button>,
        <Button
          key="save"
          type="submit"
          onClick={onValidate}
          isLoading={isLoading}
        >
          Oui, supprimer
        </Button>,
      ]}
    />
  </PopIn>
)

export default ConfirmationPopin
