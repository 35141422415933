import { camelize } from 'humps'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { Box, Button, Icon, Label } from '~design-system'

import { getParseBodyParams } from '../helpers'

type Props = {
  onChoice: (choice: Answer) => void
  options: Answer[]
  withoutLabel?: boolean
}

const Radio = styled('input')`
  display: none;
`

const Options = ({ onChoice, options, withoutLabel = false }: Props) => {
  const { register } = useFormContext()
  return (
    <>
      {!withoutLabel && <Label mb={2}>Choisir une option</Label>}
      <Box di="f" fd="c">
        {options.map((option, index) => {
          const parsedBodyParam = getParseBodyParams(option)

          const isNotEmpty = parsedBodyParam
            ? Object.keys(parsedBodyParam).length > 0
            : null

          const value = isNotEmpty
            ? (Object.values(parsedBodyParam)[0] as string)
            : option.label

          return (
            <Button
              as="label"
              fullWidth
              htmlFor={`${value}`}
              key={`register-option-${value}-${index}`}
              mb={1}
              variant="secondary"
            >
              <Radio
                id={`${value}`}
                onClick={() => onChoice(option)}
                type="radio"
                value={value}
                {...(isNotEmpty &&
                  register(camelize(Object.keys(parsedBodyParam)[0])))}
              />
              <Box di="f" fg={1} jc="space-between">
                {option.label}
                <Icon color="primary" name="arrow-right" />
              </Box>
            </Button>
          )
        })}
      </Box>
    </>
  )
}

export default Options
