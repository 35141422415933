import styled, { css } from 'styled-components'

import { Box } from '~design-system'

const IconAvatarEdition = styled(Box)`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.primary};
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    margin-left: ${theme.spaces[12]};
    margin-top: -2.5rem;
    padding-bottom: 5px;
    width: 2.5rem;
    z-index: 1;
  `}
`

export default IconAvatarEdition
