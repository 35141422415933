import styled, { css } from 'styled-components'

import { LayoutProps, SpacingProps, layout, spacing } from '../helpers'

type Props = {
  orientation?: 'horizontal' | 'vertical'
} & LayoutProps &
  SpacingProps

const Divider = styled('div').attrs((props: Props) => ({
  orientation: props.orientation || 'horizontal',
}))<Props>`
  ${({ orientation, theme }) => css`
    background-color: ${theme.colors.blueLighter};

    ${orientation === 'horizontal' &&
    css`
      height: 1px;
      width: 100%;
    `}

    ${orientation === 'vertical' &&
    css`
      height: 100%;
      width: 1px;
    `}
    ${layout};
    ${spacing};
  `}
`

export default Divider
